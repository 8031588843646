<template>
	<div class="row" v-loading="vloading">
		<div class="col-md-12">
			<div class="main-card card">
				<div class="card-body">
					<div class="row border-b border-gray-200" v-if="showActivateSub && !showFileHandler">
						<div class="col-md-4">
							<h5 class="card-title hidden md:block">{{ $t('activateApp') }}</h5>
						</div>
						<div class="col-md-4"></div>
						<div class="col-md-4">
							<h5 class="card-title hidden md:block" v-if="showTestButton">{{
									$t('testOrder')
								}}</h5>
						</div>
					</div>
					<h5 class="card-title font-light" v-else>{{ $t('fileUpload') }}</h5><br>

					<div class="row" v-if="showActivateSub && !showFileHandler">
						<h5 class="card-title block md:hidden ml-3">{{ $t('activateApp') }}</h5>
						<div class="flex space-x-3"
							 :class="{'col-md-4': showTestButton === true,'col-md-6': showTestButton=== false}">
							<button v-if='subscriptionDeactivate' :disabled='deactivating'
									@click="deactivateSubscription"
									class="v-app-7 c-btn btn-shadow mb-3 deactivate-btn text-white px-6 text-white text-xl py-2 rounded shadow-xl">
								<div class="flex items-center m-auto" v-if="deactivating">
									<svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
										 xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
										<circle class="opacity-25" cx="12" cy="12" r="10"
												stroke="currentColor" stroke-width="4"></circle>
										<path class="opacity-75" fill="currentColor"
											  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
									</svg>
									{{ $t('deactivating') }}
								</div>
								<span v-else>{{ $t('deactivate') }}</span>
							</button>
							<button v-if='subscriptionActivate' :disabled='activating'
									@click="activateSubscription"
									class="v-app-7 c-btn btn-activate mb-3 activate-btn px-10 text-white text-xl py-2 rounded shadow-xl">
								<div class="flex items-center m-auto" v-if="activating">
									<svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
										 xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
										<circle class="opacity-25" cx="12" cy="12" r="10"
												stroke="currentColor" stroke-width="4"></circle>
										<path class="opacity-75" fill="currentColor"
											  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
									</svg>
									{{ $t('activating') }}
								</div>
								<span v-else>{{ $t('activateNow') }}</span>
							</button>

						</div>

						<div class="col-md-4 mt-1" v-if="showTestButton">
							<h5 class="card-title block md:hidden">{{ $t('testOrder') }}</h5>
							<button @click="performTestSubscription"
									class="v-app-6 c-btn btn-test test-btn px-12 text-white text-xl py-2 rounded shadow-xl"
									:disabled='runningTest'>
								<!--btn btn-custom btn-slider-b btn-wide btn-lg px-5 shadow-2xl-->
								<div class="flex items-center m-auto" v-if="runningTest">
									<svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
										 xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
										<circle class="opacity-25" cx="12" cy="12" r="10"
												stroke="currentColor" stroke-width="4"></circle>
										<path class="opacity-75" fill="currentColor"
											  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
									</svg>
									{{ $t('testing') }}
								</div>
								<span v-else>{{ $t('testSend') }}</span>
							</button>
						</div>
						<div
							:class="{'col-md-4': showTestButton === true,'col-md-6': showTestButton=== false}"
							v-if="currentSubscription">
							<button v-on:click="openCancelSubscription"
									v-if="cancelledSubscriptionFlag === true"
									class="v-app-7 c-btn mb-3 btn-shadow cancel-btn px-10 text-white text-xl py-2 rounded shadow-xl float-right">
								<div class="flex items-center m-auto" v-if="activating">
									<svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
										 xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
										<circle class="opacity-25" cx="12" cy="12" r="10"
												stroke="currentColor" stroke-width="4"></circle>
										<path class="opacity-75" fill="currentColor"
											  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
									</svg>
									{{ $t('activating') }}
								</div>
								<span v-else>Cancellera integration</span>
							</button>
						</div>
					</div>
					<div class="mt-1" v-if="showFileHandler">
						<form @submit.prevent="uploadSwishFile">
							<!-- <h2 class="text-gray-400 text-base font-light">Ladda upp csv fil för inrapportering till Fortnox</h2> -->
							<div class="form-group flex">
								<label
									:class="fileAdded ? 'bg-gray-400 px-2 py-1 rounded cursor-pointer' : 'custom-file-upload'">
									<input type="file" class="inputfile form-control-file"
										   id="swish-file-upload" @change="fileChange"
										   aria-label="Swish Handel File" required
										   accept="application/pdf, text/csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet">
									<i class="iconly-brokenUpload"></i> {{ $t('chooseFile') }}
								</label>
								<span v-if="fileAdded" class="ml-3">
                                                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-green-500"
													 viewBox="0 0 20 20" fill="currentColor">
                                                <path fill-rule="evenodd"
													  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
													  clip-rule="evenodd"/>
                                                </svg>
                                            </span>
							</div>
							<button type="submit" :disabled='uploading'
									class="btn btn-theme-custom hover:text-white btn-wide btn-lg mt-2">
								<div class="flex items-center m-auto" v-if="uploading">
									<svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
										 xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
										<circle class="opacity-25" cx="12" cy="12" r="10"
												stroke="currentColor" stroke-width="4"></circle>
										<path class="opacity-75" fill="currentColor"
											  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
									</svg>
									{{ $t('uploading') }}
								</div>
								<span v-else>{{ $t('fileUpload') }}</span>
							</button>
						</form>
					</div>
				</div>
				<div class="row">
					<div class="col-md-12 p-4">
						<div class="float-right">
							<button
								class="c-btn btn-next-white btn text-sm font-black btn-lg border btn-border-all text-blue-700 hover:text-blue-900"
								@click="openTab(2)">{{ $t('settingPrevious') }}
							</button>
							<!-- <button class="btn btn-slider-b" @click="openTab(3)">Continue</button> -->
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {mapGetters} from 'vuex'
import {
	APPID as APP,

	SHOW_FILE_UPLOADER,
	SKU_VALIDATOR,
	TEST_ORDER_BUTTON,
} from './../../../app/index'
import bus from "../../../bus";

export default {
	name: "appSettingsActivateSection",
	props: {
		subscription: {
			required: true
		},
		apptype: {
			required: true
		},
		accounting: {
			required: true
		},
	},
	data: function () {
		return {
			uploading: false,
			fileAdded: false,
			showFileHandler: false,
			showActivateSub: false,
			showTestButton: false,
			deactivating: false,
			subscriptionDeactivate: false,
			subscriptionActivate: false,
			activating: false,
			vloading: false,
			runningTest: false,
			cancelledSubscriptionFlag: false,
			currentSubscription: {},

		}
	},
	methods: {
		openTab: function (tab) {
			window.Bus.$emit('open-settings-tab', parseInt(tab))
		},
		uploadSwishFile() {
			let URL
			const file = document.getElementById('swish-file-upload').files
			if (file.length < 1) {
				this.$services.helpers.notification(this.$t('fileRequired'), 'error', this)
			} else {
				this.uploading = true
				const formData = new FormData()
				if (parseInt(this.apptype) === APP.ADYEN_PLUS) {
					URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.ADYEN_PLUS_FILE + `${this.subscription}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.ADYEN_PLUS_FILE + `${this.subscription}/`
					formData.append('adyen_file', file[0])
				}
				if (parseInt(this.apptype) === APP.SWISH_HANDEL) {
					URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.SWISH_HANDEL_FILE + `${this.subscription}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.SWISH_HANDEL_FILE + `${this.subscription}/`
					formData.append('swish_file', file[0])
				}
				window.Bus.$emit('upload-setting-file', {URL, formData, app: this.apptype})
			}
		},
		getSubscriptionConfig() {
			this.vloading = true
			const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.GET_USER_APP_CONFIG + `${this.subscription}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.GET_USER_APP_CONFIG + `${this.subscription}/`
			this.$store.dispatch('customer/getSubscriptionConfig', {URL})
				.then(res => {
					this.showForm = false
					this.vloading = false
					this.showActivateSub = true
					if (res.data.success) {
						this.currentSubscription = res.data.data.subscription
						this.subscriptionDeactivate = true
						this.subscriptionActivate = false
						// checking fort-nox subscription token
						this.cancelledSubscriptionFlag = this.currentSubscription.sub_status
						if (this.currentSubscription.sub_status === true) {
							this.subscriptionDeactivate = true
							this.subscriptionActivate = false
						} else {
							this.subscriptionDeactivate = false
							this.subscriptionActivate = true
						}


					}
				}).catch((err) => {
				this.vloading = false
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								this.getSubscriptionConfig()
							})
							.catch(_ => {
								// console.log('here')
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		activateSubscription() {
			window.Bus.$emit('open-activate-modal', {'subscription': this.currentSubscription})
		},

		deactivateSubscription() {
			const {key} = this.$route.query
			if (key) {
				this.deactivating = true
				const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.ACTIVATE_SUBSCRIPTION + `${key}/0/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.ACTIVATE_SUBSCRIPTION + `${key}/0/`
				this.$store.dispatch('customer/deactivateSubscription', {URL})
					.then(res => {
						this.deactivating = false
						if (res.status === 200) {
							this.$services.helpers.notification(this.$t('deactivatedSubscription'), 'success', this)
							this.getSubscriptionConfig()
							this.subscriptionDeactivate = false
							this.subscriptionActivate = true
						}
					}).catch((err) => {
					this.deactivating = false
					if (err.response.status === 400) {
						if (err.response.data.code === 401) {
							this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
								.then(_ => {
									// this.getSubscriptionConfig()
								})
								.catch(_ => {
									// console.log('here')
									this.$store.commit('auth/CLEAR_AUTH_USER', null)
									window.Bus.$emit('sign-out')
								})
						}
					}
				})
			}
		},
		openCancelSubscription() {
			window.Bus.$emit('open-cancel-subscription', {
				"key": this.subscription,
				"type": this.apptype,
				"acc": this.accounting,
				"server": 'standard'
			})

		},
		performTestSubscription() {
			this.runningTest = true
			const payload = {
				URL: this.GET_USER_COMPANY_ID ? this.$services.endpoints.TEST_SUBSCRIPTION + `${this.subscription}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.TEST_SUBSCRIPTION + `${this.subscription}/`
			}

			bus.$emit('perform-single-test', payload)
		},
		fileChange(e) {
			const self = this
			const docType = ['pdf', 'csv', 'vnd.ms-excel', 'vnd.openxmlformats-officedocument.spreadsheetml.sheet']
			const fileType = e.target.files[0].type.split('/')[1]

			if (!docType.includes(fileType)) {
				self.$services.helpers.notification(this.$t('invalidFile'), 'error', this)
				document.getElementById('swish-file-upload').value = ''
			} else {
				this.fileAdded = true
			}
		},
		showFileUploader() {
			if (SHOW_FILE_UPLOADER.includes(parseInt(this.apptype))) {
				this.showFileHandler = true
			} else {
				this.showFileHandler = false
			}
		},
		showTestOrderButton() {
			if (TEST_ORDER_BUTTON.includes(parseInt(this.apptype))) {
				this.showTestButton = true
			} else {
				this.showTestButton = false
			}
		},
		clearFileField() {
			document.getElementById('swish-file-upload').value = ''
			this.fileAdded = false
		},
		activateSubAfterSku() {
			const {key} = this.$route.query
			if (key) {
				this.activating = true
				const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.ACTIVATE_SUBSCRIPTION + `${key}/1/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.ACTIVATE_SUBSCRIPTION + `${key}/1/`
				this.$store.dispatch('customer/activateSubscription', {URL})
					.then(res => {
						this.activating = false
						if (res.status === 200) {
							this.$services.helpers.notification(this.$t('activatedSubscription'), 'success', this)
							this.getSubscriptionConfig()
							this.subscriptionDeactivate = true
							this.subscriptionActivate = false
						}
					}).catch((err) => {
					this.activating = false
					if (err.response.status === 400) {
						if (err.response.data.code === 401) {
							this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
								.then(_ => {
									// this.getSubscriptionConfig()
								})
								.catch(_ => {
									// console.log('here')
									this.$store.commit('auth/CLEAR_AUTH_USER', null)
									window.Bus.$emit('sign-out')
								})
						}
					}
				})
			}
		},
		openActivate: function () {
			window.Bus.$emit('open-activate-modal', {'subscription': this.currentSubscription})
		}
	},
	computed: {
		...mapGetters({
			USER_TOKEN: 'auth/USER_TOKEN',
			USER_REFRESH: 'auth/USER_REFRESH',
			GET_LOGIN_CREDENTIAL: 'auth/GET_LOGIN_CREDENTIAL',
			GET_USER_COMPANY_ID: 'customer/GET_USER_COMPANY_ID',
			GET_COMPANY_BILLING: 'customer/GET_COMPANY_BILLING',
			GET_SUBSCRIPTION: 'customer/GET_SUBSCRIPTION',
		})
	},
	mounted() {
		this.getSubscriptionConfig()
		this.showFileUploader()
		this.showTestOrderButton()
		window.Bus.$on('reload-current-subscription', e => {
			this.cancelledSubscriptionFlag = false
			this.getSubscriptionConfig()

		})
		window.Bus.$on('finished-upload', _ => {
			this.uploading = false
			this.clearFileField()
		})
		window.Bus.$on('finished-test', _ => {
			this.runningTest = false
		})

		window.Bus.$on('finished-sku-test', _ => {
			this.activating = false
			this.subscriptionDeactivate = true
			this.subscriptionActivate = false
			this.activateSubAfterSku()
		})
		window.Bus.$on('finished-sku-test-failed', _ => {
			this.activating = false
		})
		window.Bus.$on('reload-app-subscription-after-activate', e => {
			this.subscriptionDeactivate = true
			this.subscriptionActivate = false
			this.getSubscriptionConfig()
		})
	}
}
</script>

<style lang="scss" scoped>
@import "./../../../assets/variables.scss";

.align-box-center {
	width: 80%;
	margin: 0 auto;
}

.font-read {
	font-size: 1.6rem;
	background: #BB3333;
	color: #fff;
	font-weight: 800;
	border-radius: 5px;
	padding: 0.2rem;
}

.font-configure {
	font-size: 1.6rem;
	background: #1A9DD6;
	color: #fff;
	font-weight: 800;
	border-radius: 5px;
	padding: 0.2rem;
}

.font-activate {
	font-size: 1.6rem;
	background: #2FC058;
	color: #fff;
	font-weight: 800;
	border-radius: 5px;
	padding: 0.2rem;
}

.app-theme-gray .main-card > .card-body > .card-title::before {
	background: unset !important;
}

.btn-slider-b {
	color: #fff;
	background-color: #13c2c2;
	border-color: #13c2c2;
}

.tabs-animated .nav-link::before {
	background: #13c2c2 !important;
}

.override-form-ca {
	padding-bottom: 0.2rem !important;
	padding-top: 0.2rem !important;
	font-size: unset !important;
}

.override-form-numbers {
	padding-bottom: 0rem !important;
	padding-top: 0.2rem !important;
	font-size: unset !important;
	height: calc(1.75rem + 2px) !important;
}

// .form-control:focus{
//     // color: #495057;
//     // background-color: #fff;
//     // border-color: #e2e8f0 !important;
//     // outline: 0;
//     // box-shadow: unset !important;
// }
.input-group-append {
	height: calc(1.75rem + 2px);
}

.border-b-theme {
	border-bottom: 5px solid $form-border;
	border-bottom-left-radius: 2px;
	border-bottom-right-radius: 2px;
	// border: 1px solid $form-border !important;
}

.theme-color-tab {
	color: $form-border;
}

.theme-color-sec {
	color: $theme-primary-color !important;
	font-weight: 900;
}

.deep-b-theme {
	border-bottom: 5px solid $theme-primary-color !important;
}

.btn-border-all {
	border: 1px solid $theme-secondary-color !important;
	// color: $theme-secondary-color !important;
}

.btn-full-theme {
	background: $theme-secondary-color !important;
}

.pad-overi {
	padding: .175rem .75rem !important;
}

.form-select, .form-input {
	border: 1px solid $form-border !important;
	height: unset !important;
	padding: .175rem .75rem !important;
}

.form-control:focus {
	box-shadow: unset !important;
	border: 1px solid $theme-primary-color !important;
}

.form-control {
	border: 1px solid $form-border !important;
}

input[type="file"] {
	display: none;
}

.custom-file-upload {
	// border: 1px solid #ccc;
	color: white;
	border-radius: 5px;
	background-color: $theme-primary-color;
	display: inline-block;
	padding: 6px 12px;
	cursor: pointer;
}

table th, .table td {
	padding: 0.2rem !important;
}

.disable-btn {
	background-color: #800080 !important;
	color: #ffff !important;
}

.w-f-100 {
	width: 100% !important;
}

.test-btn {
	background-color: #EE4D81;
	// border-color: #EE4D81;
}

.activate-btn {
	background-color: #0DB23C;
}

.deactivate-btn {
	background-color: #583EF9;
}

.table-responsive::-webkit-scrollbar {
	display: none !important; /* width of the entire scrollbar */
}

.table-responsive::-webkit-scrollbar-track {
	background: $form-border; /* color of the tracking area */
}

.table th, .table td {
	border-top: unset !important;
}

.cancel-btn, .cancel-btn:hover {
	background: #10a4c2 !important;
	color: #ffffff
}

.c-btn {
	font-size: 15px !important;
}

.app-theme-gray .main-card {
	box-shadow: 0 0 0 0 transparent !important;
}

.pl-1, .px-1 {
	padding-left: 0.25rem !important;
}

.bg-white {
	background-color: #fff !important;
}

.color-text {
	color: #61696d !important;
	font-weight: 300 !important;
}
</style>
