<template>
	<div>
		<span
			class="block text-gray-500 text-base pl-3 mt-3">{{
				$t('accountSetting')
			}} <span class="ml-1 tooltip-settings"><i
				class="text-sm help-color fa fa-question-circle"></i><span
				class="w-80 tooltiptext text-xs shadow-sm border word-breaking">Här har ni möjlighet att mappa specifika länder och/eller valutor med specifika konton. Ni kan också ange specifika konton för specifika omständigheter.</span></span></span>
		<div class="border col-md-11 rounded mb-8 mt-1 p-2">
			<div class="row">
				<div class="col-md-12 rounded md:pr-4 p-3 mb-3">
					<form class="flex flex-col"
						  @submit.prevent="addCustomAccount">
						<div class="row flex flex-col md:flex-row md:pr-3">
							<div class="col-sm-3 optional-config-form">
								<label
									class="text-gray-600 font-semibold">Kontotyp
									<span class="ml-1 tooltip-settings"><i
										class="text-sm help-color fa fa-question-circle cursor-pointer"></i><span
										class="w-60 tooltiptext text-xs shadow-sm border word-breaking">Ange vilken typ av konto ni vill mappa (t.ex. intäktskonto eller utbetalningskonto)</span></span></label><br>
								<select v-model="accType"
										@change="onChangeAccountType"
										id="konto-type"
										class="form-select override-form-ca w-full"
										required>
									<option :value="Object.keys(type)[0]"
											v-for="(type, i) in accountType"
											:key="i">{{
											Object.values(type)[0]
										}}
									</option>
								</select>
							</div>
							<div class="col-sm-3 optional-config-form">
								<label for="Matchningstyp"
									   class="text-gray-600 font-semibold">Matchningstyp
									<span class="ml-1 tooltip-settings"><i
										class="text-sm help-color fa fa-question-circle"></i><span
										class="w-60 tooltiptext text-xs shadow-sm border word-breaking">Här anger ni om ni vill att matchningen avgörs per land eller per valuta.</span></span></label><br>
								<select :value="matchTyp" id="match-type"
										@change="switchType"
										class="form-select override-form-ca w-full"
										required>
									<option :value="Object.keys(type)[0]"
											v-for="(type, i) in matchType"
											:key="i">{{
											Object.values(type)[0]
										}}
									</option>
								</select>
							</div>
							<div class="col-sm-3">
								<label for="Landskod"
									   class="text-gray-600 font-semibold"
									   v-if="parseInt(matchTyp) === 1">Landskod
									<span class="ml-1 tooltip-settings"><i
										class="text-sm help-color fa fa-question-circle"></i><span
										class="w-60 tooltiptext text-xs shadow-sm border word-breaking">Ange landet ni vill mappa till kontot i fråga.</span></span></label>
								<label for="Valuta"
									   class="text-gray-600 font-semibold"
									   v-else>Valuta <span
									class="ml-1 tooltip-settings"><i
									class="text-sm help-color fa fa-question-circle"></i><span
									class="w-60 tooltiptext text-xs shadow-sm border word-breaking">Ange valutan ni vill mappa till kontot i fråga.</span></span></label><br>
								<div>
									<div v-show="showCountry"
										 class="optional-config-form">
										<select v-model="matchCode"
												id="country-select"

												class="form-select select-field override-form-ca w-full">
											<option :value="country[0]"
													v-for="(country, i) in countries"
													:key="i">{{ country[1] }}
											</option>
										</select>
									</div>
									<div v-show="!showCountry"
										 class="optional-config-form">
										<select v-model="matchCode"
												id="currency-select"
												class="form-select select-field override-form-ca w-full">
											<option :value="currency"
													v-for="(currency, i) in allCurrencies"
													:key="i">{{ currency }}
											</option>
										</select>
									</div>
								</div>


							</div>
							<div class="col-sm-3 optional-config-form">
								<label for="kontotyp"
									   class="text-gray-600 font-semibold">Konto#
									<span class="ml-1 tooltip-settings"><i
										class="text-sm help-color fa fa-question-circle"></i><span
										class="w-60 tooltiptext text-xs shadow-sm border word-breaking">Här anger ni kontonumret på det konto ni försöker lägga upp</span></span></label><br>
								<div
									class="flex form-group optional-config-form">
									<select v-model="acctNumber"
											v-if="[0,1].includes(parseInt($route.query.acc)) === true"
											class="form-select override-form-ca w-full"
											id="account-numbers-select">
										<option selected disabled></option>
										<option :value="account.Number"
												v-for="account in accountNumbers"
												:key="uid(account)">
											{{
												account.Number.length > 6 ? null : '(' + account.Number
													+ ') '
											}}
											{{ account.Description }}
										</option>
									</select>
									<input type="text" v-model="acctNumber"
										   class="border-pink-500 rounded border px-2 form-input w-full h-8 text-xs"
										   style="height: 32px !important;" v-else/>
								</div>

							</div>
							<div class="col-sm-3"
								 v-if="hideReconciliationField">
								<label
									class="text-gray-600 font-semibold">{{
										$t('ruleType')
									}} <span class="ml-1 tooltip-settings"><i
										class="text-sm help-color fa fa-question-circle"></i><span
										class="w-60 tooltiptext text-xs shadow-sm border word-breaking">Här har ni möjlighet att ange om detta konto ska användas för något specifikt, så som frakt eller företagskunder</span></span></label><br>
								<select v-model="ruleType" id="konto-type"
										class="form-select override-form-ca w-full">
									<option value="company">{{
											$t('forCompany')
										}}
									</option>
									<option value="shipping">
										{{ $t('forShipping') }}
									</option>
									<option value="feelines">
										{{ $t('forFeeLines') }}
									</option>
									<option value="gift_card">
										{{ $t('forGiftCard') }}
									</option>
									<option value="discount">
										{{ $t('forDiscount') }}
									</option>
								</select>
							</div>
							<div class="col-sm-3 optional-config-form"
								 v-if="parseInt(accType) === 1 || parseInt(accType) === 3">
								<label
									class="text-gray-600 font-semibold"> {{ $t('vat') }} <span
									class="ml-1 tooltip-settings"><i
									class="text-sm help-color fa fa-question-circle"></i><span
									class="w-60 tooltiptext text-xs shadow-sm border word-breaking">Vat</span></span></label><br>
								<select v-model="vatValue" id="vat-type-value"
										class="form-select override-form-ca w-full">
									<option :value="`${item.vat}-${item.code}`"
											v-for="(item, i) in vatRates"
											:key="i">{{ item.country + `: ${item.vat}%` }}
									</option>
								</select>
							</div>
							<div class="col-sm-1">
								<label class="mb-3 mt-1"></label><br>
								<button
									class="hover:text-white text-xs btn-custom pad-overi h-8 p-3 rounded cursor-pointer"
									:disabled='addingCustomAccount'>
                                                        <span class="relative bottom-2.5 left-2.5"
															  v-if="addingCustomAccount">
                                                            <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
																 xmlns="http://www.w3.org/2000/svg" fill="none"
																 viewBox="0 0 24 24">
                                                            <circle class="opacity-25" cx="12" cy="12" r="10"
																	stroke="currentColor" stroke-width="4"></circle>
                                                            <path class="opacity-75" fill="currentColor"
																  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                            </svg>
                                                        </span>
									<span v-else class="text-xs relative bottom-2.5" style="left: 1px">
                                                            Spara
                                                        </span>
								</button>
							</div>

						</div>
						<!-- <div class="flex flex-col md:flex-row align-items-center md:pr-3">
							<div class="col-sm-3 optional-config-form">
								<label for="company" class="text-gray-600 font-semibold">{{ $t('forCompany') }} <span class="ml-1"><i class="text-sm help-color fa fa-question-circle"></i><span class="w-60 tooltiptext text-xs shadow-sm border word-breaking"></span></span></label><br>
								<input type="checkbox" v-model="forCompany" class="form-control h-4 checkbox-height">
							</div>
							<div class="col-sm-3 optional-config-form">
								<label for="shipping" class="text-gray-600 font-semibold">{{ $t('forShipping') }} <span class="ml-1"><i class="text-sm help-color fa fa-question-circle"></i><span class="w-60 tooltiptext text-xs shadow-sm border word-breaking"></span></span></label><br>
								<input type="checkbox" v-model="forShipping" class="form-control h-4 checkbox-height">
							</div>
							<div class="col-sm-3 optional-config-form mb-1">
								<label for="feelines" class="text-gray-600 font-semibold">{{ $t('forFeeLines') }} <span class="ml-1"><i class="text-sm help-color fa fa-question-circle"></i><span class="w-60 tooltiptext text-xs shadow-sm border word-breaking"></span></span></label><br>
								<input type="checkbox" v-model="forFeeLines" class="form-control h-4 checkbox-height">
							</div>
							<div class="col-sm-3 optional-config-form">
								<button class="btn hover:text-white text-base btn-custom pad-overi w-full" :disabled='addingCustomAccount'>
									<span v-if="addingCustomAccount">
										<svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
										<circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
										<path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
										</svg>
									</span>
									<span v-else>
										Spara
									</span>
								</button>
							</div>
						</div> -->
					</form>
				</div>
				<div class="col-md-10 p-3">
					<div class="table-responsive">
						<table style="width: 100%;" id="exampledestroy"
							   class="table">
							<thead class="hidden">
							<tr>
								<th class="text-xs">Kontotyp</th>
								<th class="text-xs">Matchningsfält</th>
								<th class="text-xs">Land/Valutatyp</th>
								<th class="text-xs">Konto#</th>
								<th class="text-xs"></th>
								<th class="text-xs">Action</th>
							</tr>
							</thead>
							<tbody>
							<tr v-for="(account,key) in customAccounts"
								:key="key">
								<td class="text-xs">
                                                            <span>
                                                               {{ getType(accountType, account.acct_type) }}
                                                            </span>
								</td>
								<td class="text-xs">
                                                            <span>
                                                                {{ getMatchFormType(matchType, account.match_type) }}
                                                            </span>
								</td>
								<td class="text-xs">
														<span
															v-if="account.match_code && account.match_code.length > 2">{{
																account.match_code
															}}</span>
									<span v-else>{{
											filterAccountMatchCode(account.match_code)
										}}</span>
								</td>
								<td class="text-xs">
									<span v-if="[0,1].includes(parseInt($route.query.acc))">{{
											filterAccountNumberMatch(account.acct_no)
										}}</span>
									<span v-else>
										{{ account.acct_no }}
									</span>
								</td>
								<td class="text-xs">
                                                            <span v-if="account.hasOwnProperty('for_shipping')">
                                                                <span v-if="account.for_shipping">
                                                                    {{
																		$t('forShipping')
																	}}
                                                                <br>
                                                                </span>
                                                                <span v-if="account.for_company">
                                                                    {{
																		$t('forCompany')
																	}}
                                                                <br>
                                                                </span>
                                                                <span v-if="account.for_fee_lines">
                                                                    {{
																		$t('forFeeLines')
																	}}
                                                                </span>

                                                                <span v-if="account.for_discount">
                                                                    {{
																		$t('forDiscount')
																	}}
                                                                </span>
                                                                <span v-if="account.for_gift_cards">
                                                                    {{
																		$t('forGiftCard')
																	}}
                                                                </span>
                                                            </span>
									<!-- <span></span> -->
								</td>
								<td class="text-xs">
                                                            <span>
																{{ account.vat_rate }}
															</span>
									<!-- <span></span> -->
								</td>
								<td class="text-xs">
														<span @click="editCustomAccount(account)"
															  class="text-base cursor-pointer iconly-brokenEdit icon-color mr-2"></span>
									<span @click="deleteCustomAccount(account.id)"
										  class="text-base cursor-pointer iconly-brokenDelete icon-color"></span>
								</td>
							</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {cleanVatValues} from "../../../services/constants/vatConstant";
import {ACCOUNT_TYPE, CUSTOM_V3_APPS_VISMA, RECONCILIATION_APPS} from "../../../app/index";
import bus from "../../../bus";
import {checkIfExist, getDataLocalStorage, saveDataLocalStorage} from "../../../services/storage-window";
import {mapGetters} from "vuex";

export default {
	name: "accountSettingMapping",
	props: {
		accounting: {
			required: false
		},
		subID: {
			required: false
		},
		appId: {
			required: false
		}
	},
	data: function () {
		return {
			accountTypeList: [{1: 'Moms konto'},
				{2: 'Utbetalningskonto'},
				{3: 'Intäktskonto'},
				{4: 'leverantörsreskontra'},
				{5: 'Kundfordringar'},
				{6: 'Periodiseringskonto Intäkt'},
				{7: 'Periodiserad debet konto'},
				{8: 'Periodiserad kredit konto'},
				{9: 'Leverantörskonto'},
				{10: 'Konto betalväxel avgift'},
				{11: 'Monmskonto för betalväxel avgift'},
				{12: 'Utbetalningskonto Bank'},
				{13: 'Omvänd moms på betalväxel avgift - Debet'},
				{14: 'Omvänd moms på betalväxel avgift - Kredit'},
				{15: 'Specific Fee Account'}],
			accType: '',
			matchTyp: '',
			matchCode: '',
			acctNumber: '',
			ruleType: '',
			vatValue: '',
			accountType: [],
			matchType: [],
			countries: [],
			allCurrencies: [],
			vatRates: cleanVatValues(),
			accountNumbers: [],
			customAccounts: [],
			showAllCountries: false,
			showCountry: false,
			hideReconciliationField: false,
			addingCustomAccount: false,
			reconciliatonAccountList: {
				'payout': {
					acct_type: 2,
					acct_no: '',
					match_type: 2,
					match_code: 'SEK',
					id: null
				},
				'payout_account_bank': {
					acct_type: 12,
					acct_no: '',
					match_type: 2,
					match_code: 'SEK',
					id: null
				},
				'provider_fee_account': {
					acct_type: 10,
					acct_no: '',
					match_type: 2,
					match_code: 'SEK',
					id: null
				},
				'fee_vat_acct_debit': {
					acct_type: 13,
					acct_no: '',
					match_type: 2,
					match_code: 'SEK',
					id: null
				},
				'fee_vat_acct_credit': {
					acct_type: 14,
					acct_no: '',
					match_type: 2,
					match_code: 'SEK',
					id: null
				},
			},
			proAccountList: {
				'vat_acct': {
					acct_type: 1,
					acct_no: '',
					match_type: 1,
					match_code: 'SE',
					id: null
				},
				'receivable_acct': {
					acct_type: 5,
					acct_no: '',
					match_type: 1,
					match_code: 'SEK',
					id: null
				},
				'sales_account': {
					acct_type: 3,
					acct_no: '',
					match_type: 1,
					match_code: 'SEK',
					id: null
				},
			},
			voucherAccountList: {
				'vat_acct': {
					acct_type: 1,
					acct_no: '',
					match_type: 1,
					match_code: 'SE',
					id: null
				},
				'payout': {
					acct_type: 5,
					acct_no: '',
					match_type: 1,
					match_code: 'SEK',
					id: null
				},
				'sales_account': {
					acct_type: 3,
					acct_no: '',
					match_type: 1,
					match_code: 'SEK',
					id: null
				},
			},
		}
	},
	methods: {
		getType(arrayValue, num) {
			if (this.accountTypeList.length > 0) {
				const type = this.accountTypeList.filter(item => parseInt(Object.keys(item)[0]) === parseInt(num))[0]
				if (type) {
					return Object.values(type)[0]
				}
			}
		},
		getMatchFormType(arrayValue, num) {
			if (arrayValue.length > 0) {
				const type = arrayValue.filter(item => parseInt(Object.keys(item)[0]) === parseInt(num))[0]
				if (type) {
					return Object.values(type)[0]
				}
			}
		},
		filterAccountMatchCode(matchCode) {
			const filter = this.countries.filter(item => item[0] === matchCode)
			if (filter.length > 0) {
				return filter[0][1]
			} else {
				if (matchCode === 'WO') {
					return 'Export'
				} else {
					return matchCode
				}
			}
		},
		filterAccountNumberMatch(number) {
			if (parseInt(this.$route.query.acc) === ACCOUNT_TYPE.VISMA) {
				const filter = this.accountNumbers.filter(item => item.Number === number)
				if (filter.length > 0) {
					return filter[0].Description
				}
			} else {
				return number
			}
		},
		uid(e) {
			if (e.uid) return e.uid
			const key = Math.random()
				.toString(16)
				.slice(2)
			this.$set(e, 'uid', key)
			return e.uid
		},
		getMatchType() {
			this.$store.dispatch('customer/getMatchType', {URL: this.$services.endpoints.GET_MATCH_TYPE})
				.then(res => {
					if (res.status === 200) {
						this.matchType = res.data.data
						this.matchTypeCopy = res.data.data
					}
				}).catch((err) => {
				// this.showCustomAccount = false
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								// this.getMatchType()
							})
							.catch(_ => {
								// console.log('here')
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		addCustomAccount() {
			const {type, key} = this.$route.query
			if (this.accType === '' || this.matchTyp === '' || this.matchCode === '') {
				this.$services.helpers.notification(this.$t('fieldRequired'), 'error', this)
			} else if (this.acctNumber === '') {
				this.$services.helpers.notification(this.$t('accountNumberRequired'), 'error', this)
			} else {
				this.addingCustomAccount = true
				const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.CREATE_CUSTOM_ACCOUNT + `${this.$route.query.key}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.CREATE_CUSTOM_ACCOUNT + `${this.$route.query.key}/`
				const payload = {
					subscription: type,
					acct_type: this.accType,
					match_type: this.matchTyp,
					match_code: this.matchCode,
					// vat_rate : this.vatCode,
					acct_no: this.acctNumber
				}

				if (parseInt(this.vatValue)) {
					// calculate vatcentage
					let val = this.vatValue.split('-')[0]
					payload.vat_rate = parseFloat(val / 100).toFixed(2)
				}

				if (!RECONCILIATION_APPS.includes(parseInt(this.appType))) {
					if (this.ruleType !== null) {
						this.forCompany = this.ruleType === 'company'
						this.forShipping = this.ruleType === 'shipping'
						this.forFeeLines = this.ruleType === 'feelines'
						this.forDiscount = this.ruleType === 'discount'
						this.forGiftCard = this.ruleType === 'gift_card'
					}

					payload.for_company = this.forCompany
					payload.for_shipping = this.forShipping
					payload.for_fee_lines = this.forFeeLines
					payload.for_gift_cards = this.forGiftCard
					payload.for_discount = this.forDiscount
				}


				this.$store.dispatch('customer/addCustomAccount', {data: payload, URL})
					.then(res => {
						this.addingCustomAccount = false
						// console.log(res)
						if (res.status === 200) {
							this.clearFormField()
							this.$services.helpers.notification(this.$t('createdCustom'), 'success', this)
							this.getCustomAccounts(key)
						}
					}).catch((err) => {
					this.addingCustomAccount = false
					if (err.response.status === 400) {
						if (err.response.data.code === 401) {
							this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
								.then(_ => {

								})
								.catch(_ => {
									// console.log('here')
									this.$store.commit('auth/CLEAR_AUTH_USER', null)
									window.Bus.$emit('sign-out')
								})
						}
						this.$services.helpers.notification(err.response.data, 'error', this)
					}
				})

			}
		},
		editCustomAccount: function (account) {
			window.Bus.$emit('open-custom-account-edit', {
				account: account,
				accountNumbers: this.accountNumbers,
				currencies: this.allCurrencies,
				countries: this.countries,
				accountType: this.accountType,
				matchType: this.matchTypeCopy
			})
		},
		deleteCustomAccount(customAcId) {
			const {key} = this.$route.query
			const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.DELETE_CUSTOM_ACCOUNT + `${this.$route.query.key}/${customAcId}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.DELETE_CUSTOM_ACCOUNT + `${this.$route.query.key}/${customAcId}/`

			this.$store.dispatch('customer/deleteCustomAccount', {URL})
				.then(res => {
					bus.$emit('hide-notify-me', '')
					// console.log(res)
					if (res.status === 204) {
						this.getCustomAccounts(key)
						this.$services.helpers.notification(this.$t('deletedCustom'), 'success', this)
					}
				}).catch((err) => {
				bus.$emit('hide-notify-me', '')
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {

							})
							.catch(_ => {
								// console.log('here')
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})

		},
		onChangeAccountType(e) {
			let self = this
			let onlySekCountryCode = [14, 13, 11]
			if ([3].includes(parseInt(e.target.value))) {
				this.matchType = this.matchTypeCopy
			} else {
				// show only  currency (valuta)
				let code = []
				for (var j = 0; j < this.matchTypeCopy.length; j++) {
					let mtype = this.matchTypeCopy[j]
					if (parseInt(Object.keys(mtype)[0]) === 2) {
						code.push(mtype)
					}
				}
				this.showCountry = false
				this.matchType = code
			}
			window.$('#currency-select').select2('destroy')
			if (onlySekCountryCode.includes(parseInt(e.target.value))) {
				this.allCurrencies = ['SEK']
				this.matchCode = 'SEK'
			} else {

				this.allCurrencies = this.$services.helpers.allCurrencies()
			}
			if ([10, 11, 12, 2, 14, 13].includes(parseInt(e.target.value))) {
				this.matchTyp = 2
				this.showCountry = false
			} else {
				this.matchTyp = 1
				this.matchCode = ''
				this.showCountry = true
			}
			document.getElementById('match-type').attributes
			if (parseInt(this.accType) === 1 || parseInt(this.accType) === 3) {
				setTimeout(function () {
					window.$('#vat-type-value').select2()
					window.$('#vat-type-value').on("select2:select", function (e) {
						self.vatValue = e.target.value
					});
				}, 300)
			}
			setTimeout(function () {
				window.$('#currency-select').select2()
			}, 300)
		},
		getAccountType() {
			const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.GET_ACCOUNT_TYPES + `${this.$route.query.key}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.GET_ACCOUNT_TYPES + `${this.$route.query.key}/`
			this.$store.dispatch('customer/getAccountType', {URL: URL})
				.then(res => {
					if (res.status === 200) {
						this.accountType = res.data.data
					}
				}).catch((err) => {
				// this.showCustomAccount = false
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								// this.getAccountType()
							})
							.catch(_ => {
								// console.log('here')
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		switchType(e) {
			this.matchTyp = parseInt(e.target.value)
			if (parseInt(e.target.value) === 2) {
				// this.matchCode = ''
				this.showAllCountries = true
				this.showCountry = false

			} else {
				this.showAllCountries = true
				// this.matchCode = ''
				this.showCountry = true
			}
		},
		getCountry() {
			let country_key = 'country_key'
			if (checkIfExist(country_key) === true) {
				this.countries = JSON.parse(getDataLocalStorage(country_key)).data
			} else {
				this.$store.dispatch('customer/getCountry', {URL: this.$services.endpoints.GET_COUNTRIES})
					.then(res => {
						// console.log('countries:', res)
						if (res.status === 200) {
							this.countries = res.data.data
							this.allCountries = res.data.data
							saveDataLocalStorage(country_key, JSON.stringify({data: res.data.data}))
						}
					}).catch((err) => {
					// this.showCustomAccount = false
					if (err.response.status === 400) {
						if (err.response.data.code === 401) {
							this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
								.then(_ => {
									// this.getCountry()
								})
								.catch(_ => {
									// console.log('here')
									this.$store.commit('auth/CLEAR_AUTH_USER', null)
									window.Bus.$emit('sign-out')
								})
						}
					}
				})
			}
		},
		getCustomAccounts(subID) {
			const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.GET_CUSTOM_ACCOUNT_SUB + `${subID}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.GET_CUSTOM_ACCOUNT_SUB + `${subID}/`


			this.$store.dispatch('customer/getCustomAccounts', {URL})
				.then(res => {
					let exclude_dict = ["EU", "WO", "SE", 0.25, 0.12, 0.06, 0]
					this.customAccounts = []
					let customAccountListDict = {
						0.12: 'sales_account_se_twelve',
						0.06: 'sales_account_se_six',
						0.25: 'sales_account_se_twenty_five',
					}
					this.customAccountList = {
						'sales_account_wo': '',
						'sales_account_eu': '',
						'sales_account_se_six': '',
						'sales_account_se_twelve': '',
						'sales_account_se_twenty_five': '',
					}

					if (res.data.success) {
						// if (CUSTOM_V3_APPS_VISMA.includes(parseInt(this.$route.query.type)) && [1].includes(parseInt(this.$route.query.acc)) === true) {
						// this.customAccounts = res.data.data
						let account_to_exclude = []
						Object.keys(this.reconciliatonAccountList).map(val => {
							let filtered = res.data.data.filter(item => parseInt(item.acct_type) === this.reconciliatonAccountList[val].acct_type && item.match_code === 'SEK' && item.vat_rate === null)
							if (filtered.length > 0) {
								account_to_exclude.push(filtered[0].id)
							}
						})
						Object.keys(this.proAccountList).map(val => {
							let filtered = res.data.data.filter(item => parseInt(item.acct_type) === parseInt(this.proAccountList[val].acct_type) && item.match_code === this.proAccountList[val].match_code && item.vat_rate === null)
							if (filtered.length > 0) {
								account_to_exclude.push(filtered[0].id)
							}
						})
						Object.keys(this.voucherAccountList).map(val => {
							let filtered = res.data.data.filter(item => parseInt(item.acct_type) === parseInt(this.voucherAccountList[val].acct_type) && item.match_code === this.voucherAccountList[val].match_code && item.vat_rate === null)
							if (filtered.length > 0) {
								account_to_exclude.push(filtered[0].id)
							}
						})
						Object.keys(customAccountListDict).map(key => {
							let exist = res.data.data.filter(dt => String(dt.vat_rate) === String(key) && dt.match_code === 'SEK')
							if (exist.length > 0) {
								account_to_exclude.push(exist[0].id)
							}
						})
						// console.log(account_to_exclude)
						this.customAccounts = res.data.data.filter(item => account_to_exclude.includes(item.id) === false)
						// }
					}
				}).catch((err) => {
				// this.showCustomAccount = false
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								// this.getCustomAccounts(subID)
							})
							.catch(_ => {
								// console.log('here')
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})

		},
		onChangeCurrencyCountry(val) {
			let onlySekCountryCode = [10, 13]
			if (onlySekCountryCode.includes(parseInt(this.accType))) {
				this.matchCode = 'SEK'
			} else {
				this.matchCode = val
			}

		},
		onChangeAccountNumber(val) {
			this.acctNumber = val
		},
		getActiveAccountNumbers(key) {
			const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.GET_ACTIVE_ACCOUNT_NUMBERS + `${key}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.GET_ACTIVE_ACCOUNT_NUMBERS + `${key}/`
			this.$store.dispatch('customer/getActiveAccountNumbers', {URL})
				.then(res => {
					this.accountNumbers = res.data.data.results
				}).catch((err) => {
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
							})
							.catch(_ => {
								// console.log('error response')
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})

		},
		removeValueFromSelectLib() {
			window.$('#currency-select, #konto-typ, #match-types, #country-select,#country-select-sek, #account-numbers-select, #vat-type-value').val(null).trigger('change')
		},
		clearFormField() {
			this.accType = ''
			this.matchTyp = ''
			this.matchCode = ''
			this.vatValue = ''
			this.acctNumber = ''
			this.ruleType = ''
			// this.onChangeCurrencyCountry('')
			this.onChangeAccountNumber('')
			this.removeValueFromSelectLib()
		},
	},
	mounted() {
		this.getAccountType()
		this.getMatchType()
		this.getCountry()
		if ([0, 1].includes(parseInt(this.$route.query.acc)) === true) {
			this.getActiveAccountNumbers(this.$route.query.key)
		}

		this.allCurrencies = this.$services.helpers.allCurrencies()
		this.getCustomAccounts(this.$route.query.key)
		setTimeout(() => {
			const self = this
			window.$('#currency-select, #konto-typ, #match-types, #country-select, #account-numbers-select').select2({
				templateSelection: function (data) {
					if (data.element.parentNode.id === 'country-select' || data.element.parentNode.id === 'currency-select') {
						self.onChangeCurrencyCountry(data.id)
					}

					if (data.element.parentNode.id === 'account-numbers-select') {
						self.onChangeAccountNumber(data.id)
					}

					if (data.element.parentNode.id === 'vat-type-value') {
						self.onChangeVat(data.id)
					}

					return data.id
				},
			})
			window.$('#country-select').on("select2:select", function (e) {
				self.matchCode = e.target.value
			});
			window.$('#account-numbers-select').on("select2:select", function (e) {
				self.acctNumber = e.target.value
			});
			window.$('#currency-select').on("select2:select", function (e) {
				self.matchCode = e.target.value
			});

		}, 3000)
		window.Bus.$on('reload-custom-account', e => {
			this.getCustomAccounts(this.$route.query.key)
		})
	},
	computed: {
		...mapGetters({
			USER_TOKEN: 'auth/USER_TOKEN',
			USER_REFRESH: 'auth/USER_REFRESH',
			GET_LOGIN_CREDENTIAL: 'auth/GET_LOGIN_CREDENTIAL',
			GET_USER_COMPANY_ID: 'customer/GET_USER_COMPANY_ID',
			GET_COMPANY_BILLING: 'customer/GET_COMPANY_BILLING',
			GET_SUBSCRIPTION: 'customer/GET_SUBSCRIPTION',
			GET_V3_APP: 'customer/GET_V3_APP',
			GET_LOCALE: 'utility/GET_LOCALE_LANG'
		})
	},
}
</script>

<style scoped>

</style>
