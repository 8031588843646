<template>
	<div>
	<span
		class="text-gray-500 text-base mt-4 pl-3 pl-3">{{
			this.$t('bambooHRSetting')
		}}</span>
		<div class="border col-md-10 rounded mb-8 mt-1 md:pr-3 p-2">
			<div class="row">
				<div class="col-md-11 rounded p-3 mb-3">
					<form @submit.prevent="CreateBambooMapping">
						<div class="flex flex col md:flex-row">
							<div class="col-sm-3">
								<label for="kontotyp"
									   class="text-gray-600 font-semibold">Typ</label><br>
								<select v-model="absenceType" @change="switchAbsenceType"
										class="form-select override-form-ca w-full" required>
									<option :value="type[0]" v-for="(type, i) in bambooHRType"
											:key="i">{{ type[1] }}
									</option>
								</select>
							</div>
							<div class="col-sm-3">
								<label
									class="text-gray-600 font-semibold whitespace-nowrap"
									v-if="showFortnoxCause">Fortnox Absence Cause</label>
								<label
									class="text-gray-600 font-semibold whitespace-nowrap"
									v-else>Fortnox
									Employee Field</label><br>
								<select v-model="absenceCauseEmployee"
										class="form-select override-form-ca w-full" required
										v-if="showFortnoxCause">
									<option :value="type[0]"
											v-for="(type, i) in bambooFortnoxCause"
											:key="i">{{ type[1] }}
									</option>
								</select>
								<select v-model="absenceCauseEmployee"
										class="form-select override-form-ca w-full" required
										v-else>
									<option :value="type" v-for="(type, i) in bambooEmpField"
											:key="i">{{ type }}
									</option>
								</select>
							</div>
							<div class="col-md-3" v-if="showFortnoxCause">
								<label for="Matchningstyp"
									   class="text-gray-600 font-semibold whitespace-nowrap">BambooHR
									TimeOff Type</label>
								<select v-model="timeOffValue"
										class="form-select override-form-ca w-full" required>
									<option :value="type[0]" v-for="(type, i) in bambooTimeOff"
											:key="i">{{ type[1] }}
									</option>
								</select>
							</div>
							<div class="col-md-3" v-if="!showFortnoxCause">
								<label for="custom field"
									   class="text-gray-600 text-xs font-semibold whitespace-nowrap">Employee
									Table</label>
								<input v-model="empTable" type="text"
									   class="form-control override-form-numbers" required>
							</div>
							<div class="col-md-3" v-if="!showFortnoxCause">
								<label for="custom field"
									   class="text-gray-600 text-xs font-semibold whitespace-nowrap">Employee
									Field</label>
								<input v-model="empField" type="text"
									   class="form-control override-form-numbers" required>
							</div>
							<div class="col-sm-3">
								<label for="kontotyp" class="mb-3 mt-1"></label><br>
								<button
									class="btn hover:text-white text-base btn-custom pad-overi h-8"
									:disabled='addingBamboo'>
                                                        <span v-if="addingBamboo">
                                                            <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
																 xmlns="http://www.w3.org/2000/svg" fill="none"
																 viewBox="0 0 24 24">
                                                            <circle class="opacity-25" cx="12" cy="12" r="10"
																	stroke="currentColor" stroke-width="4"></circle>
                                                            <path class="opacity-75" fill="currentColor"
																  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                            </svg>
                                                        </span>
									<span v-else class="relative -top-1 text-xs">
                                                            Spara
                                                        </span>
								</button>
							</div>
						</div>
					</form>
				</div>
				<div class="col-md-8 rounded">
					<!-- <div class="row" v-for="bambooMapped in bambooMapData" :key="bambooMapped.id">
						<div class="col-md-3">{{ bambooMapped.value }}</div>
						<div class="col-md-3">{{ bambooMapped.name }}</div>
						<div class="col-md-3">{{ bambooMapped.type }}</div>
						<div class="col-md-3"><span @click="deleteBambooData(bambooMapped.id)" class="text-base cursor-pointer iconly-brokenDelete icon-color"></span></div>
					</div> -->

					<div class="table-responsive">
						<table style="width: 100%;" id="exampledestroy" class="table">
							<thead class="hidden">
							<tr>
								<th style="width:33%" class="text-xs">value</th>
								<th style="width:33%" class="text-xs">name</th>
								<th style="width:33%" class="text-xs">type</th>
								<th style="width:33%" class="text-xs">{{ $t('action') }}</th>
							</tr>
							</thead>
							<tbody>
							<tr v-for="bambooMapped in bambooMapData" :key="bambooMapped.id">
								<td class="text-xs">{{ bambooMapped.value }}</td>
								<td class="text-xs">{{ bambooMapped.name }}</td>
								<td class="text-xs">{{ bambooMapped.type }}</td>
								<td class="text-xs">
														<span @click="deleteBambooData(bambooMapped.id)"
															  class="text-base cursor-pointer iconly-brokenDelete icon-color"></span>
								</td>
							</tr>
							</tbody>
						</table>
					</div>

				</div>
			</div>
		</div>
	</div>
</template>

<script>
import bus from "../../../bus";

export default {
	name: "bamboAccount",
	data: function () {
		return {
			absenceType: '',
			absenceCauseEmployee: '',
			timeOffValue: '',
			empField: '',
			empTable: '',
			addingBamboo: false,
			showFortnoxCause: false,
			bambooHRType: [],
			bambooMapData: [],
			bambooEmpField: [],
			bambooFortnoxCause: [],
			bambooTimeOff: [],
		}
	},
	methods: {
		switchAbsenceType(e) {
			if (e.target.value === 'emp_custom_fields') {
				this.showFortnoxCause = false
			} else {
				this.showFortnoxCause = true
			}
			// console.log(e.target.value)
		},
		CreateBambooMapping() {
			if (this.absenceType === '' || this.absenceCauseEmployee === '') {
				this.$services.helpers.notification(this.$t('fieldRequired'), 'error', this)
			} else if (this.absenceType === 'absence_types' && this.timeOffValue === '') {
				this.$services.helpers.notification(this.$t('fieldRequired'), 'error', this)
			} else if ((this.absenceType === 'emp_custom_fields' && this.empTable === '') ||
				(this.absenceType === 'emp_custom_fields' && this.empField === '')) {
				this.$services.helpers.notification(this.$t('fieldRequired'), 'error', this)
			} else {
				const obj = {}
				this.addingBamboo = true
				const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.CREATE_BAMBOO_MAPPING + `${this.$route.query.key}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.CREATE_BAMBOO_MAPPING + `${this.$route.query.key}/`

				if (this.absenceType === 'absence_types') {
					obj.key = this.timeOffValue
					obj.val = this.absenceCauseEmployee
					obj.group_desc = this.absenceType
				} else {
					obj.key = this.empField
					obj.val = this.absenceCauseEmployee
					obj.table = this.empTable
					obj.group_desc = this.absenceType
				}

				this.$store.dispatch('customer/CreateBambooMapping', {URL, obj})
					.then(res => {
						this.addingBamboo = false
						if (res.data.success) {
							this.$services.helpers.notification(this.$t('bambooCreated'), 'success', this)
							this.getBambooMapData()
						}
					}).catch((err) => {
					this.addingBamboo = false
					if (err.response.status === 400) {
						if (err.response.data.code === 401) {
							this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
								.then(_ => {
								})
								.catch(_ => {
									// console.log('error response')
									this.$store.commit('auth/CLEAR_AUTH_USER', null)
									window.Bus.$emit('sign-out')
								})
						}
						this.$services.helpers.notification(err.response.data.data, 'error', this)
					}
				})

			}
		},
		deleteBambooData(id) {
			this.$services.helpers.notification(this.$t('pleaseWait'), 'progress', this)
			const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.DELETE_BAMBOO_DATA + `${this.$route.query.key}/${id}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.DELETE_BAMBOO_DATA + `${this.$route.query.key}/${id}/`

			this.$store.dispatch('customer/deleteBambooData', {URL})
				.then(res => {
					bus.$emit('hide-notify-me', '')
					if (res.status === 204) {
						this.$services.helpers.notification(this.$t('bambooDeleted'), 'success', this)
						this.getBambooMapData(id)
					}
				}).catch((err) => {
				bus.$emit('hide-notify-me', '')
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								// this.getSubscriptionList()
							})
							.catch(_ => {
								// console.log('error response')
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})

		},
		getBambooType() {
			this.$store.dispatch('customer/getBambooType', {URL: this.$services.endpoints.GET_BAMBOOHR_TYPES})
				.then(res => {
					// console.log('TYp:', res)
					if (res.status === 200) {
						this.bambooHRType = res.data.data
					}
				}).catch((err) => {
				// this.showCustomAccount = false
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								this.getBambooType()
							})
							.catch(_ => {
								// console.log('here')
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		getBambooFortnoxCause() {
			// this.$services.axios.defaults.headers.common.Authorization = `Bearer ${this.USER_TOKEN}`
			// this.$services.axios.get(this.$services.endpoints.GET_FORTNOX_CAUSE_CODE)
			this.$store.dispatch('customer/getBambooFortnoxCause', {URL: this.$services.endpoints.GET_FORTNOX_CAUSE_CODE})
				.then(res => {
					// console.log('fortnox cause:', res)
					if (res.status === 200) {
						this.bambooFortnoxCause = res.data.data
					}
				}).catch((err) => {
				// this.showCustomAccount = false
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								this.getBambooFortnoxCause()
							})
							.catch(_ => {
								// console.log('here')
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		getBambooTimeOffs() {
			// this.$services.axios.defaults.headers.common.Authorization = `Bearer ${this.USER_TOKEN}`
			// this.$services.axios.get(this.$services.endpoints.GET_BAMBOOHR_TIME_OFFS + `${this.$route.query.key}/`)
			this.$store.dispatch('customer/getBambooTimeOffs', {URL: this.$services.endpoints.GET_BAMBOOHR_TIME_OFFS + `${this.$route.query.key}/`})
				.then(res => {
					// console.log('timeoffs:', res)
					if (res.status === 200) {
						this.bambooTimeOff = res.data.data
					}
				}).catch((err) => {
				// this.showCustomAccount = false
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								// this.getBambooTimeOffs()
							})
							.catch(_ => {
								// console.log('here')
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		getBambooEmpField() {
			// this.$services.axios.defaults.headers.common.Authorization = `Bearer ${this.USER_TOKEN}`
			// this.$services.axios.get(this.$services.endpoints.GET_BAMBOOHR_EMP_FIELDS)
			this.$store.dispatch('customer/getBambooEmpField', {URL: this.$services.endpoints.GET_BAMBOOHR_EMP_FIELDS})
				.then(res => {
					// console.log('emp field:', res)
					if (res.status === 200) {
						this.bambooEmpField = res.data.data
					}
				}).catch((err) => {
				// this.showCustomAccount = false
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								// this.getBambooEmpField()
							})
							.catch(_ => {
								// console.log('here')
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		getBambooMapData() {
			// this.$services.axios.defaults.headers.common.Authorization = `Bearer ${this.USER_TOKEN}`
			// this.$services.axios.get(this.$services.endpoints.GET_BAMBOOHR_MAP_DATA + `${this.$route.query.key}/`)
			this.$store.dispatch('customer/getBambooMapData', {URL: this.$services.endpoints.GET_BAMBOOHR_MAP_DATA + `${this.$route.query.key}/`})
				.then(res => {
					// console.log('map dta:', res)
					if (res.status === 200) {
						this.bambooMapData = res.data.data
					}
				}).catch((err) => {
				// this.showCustomAccount = false
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								// this.getBambooMapData()
							})
							.catch(_ => {
								// console.log('here')
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
	},
	mounted() {
		this.getBambooType()
		this.getBambooFortnoxCause()
		this.getBambooTimeOffs()
		this.getBambooEmpField()
		this.getBambooMapData()
	}
}
</script>

<style lang="scss">
@import "./../../../assets/variables.scss";

.form-select, .form-input {
	border: 1px solid $form-border !important;
	height: unset !important;
	padding: .175rem .75rem !important;
}

.form-control:focus {
	box-shadow: unset !important;
	border: 1px solid $theme-primary-color !important;
}

.form-control {
	border: 1px solid $form-border !important;
}

.override-form-numbers {
	padding-bottom: 0rem !important;
	padding-top: 0.2rem !important;
	font-size: unset !important;
	height: calc(1.75rem + 2px) !important;
}

</style>
