<template>
	<div class="">
		<div class="absolute inset-0 z-40" @click="modal = false" v-if="modal"></div>
		<div class="card-body">
			<h5 data-cy="tab-config-2" class="card-title">{{ $t('config') }} </h5>
			<div class="container">

				<div class="row" v-loading='vloading'>
					<div class="col-md-12 mb-3" v-if="showAmazon">
						<div class="flex space-x-10">
							<button @click="ConnectAmazonLegacy" :disabled='connectLegacy'
									class="c-btn btn-amazon btn btn-theme-amazon btn-primary btn-wide cursor-not-allowed shadow-lg">
								<div class="flex items-center m-auto" v-if="connectLegacy">
									<svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
										 xmlns="http://www.w3.org/2000/svg" fill="none"
										 viewBox="0 0 24 24">
										<circle class="opacity-25" cx="12" cy="12" r="10"
												stroke="currentColor" stroke-width="4"></circle>
										<path class="opacity-75" fill="currentColor"
											  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
									</svg>
									{{ $t('connecting') }}
								</div>
								<div class="flex" v-else>
									<img src="https://img.icons8.com/plumpy/24/000000/amazon.png"/>
									<span
										class="text-white font-semibold">Anslut till Amazon Nordamerika (Legacy)</span>
								</div>

							</button>
							<button @click="connectAmazonEU" :disabled='connectEu'
									class="c-btn btn-amazon btn btn-theme-amazon btn-primary btn-wide cursor-not-allowed shadow-lg">
								<div class="flex items-center m-auto" v-if="connectEu">
									<svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
										 xmlns="http://www.w3.org/2000/svg" fill="none"
										 viewBox="0 0 24 24">
										<circle class="opacity-25" cx="12" cy="12" r="10"
												stroke="currentColor" stroke-width="4"></circle>
										<path class="opacity-75" fill="currentColor"
											  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
									</svg>
									{{ $t('connecting') }}
								</div>
								<div class="flex" v-else>
									<img src="https://img.icons8.com/plumpy/24/000000/amazon.png"/>
									<span class="text-white font-semibold">Anslut till Amazon EU (Legacy)</span>
								</div>
							</button>
						</div>
					</div>
					<div class="col-md-12 mb-3" v-if="showAmazonMarketPlace">
						<div class="flex space-x-10">
							<button @click="connectAmazonMarketPlace" :disabled='connectLegacy'
									class="c-btn btn-amazon btn btn-theme-amazon btn-primary btn-wide cursor-not-allowed shadow-lg">
								<div class="flex items-center m-auto" v-if="connectLegacy">
									<svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
										 xmlns="http://www.w3.org/2000/svg" fill="none"
										 viewBox="0 0 24 24">
										<circle class="opacity-25" cx="12" cy="12" r="10"
												stroke="currentColor" stroke-width="4"></circle>
										<path class="opacity-75" fill="currentColor"
											  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
									</svg>
									{{ $t('connecting') }}
								</div>
								<div class="flex" v-else>
									<img src="https://img.icons8.com/plumpy/24/000000/amazon.png"/>
									<span class="text-white font-semibold">Anslut till Amazon</span>
								</div>

							</button>
						</div>
					</div>
					<div class="col-md-12 mb-10" v-if="showForm">
						<div class="flex flex-col space-y-4">
												<span id='stripe-auth-form' class="flex space-x-4"
													  v-if="showStripe"></span>

							<span id='shopify-auth-form' class="flex space-x-4"
								  v-if="showShopify"></span>
						</div>
					</div>
					<div class="col-md-3 v-app-3">
						<div v-if="showForm">
							<div class="flex flex-col space-y-4">
								<div data-cy='subscription-form'
									 class="flex flex-col font-light space-y-3 form-config" id="subscription-form"
									 v-html="helpText"></div>
								<div class="form-config"
									 v-if="customAppForSalesAccountVisma.includes(parseInt($route.query.type)) === true && parseInt($route.query.acc) === 1">
									<label class="flex justify-between w-full mt-1 color-text">{{
											$t('sales_account_se')
										}}
										<span
											class="tooltip-settings"><i
											class="text-sm help-color fa fa-question-circle"></i></span>
									</label>
									<select2-component :options="accountNumbers"
													   v-model="customSalesAccountVisma"
													   :width="280"></select2-component>
								</div>
								<div id="custom-v3" class="form-config"
									 v-if="showV3">

									<div class="form-config">
										<label class="flex justify-between w-full mt-1 color-text">{{
												$t('sales_account_se')
											}}
											(25%):
											<span
												class="tooltip-settings" id="id_account_span_tooltip"><i
												class="text-sm help-color fa fa-question-circle"></i></span>
										</label>
										<select2-component :options="accountNumbers"
														   v-model="customAccountList.sales_account_se_twenty_five"
														   :width="280"></select2-component>
										<span class="custom-v3-class" custom='sales_account_se_twenty_five'
											  id='id_sales_account_se_twenty_five'></span>
										<!--										<select id='id_sales_account_se_twenty_five'-->
										<!--												name="id_account_span_name"-->
										<!--												v-model="customAccountList.sales_account_se_twenty_five"-->
										<!--												maxlength="256"-->
										<!--												required=""-->
										<!--												class="custom-v3-class c-pad-setting form-control h-c28 font-light text-xs"-->
										<!--												custom='sales_account_se_twenty_five'-->
										<!--												is_account_field=""></select>-->
									</div>

									<div class="form-config">
										<label class="flex justify-between w-full mt-1 color-text">{{
												$t('sales_account_se')
											}}
											(12%):
											<span
												class="tooltip-settings"><i
												class="text-sm help-color fa fa-question-circle"></i></span>
										</label>
										<select2-component :options="accountNumbers"
														   v-model="customAccountList.sales_account_se_twelve"
														   :width="280"></select2-component>
										<span class="custom-v3-class" custom='sales_account_se_twelve'
											  id='id_sales_account_se_twelve'></span>
										<!--										<select id='id_sales_account_se_twelve' maxlength="256"-->
										<!--												required=""-->
										<!--												v-model="customAccountList.sales_account_se_twelve"-->
										<!--												class="custom-v3-class c-pad-setting form-control h-c28 font-light text-xs"-->
										<!--												custom='sales_account_se_twelve'-->
										<!--												is_account_field=""></select>-->
									</div>
									<div class="form-config">
										<label class="flex justify-between w-full mt-1 color-text">{{
												$t('sales_account_se')
											}}
											(6%):
											<span
												class="tooltip-settings"><i
												class="text-sm help-color fa fa-question-circle"></i></span>
										</label>
										<select2-component :options="accountNumbers"
														   v-model="customAccountList.sales_account_se_six"
														   :width="280"></select2-component>
										<span class="custom-v3-class" custom='sales_account_se_six'
											  id='id_sales_account_se_six'></span>
										<!--										<select id='id_sales_account_se_six' maxlength="256"-->
										<!--												required=""-->
										<!--												v-model="customAccountList.sales_account_se_six"-->
										<!--												class="custom-v3-class c-pad-setting form-control h-c28 font-light text-xs"-->
										<!--												custom='sales_account_se_six'-->
										<!--												is_account_field=""></select>-->
									</div>

									<div v-if="!removeExtraV3Fields" class="form-config">

										<label class="flex justify-between w-full mt-1 color-text">{{
												$t('sales_account')
											}}
											EU:
											<span
												class="tooltip-settings"><i
												class="text-sm help-color fa fa-question-circle"></i></span>
										</label>
										<select2-component :options="accountNumbers"
														   v-model="customAccountList.sales_account_eu"
														   :width="280"></select2-component>
										<span class="custom-v3-class" custom='sales_account_eu'
											  id='id_sales_account_eu'></span>
										<!--										<select id='id_sales_account_eu' maxlength="256"-->
										<!--												required=""-->
										<!--												v-model="customAccountList.sales_account_eu"-->
										<!--												class="custom-v3-class c-pad-setting form-control h-c28 font-light text-xs"-->
										<!--												custom='sales_account_eu'-->
										<!--												is_account_field=""></select>-->
									</div>

									<div v-if="!removeExtraV3Fields" class="form-config">
										<label class="flex justify-between  w-full mt-1 color-text">{{
												$t('sales_account_export')
											}}:
											<span
												class="tooltip-settings"><i
												class="text-sm help-color fa fa-question-circle"></i></span>
										</label>
										<select2-component :options="accountNumbers"
														   v-model="customAccountList.sales_account_wo"
														   :width="280"></select2-component>
										<span class="custom-v3-class" custom='sales_account_wo'
											  id='id_sales_account_wo'></span>
									</div>
								</div>
								<div id="pro-app" class="mt-2 form-config">
									<div class="form-config" v-if="pro_app.vat_acct">
										<label class="flex justify-between w-full mt-1 color-text py-1">{{
												$t('vat_acct')
											}}:
											<span
												class="tooltip-settings"><i
												class="text-sm help-color fa fa-question-circle"></i>
												<span
													class="w-60 tooltiptext shadow-sm border">{{
														$t('momskonto')
													}}</span></span>
										</label>
										<select2-component :options="accountNumbers"
														   v-model="proAccountList.vat_acct.acct_no"
														   :width="280"></select2-component>
									</div>
									<div class="form-config"
										 v-if="pro_app.receivable_acct">
										<label class="flex justify-between w-full mt-1 color-text py-1">{{
												$t('receivable_acct')
											}}:
											<span
												class="tooltip-settings"><i
												class="text-sm help-color fa fa-question-circle"></i>
												<span
													class="w-60 tooltiptext shadow-sm border">{{
														$t('kundfodranskonto')
													}}</span></span>
										</label>
										<select2-component :options="accountNumbers"
														   v-model="proAccountList.receivable_acct.acct_no"
														   :width="280"></select2-component>
									</div>
									<div class="form-config"
										 v-if="pro_app.sales_account">
										<label class="flex justify-between w-full mt-1 color-text py-1">{{
												$t('sales_account')
											}}:
											<span
												class="tooltip-settings"><i
												class="text-sm help-color fa fa-question-circle"></i>
												<span class="w-60 tooltiptext shadow-sm border">{{
														$t('forsaljningskonto')
													}}</span></span>
										</label>
										<select2-component :options="accountNumbers"
														   v-model="proAccountList.sales_account.acct_no"
														   :width="280"></select2-component>
									</div>
								</div>
								<div id="reconciliation-app" class="mt-2 form-config">
									<div class="form-config" v-if="reconciliations.payout">
										<label class="flex justify-between w-full mt-1 color-text py-1">{{
												$t('payout')
											}}:
											<span
												class="tooltip-settings"><i
												class="text-sm help-color fa fa-question-circle"></i>
												<span
													class="w-60 tooltiptext shadow-sm border">{{
														$t('utbetalningskonto')
													}}</span></span>
										</label>
										<select2-component :options="vismaBankAccount" :option-type="`bank-account`"
														   v-if="vismaPlusAndBasicApp.includes(parseInt($route.query.type)) === true && parseInt($route.query.acc) === 1"
														   v-model="reconciliatonAccountList.payout.acct_no"
														   :width="280"></select2-component>
										<select2-component :options="accountNumbers" v-else
														   v-model="reconciliatonAccountList.payout.acct_no"
														   :width="280"></select2-component>
									</div>
									<div class="form-config"
										 v-if="reconciliations.payout_account_bank">
										<label class="flex justify-between w-full mt-1 color-text py-1">{{
												$t('payoutAccountBank')
											}}:
											<span
												class="tooltip-settings"><i
												class="text-sm help-color fa fa-question-circle"></i>
												<span class="w-60 tooltiptext shadow-sm border"></span></span>
										</label>
										<select2-component :options="vismaBankAccount" :option-type="`bank-account`"
														   v-if="vismaPlusAndBasicApp.includes(parseInt($route.query.type)) === true && parseInt($route.query.acc) === 1"
														   v-model="reconciliatonAccountList.payout_account_bank.acct_no"
														   :width="280"></select2-component>
										<select2-component :options="accountNumbers"
														   v-else
														   v-model="reconciliatonAccountList.payout_account_bank.acct_no"
														   :width="280"></select2-component>
									</div>
									<div class="form-config"
										 v-if="reconciliations.provider_fee_account">
										<label class="flex justify-between w-full mt-1 color-text py-1">{{
												$t('providerFeeAccount')
											}}:
											<span
												class="tooltip-settings"><i
												class="text-sm help-color fa fa-question-circle"></i>
												<span
													class="w-60 tooltiptext shadow-sm border">{{
														$t('avgifter_betalvaxel')
													}}</span></span>
										</label>
										<input type="text"
											   v-if="vismaPlusAndBasicApp.includes(parseInt($route.query.type)) === true && parseInt($route.query.acc) === 1"
											   class="border-pink-500 rounded border px-2 form-input w-full h-8 text-xs"
											   v-model="reconciliatonAccountList.provider_fee_account.acct_no"
											   style="height: 32px !important;"/>
										<select2-component :options="accountNumbers" v-else
														   v-model="reconciliatonAccountList.provider_fee_account.acct_no"
														   :width="280"></select2-component>

									</div>
									<div class="form-config"
										 v-if="reconciliations.fee_vat_acct_debit">
										<label class="flex justify-between w-full mt-1 color-text py-1">{{
												$t('reverseFeeVatDebitAccount')
											}}:
											<span
												class="tooltip-settings"><i
												class="text-sm help-color fa fa-question-circle"></i>
												<span
													class="w-60 tooltiptext shadow-sm border">{{
														$t('omvand_moms_pa_avgifter_debit')
													}}</span></span>
										</label>
										<input type="text"
											   v-if="vismaPlusAndBasicApp.includes(parseInt($route.query.type)) === true && parseInt($route.query.acc) === 1"
											   class="border-pink-500 rounded border px-2 form-input w-full h-8 text-xs"
											   v-model="reconciliatonAccountList.fee_vat_acct_debit.acct_no"
											   style="height: 32px !important;"/>
										<select2-component :options="accountNumbers" v-else
														   v-model="reconciliatonAccountList.fee_vat_acct_debit.acct_no"
														   :width="280"></select2-component>
									</div>
									<div class="form-config"
										 v-if="reconciliations.fee_vat_acct_credit">
										<label class="flex justify-between w-full mt-1 color-text py-1">{{
												$t('reverseFeeVatCreditAccount')
											}}:
											<span
												class="tooltip-settings"><i
												class="text-sm help-color fa fa-question-circle"></i>
												<span
													class="w-60 tooltiptext shadow-sm border">{{
														$t('omvand_moms_pa_avgifter_kredit')
													}}</span></span>
										</label>
										<input type="text"
											   v-if="vismaPlusAndBasicApp.includes(parseInt($route.query.type)) === true && parseInt($route.query.acc) === 1"
											   class="border-pink-500 rounded border px-2 form-input w-full h-8 text-xs"
											   v-model="reconciliatonAccountList.fee_vat_acct_credit.acct_no"
											   style="height: 32px !important;"/>
										<select2-component :options="accountNumbers" v-else
														   v-model="reconciliatonAccountList.fee_vat_acct_credit.acct_no"
														   :width="280"></select2-component>
									</div>
									<div class="form-config"
										 v-if="reconciliations.accrual_account_income">
										<label class="flex justify-between w-full mt-1 color-text py-1">{{
												$t('accrualAccountIncome')
											}}:
											<span
												class="tooltip-settings"><i
												class="text-sm help-color fa fa-question-circle"></i>
												<span class="w-60 tooltiptext shadow-sm border"></span></span>
										</label>
										<select2-component :options="accountNumbers"
														   v-model="reconciliatonAccountList.accrual_account_income.acct_no"
														   :width="280"></select2-component>
									</div>
									<div class="form-config"
										 v-if="reconciliations.accrual_debit_acct">
										<label class="flex justify-between w-full mt-1 color-text py-1">{{
												$t('accrualAccountDebit')
											}}:
											<span
												class="tooltip-settings"><i
												class="text-sm help-color fa fa-question-circle"></i>
												<span class="w-60 tooltiptext shadow-sm border"></span></span>
										</label>
										<select2-component :options="accountNumbers"
														   v-model="reconciliatonAccountList.accrual_debit_acct.acct_no"
														   :width="280"></select2-component>
									</div>
									<div class="form-config"
										 v-if="reconciliations.accrual_credit_acct">
										<label class="flex justify-between w-full mt-1 color-text py-1">{{
												$t('accrualAccountCredit')
											}}:
											<span
												class="tooltip-settings"><i
												class="text-sm help-color fa fa-question-circle"></i>
												<span class="w-60 tooltiptext shadow-sm border"></span></span>
										</label>
										<select2-component :options="accountNumbers"
														   v-model="reconciliatonAccountList.accrual_credit_acct.acct_no"
														   :width="280"></select2-component>
									</div>
									<div class="form-config"
										 v-if="reconciliations.fee_vat_account">
										<label class="flex justify-between w-full mt-1 color-text py-1">{{
												$t('feeVatAccount')
											}}:
											<span
												class="tooltip-settings"><i
												class="text-sm help-color fa fa-question-circle"></i>
												<span class="w-60 tooltiptext shadow-sm border"></span></span>
										</label>
										<select2-component :options="accountNumbers"
														   v-model="reconciliatonAccountList.fee_vat_account.acct_no"
														   :width="280"></select2-component>
									</div>

								</div>
								<div id="voucher-app" class="mt-2 form-config"
									 v-if="Object.values(voucher_app).includes(true) === true">
									<div class="form-config"
										 v-for="key in Object.keys(voucher_app)">
										<div v-if="voucher_app[key] === true">
											<label
												class="flex justify-between w-full mt-1 color-text py-1">{{
													$t(`${key}`)
												}}:
												<span
													class="tooltip-settings"><i
													class="text-sm help-color fa fa-question-circle"></i>
												<span class="w-60 tooltiptext shadow-sm border"></span></span>
											</label>
											<select2-component :options="accountNumbers"
															   v-model="voucherAccountList[key].acct_no"
															   :width="280"></select2-component>
										</div>
									</div>
								</div>

								<button @click="updateAppSettings" type="submit"
										style="width:80%; border-radius:8px !important;"
										class="v-app-4 c-btn btn-submit btn btn-lg text-sm font-black btn-theme-custom btn-lg shadow-lg"
										:disabled='updating'>
									<span v-if="updating">{{ $t('updating') }}</span>
									<span class='whitespace-nowrap' v-else>
                                                            {{ $t('updateInfo') }}
                                                        </span>
								</button>
							</div>
						</div>
						<div v-else>
							<div v-if="parseInt(accountingValue) === parseInt(vismaAccounting)">
								<h2 class="text-gray-600 text-xl mb-3 md:whitespace-nowrap">
									Välkommen till din Visma eEkonomi Koppling</h2>
								<button @click="redirectVisma" :disabled='connecting'
										class="c-btn btn-test btn hover:text-white text-base btn-custom pad-overi cursor-not-allowed shadow-lg">
									<div class="flex items-center m-auto" v-if="connecting">
										<svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
											 xmlns="http://www.w3.org/2000/svg" fill="none"
											 viewBox="0 0 24 24">
											<circle class="opacity-25" cx="12" cy="12" r="10"
													stroke="currentColor" stroke-width="4"></circle>
											<path class="opacity-75" fill="currentColor"
												  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
										</svg>
										{{ $t('connecting') }}
									</div>
									<span v-else>Anslut till Visma</span>
								</button>
							</div>
							<div v-if="parseInt(accountingValue) === parseInt(fortnoxAccounting)">

								<!-- <h2 class="text-gray-600 text-xl mb-3 md:whitespace-nowrap">Välkommen till din Fortnox eEkonomi Koppling</h2> -->
								<button @click="redirectFortnox" :disabled='connecting'
										class="c-btn btn-test btn hover:text-white text-base btn-custom pad-overi cursor-not-allowed shadow-lg">
									<div class="flex items-center m-auto" v-if="connecting">
										<svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
											 xmlns="http://www.w3.org/2000/svg" fill="none"
											 viewBox="0 0 24 24">
											<circle class="opacity-25" cx="12" cy="12" r="10"
													stroke="currentColor" stroke-width="4"></circle>
											<path class="opacity-75" fill="currentColor"
												  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
										</svg>
										{{ $t('connecting') }}
									</div>
									<span v-else>Anslut till Fortnox</span>
								</button>
							</div>
						</div>
					</div>

				</div>
			</div>
			<div class="col-md-8"></div>
		</div>
		<h3 class="text-gray-400 text-xl font-semibold mt-5 mb-4 pl-3"
			v-if="showPaymentTerms ||showCustomAccount || showDeliverySettings || showBamboo">
			{{ $t('optionalSetting') }}</h3>
		<account-setting-mapping v-if="showCustomAccount" :key="`accountSetting_${accountComponentkey}`"
								 :accounting="accountingValue" :sub-i-d="subId" :app-id="appType"/>
		<bambo-account v-if="showBamboo" :key="`bamboo_${bambooHrComponentKey}`"/>
		<payment-mapping-component v-if="showPaymentTerms" :key="`paymentTerms_${paymentTermsComponentKey}`"/>
		<delivery-mapping-component v-if="showDeliverySettings" :key="`deliveryMap_${deliveryMapKeyComponent}`"/>
		<cost-center-component v-if="showCostCenter" :key="`costCenter_${costCenterKey}`"/>
	</div>
</template>

<script>
import {mapGetters} from 'vuex'
import bus from './../../../bus'
import {
	ACCOUNT_TYPE,
	APPID as APP,
	BAMBOO_TABLE_ONLY,
	COST_CENTER_LIST,
	CUSTOM_ACCOUNT_WIDGET_FORTNOX,
	CUSTOM_DELIVERY_WIDGET_FORTNOX,
	CUSTOM_PAYMENT_TERM_WIDGET_FORTNOX,
	CUSTOM_V3_APPS_FORTNOX,
	CUSTOM_V3_APPS_VISMA,
	CUSTOM_VAT_RATE_V3_APP_VISMA,
	PAYMENT_TERM_FIELD_FORTNOX,
	PAYMENT_TERM_FIELD_VISMA,
	PRICE_LIST_SUBSCRIPTION_FORTNOX,
	RECONCILIATION_APPS,
	SHOPIFY_BTN_REDIRECT,
	SHOPIFY_V3_APPS,
	SHOW_FILE_UPLOADER,
	SKU_VALIDATOR,
	STOCKPOINT_FORTNOX,
	STRIPE_SUBSCRIPTIONS,
	TEST_ORDER_BUTTON,
	VOUCHER_SERIES_FORTNOX,
	VOUCHER_SERIES_VISMA,
	RECONCILIATION_APPS_WITH_NO_FEE,
	RECONCILIATION_APPS_WITH_VAT_FEE_AND_REVERSE_VAT,
	RECONCILIATION_APPS_WITH_VAT_FEE,
	RECONCILIATION_APPS_WITH_NO_VAT_FEE,
	PRO_APP,
	VOUCHERS_APP,
	VISMA_PLUS_AND_BASIC_APP,
	PAYOUT_ACC_BANK_APPS,
	FORTNOX_SHOPIFY_GUIDE, STRIPE_APPS_LIST

} from './../../../app/index'
import {cleanVatValues} from './../../../services/constants/vatConstant'
import {
	removeDataLocalStorage
} from "./../../../services/storage-window"
import moment from "moment"
import costCenterComponent from "./costCenterComponent";
import paymentMappingComponent from "./paymentMappingComponent";
import deliveryMappingComponent from "./deliveryMappingComponent";
import accountSettingMapping from "./accountSettingMapping";
import bamboAccount from "./bamboAccount";
import debounce from 'lodash/debounce'

window.$ = window.jQuery = require('jquery')
// Required jquery-ui
require('./../../../assets/jquery-ui/jquery-ui')
require('./../../../assets/select2/select2.min')
import select2Component from "../../Components/select2Component";

export default {
	name: "appSettingConfigurationSection",
	components: {
		costCenterComponent,
		paymentMappingComponent,
		deliveryMappingComponent,
		accountSettingMapping,
		bamboAccount,
		select2Component
	},
	data() {
		return {
			shopSettings: {},
			shopSettingsCopy: {},
			helpText: [],
			configKey: null,
			tabId: 1,
			select2Value: '',
			costCenterKey: new Date().getMinutes(),
			accountComponentkey: new Date().getMinutes(),
			errorFlag: false,
			showCustomAccount: false,
			showCountry: true,
			subscriptionDeactivate: false,
			subscriptionActivate: false,
			showActivateSub: false,
			showContinue: false,
			connectLegacy: false,
			connectEu: false,
			runningTest: false,
			showFortnoxCause: true,
			fileAdded: false,
			ruleType: '',
			vatValue: '',
			fortnoxLink: '',
			vismaLink: '',
			vismaTextName: '',
			absenceType: '',
			absenceCauseEmployee: '',
			timeOffValue: '',
			empTable: '',
			empField: '',
			selected: '',
			countries: [],
			allCountries: [],
			accountNumbers: [],
			filteredAccount: [],
			selectAccount: '',
			currency: [],
			accountType: [],
			customAccounts: [],
			bambooHRType: [],
			bambooFortnoxCause: [],
			bambooTimeOff: [],
			bambooEmpField: [],
			bambooMapData: [],
			matchType: [],
			matchTypeCopy: [],
			currentSubscription: [],
			singleSubscription: [],
			waysOfDeliveryOption: [],
			wayOfDeliveryArray: [],
			providerPaym: [],
			paymentTermsArray: [],
			wayDoption: '',
			storeTitle: '',
			paymentTerms: [],
			voucherSeries: [],
			priceList: [],
			allCurrencies: [],
			vatRates: cleanVatValues(),
			customV3ListAccounts: [
				{
					key: 'sales_account_wo',
					match: 'WO',
					vat: ''
				},
				{
					key: 'sales_account_eu',
					match: 'EU',
					vat: ''
				},
				{
					key: 'sales_account_se_six',
					match: 'SE',
					vat: '0.06'
				},
				{
					key: 'sales_account_se_twelve',
					match: 'SE',
					vat: '0.12'
				},
				{
					key: 'sales_account_se_twenty_five',
					match: 'SE',
					vat: '0.25'
				}
			],
			customAccountList: {
				sales_account_wo: '',
				sales_account_eu: '',
				sales_account_se_six: '',
				sales_account_se_twelve: '',
				sales_account_se_twenty_five: '',
			},
			ayden_plus_form: {
				'eu_vat_account': '',
				'export_vat_account': '',
			},
			customAccountLisEqual: {
				'sales_account_wo': 'Sales account export',
				'sales_account_eu': 'Sales account EU',
				'sales_account_se_six': 'Sales account SE (6%)',
				'sales_account_se_twelve': 'Sales account SE (12%)',
				'sales_account_se_twenty_five': 'Sales account SE (25%)',
				'export_vat_account': 'Export vat account',
				'eu_vat_account': 'EU vat account',
			},
			// paymentProviderArray : [],
			paymentTcode: '',
			paymentProvider: '',
			subId: null,
			appType: null,
			accountingValue: null,
			vismaAccounting: null,
			fortnoxAccounting: null,
			accType: '',
			currentAppType: '',
			matchTyp: 1,
			matchCode: '',
			matchCurrency: '',
			vatCode: '',
			acctNumber: '',
			forCompany: false,
			forShipping: false,
			forFeeLines: false,
			forGiftCard: false,
			forDiscount: false,
			showAllCountries: true,
			linkAmazonLegacy: '',
			linkAmazonEU: '',
			color: '#13c2c2',
			showFileHandler: false,
			showAmazon: false,
			showAmazonMarketPlace: false,
			percentage: 30,
			updating: false,
			addingCustomAccount: false,
			showForm: false,
			showDeliverySettings: false,
			deliveryMapKeyComponent: new Date().getMinutes(),
			showPaymentTerms: false,
			paymentTermsComponentKey: new Date().getMinutes(),
			paypalSupplierDetails: [],
			vloading: true,
			connecting: false,
			uploading: false,
			loadingInfo: false,
			activating: false,
			deactivating: false,
			addingDeliveryWay: false,
			addingPaymentTerms: false,
			addingCostCenter: false,
			addingBamboo: false,
			showBamboo: false,
			bambooHrComponentKey: new Date().getMinutes(),
			modal: false,
			showStripe: false,
			showShopify: false,
			showShopifyBtn: false,
			shopifyPayBtnOnly: false,
			checkVismaAvailabilityToken: null,
			showTestButton: false,
			vismaBankAccount: [],
			v3CustomAccount: [],
			vismaForeignPayment: [],
			vismaUnit: [],
			hideReconciliationField: true,
			app_type: null,
			acc: null,
			showV3: false,
			cancelledSubscriptionFlag: false,
			removeExtraV3Fields: false,
			showCostCenter: false,
			shopName: '',
			stockPointsList: [],
			costCenter: {
				cost_center_code: '',
				customer_country: ''
			},
			costCenterList: [],
			costCenterDbList: [],
			subscriptionConfigResponse: {},
			reconciliations: {
				payout: false,
				payout_account_bank: false,
				provider_fee_account: false,
				fee_vat_acct_debit: false,
				fee_vat_acct_credit: false,
				accrual_account_income: false,
				fee_vat_account: false,
				accrual_debit_acct: false,
				accrual_credit_acct: false,
			},
			pro_app: {
				vat_acct: false,
				receivable_acct: false,
				sales_account: false,
			},
			voucher_app: {
				vat_acct: false,
				payout: false,
				sales_account: false,
			},
			reconciliatonAccountList: {
				'payout': {
					acct_type: 2,
					acct_no: '',
					match_type: 2,
					match_code: 'SEK',
					id: null
				},
				'payout_account_bank': {
					acct_type: 12,
					acct_no: '',
					match_type: 2,
					match_code: 'SEK',
					id: null
				},
				'provider_fee_account': {
					acct_type: 10,
					acct_no: '',
					match_type: 2,
					match_code: 'SEK',
					id: null
				},
				'fee_vat_acct_debit': {
					acct_type: 13,
					acct_no: '',
					match_type: 2,
					match_code: 'SEK',
					id: null
				},
				'fee_vat_acct_credit': {
					acct_type: 14,
					acct_no: '',
					match_type: 2,
					match_code: 'SEK',
					id: null
				},
				'accrual_account_income': {
					acct_type: 6,
					acct_no: '',
					match_type: 1,
					match_code: 'SE',
					id: null
				},
				'accrual_debit_acct': {
					acct_type: 7,
					acct_no: '',
					match_type: 1,
					match_code: 'SE',
					id: null
				},
				'accrual_credit_acct': {
					acct_type: 8,
					acct_no: '',
					match_type: 1,
					match_code: 'SE',
					id: null
				},
				'fee_vat_account': {
					acct_type: 11,
					acct_no: '',
					match_type: 2,
					match_code: 'SEK',
					id: null
				},
			},
			proAccountList: {
				'vat_acct': {
					acct_type: 1,
					acct_no: '',
					match_type: 1,
					match_code: 'SE',
					id: null
				},
				'receivable_acct': {
					acct_type: 5,
					acct_no: '',
					match_type: 1,
					match_code: 'SEK',
					id: null
				},
				'sales_account': {
					acct_type: 3,
					acct_no: '',
					match_type: 1,
					match_code: 'SEK',
					id: null
				},
			},
			voucherAccountList: {
				'vat_acct': {
					acct_type: 1,
					acct_no: '',
					match_type: 1,
					match_code: 'SE',
					id: null
				},
				'payout': {
					acct_type: 2,
					acct_no: '',
					match_type: 2,
					match_code: 'SEK',
					id: null
				},
				'sales_account': {
					acct_type: 3,
					acct_no: '',
					match_type: 1,
					match_code: 'SE',
					id: null
				},
			},
			accountTypeList: [{1: 'Moms konto'},
				{2: 'Utbetalningskonto'},
				{3: 'Intäktskonto'},
				{4: 'leverantörsreskontra'},
				{5: 'Kundfordringar'},
				{6: 'Periodiseringskonto Intäkt'},
				{7: 'Periodiserad debet konto'},
				{8: 'Periodiserad kredit konto'},
				{9: 'Leverantörskonto'},
				{10: 'Konto betalväxel avgift'},
				{11: 'Monmskonto för betalväxel avgift'},
				{12: 'Utbetalningskonto Bank'},
				{13: 'Omvänd moms på betalväxel avgift - Debet'},
				{14: 'Omvänd moms på betalväxel avgift - Kredit'},
				{15: 'Specific Fee Account'}],
			officeLocations: [],
			vismaAdminCustomField: ['payment_term_code', 'price_list'],
			salesAccountList: {
				sales_account_wo: {value: ''},
				sales_account_eu: '',
				sales_account_se_six: '',
				sales_account_se_twelve: '',
				sales_account_se_twenty_five: '',
			},
			customSalesAccountVisma: '',
			customAppForSalesAccountVisma: [58, 57, 60, 61, 53, 54], // only applicable to visma accounting type,
			vismaPlusAndBasicApp: VISMA_PLUS_AND_BASIC_APP,
			customAppSalesAccountList: {
				sales_account_se_six: {
					acct_type: 3,
					acct_no: '',
					match_type: 1,
					match_code: 'SE',
					vat_rate: 0.06,
					id: null
				},
				sales_account_se_twelve: {
					acct_type: 3,
					acct_no: '',
					match_type: 1,
					match_code: 'SE',
					vat_rate: 0.12,
					id: null
				},
				sales_account_se_twenty_five: {
					acct_type: 3,
					acct_no: '',
					match_type: 1,
					match_code: 'SE',
					vat_rate: 0.25,
					id: null
				},
			} //this custom account list is being used for visam stripe plus
			// and basic that requires same sales account for vat_rate 0.06,0.12,0.25
		}
	},
	created() {
		this.allCurrencies = this.$services.helpers.allCurrencies()
	},
	computed: {
		...mapGetters({
			USER_TOKEN: 'auth/USER_TOKEN',
			USER_REFRESH: 'auth/USER_REFRESH',
			GET_LOGIN_CREDENTIAL: 'auth/GET_LOGIN_CREDENTIAL',
			GET_USER_COMPANY_ID: 'customer/GET_USER_COMPANY_ID',
			GET_COMPANY_BILLING: 'customer/GET_COMPANY_BILLING',
			GET_SUBSCRIPTION: 'customer/GET_SUBSCRIPTION',
			GET_V3_APP: 'customer/GET_V3_APP',
			GET_LOCALE: 'utility/GET_LOCALE_LANG'
		})
	},
	beforeMount() {
		this.helpText = []
		this.showAmazonMarketPlace = false
	},
	watch: {
		$route(to, from) {
			// react to route changes
			const {key, type, acc} = to.query
			this.app_type = type
			this.subId = key
			this.appType = type
			this.accountingValue = acc
			this.helpText = []
			// this.customAccounts = []
			this.paymentTermsArray = []
			this.voucherSeries = []
			this.priceList = []
			this.accountNumbers = []
			this.paymentTerms = []
			this.showContinue = false
			this.showActivateSub = false
			this.checkVismaAvailabilityToken = null
			this.vismaAccounting = ACCOUNT_TYPE.VISMA
			this.fortnoxAccounting = ACCOUNT_TYPE.FORTNOX

			// this.showForm = false
			this.showDeliverySettings = false
			this.showPaymentTerms = false
			this.showBamboo = false
			this.showCustomAccount = false
			this.showAmazon = false
			this.showFileHandler = false

			this.configKey = key
			this.percentage = 30
			this.tabId = 1
			Object.keys(this.reconciliatonAccountList).map(item => {
				this.reconciliatonAccountList[item].acct_no = ''
				this.reconciliatonAccountList[item].id = null
			})
			Object.keys(this.proAccountList).map(item => {
				this.proAccountList[item].acct_no = ''
				this.proAccountList[item].id = null
			})
			Object.keys(this.voucherAccountList).map(item => {
				this.voucherAccountList[item].acct_no = ''
				this.voucherAccountList[item].id = null
			})

			window.localStorage.setItem('__settingTab', this.tabId)
			this.setUpAccountSetting()
			// this.getSubscriptionApps(key)

			// this.helpText = this.helpText
			document.getElementById('tab-0').classList.add('active')
			document.getElementById('tab-1').classList.remove('active')
			document.getElementById('tab-2').classList.remove('active')
		},
		tabId: function (old, newVal) {
			if (this.tabId === 2) {
				this.runShopifyScript()
			}
		}
	},
	methods: {
		setValue: function () {

		},
		showCustomV3Apps(type, acc) {
			if (parseInt(acc) === ACCOUNT_TYPE.FORTNOX && CUSTOM_V3_APPS_FORTNOX.includes(parseInt(type))) {
				this.showV3 = true
				this.removeExtraV3Fields = false
			} else if (parseInt(acc) === ACCOUNT_TYPE.VISMA && CUSTOM_V3_APPS_VISMA.includes(parseInt(type))) {
				this.showV3 = true
				this.removeExtraV3Fields = true
			} else {
				this.showV3 = false
			}
		},
		showReconciliationAppsField(type, acc) {
			/*
			* this method handle enabling of custom account fields
			* */
			Object.keys(this.reconciliations).map(key => {
				this.reconciliations[key] = false
			})
			Object.keys(this.pro_app).map(key => {
				this.pro_app[key] = false
			})
			Object.keys(this.voucher_app).map(key => {
				this.voucher_app[key] = false
			})
			Object.keys(this.reconciliatonAccountList).map(item => {
				this.reconciliatonAccountList[item].acct_no = ''
				this.reconciliatonAccountList[item].id = null
			})
			Object.keys(this.proAccountList).map(item => {
				this.proAccountList[item].acct_no = ''
				this.proAccountList[item].id = null
			})
			Object.keys(this.voucherAccountList).map(item => {
				this.voucherAccountList[item].acct_no = ''
				this.voucherAccountList[item].id = null
			})
			if (RECONCILIATION_APPS_WITH_NO_FEE.includes(parseInt(type))) {
				this.reconciliations.payout = true
			}
			if (RECONCILIATION_APPS_WITH_NO_VAT_FEE.includes(parseInt(type))) {
				this.reconciliations.payout = true
				this.reconciliations.provider_fee_account = true
			}
			if (RECONCILIATION_APPS_WITH_VAT_FEE.includes(parseInt(type))) {
				this.reconciliations.payout = true
				this.reconciliations.provider_fee_account = true
				this.reconciliations.fee_vat_account = true
			}
			if (RECONCILIATION_APPS_WITH_VAT_FEE_AND_REVERSE_VAT.includes(parseInt(type))) {
				this.reconciliations.payout = true
				this.reconciliations.provider_fee_account = true
				this.reconciliations.fee_vat_acct_debit = true
				this.reconciliations.fee_vat_acct_credit = true
			}
			if (PAYOUT_ACC_BANK_APPS.includes(parseInt(type))) {
				this.reconciliations.payout_account_bank = true
			}
			if (PRO_APP.includes(parseInt(type))) {
				Object.keys(this.pro_app).map(key => {
					this.pro_app[key] = true
				})
			}

			if (VOUCHERS_APP.includes(parseInt(type))) {
				Object.keys(this.voucher_app).map(key => {
					this.voucher_app[key] = true
				})
			}
			if (parseInt(this.$route.query.type) === 39) {
				this.reconciliations.fee_vat_account = true
			}
			if (parseInt(this.$route.query.type) === 37) {
				this.reconciliations.accrual_account_income = true
				this.reconciliations.accrual_credit_acct = true
				this.reconciliations.accrual_debit_acct = true
			}

			// }
		},
		showCostCenterField(type, acc) {
			/*
			* Show cost center field for all app inside allow cost center list
			* */

			if (COST_CENTER_LIST.includes(parseInt(type)) && parseInt(acc) === ACCOUNT_TYPE.FORTNOX) {
				this.showCostCenter = true
				this.costCenterKey = new Date().getMilliseconds()
			} else {
				this.showCostCenter = false
			}
		},
		getType(arrayValue, num) {
			if (this.accountTypeList.length > 0) {
				const type = this.accountTypeList.filter(item => parseInt(Object.keys(item)[0]) === parseInt(num))[0]
				if (type) {
					return Object.values(type)[0]
				}
			}
		},
		getMatchFormType(arrayValue, num) {
			if (arrayValue.length > 0) {
				const type = arrayValue.filter(item => parseInt(Object.keys(item)[0]) === parseInt(num))[0]
				if (type) {
					return Object.values(type)[0]
				}
			}
		},
		getAppGateway(arrayValue, value) {
			if (arrayValue.length > 0) {
				const filterArray = arrayValue.filter(item => parseInt(item[1]) === parseInt(value.gateway_in_app))
				if (filterArray.length > 0) {
					return filterArray[0][1]
				}
			}
		},
		getAccountingGateway(arrayValue, value) {
			if (arrayValue.length > 0) {
				const filterArray = arrayValue.filter(item => item.Code === value.gateway_in_accounting)
				if (filterArray.length > 0) {
					return filterArray[0].Description
				}
			}
		},
		getCountryName(countryCode) {
			let country = this.countries.filter(item => item.includes(countryCode))
			if (country.length > 0) {
				return country[0][1]
			} else {
				return ''
			}
		},
		showTestOrderButton(type) {
			if (TEST_ORDER_BUTTON.includes(parseInt(type))) {
				this.showTestButton = true
			} else {
				this.showTestButton = false
			}
		},
		identifyShopifyBtn() {
			if (this.currentSubscription) {
				// parseInt(APP.SHOPIFY_PAYMENT), parseInt(APP.SHOPIFY), parseInt(APP.SHOPIFY_V3), parseInt(APP.SHOPIFY_PAY), parseInt(APP.SHOPIFYV3),
				// 	parseInt(APP.SHOPIFYV3_PRO)
				if (FORTNOX_SHOPIFY_GUIDE.includes(parseInt(this.currentSubscription.app_type)) === true) {
					this.showShopifyBtn = true
					this.showShopify = true
				} else if (this.currentSubscription.app_type === 51 && parseInt(this.$route.query.acc) === 1) {
					this.showShopifyBtn = true
					this.showShopify = true
				} else {
					this.showShopifyBtn = false
					this.showShopify = false
				}
				// if (this.GET_SUBSCRIPTION.all_subscriptions) {
				//
				// 	const filterShopifyPay = this.GET_SUBSCRIPTION.all_subscriptions.filter(item => parseInt(item.app_type) === parseInt(APP.SHOPIFY_PAYMENT))
				// 	// console.log('pay:', filterShopifyPay)
				// 	const filterShopify = this.GET_SUBSCRIPTION.all_subscriptions.filter(item => parseInt(item.app_type) === parseInt(APP.SHOPIFY))
				//
				// 	const filterShopifyV3 = this.GET_SUBSCRIPTION.all_subscriptions.filter(item => parseInt(item.app_type) === parseInt(SHOPIFY_V3_APPS[0]))
				// 	if (filterShopify.length > 0 && filterShopifyPay.length > 0 && filterShopifyV3.length > 0) {
				// 		this.showShopifyBtn = true
				//
				// 	} else {
				// 		this.showShopifyBtn = true
				// 	}
				// }
			}
		},
		runVismaApps(key, type, acc) {
			if (parseInt(acc) === ACCOUNT_TYPE.VISMA) {
				this.getPaymentTermForSubscription(key, type, acc)
				this.getVismaBankAccountNumbers(key)
				this.getVismaUnit(key);
				this.getVismaForeignPaymentCode(key)
				this.getVoucherSeriesForSubscription(key, type, acc)
				this.getActiveAccountNumbers(key, type, acc)
			}
		},
		reconciliationAppField(type) {
			if (RECONCILIATION_APPS.includes(parseInt(type))) {
				this.hideReconciliationField = false
			} else {
				this.hideReconciliationField = true
			}
		},
		getSubscriptionApps(key) {
			this.showActivateSub = true
			if (this.GET_SUBSCRIPTION.active_subscriptions) {
				// Check if subscription is already active
				const filter = this.GET_SUBSCRIPTION.all_subscriptions.filter(item => item.id === parseInt(key))
				this.currentSubscription = filter
				if (filter.length > 0) {
					this.subscriptionDeactivate = true
					this.subscriptionActivate = false
					// checking fortnox subscription token
					this.cancelledSubscriptionFlag = filter[0].sub_status
					if (filter[0].sub_status === true) {
						this.subscriptionDeactivate = true
						this.subscriptionActivate = false
					} else {
						this.subscriptionDeactivate = false
						this.subscriptionActivate = true
					}
					if (filter[0].fortnox_token_state.hasOwnProperty('fortnox_redirect_url')) {
						this.fortnoxLink = filter[0].fortnox_token_state.fortnox_redirect_url
						this.showForm = filter[0].fortnox_token_state.token_status !== 'show_button';
					} else {
						if (this.shopSettings.hasOwnProperty('token_status')) {
							if (this.shopSettings.token_status === true) {
								this.showForm = false
							} else {
								this.showForm = true
							}
						} else {
							this.showForm = true
						}

					}
				} else {
					this.subscriptionDeactivate = false
					this.subscriptionActivate = true
				}
				if (this.tabId === 3) window.Bus.$emit('start-appsetting-tab3-tour')
			}
		},
		showStripeData(type) {
			if ([9, 11, 57, 58, 59].includes(parseInt(type))) {
				this.showStripe = true
			} else {
				this.showStripe = false
			}
		},
		showShopifyData(type) {
			// parseInt(type) === APP.SHOPIFY || parseInt(type) === APP.SHOPIFY_PAYMENT || parseInt(type) === APP.SHOPIFY_V3 || parseInt(type) === APP.SHOPIFYB2B
			if (FORTNOX_SHOPIFY_GUIDE.includes(parseInt(type)) === true) {
				this.showShopify = true
			} else if (this.currentSubscription.app_type === 51 && parseInt(this.$route.query.acc) === 1) {
				this.showShopify = true
			} else {
				this.showShopify = false
			}
		},
		switchAbsenceType(e) {
			if (e.target.value === 'emp_custom_fields') {
				this.showFortnoxCause = false
			} else {
				this.showFortnoxCause = true
			}
			// console.log(e.target.value)
		},
		showBambooTable(type) {
			if (BAMBOO_TABLE_ONLY.includes(parseInt(type))) {
				this.showBamboo = true
				this.bambooHrComponentKey = new Date().getMilliseconds()
			} else {
				this.showBamboo = false
			}
		},
		openAddAccount() {
			this.$store.commit('utility/OPEN_MODAL_UTIL')
		},
		filterAccnt() {
			// console.log(this.accountNumbers)
			this.filteredAccount = this.accountNumbers.filter(account => {
				return String(account.Number).includes(this.acctNumber)
			})
		},
		setAccount(account) {
			this.acctNumber = account.Number
			this.selectAccount = account.Number
			this.modal = false
		},
		getV3CustomAccountForm(app_type) {
			// this make request to fetch custodm account field for V3 APPS
			// console.log('V3:', app_type)
			if (CUSTOM_V3_APPS_FORTNOX.includes(parseInt(app_type))) {
				// console.log(this.CUSTOM_V3_APPS_FORTNOX)
				let payload = {URL: `${this.$services.endpoints.GET_CUSTOM_V3_ACCOUNT}${app_type}`}
				this.$store.dispatch('customer/customGetRequest', payload)
					.then(_ => {
					}).catch(_ => {

				})
			} else if (CUSTOM_V3_APPS_VISMA.includes(parseInt(app_type))) {
				const URL_PATH = this.GET_USER_COMPANY_ID ? this.$services.endpoints.FETCH_CUSTOM_V3_ACCOUNT + `${this.subId}/${this.GET_USER_COMPANY_ID}/` : `${this.$services.endpoints.FETCH_CUSTOM_V3_ACCOUNT}${this.subId}`
				let payload = {URL: URL_PATH}
				this.$store.dispatch('customer/customGetRequest', payload)
					.then(resp => {


					}).catch(_ => {

				})
			}
		},
		insertAfter(newNode, existingNode) {
			existingNode.parentNode.insertBefore(newNode, existingNode.nextSibling)
		},
		filterAccountMatchCode(matchCode) {
			const filter = this.countries.filter(item => item[0] === matchCode)
			if (filter.length > 0) {
				return filter[0][1]
			} else {
				if (matchCode === 'WO') {
					return 'Export'
				} else {
					return matchCode
				}
			}
		},
		filterAccountNumberMatch(number) {
			if (parseInt(this.accountingValue) === ACCOUNT_TYPE.VISMA) {
				const filter = this.accountNumbers.filter(item => item.Number === number)
				if (filter.length > 0) {
					return filter[0].Description
				}
			} else {
				return number
			}
		},
		runShopifyScript() {
			const self = this
			let timer
			const waitTime = 500
			if (SHOPIFY_BTN_REDIRECT.includes(parseInt(this.appType))) {
				if (document.getElementById('id_shop_name')) {
					const el = document.getElementById('id_shop_name')
					// self.shopName = text
					el.addEventListener('keyup', e => {
						const text = e.currentTarget.value

						clearTimeout(timer)

						// Wait for x seconds and save data
						timer = setTimeout(() => {
							self.shopName = text
							self.autoSaveShopName(text)
						}, waitTime)
					})
				}
			}
		},
		autoSaveShopName(name) {
			const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.GET_USER_APP_CONFIG + `${this.subId}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.GET_USER_APP_CONFIG + `${this.subId}/`
			this.$store.dispatch('customer/autoSaveShopName', {URL, shop_name: name})

		},
		autoSaveAmazonRegion(name) {
			const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.GET_USER_APP_CONFIG + `${this.subId}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.GET_USER_APP_CONFIG + `${this.subId}/`
			this.$store.dispatch('customer/autoSaveAmazonRegion', {URL, region: name})
			// send data to dedicated server

		},
		runShopifyAuth() {
			this.identifyShopifyBtn()
			const self = this
			// if (document.getElementById('shopify-auth-btn')) {
			// 	document.getElementById('shopify-auth-btn').addEventListener('click', function () {
			// 		if (self.shopName) {
			// 			document.getElementById('shopify-auth-btn').disabled = true
			// 			document.getElementById('shopify-auth-btn').innerText = 'Bearbetar'
			// 			const URL = self.GET_USER_COMPANY_ID ? self.$services.endpoints.SHOPIFY_AUTH + `${self.subId}/${self.GET_USER_COMPANY_ID}/?app_bridge=${self.app_type}` : self.$services.endpoints.SHOPIFY_AUTH + `${self.subId}/?app_bridge=${self.app_type}`
			// 			if (self.app_type === APP.SHOPIFY_V3) {
			// 				window.open(`https://shopify.automatiseramera.se/?shop=${self.shopSettings.shop_name}.myshopify.com`, 'self')
			// 			} else {
			// 				self.$store.dispatch('customer/runShopifyAuth', {URL})
			// 					.then(res => {
			// 						// console.log(res)
			// 						document.getElementById('shopify-auth-btn').disabled = false
			// 						document.getElementById('shopify-auth-btn').innerText = 'Anslut med shopify'
			// 						if (res.status === 200) {
			// 							setTimeout(() => {
			// 								window.open(res.data.data, '_self')
			// 							}, 1000)
			// 						}
			// 					}).catch((err) => {
			// 					document.getElementById('shopify-auth-btn').disabled = false
			// 					document.getElementById('shopify-auth-btn').innerText = 'Anslut med shopify'
			// 					if (err.response.status === 400) {
			// 						if (err.response.data.code === 401) {
			// 							self.$store.dispatch('auth/refreshUserToken', self.USER_REFRESH)
			// 								.then(_ => {
			//
			// 								})
			// 								.catch(_ => {
			// 									// console.log('here')
			// 									self.$store.commit('auth/CLEAR_AUTH_USER', null)
			// 									window.Bus.$emit('sign-out')
			// 								})
			// 						}
			// 					}
			// 				})
			// 			}
			//
			// 		} else {
			// 			self.$services.helpers.notification(self.$t('shopNameRequire'), 'error', self)
			// 		}
			// 	})
			// }
		},
		runStripeAuth() {
			const self = this
			if (document.getElementById('stripe-auth-btn')) {
				document.getElementById('stripe-auth-btn').addEventListener('click', function () {
					document.getElementById('stripe-auth-btn').disabled = true
					document.getElementById('stripe-auth-btn').innerText = 'Bearbetar'
					const URL = self.GET_USER_COMPANY_ID ? self.$services.endpoints.STRIPE_AUTH + `${self.subId}/${self.GET_USER_COMPANY_ID}/` : self.$services.endpoints.STRIPE_AUTH + `${self.subId}/`
					// self.$services.axios.defaults.headers.common.Authorization = `Bearer ${self.USER_TOKEN}`
					// self.$services.axios.get(URL)
					self.$store.dispatch('customer/runShopifyAuth', {URL})
						.then(res => {
							// console.log(res)
							document.getElementById('stripe-auth-btn').disabled = false
							document.getElementById('stripe-auth-btn').innerText = 'Anslut med stripe'
							if (res.status === 200) {
								setTimeout(() => {
									window.open(res.data.data, '_self')
								}, 1000)
							}
						}).catch((err) => {
						document.getElementById('stripe-auth-btn').disabled = false
						document.getElementById('stripe-auth-btn').innerText = 'Anslut med stripe'
						if (err.response.status === 400) {
							if (err.response.data.code === 401) {
								self.$store.dispatch('auth/refreshUserToken', self.USER_REFRESH)
									.then(_ => {

									})
									.catch(_ => {
										// console.log('here')
										self.$store.commit('auth/CLEAR_AUTH_USER', null)
										window.Bus.$emit('sign-out')
									})
							}
						}
					})
				})
			}
		},
		checkVismaAvailability(param, ty = null) {
			this.$store.dispatch('customer/checkVismaAvailability', {sub: this.subId}) // Checking availability of visma sub to display button
				.then(response => {
					this.checkVismaAvailabilityToken = response.data.data.credentials_healthy
					// this.getSubscriptionConfig(param)
				})
				.catch(_ => {
					this.checkVismaAvailabilityToken = null
					// this.getSubscriptionConfig(param)
				})
		},
		runCustomAccountWidget(type, accountingType, key) {
			this.accountComponentkey = new Date().getMilliseconds()
			if (parseInt(accountingType) === ACCOUNT_TYPE.FORTNOX && (CUSTOM_ACCOUNT_WIDGET_FORTNOX.includes(parseInt(type)))) {
				this.showCustomAccount = true
			} else if (parseInt(type) === APP.WOOCOMMERCE && parseInt(accountingType) === ACCOUNT_TYPE.VISMA_ADMIN) {
				this.showCustomAccount = true
			} else if ((parseInt(type) === APP.QUICKBUTIK || parseInt(type) === APP.WOOCOMMERCE) && parseInt(accountingType) === ACCOUNT_TYPE.VISMA) {

				this.showCustomAccount = true
			} else {
				this.showCustomAccount = false
			}
		},
		runCustomPaymentTermWidget(type, accountingType, key) {
			if (parseInt(accountingType) === ACCOUNT_TYPE.FORTNOX && (CUSTOM_PAYMENT_TERM_WIDGET_FORTNOX.includes(parseInt(type)))) {
				// this.PaymentTermsData(key)

				this.paymentTermsComponentKey = new Date().getMilliseconds()
				this.showPaymentTerms = true
			} else {
				this.showPaymentTerms = false
			}
		},
		runCustomDeliveryTermWidget(type, accountingType, key) {
			if (parseInt(accountingType) === ACCOUNT_TYPE.FORTNOX && (CUSTOM_DELIVERY_WIDGET_FORTNOX.includes(parseInt(type)))) {
				this.deliveryMapKeyComponent = new Date().getMilliseconds()
				this.showDeliverySettings = true
			} else {
				this.showDeliverySettings = false
			}
		},
		runWoocommerceSettings(type, accountingType, key) {
			if (parseInt(type) === APP.WOOCOMMERCE && parseInt(accountingType) === ACCOUNT_TYPE.FORTNOX) {
				this.getPriceList(key)
			}
		},
		runAmazonSettings(type, accountingType, key) {
			if ([APP.AMAZON].includes(parseInt(type)) === true && parseInt(accountingType) === ACCOUNT_TYPE.FORTNOX) {
				// this.customAccountData(key)parseInt(type)
				// this.showCustomAccount = true
				this.showAmazon = true
			} else if (APP.AMAZON_MARKETPLACE === parseInt(type)) {
				this.showAmazonMarketPlace = true

			} else {
				this.showAmazonMarketPlace = false
				this.showAmazon = false
			}
		},
		customAccountData(key) {
			// this.getActiveAccountNumbers(key)  // We are adding this active account because it will be fetch first
			this.getAccountType()
			this.getMatchType()
			this.getCountry()
			this.getCustomAccounts(key)

		},
		onChangeCurrencyCountry(val) {
			let onlySekCountryCode = [10, 13]
			if (onlySekCountryCode.includes(parseInt(this.accType))) {
				this.matchCode = 'SEK'
			} else {
				this.matchCode = val
			}

		},
		onChangeAccountNumber(val) {
			this.acctNumber = val
		},
		onChangeVat(val) {
			this.vatValue = val
		},
		runActiveAccountNumbersFortnox(key, type, acc) {
			if (parseInt(acc) === ACCOUNT_TYPE.FORTNOX) {
				this.getVoucherSeriesForSubscription(key, type, acc)
				this.getPricelistForSubscription(key, type, acc)
				this.getPaymentTermForSubscription(key, type, acc)
				this.getActiveAccountNumbers(key, type, acc)
			}

		},
		async runPaypalSupplierDetails(key, type, acc) {
			if (parseInt(acc) === ACCOUNT_TYPE.FORTNOX && parseInt(type) === APP.PAYPAL_SUPPLIER) {
				this.getPaypalSupplierDropdown(key)
				this.getVismaForeignPaymentCode(key)
			}
		},
		runActiveAccountVisma(key, type, acc) {
			if (parseInt(acc) === ACCOUNT_TYPE.VISMA) {
				this.getActiveAccountNumbers(key, type, acc)
			}
		},
		getVoucherSeriesForSubscription(key, type, acc) {
			if ((parseInt(acc) === ACCOUNT_TYPE.FORTNOX) && (VOUCHER_SERIES_FORTNOX.includes(parseInt(type)))) {
				this.getVoucherSeries(key)
			}
			if ((parseInt(acc) === ACCOUNT_TYPE.VISMA) && (VOUCHER_SERIES_VISMA.includes(parseInt(type)))) {
				this.getVoucherSeries(key)
			}
		},
		getPricelistForSubscription(key, type, acc) {
			if ((parseInt(acc) === ACCOUNT_TYPE.FORTNOX) && (PRICE_LIST_SUBSCRIPTION_FORTNOX.includes(parseInt(type)))) {
				this.getPriceList(key)
			}
		},
		getPaymentTermForSubscription(key, type, acc) {
			if ((parseInt(acc) === ACCOUNT_TYPE.FORTNOX) && (PAYMENT_TERM_FIELD_FORTNOX.includes(parseInt(type)))) {
				this.getPaymentTermOption(key)
			}
			if ((parseInt(acc) === ACCOUNT_TYPE.VISMA) && (PAYMENT_TERM_FIELD_VISMA.includes(parseInt(type)))) {
				this.getPaymentTermOption(key)
			}
		},
		getCostCenter(subID, type, acc) {
			if (COST_CENTER_LIST.includes(parseInt(type)) && parseInt(acc) === ACCOUNT_TYPE.FORTNOX) {
				const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.GET_COST_CENTER + `${subID}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.GET_COST_CENTER + `${subID}/`

				this.$store.dispatch('customer/customGetRequest', {URL})
					.then(res => {
						this.costCenterList = res.data.data
					}).catch((err) => {
					// this.showCustomAccount = false
					if (err.response.status === 400) {
						if (err.response.data.code === 401) {
							this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
								.then(_ => {
									this.getCostCenter(subID)
								})
								.catch(_ => {
									// console.log('here')
									this.$store.commit('auth/CLEAR_AUTH_USER', null)
									window.Bus.$emit('sign-out')
								})
						}
					}
				})
			}
		},
		getSubscriptionCostCenter(subID, type, acc) {
			if (COST_CENTER_LIST.includes(parseInt(type)) && parseInt(acc) === ACCOUNT_TYPE.FORTNOX) {
				const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.GET_SUBSCRIPTION_COST_CENTER + `${subID}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.GET_SUBSCRIPTION_COST_CENTER + `${subID}/`

				this.$store.dispatch('customer/customGetRequest', {URL})
					.then(res => {
						// console.log('Acccounts::::::', res)
						if (res.data.data.length > 0) {
							this.costCenterDbList = res.data.data
						}
					}).catch((err) => {
					// this.showCustomAccount = false
					if (err.response.status === 400) {
						if (err.response.data.code === 401) {
							this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
								.then(_ => {
									this.getCostCenter(subID)
								})
								.catch(_ => {
									// console.log('here')
									this.$store.commit('auth/CLEAR_AUTH_USER', null)
									window.Bus.$emit('sign-out')
								})
						}
					}
				})
			}
		},
		DeliveryData(key) {
			this.getWaysOfDeliveryOption(key)
			this.getWaysOfDeliverySubscription(key)
			this.getTermOfDeliveryOption(key)
		},
		PaymentTermsData(key) {
			this.getPaymentProvider()
			this.getPaymentTermOption(key) // Already calleds
			this.getPaymentTermSubscription(key)
		},
		showFileUploader(type) {
			if (SHOW_FILE_UPLOADER.includes(parseInt(type))) {
				this.showFileHandler = true
			} else {
				this.showFileHandler = false
			}
		},
		convertHTML(html) {
			// Create a new div element
			var tempDivElement = document.createElement('div')
			// Set the HTML content with the given value
			tempDivElement.innerHTML = html
			// Retrieve the text property of the element
			return tempDivElement.textContent || tempDivElement.innerText || ''
		},
		redirectVisma() {
			this.connecting = true
			const {key, type} = this.$route.query
			const obj = {
				id: key,
				app_type: type
			}
			setTimeout(() => {
				window.sessionStorage.setItem('vsubs', JSON.stringify(obj))
				this.connecting = false
				window.open(this.vismaLink, '_self')
			}, 1000)
		},
		redirectFortnox() {
			this.connecting = true
			const {key, type} = this.$route.query
			const obj = {
				id: key,
				app_type: type
			}
			setTimeout(() => {
				window.sessionStorage.setItem('fsubs', JSON.stringify(obj))
				this.connecting = false
				window.open(this.fortnoxLink, '_self')
			}, 1000)
		},
		deleteCustomAccount(customAcId) {
			const {key} = this.$route.query
			const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.DELETE_CUSTOM_ACCOUNT + `${this.subId}/${customAcId}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.DELETE_CUSTOM_ACCOUNT + `${this.subId}/${customAcId}/`
			// this.$services.axios.defaults.headers.common.Authorization = `Bearer ${this.USER_TOKEN}`
			// this.$services.axios.delete(URL)
			this.$store.dispatch('customer/deleteCustomAccount', {URL})
				.then(res => {
					bus.$emit('hide-notify-me', '')
					// console.log(res)
					if (res.status === 204) {
						this.getCustomAccounts(key)
						this.$services.helpers.notification(this.$t('deletedCustom'), 'success', this)
					}
				}).catch((err) => {
				bus.$emit('hide-notify-me', '')
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {

							})
							.catch(_ => {
								// console.log('here')
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
			// delete data from dedicated server

		},
		getVoucherSeries(param) {
			// this.$services.endpoints.GET_VOUCHER_SERIES + `${param}/`
			const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.GET_VOUCHER_SERIES + `${param}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.GET_VOUCHER_SERIES + `${param}/`
			this.$store.dispatch('customer/getVoucherSeries', {URL: URL})
				.then(response => {
					let self = this
					this.voucherSeries = response.data.data


				})
				.catch(err => {
					if (err.response.status === 400) {
						if (err.response.data.code === 401) {
							this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
								.then(_ => {
									this.getVoucherSeries(param)

								})
								.catch(_ => {
									// console.log('here')
									this.$store.commit('auth/CLEAR_AUTH_USER', null)
									window.Bus.$emit('sign-out')
								})
						}
					}
				})
		},
		getPaypalSupplierDropdown(param) {
			this.$store.dispatch('customer/getPaypalSupplier', {URL: this.$services.endpoints.GET_PAYPAL_SUPPLIER_DETAILS + `${param}/`})
				.then(response => {
					// console.log('supplier:', response)
					this.paypalSupplierDetails = response.data.data
				})
				.catch(err => {
					if (err.response.status === 400) {
						if (err.response.data.code === 401) {
							this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
								.then(_ => {
									this.getPaypalSupplierDropdown(param)
								})
								.catch(_ => {
									// console.log('here')
									this.$store.commit('auth/CLEAR_AUTH_USER', null)
									window.Bus.$emit('sign-out')
								})
						}
					}
				})
		},
		getPriceList(key) {
			const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.GET_PRICE_LIST + `${key}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.GET_PRICE_LIST + `${key}/`

			this.$store.dispatch('customer/getPriceList', {URL})
				.then(response => {
					this.priceList = response.data.data
				})
				.catch(err => {
					if (err.response.status === 400) {
						if (err.response.data.code === 401) {
							this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
								.then(_ => {
									this.getPriceList(key)
								})
								.catch(_ => {
									// console.log('here')
									this.$store.commit('auth/CLEAR_AUTH_USER', null)
									window.Bus.$emit('sign-out')
								})
						}
					}
				})
		},
		activateSubAfterSku() {
			const {key} = this.$route.query
			if (key) {
				this.activating = true
				const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.ACTIVATE_SUBSCRIPTION + `${key}/1/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.ACTIVATE_SUBSCRIPTION + `${key}/1/`
				this.$store.dispatch('customer/activateSubscription', {URL})
					.then(res => {
						this.activating = false
						if (res.status === 200) {
							this.$services.helpers.notification(this.$t('activatedSubscription'), 'success', this)
							this.getSubscriptionConfig(key)
							// this.getSubscriptionList(key)
							this.subscriptionDeactivate = true
							this.subscriptionActivate = false
						}
					}).catch((err) => {
					this.activating = false
					if (err.response.status === 400) {
						if (err.response.data.code === 401) {
							this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
								.then(_ => {
									// this.getSubscriptionConfig()
								})
								.catch(_ => {
									// console.log('here')
									this.$store.commit('auth/CLEAR_AUTH_USER', null)
									window.Bus.$emit('sign-out')
								})
						}
					}
				})
			}
		},
		activateSubscription() {
			const {key} = this.$route.query

			if (key) {
				if (SKU_VALIDATOR.includes(parseInt(this.appType))) {
					// const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.SKU_ACTIVATE_SUBSCRIPTION + `${key}/1/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.SKU_ACTIVATE_SUBSCRIPTION + `${key}/1/`
					if (this.currentSubscription.length > 0) {
						let URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.SKU_ACTIVATE_SUBSCRIPTION + `${key}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.SKU_ACTIVATE_SUBSCRIPTION + `${key}/`
						bus.$emit('sku-modal', {URL: URL, 'server': this.currentSubscription[0].dedicated_server})
					}

				} else {
					this.activating = true
					const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.ACTIVATE_SUBSCRIPTION + `${key}/1/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.ACTIVATE_SUBSCRIPTION + `${key}/1/`
					this.$store.dispatch('customer/activateSubscription', {URL})
						.then(res => {
							this.activating = false
							// console.log('Activated app:', res)
							if (res.status === 200) {
								this.$services.helpers.notification(this.$t('activatedSubscription'), 'success', this)
								this.getSubscriptionConfig(key)
								this.subscriptionDeactivate = true
								this.subscriptionActivate = false
							}
						}).catch((err) => {
						this.activating = false
						if (err.response.status === 400) {
							if (err.response.data.code === 401) {
								this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
									.then(_ => {
										// this.getSubscriptionConfig()
									})
									.catch(_ => {
										// console.log('here')
										this.$store.commit('auth/CLEAR_AUTH_USER', null)
										window.Bus.$emit('sign-out')
									})
							}
						}
					})
				}
			}
		},
		deactivateSubscription() {
			const {key} = this.$route.query
			if (key) {
				this.deactivating = true
				const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.ACTIVATE_SUBSCRIPTION + `${key}/0/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.ACTIVATE_SUBSCRIPTION + `${key}/0/`
				this.$store.dispatch('customer/deactivateSubscription', {URL})
					.then(res => {
						this.deactivating = false
						if (res.status === 200) {
							this.$services.helpers.notification(this.$t('deactivatedSubscription'), 'success', this)
							this.getSubscriptionConfig(key)
							this.subscriptionDeactivate = false
							this.subscriptionActivate = true
						}
					}).catch((err) => {
					this.deactivating = false
					if (err.response.status === 400) {
						if (err.response.data.code === 401) {
							this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
								.then(_ => {
									// this.getSubscriptionConfig()
								})
								.catch(_ => {
									// console.log('here')
									this.$store.commit('auth/CLEAR_AUTH_USER', null)
									window.Bus.$emit('sign-out')
								})
						}
					}
				})
			}
		},
		autoRefreshFortnoxToken(subID) {
			const URL = this.$services.endpoints.AUTO_REFRESH_FORTNOX_SUBSCRIPTIONS_TOKEN + `${subID}/`
			this.$store.dispatch('customer/customPutRequest', {URL: URL, 'data': {}}).then(_ => {
				this.showForm = true
				this.getSubscriptionConfig(subID)
				this.getSubscriptionList(subID)
			}).catch(err => {
				this.showForm = false
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								// console.log('hey me')
								this.autoRefreshFortnoxToken(subID)
							})
							.catch(_ => {
								// console.log('here')
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					} else {
						this.showForm = false
					}

				}
			})
		},
		updateAppSettings() {
			if (document.getElementById('id_booking_date')) {
				let currentSubscription = this.currentSubscription
				// let currentDate = currentSubscription[0].created
				const subscriptionCreatedDate = currentSubscription.created
				const subcriptionDate = document.getElementById('id_booking_date').value
				const difference = this.$services.moment(subcriptionDate).diff(subscriptionCreatedDate, 'days')
				if (difference < -6 && subcriptionDate) {
					this.$services.helpers.notification(this.$t('daysBack'), 'error', this)
					return false
				}
			}
			const dataObject = {}
			const customV3Object = {}
			const reconciliationAccounts = {}
			const proAccounts = {}
			const voucherAccounts = {}
			const arraySetting = Object.keys(this.shopSettingsCopy)
			let hasError = false
			let errorMessage = ''
			let _self = this
			arraySetting.forEach(element => {
				if (document.getElementById(`id_${element}`)) {
					if (element === 'master_location') {
						if (document.getElementById(`id_${element}`).value) {
							if (document.getElementById(`id_${element}`).value === 'all') {
								dataObject[element] = ''
							} else {
								dataObject[element] = document.getElementById(`id_${element}`).value
							}
						} else {
							hasError = true
							errorMessage = _self.$t('fieldRequired')
						}
					} else {
						if (element === 'swish_bank_type' || element === "swish_bank_id") {
							if (element === 'swish_bank_type') {
								dataObject['swish_bank_type'] = document.getElementById(`id_swish_bank_type`).value
								if (document.getElementById(`id_swish_bank_type`).value !== 'seb') {
									dataObject['swish_bank_id'] = 0
								} else {
									dataObject['swish_bank_id'] = document.getElementById(`id_swish_bank_id`).value
								}
							}
						} else if (element === 'na_supported_countries') {
							dataObject[element] = window.$(`#id_${element}`).val()
						} else if (document.getElementById(`id_${element}`).hasAttribute('is_multiple_select')) {
							if (window.$(`#id_${element}`).hasClass("select2-hidden-accessible")) {
								dataObject[element] = window.$(`#id_${element}`).select2('val')
							} else {
								dataObject[element] = window.$(`#id_${element}`).val()
							}
						} else {
							if (document.getElementById(`id_${element}`).hasAttribute('checked')) {
								dataObject[element] = document.getElementById(`id_${element}`).checked
							} else if (document.getElementById(`id_${element}`).classList.contains('hasDatepicker') && document.getElementById(`id_${element}`).value) {
								dataObject[element] = moment(document.getElementById(`id_${element}`).value).format('YYYY-MM-DD')
							} else {
								if (document.getElementById(`id_${element}`).value) {
									dataObject[element] = document.getElementById(`id_${element}`).value
								} else {
									hasError = true
									errorMessage = _self.$t('fieldRequired')
								}
							}

						}

					}

				}
			})
			if (hasError === true) {
				this.$services.helpers.notification(errorMessage, 'error', this)
				return false
			}
			// swish handel validation
			try {
				if (document.getElementById('id_swish_bank_type')) {
					let swish_bank_type = document.getElementById('id_swish_bank_type').value
					if (swish_bank_type === 'seb') {
						if (window.$('#id_swish_bank_id').val() === '') {
							this.$services.helpers.notification('SEB Swish bankreferens är obligatoriskt', 'error', this)
							return false
						}
					}
				}
			} catch (e) {
			}
			hasError = false
			if (CUSTOM_V3_APPS_FORTNOX.includes(parseInt(this.app_type)) || CUSTOM_V3_APPS_VISMA.includes(parseInt(this.app_type))) {
				const customv3 = document.querySelectorAll('.custom-v3-class')
				customv3.forEach(element => {
					// getAttribute
					if (_self.customAccountList[element.getAttribute('custom')]) {
						customV3Object[element.getAttribute('custom')] = _self.customAccountList[element.getAttribute('custom')]
					} else {
						hasError = true
						errorMessage = _self.$t('allAccountFieldRequired')
					}
				})
			}
			if (hasError === true) {
				this.$services.helpers.notification(errorMessage, 'error', this)
				return false
			}
			hasError = false
			if (Object.values(this.reconciliations).includes(true)) {
				for (const [key, value] of Object.entries(this.reconciliations)) {
					if (value === true) {
						if (this.reconciliatonAccountList[key].acct_no === '') {
							this.$services.helpers.notification(errorMessage, 'error', this)
							return false
						} else {
							reconciliationAccounts[key] = this.reconciliatonAccountList[key].acct_no
						}
					}
				}
			} // validation for reconciliation apps

			hasError = false
			if (Object.values(this.pro_app).includes(true)) {
				// const propFields = document.querySelectorAll('.pro-app')
				for (const [key, value] of Object.entries(this.pro_app)) {
					if (value === true) {
						if (this.proAccountList[key].acct_no === '') {
							this.$services.helpers.notification(errorMessage, 'error', this)
							return false
						} else {
							proAccounts[key] = this.proAccountList[key].acct_no
						}
					}
				}
			} // validation for prop apps
			if (hasError === true) {
				this.$services.helpers.notification(errorMessage, 'error', this)
				return false
			}
			hasError = false
			if (Object.values(this.voucher_app).includes(true)) {
				for (const [key, value] of Object.entries(this.voucher_app)) {
					if (value === true) {
						if (this.voucherAccountList[key].acct_no === '') {
							this.$services.helpers.notification(errorMessage, 'error', this)
							return false
						} else {
							voucherAccounts[key] = this.voucherAccountList[key].acct_no
						}
					}
				}
			} // validation for vouchers apps
			if (this.customAppForSalesAccountVisma.includes(parseInt(this.$route.query.type)) === true && parseInt(this.$route.query.acc) === 1) {
				if (!this.customSalesAccountVisma) {
					this.$services.helpers.notification(_self.$t('fieldRequired'), 'error', this)
					return false
				}
			}

			if (hasError === true) {
				this.$services.helpers.notification(errorMessage, 'error', this)
				return false
			}
			hasError = false
			this.updating = true
			const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.GET_USER_APP_CONFIG + `${this.subId}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.GET_USER_APP_CONFIG + `${this.subId}/`
			this.$store.dispatch('customer/updateAppSettings', {URL, dataObject})
				.then(res => {
					if (res.data.success) {
						this.updating = false
						this.$services.helpers.notification(this.$t('updateApp'), 'success', this)
					}
				}).catch((err) => {
				this.updating = false
				// console.log(err.response)
				this.$services.helpers.notification(this.$t('generic_error'), 'error', this)
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
							})
							.catch(_ => {
								// console.log('here')
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
			if (CUSTOM_V3_APPS_FORTNOX.includes(parseInt(this.app_type)) && parseInt(this.$route.query.acc) === 0) {
				let success = true
				const {type, key} = this.$route.query
				Object.keys(customV3Object).forEach(element => {
					const customValues = this.customV3ListAccounts.filter(el => el.key === element)
					const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.CREATE_CUSTOM_ACCOUNT + `${this.subId}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.CREATE_CUSTOM_ACCOUNT + `${this.subId}/`
					const payload = {
						subscription: type,
						acct_type: "3",
						match_type: 1,
						match_code: customValues.length > 0 ? customValues[0].match : '',
						acct_no: customV3Object[element]
					}
					let accountExist = []
					if (['WO', 'EU'].includes(payload.match_code)) {
						accountExist = this.customAccounts.filter(item => String(item.match_code) === String(customValues[0].match))
					} else {
						accountExist = this.customAccounts.filter(item => String(item.vat_rate) === String(customValues[0].vat))
					}
					if (customValues[0].vat !== '') {
						payload.vat_rate = customValues[0].vat ? customValues[0].vat : ''
					}
					if (accountExist.length > 0) {
						let URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.UPDATE_CUSTOM_ACCOUNT + `${this.subId}/${accountExist[0].id}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.UPDATE_CUSTOM_ACCOUNT + `${this.subId}/${accountExist[0].id}/`
						this.$store.dispatch('customer/updateCustomAccount', {data: payload, URL})
							.then(() => {
							})
							.catch(err => {
								success = false
								this.updating = false
								if (err.response.status === 400) {
									if (err.response.data.code === 400) {
										this.$services.helpers.notification(err.response.data.data, 'error', this)
									}

									if (err.response.data.code === 401) {
										this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
											.then(_ => {
												// this.updateAppSettings()
											})
											.catch(_ => {
												// console.log('here')
												this.$store.commit('auth/CLEAR_AUTH_USER', null)
												window.Bus.$emit('sign-out')
											})
									}
								}
							})
					} else {
						this.$store.dispatch('customer/addCustomAccount', {data: payload, URL})
							.then(res => {
								this.customAccounts.push(res.data.data)

							})
							.catch(err => {
								success = false
								this.updating = false
								if (err.response.status === 400) {
									if (err.response.data.code === 400) {
										this.$services.helpers.notification(err.response.data.data, 'error', this)
									}

									if (err.response.data.code === 401) {
										this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
											.then(_ => {
												// this.updateAppSettings()
											})
											.catch(_ => {
												// console.log('here')
												this.$store.commit('auth/CLEAR_AUTH_USER', null)
												window.Bus.$emit('sign-out')
											})
									}
								}
							})
					}

				})
				if (success) {
					this.updating = false
					this.errorFlag = false
					// this.$services.helpers.notification(this.$t('updateApp'), 'success', this)
				}
			}
			if (CUSTOM_V3_APPS_VISMA.includes(parseInt(this.app_type)) && [1].includes(parseInt(this.$route.query.acc)) === true) {
				let success = true
				const {type, key} = this.$route.query
				Object.keys(customV3Object).forEach(element => {
					const customValues = this.customV3ListAccounts.filter(el => el.key === element)
					let URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.CREATE_CUSTOM_ACCOUNT + `${this.subId}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.CREATE_CUSTOM_ACCOUNT + `${this.subId}/`
					const payload = {
						subscription: type,
						acct_type: "3",
						match_type: 1,
						match_code: customValues.length > 0 ? customValues[0].match : '',
						acct_no: customV3Object[element]
					}
					let accountExist = this.customAccounts.filter(item => String(item.vat_rate) === String(customValues[0].vat))
					if (customValues[0].vat !== '') {
						payload.vat_rate = customValues[0].vat ? customValues[0].vat : ''
					}
					if (accountExist.length > 0) {
						let URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.UPDATE_CUSTOM_ACCOUNT + `${this.subId}/${accountExist[0].id}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.UPDATE_CUSTOM_ACCOUNT + `${this.subId}/${accountExist[0].id}/`
						this.$store.dispatch('customer/updateCustomAccount', {data: payload, URL})
							.then(res => {
								// if (document.getElementById(`id_${element}`)) {
								// 	window.$(document.getElementById(`id_${element}`)).val(payload.acct_no).trigger('change')
								// }
							})
							.catch(err => {
								success = false
								this.updating = false
								if (err.response.status === 400) {
									if (err.response.data.code === 400) {
										this.$services.helpers.notification(err.response.data.data, 'error', this)
									}

									if (err.response.data.code === 401) {
										this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
											.then(_ => {
												// this.updateAppSettings()
											})
											.catch(_ => {
												// console.log('here')
												this.$store.commit('auth/CLEAR_AUTH_USER', null)
												window.Bus.$emit('sign-out')
											})
									}
								}
							})
					} else {
						this.$store.dispatch('customer/addCustomAccount', {data: payload, URL})
							.then(res => {
								this.v3CustomAccount.push(res.data.data)
								this.customAccounts.push(res.data.data)
								// if (document.getElementById(`id_${element}`)) {
								// 	window.$(document.getElementById(`id_${element}`)).val(payload.acct_no).trigger('change')
								// }
							})
							.catch(err => {
								success = false
								this.updating = false
								if (err.response.status === 400) {
									if (err.response.data.code === 400) {
										this.$services.helpers.notification(err.response.data.data, 'error', this)
									}

									if (err.response.data.code === 401) {
										this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
											.then(_ => {
												// this.updateAppSettings()
											})
											.catch(_ => {
												// console.log('here')
												this.$store.commit('auth/CLEAR_AUTH_USER', null)
												window.Bus.$emit('sign-out')
											})
									}
								}
							})
					}


				})
			}
			if (Object.values(this.reconciliations).includes(true)) {
				Object.keys(reconciliationAccounts).map(item => {
					const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.CREATE_CUSTOM_ACCOUNT + `${this.subId}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.CREATE_CUSTOM_ACCOUNT + `${this.subId}/`
					let payload = this.reconciliatonAccountList[item]
					payload['acct_no'] = reconciliationAccounts[item]
					this.reconciliatonAccountList[item].acct_no = reconciliationAccounts[item]
					payload.subscription = this.$route.query.key
					if (payload['id'] !== null && payload['id'] !== '') {
						let URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.UPDATE_CUSTOM_ACCOUNT + `${this.subId}/${payload.id}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.UPDATE_CUSTOM_ACCOUNT + `${this.subId}/${payload.id}/`
						this.$store.dispatch('customer/updateCustomAccount', {data: payload, URL})
							.then(res => {
								this.reconciliatonAccountList[item].id = res.data.data.id

							})
							.catch(err => {
								if (err.response.status === 400) {
									if (err.response.data.code === 400) {
										this.$services.helpers.notification(err.response.data.data, 'error', this)
									}

									if (err.response.data.code === 401) {
										this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
											.then(_ => {
												// this.updateAppSettings()
											})
											.catch(_ => {
												// console.log('here')
												this.$store.commit('auth/CLEAR_AUTH_USER', null)
												window.Bus.$emit('sign-out')
											})
									}
								}
							})
					} else {
						delete payload.id
						this.$store.dispatch('customer/addCustomAccount', {data: payload, URL})
							.then(res => {
								this.reconciliatonAccountList[item].id = res.data.data.id
								this.customAccounts.push(res.data.data)
							})
							.catch(err => {
								this.updating = false
								if (err.response.status === 400) {
									if (err.response.data.code === 400) {
										this.$services.helpers.notification(err.response.data.data, 'error', this)
									}

									if (err.response.data.code === 401) {
										this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
											.then(_ => {
												// this.updateAppSettings()
											})
											.catch(_ => {
												// console.log('here')
												this.$store.commit('auth/CLEAR_AUTH_USER', null)
												window.Bus.$emit('sign-out')
											})
									}
								}
							})
					}


				})
			}
			if (Object.values(this.pro_app).includes(true)) {
				Object.keys(proAccounts).map(item => {
					const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.CREATE_CUSTOM_ACCOUNT + `${this.subId}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.CREATE_CUSTOM_ACCOUNT + `${this.subId}/`
					let payload = this.proAccountList[item]
					payload.acct_no = proAccounts[item]
					this.proAccountList[item].acct_no = proAccounts[item]
					payload.subscription = this.$route.query.key
					if (payload['id'] !== null && payload['id'] !== '') {
						let URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.UPDATE_CUSTOM_ACCOUNT + `${this.subId}/${payload.id}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.UPDATE_CUSTOM_ACCOUNT + `${this.subId}/${payload.id}/`
						this.$store.dispatch('customer/updateCustomAccount', {data: payload, URL})
							.then(res => {
								this.proAccountList[item].id = res.data.data.id
								// if (document.getElementById(`${item}`)) {
								// 	window.$(document.getElementById(`${item}`)).val(payload.acct_no).trigger('change')
								// }
							})
							.catch(err => {
								if (err.response.status === 400) {
									if (err.response.data.code === 400) {
										this.$services.helpers.notification(err.response.data.data, 'error', this)
									}

									if (err.response.data.code === 401) {
										this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
											.then(_ => {
												// this.updateAppSettings()
											})
											.catch(_ => {
												// console.log('here')
												this.$store.commit('auth/CLEAR_AUTH_USER', null)
												window.Bus.$emit('sign-out')
											})
									}
								}
							})
					} else {
						delete payload.id
						this.$store.dispatch('customer/addCustomAccount', {data: payload, URL})
							.then(res => {
								this.proAccountList[item].id = res.data.data.id
								this.customAccounts.push(res.data.data)
								// if (document.getElementById(`${item}`)) {
								// 	window.$(document.getElementById(`${item}`)).val(payload.acct_no).trigger('change')
								// }
							})
							.catch(err => {
								this.updating = false
								if (err.response.status === 400) {
									if (err.response.data.code === 400) {
										this.$services.helpers.notification(err.response.data.data, 'error', this)
									}

									if (err.response.data.code === 401) {
										this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
											.then(_ => {
												// this.updateAppSettings()
											})
											.catch(_ => {
												// console.log('here')
												this.$store.commit('auth/CLEAR_AUTH_USER', null)
												window.Bus.$emit('sign-out')
											})
									}
								}
							})
					}


				})
			}
			if (Object.values(this.voucher_app).includes(true)) {
				Object.keys(voucherAccounts).map(item => {
					const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.CREATE_CUSTOM_ACCOUNT + `${this.subId}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.CREATE_CUSTOM_ACCOUNT + `${this.subId}/`
					let payload = this.voucherAccountList[item]
					payload.acct_no = voucherAccounts[item]
					this.voucherAccountList[item].acct_no = voucherAccounts[item]
					payload.subscription = this.$route.query.key
					if (payload['id'] !== null && payload['id'] !== '') {
						let URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.UPDATE_CUSTOM_ACCOUNT + `${this.subId}/${payload.id}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.UPDATE_CUSTOM_ACCOUNT + `${this.subId}/${payload.id}/`
						this.$store.dispatch('customer/updateCustomAccount', {data: payload, URL})
							.then(res => {
								this.voucherAccountList[item].id = res.data.data.id
								// if (document.getElementById(`${item}`)) {
								// 	window.$(document.getElementById(`${item}`)).val(payload.acct_no).trigger('change')
								// }
							})
							.catch(err => {
								if (err.response.status === 400) {
									if (err.response.data.code === 400) {
										this.$services.helpers.notification(err.response.data.data, 'error', this)
									}

									if (err.response.data.code === 401) {
										this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
											.then(_ => {
												// this.updateAppSettings()
											})
											.catch(_ => {
												// console.log('here')
												this.$store.commit('auth/CLEAR_AUTH_USER', null)
												window.Bus.$emit('sign-out')
											})
									}
								}
							})
					} else {
						delete payload.id
						this.$store.dispatch('customer/addCustomAccount', {data: payload, URL})
							.then(res => {
								this.voucherAccountList[item].id = res.data.data.id
								this.customAccounts.push(res.data.data)
								// if (document.getElementById(`${item}`)) {
								// 	window.$(document.getElementById(`${item}`)).val(payload.acct_no).trigger('change')
								// }
							})
							.catch(err => {
								this.updating = false
								if (err.response.status === 400) {
									if (err.response.data.code === 400) {
										this.$services.helpers.notification(err.response.data.data, 'error', this)
									}

									if (err.response.data.code === 401) {
										this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
											.then(_ => {
												// this.updateAppSettings()
											})
											.catch(_ => {
												// console.log('here')
												this.$store.commit('auth/CLEAR_AUTH_USER', null)
												window.Bus.$emit('sign-out')
											})
									}
								}
							})
					}


				})
			}
			if (this.customAppForSalesAccountVisma.includes(parseInt(this.$route.query.type)) === true && parseInt(this.$route.query.acc) === 1) {
				Object.keys(this.customAppSalesAccountList).map(key => {
					let payload = this.customAppSalesAccountList[key]
					payload['acct_no'] = this.customSalesAccountVisma
					let URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.CREATE_CUSTOM_ACCOUNT + `${this.subId}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.CREATE_CUSTOM_ACCOUNT + `${this.subId}/`
					if (payload['id'] !== null && payload['id'] !== '') {
						let URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.UPDATE_CUSTOM_ACCOUNT + `${this.subId}/${payload.id}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.UPDATE_CUSTOM_ACCOUNT + `${this.subId}/${payload.id}/`
						this.$store.dispatch('customer/updateCustomAccount', {data: payload, URL})
							.then(res => {
								this.customAppSalesAccountList[key].id = res.data.data.id
							})
							.catch(err => {
								if (err.response.status === 400) {
									if (err.response.data.code === 400) {
										this.$services.helpers.notification(err.response.data.data, 'error', this)
									}
									if (err.response.data.code === 401) {
										this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
											.then(_ => {
												// this.updateAppSettings()
											})
											.catch(_ => {
												// console.log('here')
												this.$store.commit('auth/CLEAR_AUTH_USER', null)
												window.Bus.$emit('sign-out')
											})
									}
								}
							})
					} else {
						delete payload.id
						this.$store.dispatch('customer/addCustomAccount', {data: payload, URL})
							.then(res => {
								this.customAppSalesAccountList[key].id = res.data.data.id
							})
							.catch(err => {
								this.updating = false
								if (err.response.status === 400) {
									if (err.response.data.code === 400) {
										this.$services.helpers.notification(err.response.data.data, 'error', this)
									}

									if (err.response.data.code === 401) {
										this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
											.then(_ => {
												// this.updateAppSettings()
											})
											.catch(_ => {
												// console.log('here')
												this.$store.commit('auth/CLEAR_AUTH_USER', null)
												window.Bus.$emit('sign-out')
											})
									}
								}
							})
					}
				})
			}

		},
		getCustomAccounts(subID) {
			const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.GET_CUSTOM_ACCOUNT_SUB + `${subID}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.GET_CUSTOM_ACCOUNT_SUB + `${subID}/`
			this.$store.dispatch('customer/getCustomAccounts', {URL})
				.then(res => {
					let self = this
					let exclude_dict = ["EU", "WO", "SE", 0.25, 0.12, 0.06, 0]
					this.customAccounts = []
					let customAccountListDict = {
						0.12: 'sales_account_se_twelve',
						0.06: 'sales_account_se_six',
						0.25: 'sales_account_se_twenty_five',
					}
					this.customAccountList = {
						'sales_account_wo': '',
						'sales_account_eu': '',
						'sales_account_se_six': '',
						'sales_account_se_twelve': '',
						'sales_account_se_twenty_five': '',
					}

					if (res.data.success) {
						this.customAccounts = res.data.data
						if (CUSTOM_V3_APPS_VISMA.includes(parseInt(this.$route.query.type)) && [1].includes(parseInt(this.$route.query.acc)) === true) {
							this.v3CustomAccount = res.data.data.filter(item => CUSTOM_VAT_RATE_V3_APP_VISMA.includes(String(item.vat_rate)) === true && item.for_company === false && item.for_fee_lines === false && item.for_gift_cards === false && item.for_recurring_transactions === false && item.for_shipping === false)
							setTimeout(function () {
								self.v3CustomAccount.map(item => {
									if (String(item.vat_rate) === '0.25') {
										self.customAccountList.sales_account_se_twenty_five = item.acct_no
									} else if (String(item.vat_rate) === '0.12') {
										self.customAccountList.sales_account_se_twelve = item.acct_no
									} else if (String(item.vat_rate) === '0.06') {
										self.customAccountList.sales_account_se_six = item.acct_no
									}

								})
							}, 500)
						} else {
							setTimeout(function () {
								res.data.data.map(item => {
									if (item.match_code === "EU") {
										self.customAccountList.sales_account_eu = item.acct_no
										// window.$(`#id_sales_account_eu`).val(item.acct_no).trigger('change');
									} else if (item.match_code === "WO") {
										self.customAccountList.sales_account_wo = item.acct_no
										// window.$(`#id_sales_account_wo`).val(item.acct_no).trigger('change');
									}
								})
								Object.keys(customAccountListDict).map(key => {
									let exist = res.data.data.filter(dt => String(dt.vat_rate) === String(key) && dt.for_company === false && dt.for_fee_lines === false && dt.for_gift_cards === false && dt.for_recurring_transactions === false && dt.for_shipping === false)
									if (exist.length > 0) {
										self.customAccountList[customAccountListDict[key]] = exist[0].acct_no
									}
								})
								Object.keys(self.reconciliatonAccountList).map(val => {
									let filtered = res.data.data.filter(item => parseInt(item.acct_type) === parseInt(self.reconciliatonAccountList[val].acct_type) && item.match_code === self.reconciliatonAccountList[val].match_code && item.vat_rate === null)
									if (filtered.length > 0) {
										self.reconciliatonAccountList[val].acct_no = filtered[0].acct_no
										self.reconciliatonAccountList[val].id = filtered[0].id
									} else {

										if (document.getElementById(val)) {
											window.$(`#${val}`).val('').trigger('change');
										}
									}
								})
								Object.keys(self.proAccountList).map(val => {
									let filtered = res.data.data.filter(item => parseInt(item.acct_type) === parseInt(self.proAccountList[val].acct_type) && item.match_code === self.proAccountList[val].match_code && item.vat_rate === null)
									if (filtered.length > 0) {
										self.proAccountList[val].acct_no = filtered[0].acct_no
										self.proAccountList[val].id = filtered[0].id
										if (document.getElementById(val)) {
											window.$(`#${val}`).val(self.proAccountList[val].acct_no).trigger('change');
										}
									} else {
										if (document.getElementById(val)) {
											window.$(`#${val}`).val('').trigger('change');
										}
									}
								})
								Object.keys(self.voucherAccountList).map(val => {
									let filtered = res.data.data.filter(item => parseInt(item.acct_type) === parseInt(self.voucherAccountList[val].acct_type) && item.match_code === self.voucherAccountList[val].match_code && item.vat_rate === null)
									if (filtered.length > 0) {
										self.voucherAccountList[val].acct_no = filtered[0].acct_no
										self.voucherAccountList[val].id = filtered[0].id
									} else {
										if (document.getElementById(val)) {
											window.$(`#${val}`).val('').trigger('change');
										}
									}
								})
							}, 500)
						}
						if (this.customAppForSalesAccountVisma.includes(parseInt(this.$route.query.type)) === true && parseInt(this.$route.query.acc) === 1) {
							Object.keys(this.customAppSalesAccountList).map(key => {
								let item = this.customAppSalesAccountList[key]
								let dt = res.data.data.filter(dt => String(dt.vat_rate) === String(item.vat_rate) && String(item.match_type) === String(dt.match_type) && String(item.acct_type) === String(dt.acct_type))
								if (dt.length > 0) {
									this.customSalesAccountVisma = dt[0].acct_no
									item.acct_no = dt[0].acct_no
									item.id = dt[0].id
								} else {
									item.id = null
									item.acct_no = ''
									this.customSalesAccountVisma = ''
								}
								this.customAppSalesAccountList[key] = item
							})
						}


					}
				}).catch((err) => {
				// this.showCustomAccount = false
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								// this.getCustomAccounts(subID)
							})
							.catch(_ => {
								// console.log('here')
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})

		},
		getSeCustomAccounts(subID) {
			const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.GET_CUSTOM_ACCOUNT_SUB + `${subID}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.GET_CUSTOM_ACCOUNT_SUB + `${subID}/`
			// this.$services.axios.defaults.headers.common.Authorization = `Bearer ${this.USER_TOKEN}`
			// this.$services.axios.get(URL)
			this.$store.dispatch('customer/getCustomAccounts', {URL})
				.then(res => {
					// console.log('Acccounts::::::', res)
					// if (res.data.success) {
					// 	console.log(this.customAccounts)
					// }
				}).catch((err) => {
				// this.showCustomAccount = false
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								this.getCustomAccounts(subID)
							})
							.catch(_ => {
								// console.log('here')
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		getAccountType() {
			// this.$services.axios.defaults.headers.common.Authorization = `Bearer ${this.USER_TOKEN}` this.$services.endpoints.GET_ACCOUNT_TYPES + `${this.subId}/`
			// this.$services.axios.get(this.$services.endpoints.GET_ACCOUNT_TYPES)
			const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.GET_ACCOUNT_TYPES + `${this.subId}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.GET_ACCOUNT_TYPES + `${this.subId}/`
			this.$store.dispatch('customer/getAccountType', {URL: URL})
				.then(res => {
					if (res.status === 200) {
						this.accountType = res.data.data
					}
				}).catch((err) => {
				// this.showCustomAccount = false
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								// this.getAccountType()
							})
							.catch(_ => {
								// console.log('here')
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		getMatchType() {
			// this.$services.axios.defaults.headers.common.Authorization = `Bearer ${this.USER_TOKEN}`
			// this.$services.axios.get(this.$services.endpoints.GET_MATCH_TYPE)
			this.$store.dispatch('customer/getMatchType', {URL: this.$services.endpoints.GET_MATCH_TYPE})
				.then(res => {
					// console.log('Match TYPE::::::', res)
					if (res.status === 200) {
						this.matchType = res.data.data
						this.matchTypeCopy = res.data.data
					}
				}).catch((err) => {
				// this.showCustomAccount = false
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								// this.getMatchType()
							})
							.catch(_ => {
								// console.log('here')
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		getCountry() {
			this.$store.dispatch('customer/getCountry', {URL: this.$services.endpoints.GET_COUNTRIES})
				.then(res => {
					// console.log('countries:', res)
					if (res.status === 200) {
						this.countries = res.data.data
						this.allCountries = res.data.data
					}
				}).catch((err) => {
				// this.showCustomAccount = false
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								// this.getCountry()
							})
							.catch(_ => {
								// console.log('here')
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
			if (this.countries.length > 0) {
				if (this.showCostCenter === true) {
					let el = document.getElementById('customer_country')
					if (el) {
						window.$(el).select2({
							width: 200
						})
						window.$(el).val("").trigger('change')
					}
				}
			}
		},
		handleVismaAdminFields() {
			if (parseInt(this.$route.query.acc) === 2) {
				let shopSettings = this.shopSettings
				let baseElement = document.getElementById('subscription-form')
				let vismaAdminFields = this.vismaAdminCustomField
				let labelDict = {}
				vismaAdminFields.map(item => {
					let selector = `for=id_${item}`
					let idSelector = `id_${item}`
					if (document.querySelector(`[${selector}]`)) {
						labelDict[item] = document.querySelector(`[${selector}]`)
					}
					if (document.getElementById(idSelector)) {
						if (document.getElementById(idSelector).hasAttribute('is_payment_term_field') ||
							document.getElementById(idSelector).hasAttribute('is_price_list_field')) {
							document.querySelector(`[${selector}]`).remove()
							document.getElementById(idSelector).remove()
							baseElement.append(labelDict[item])
							let newEl = document.createElement('INPUT')
							newEl.setAttribute("type", "text");
							newEl.setAttribute("is_payment_term_field_text", "");
							newEl.setAttribute('class', 'c-pad-setting form-control h-c28 font-light text-xs')
							newEl.setAttribute('id', `id_${item}`)
							newEl.style.height = '32px !important'
							if (shopSettings[item]) {
								newEl.value = shopSettings[item]
							}
							baseElement.append(newEl)
						}
					}

				})
			}
		},
		getSubscriptionList() {
			const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.GET_USER_SUBSCRIPTION + `${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.GET_USER_SUBSCRIPTION
			this.$store.dispatch('customer/getSubscriptionList', {URL})
				.then(_ => {
					if (this.tabId === 3) window.Bus.$emit('start-appsetting-tab3-tour')
				}).catch((err) => {
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								this.getSubscriptionList()
							})
							.catch(_ => {
								// console.log('error response')
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		async setUpAccountSetting() {
			this.vloading = true
			let self = this
			if (parseInt(this.$route.query.acc) === 0) {
				// this.getSubscriptionConfig(this.$route.query.key)
				const accountUrl = this.GET_USER_COMPANY_ID ? this.$services.endpoints.GET_ACTIVE_ACCOUNT_NUMBERS + `${this.$route.query.key}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.GET_ACTIVE_ACCOUNT_NUMBERS + `${this.$route.query.key}/`
				let rep = await this.$store.dispatch('customer/getActiveAccountNumbers', {URL: accountUrl}).then(() => {
					this.getSubscriptionConfig(this.$route.query.key)
				}).catch(er => {
					this.vloading = false
					this.fortnoxLink = er.response.data.data.redirect_url
					if (er.status === 400) {
						let error_message = er.response.data.data
						if (error_message && rep.response.data.code === 500 && parseInt(self.$route.query.acc) === 0) {
							if (error_message.includes('refreshing access_token')) {
								this.showForm = false
								return false
							}
						}
						this.getSubscriptionConfig(this.$route.query.key)
					}
				})
			} else if ([1].includes(parseInt(this.$route.query.acc)) === true) {
				const vismaUrl = this.GET_USER_COMPANY_ID ? this.$services.endpoints.VISMA_BANK_ACCOUNT_NUM + `${this.$route.query.key}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.VISMA_BANK_ACCOUNT_NUM + `${this.$route.query.key}/`
				await this.$store.dispatch('customer/getVismaBankAccountNumbers', {URL: vismaUrl}).then(() => {
					this.getSubscriptionConfig(this.$route.query.key)
				}).catch(err => {
					this.vloading = false
					if (err.response.data.hasOwnProperty('message')) {
						let message = err.response.data.message
						if (message.includes('Authorization has been denied for this request')) {
							this.vismaLink = err.response.data.redirect_url
							this.showForm = false
							return false
						}
					}
					this.getSubscriptionConfig(this.$route.query.key)
				})

			} else {
				this.getSubscriptionConfig(this.$route.query.key)
			}
		},
		async getSubscriptionConfig(param) {
			this.vloading = true
			let self = this
			const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.GET_USER_APP_CONFIG + `${param}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.GET_USER_APP_CONFIG + `${param}/`
			this.$store.dispatch('customer/getSubscriptionConfig', {URL})
				.then(res => {
					this.showForm = false
					this.showActivateSub = true
					this.vloading = false
					if (res.data.success) {
						this.shopSettings = res.data.data[Object.keys(res.data.data)[0]]
						this.shopSettingsCopy = JSON.parse(JSON.stringify(res.data.data[Object.keys(res.data.data)[0]]))
						this.subscriptionConfigResponse = res.data.data
						this.currentSubscription = res.data.data.subscription
						this.subscriptionDeactivate = false
						this.subscriptionActivate = false
						// checking fort-nox subscription token
						this.cancelledSubscriptionFlag = this.currentSubscription.sub_status
						if (this.currentSubscription.sub_status === true) {
							this.subscriptionDeactivate = true
							this.subscriptionActivate = false
						} else {
							this.subscriptionDeactivate = false
							this.subscriptionActivate = true
						}
						if (this.currentSubscription.fortnox_token_state.hasOwnProperty('fortnox_redirect_url')) {
							this.fortnoxLink = this.currentSubscription.fortnox_token_state.fortnox_redirect_url
							this.showForm = this.currentSubscription.fortnox_token_state.token_status === 'show_button' ? false : true;
						} else if (this.subscriptionConfigResponse.hasOwnProperty('visma_redirect_url')) {
							this.vismaLink = this.subscriptionConfigResponse.visma_redirect_url
							if (this.subscriptionConfigResponse.token_is_expired === true) {
								this.showForm = false
							} else {
								this.showForm = true
							}
						} else {
							this.showForm = true
						}
						if (this.subscriptionConfigResponse.hasOwnProperty('amazon_settings')) {
							this.linkAmazonLegacy = this.subscriptionConfigResponse.na_auth_url
							this.linkAmazonEU = this.subscriptionConfigResponse.eu_auth_url
						}
						if (this.showForm === true) {
							this.addonRequest(this.$route.query.key, this.$route.query.type, this.$route.query.acc)
							// this.identifyShopifyBtn()

						}
						if (this.tabId === 3) window.Bus.$emit('start-appsetting-tab3-tour')


					}
				}).catch((err) => {
				this.vloading = false
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								// this.getSubscriptionConfig(param)
							})
							.catch(_ => {
								// console.log('here')
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		getConfigHelpText(param, acc = null) {
			this.vloading = true
			let self = this
			this.$store.dispatch('customer/getConfigForm', {URL: this.$services.endpoints.GET_HELP_CONFIG_TEXT + `${param}/${acc}/`})
				.then(res => {
					this.vloading = false
					if (res.data.success) {
						this.helpText = res.data.data.form.join('')
						setTimeout(() => {
							Object.keys(res.data.data.help_text).forEach(element => {
								if (document.getElementById(`id_${element}`)) {
									const node = document.createElement('span')
									// node.setAttribute('tooltip-settings', this.convertHTML(res.data.data.help_text[element])) //
									node.setAttribute('class', 'ml-1') // res.data.data.help_text[element]
									node.setAttribute('class', 'tooltip-settings') // tooltip-settings
									node.setAttribute('id', `id_${element}_span_tooltip`) // tooltip-settings

									if (!document.getElementById(`id_${element}_span_tooltip`)) {
										const child = document.createElement('i')
										node.appendChild(child)
										if (res.data.data.help_text[element] !== '') {
											const child2 = document.createElement('span')
											const text = document.createTextNode(this.convertHTML(res.data.data.help_text[element]))
											child2.setAttribute('class', 'w-60 tooltiptext shadow-sm border')
											child2.appendChild(text)
											node.appendChild(child2)
										}
										child.setAttribute('class', 'text-sm help-color fa fa-question-circle')
										const label = document.getElementById(`id_${element}`).previousSibling
										label.setAttribute('class', 'flex justify-between w-full')
										// console.log('label:::', label)
										label.appendChild(node)
									}

									// amazon multiple select for available countries
									if (document.getElementById(`id_${element}`).hasAttribute('is_multiple_select') && document.getElementById(`id_${element}`).tagName === 'SELECT') {
										window.$(`#id_${element}`).select2({
											tokenSeparators: [',', ' '],
											templateSelection: function (data) {
												return data.text
											},
										})
									}

									// For Fortnox subscription
									// Let create dropdown options for all fields that has payment mode for paypal supplier
									if (document.getElementById(`id_${element}`).hasAttribute('is_payment_mode_field') && document.getElementById(`id_${element}`).tagName === 'SELECT') {
										const paymentModeSelect = document.getElementById(`id_${element}`)
										// paymentModeSelect.setAttribute('class', 'w-c65')
										if (paymentModeSelect.options.length < 2) {
											if (this.paypalSupplierDetails.is_payment_mode_field && this.paypalSupplierDetails.is_payment_mode_field.length > 0) {
												this.paypalSupplierDetails.is_payment_mode_field.forEach(item => {
													const option = document.createElement('option')
													option.text = item.AccountNumber + ' ' + item.Description
													option.value = item.Code
													paymentModeSelect.add(option)
												})
												window.$(`#id_${element}`).select2({
													templateSelection: function (data) {
														return data.id
													},
												})
											}
										}
									}

									// For visma apps (VISMA Unit)
									if (document.getElementById(`id_${element}`).hasAttribute('is_unit_id_field') && document.getElementById(`id_${element}`).tagName === 'SELECT') {
										const vismaUnit = document.getElementById(`id_${element}`)
										// vismaUnit.setAttribute('class', 'w-c65')
										if (vismaUnit.options.length < 2) {
											if (this.vismaUnit.length > 0) {
												this.vismaUnit.forEach(item => {
													const option = document.createElement('option')
													option.text = item.Name
													option.value = item.Id
													vismaUnit.add(option)
												})
												window.$(`#id_${element}`).select2({
													tags: true,
													createTag: function (params) {
														return {
															id: params.id,
															text: params.text,
														}
													},
												})
											}
										}
									}

									// Let create dropdown options for all fields that has accounts
									if (document.getElementById(`id_${element}`).hasAttribute('is_account_field') && document.getElementById(`id_${element}`).tagName === 'SELECT') {
										const accountSelect = document.getElementById(`id_${element}`)
										accountSelect.setAttribute('required', true)
										// accountSelect.setAttribute('class', 'w-c65')
										if (accountSelect.options.length < 2) {
											if (this.accountNumbers.length > 0) {
												this.accountNumbers.forEach(item => {
													const option = document.createElement('option')
													option.text = parseInt(acc) === 1 ? item.Description : item.Number + ' ' + item.Description
													option.value = item.Number
													accountSelect.add(option)
												})
												window.$(`#id_${element}`).select2({
													tags: true,
													createTag: function (params) {
														return {
															id: params.id,
															text: params.text,
														}
													},
													// templateSelection : function (data) {
													//     console.log(data)
													//     return data.id
													// },
												})
											}
										}
									}

									// Let create dropdown options for all fields that has payment term or payment term code
									if (document.getElementById(`id_${element}`).hasAttribute('is_payment_term_field') && document.getElementById(`id_${element}`).tagName === 'SELECT') {
										const paymentTermSelect = document.getElementById(`id_${element}`)
										paymentTermSelect.setAttribute('required', true)
										if (paymentTermSelect.options.length < 2) {
											if (this.paymentTerms.length > 0) {
												this.paymentTerms.forEach(item => {
													const option = document.createElement('option')
													option.text = item.Description
													option.value = item.Code
													paymentTermSelect.add(option)
												})
												window.$(`#id_${element}`).select2({
													tags: true,
													createTag: function (params) {
														return {
															id: params.id,
															text: params.text,
														}
													},
													// templateSelection : function (data) {
													//     return data.id
													// },
												})
											}
										}
									}

									// Dropdown options for all fields for voucher series
									if (document.getElementById(`id_${element}`).hasAttribute('is_voucher_series_field') && document.getElementById(`id_${element}`).tagName === 'SELECT') {
										const voucherSelect = document.getElementById(`id_${element}`)
										voucherSelect.setAttribute('required', true)
										// voucherSelect.setAttribute('class', 'w-c65')
										if (voucherSelect.options.length < 2) {
											if (this.voucherSeries.length > 0) {
												this.voucherSeries.forEach(item => {
													const option = document.createElement('option')
													option.text = item.Description
													option.value = item.Code
													voucherSelect.add(option)
												})
												window.$(`#id_${element}`).select2({
													tags: true,
													createTag: function (params) {
														return {
															id: params.id,
															text: params.text,
														}
													},
													// templateSelection : function (data) {
													//     return data.id
													// },
												})
											}
										}
									}

									// Dropdown options for all fields for pricelist
									if (document.getElementById(`id_${element}`).hasAttribute('is_price_list_field') && document.getElementById(`id_${element}`).tagName === 'SELECT') {
										const priceListSelect = document.getElementById(`id_${element}`)
										priceListSelect.setAttribute('required', true)
										// priceListSelect.setAttribute('class', 'w-c65')
										if (priceListSelect.options.length < 2) {
											if (this.priceList.length > 0) {
												this.priceList.forEach(item => {
													const option = document.createElement('option')
													option.text = item.Description
													option.value = item.Code
													priceListSelect.add(option)
												})
												window.$(`#id_${element}`).select2({
													tags: true,
													createTag: function (params) {
														return {
															id: params.id,
															text: params.text,
														}
													},
												})
											}
										}
									}
									// Drop down options for all fields for Warehouse location
									if (document.getElementById(`id_${element}`).hasAttribute('is_warehouse_field') && document.getElementById(`id_${element}`).tagName === 'SELECT') {
										const warehouseLocation = document.getElementById(`id_${element}`)
										warehouseLocation.setAttribute('required', true)
										if (warehouseLocation.options.length < 2) {
											if (this.stockPointsList.length > 0) {
												this.stockPointsList.forEach(item => {
													const option = document.createElement('option')
													option.text = item.name
													option.value = item.code
													warehouseLocation.add(option)
												})
												window.$(`#id_${element}`).select2({
													tags: true,
													createTag: function (params) {
														return {
															id: params.id,
															text: params.text,
														}
													},
												})
											}
										}

									}
									// Remove account id field and label for stripe subscription
									if (STRIPE_SUBSCRIPTIONS.includes(parseInt(this.appType))) {
										if (document.getElementById('id_account_code')) {
											document.getElementById('id_account_code').setAttribute('type', 'hidden')

											const label = document.getElementById('id_account_code').previousSibling
											label.style.display = 'none'
											if (this.shopSettings.hasOwnProperty('account_code')) {
												delete this.shopSettings['account_code']
											}

										}
									}

									// amazon market-place connect button
									if ([52].includes(parseInt(this.app_type))) {
										if (this.shopSettings.hasOwnProperty('token_status')) {
											if (this.shopSettings.token_status === true) {
												this.showAmazonMarketPlace = false
											} else {
												this.showAmazonMarketPlace = true
											}
										}
									}

									// For Visma apps Only
									if (document.getElementById(`id_${element}`).hasAttribute('is_visma_bank_account_field') && document.getElementById(`id_${element}`).tagName === 'SELECT') {
										const vismaListSelect = document.getElementById(`id_${element}`)
										vismaListSelect.setAttribute('required', true)
										// vismaListSelect.setAttribute('class', 'w-c65')
										if (vismaListSelect.options.length < 2) {
											if (this.vismaBankAccount.length > 0) {
												this.vismaBankAccount.forEach(item => {
													const option = document.createElement('option')
													option.text = item.Name
													option.value = item.Id
													vismaListSelect.add(option)
												})
												window.$(`#id_${element}`).select2({
													tags: true,
													createTag: function (params) {
														return {
															id: params.id,
															text: params.text,
														}
													}
												})
											}
										}
									}
									// For visma subscriptions only
									if (document.getElementById(`id_${element}`).hasAttribute('is_visma_bank_account_number_field') && document.getElementById(`id_${element}`).tagName === 'SELECT') {
										const vismaListSelect = document.getElementById(`id_${element}`)
										vismaListSelect.setAttribute('required', true)
										// vismaListSelect.setAttribute('class', 'w-c65')
										if (vismaListSelect.options.length < 2) {
											if (this.vismaBankAccount.length > 0) {
												this.vismaBankAccount.forEach(item => {
													const option = document.createElement('option')
													option.text = item.Name
													option.value = item.LedgerAccountNumber
													vismaListSelect.add(option)
												})
												window.$(`#id_${element}`).select2({
													tags: true,
													createTag: function (params) {
														return {
															id: params.id,
															text: params.text,
														}
													}
												})
											}
										}
									}

									// For Visma apps Only (Paypal supplier)
									if (document.getElementById(`id_${element}`).hasAttribute('is_visma_foreign_payment_code_field') && document.getElementById(`id_${element}`).tagName === 'SELECT') {
										const vismaForeignPaymentSelect = document.getElementById(`id_${element}`)
										vismaForeignPaymentSelect.setAttribute('required', true)
										if (vismaListSelect.options.length < 2) {
											if (this.vismaForeignPayment.length > 0) {
												this.vismaForeignPayment.forEach(item => {
													const option = document.createElement('option')
													option.text = item.Description
													option.value = item.Id
													vismaForeignPaymentSelect.add(option)
												})
												window.$(`#id_${element}`).select2({
													tags: true,
													createTag: function (params) {
														return {
															id: params.id,
															text: params.text,
														}
													},
													// templateSelection : function (data) {
													//     return data.id
													// },
												})
											}
										}
									}

									// Getting stripe auth btn
									if (STRIPE_APPS_LIST.includes(parseInt(param))) {
										const stripeName = document.getElementById('stripe-auth-form')
										stripeName.innerHTML = ''
										const div = document.createElement('div')
										div.setAttribute('class', 'space-x-4')
										const button = document.createElement('button')

										if (this.shopSettings['account_code'] || this.shopSettings['stripe_token_status'] === true) {
											button.innerText = `Stripe: ${this.$t('connectedStripe')}`
											button.setAttribute('class', 'c-btn btn-activate btn-theme-custom px-6 text-white py-2 rounded shadow-xl')
											// div.append(label)
											div.append(button)
											stripeName.append(div)
										} else {
											button.innerText = this.$t('connectStripe')
											button.setAttribute('id', 'stripe-auth-btn')
											button.setAttribute('class', 'c-btn btn-auth px-6 text-white py-2 rounded shadow-xl')
											// div.append(label)
											div.append(button)
											stripeName.append(div)
										}

									}

									// Getting shopify auth btn
									if (FORTNOX_SHOPIFY_GUIDE.includes(parseInt(param)) === true) {
										const shopifyName = document.getElementById('shopify-auth-form')

										if (shopifyName) {
											shopifyName.innerHTML = ''
											const div = document.createElement('div')
											div.setAttribute('class', 'space-x-4')
											if (this.shopSettings.hasOwnProperty('shop_name')) {
												this.shopName = this.shopSettings['shop_name']
												document.getElementById('id_shop_name').value = this.shopSettings['shop_name']
											}
											if (this.shopSettings['token_status'] === true || this.shopSettings['shopify_access_token']) {
												const label = document.createElement('label')
												label.setAttribute('for', 'shopify_auth')
												label.setAttribute('class', 'text-gray-700')
												label.innerText = 'Shopify ID'
												const button = document.createElement('button')
												button.innerText = `Shopify: ${this.$t('connectedShopify')}`
												// button.setAttribute('id', 'shopify-auth-btn')
												button.setAttribute('class', 'c-btn btn-activate btn-theme-custom px-6 text-white py-2 rounded shadow-xl')
												div.append(label)
												div.append(button)
												shopifyName.append(div)
												if (document.getElementById('id_token')) {
													document.getElementById('id_token').style.display = 'none'
													document.querySelector('[for=id_token]').style.display = 'none'
												}
												if (document.getElementById('id_shopify_access_token')) {
													document.getElementById('id_shopify_access_token').style.display = 'none'
													document.querySelector('[for=id_shopify_access_token]').style.display = 'none'
													// delete self.shopSettings['shopify_access_token']
												}


												// setTimeout(function () {
												// 	if (document.getElementById('id_token')){
												//
												// 	}
												// },300)
											} else {
												// shopifyName.innerHTML = ''
												// const div = document.createElement('div')
												// div.setAttribute('class', 'space-x-4')
												const label = document.createElement('label')
												label.setAttribute('for', 'shopify_auth')
												label.setAttribute('class', 'text-gray-700')
												// label.innerText = 'Shopify ID'
												const button = document.createElement('button')
												button.innerText = this.$t('shopifyGuide')
												button.setAttribute('id', 'shopify-auth-btn')
												button.setAttribute('class', 'c-btn btn-auth-pay bg-blue-900 px-6 text-white py-2 rounded shadow-xl')
												div.append(label)
												div.append(button)
												shopifyName.append(div)
												document.getElementById('shopify-auth-btn').addEventListener('click', function () {
													let routeData = self.$router.resolve({name: 'shopify-guide'});
													window.open(routeData.href, '_blank');
													// self.$router.replace({name: 'shopify-guide'})
												})
											}

										}
									}
									// Event handle for checkbox values
									if (document.getElementById(`id_${element}`).getAttribute('type') === 'checkbox') {
										const checkbox = document.getElementById(`id_${element}`)

										checkbox.addEventListener('change', (event) => {
											let unallowed_double_checked = ['is_post_invoices', 'is_post_orders']
											if (unallowed_double_checked.includes(event.target.name)) {
												if (event.currentTarget.checked) {
													if (event.target.name === 'is_post_invoices') {
														document.getElementById(`id_is_post_invoices`).checked = true
														document.getElementById(`id_is_post_invoices`).value = true
														document.getElementById(`id_is_post_invoices`).setAttribute('checked', true)
														document.getElementById(`id_is_post_orders`).checked = false
														document.getElementById(`id_is_post_orders`).value = false
														document.getElementById(`id_is_post_orders`).removeAttribute('checked', true)
													}
													if (event.target.name === 'is_post_orders') {
														document.getElementById(`id_is_post_orders`).checked = true
														document.getElementById(`id_is_post_orders`).value = true
														document.getElementById(`id_is_post_orders`).setAttribute('checked', true)
														document.getElementById(`id_is_post_invoices`).checked = false
														document.getElementById(`id_is_post_invoices`).value = false
														document.getElementById(`id_is_post_invoices`).removeAttribute('checked', true)
													}
												} else {
													document.getElementById(`id_${element}`).value = false
													document.getElementById(`id_${element}`).removeAttribute('checked')
												}
											} else {
												if (event.currentTarget.checked) {
													document.getElementById(`id_${element}`).value = true
													document.getElementById(`id_${element}`).setAttribute('checked', true)
												} else {
													document.getElementById(`id_${element}`).value = false
													document.getElementById(`id_${element}`).removeAttribute('checked')
												}
											}

										})
										// Validate if checkbox is already true
										if (this.shopSettings[element]) {
											checkbox.setAttribute('checked', true)
										} else {
											checkbox.removeAttribute('checked')
										}
									}

									// Applying styles to certain fields
									if (document.getElementById(`id_${element}`).getAttribute('type') === 'checkbox') {
										document.getElementById(`id_${element}`).classList.add('form-control', 'h-4', 'checkbox-height')
									}
									if (document.getElementById(`id_${element}`).getAttribute('type') !== 'checkbox') {
										document.getElementById(`id_${element}`).classList.add('c-pad-setting', 'form-control', 'h-c28', 'font-light', 'text-xs')
									}
									if (document.getElementById(`id_${element}`).tagName === 'SELECT') {
										document.getElementById(`id_${element}`).classList.add('c-pad-setting', 'w-f-100', 'custom-select', 'h-c28', 'font-light', 'text-xs')
									}

									// applying data to field entry
									if (document.getElementById(`id_${element}`).hasAttribute('is_multiple_select') || element === 'na_supported_countries') {
										window.$(`#id_${element}`).val(this.shopSettings[element]).change()
									} else if (document.getElementById(`id_${element}`).hasAttribute('is_account_field')) {
										if (this.shopSettings[element]) {
											window.$(`#id_${element}`).val(this.shopSettings[element]).change()
										}

									} else if (document.getElementById(`id_${element}`).hasAttribute('is_payment_mode_field')) {
										if (this.shopSettings[element]) {
											window.$(`#id_${element}`).val(this.shopSettings[element]).change()
										}
									} else if (document.getElementById(`id_${element}`).hasAttribute('is_payment_term_field')) {
										if (this.shopSettings[element]) {
											window.$(`#id_${element}`).val(this.shopSettings[element]).change()
										}

									} else if (document.getElementById(`id_${element}`).hasAttribute('is_voucher_series_field')) {
										if (this.shopSettings[element]) {
											window.$(`#id_${element}`).val(this.shopSettings[element]).change()
										}

									} else if (document.getElementById(`id_${element}`).hasAttribute('is_visma_bank_account_field')) {
										if (this.shopSettings[element]) {
											window.$(`#id_${element}`).val(this.shopSettings[element]).change()
										}

									} else if (document.getElementById(`id_${element}`).hasAttribute('is_visma_bank_account_number_field')) {
										if (this.shopSettings[element]) {
											window.$(`#id_${element}`).val(this.shopSettings[element]).change()
										}

									} else if (document.getElementById(`id_${element}`).hasAttribute('is_visma_foreign_payment_code_field')) {
										if (this.shopSettings[element]) {
											window.$(`#id_${element}`).val(this.shopSettings[element]).change()
										}

									} else if (document.getElementById(`id_${element}`).hasAttribute('is_unit_id_field')) {
										if (this.shopSettings[element]) {
											window.$(`#id_${element}`).val(this.shopSettings[element]).change()
										}

									} else if (document.getElementById(`id_${element}`).hasAttribute('is_price_list_field')) {
										if (this.shopSettings[element]) {
											window.$(`#id_${element}`).val(this.shopSettings[element]).change()
										}
									} else if (document.getElementById(`id_warehouse_location_code`)) {
										if (this.shopSettings['warehouse_location_code']) {
											window.$(`#id_warehouse_location_code`).val(this.shopSettings['warehouse_location_code']).change()
										}
									} else {
										if (this.shopSettings[element]) {
											document.getElementById(`id_${element}`).value = this.shopSettings[element]
										}

									}

									// apply voucher series input data
									if (document.getElementById(`id_voucher_series`) && element === 'voucher_series') {
										let voucherSelect = document.getElementById(`id_voucher_series`)
										voucherSelect.setAttribute('required', true)
										if (this.shopSettings[element]) {
											voucherSelect.value = this.shopSettings[element]
										}
									}
									if (document.getElementById(`id_region`) && element === 'region') {
										let regionSelect = document.getElementById(`id_region`)
										regionSelect.setAttribute('required', true)
										if (this.shopSettings[element]) {
											document.getElementById(`id_region`).value = this.shopSettings[element]
											if (this.shopSettings['region'] === 'us-east-1') {
												window.$('label[for="id_na_supported_countries"]').show()
												window.$('#id_na_supported_countries').select2()
												window.$('#id_na_supported_countries').show()
												window.$('label[for="id_supported_countries"]').hide()
												setTimeout(() => {
													if (window.$('#id_supported_countries').hasClass("select2-hidden-accessible")) {
														window.$('#id_supported_countries').select2('destroy')
													}
													window.$('#id_supported_countries').hide()
												}, 300)
											} else {
												window.$('label[for="id_na_supported_countries"]').hide()
												setTimeout(() => {
													if (window.$('#id_na_supported_countries').hasClass("select2-hidden-accessible")) {
														window.$('#id_na_supported_countries').select2('destroy')
													}
													window.$('#id_na_supported_countries').hide()
												}, 300)
												window.$('label[for="id_supported_countries"]').show()
												window.$('#id_supported_countries').select2()
												window.$('#id_supported_countries').show()
											}
										}
										if (document.getElementById('id_region')) {
											document.getElementById('id_region').removeEventListener('change', () => {})
											document.getElementById('id_region').addEventListener('change', function (event) {
												try {

													if (document.getElementById('id_region').value) {
														if (document.getElementById('id_region').value === 'us-east-1') {
															window.$('label[for="id_na_supported_countries"]').show()
															window.$('#id_na_supported_countries').select2()
															window.$('#id_na_supported_countries').show()
															window.$('label[for="id_supported_countries"]').hide()
															if (window.$('#id_supported_countries').hasClass("select2-hidden-accessible")) {
																window.$('#id_supported_countries').select2('destroy')
															}
															window.$('#id_supported_countries').hide()
														} else {
															window.$('label[for="id_na_supported_countries"]').hide()
															if (window.$('#id_na_supported_countries').hasClass("select2-hidden-accessible")) {
																window.$('#id_na_supported_countries').select2('destroy')
															}
															window.$('#id_na_supported_countries').hide()
															window.$('label[for="id_supported_countries"]').show()
															window.$('#id_supported_countries').select2()
															window.$('#id_supported_countries').show()
														}

													}

												} catch (e) {
												}
											})
										}

									}
									if (document.getElementById(`id_booking_date`) && element === 'booking_date') {
										let dateSelect = document.getElementById(`id_booking_date`)
										dateSelect.setAttribute('required', true)
										if (this.shopSettings[element]) {
											document.getElementById(`id_booking_date`).value = this.shopSettings[element]
										}

									}
									// apply master location for officernd
									// monitor swish handle selection field
									if (document.getElementById('id_swish_bank_type')) {
										let swish_bank_type = document.getElementById('id_swish_bank_type').value
										if (swish_bank_type === 'seb') {
											window.$('[for="id_swish_bank_id"]').show()
											window.$('#id_swish_bank_id').show()
										} else {
											window.$('[for="id_swish_bank_id"]').hide()
											window.$('#id_swish_bank_id').hide()
										}
										document.getElementById('id_swish_bank_type').addEventListener('change', function (e) {
											if (e.target.value === 'seb') {
												window.$('#id_swish_bank_id').show()
												window.$('[for="id_swish_bank_id"]').show()
											} else {
												window.$('[for="id_swish_bank_id"]').hide()
												window.$('#id_swish_bank_id').hide()
											}
										})
									}
									if (element.includes('date')) {
										window.$(`#id_${element}`).datepicker({
											dateFormat: 'yy-mm-dd',
											startDate: new Date(),
											todayBtn: 'linked',
											keyboardNavigation: false,
											forceParse: false,
											calendarWeeks: true,
											autoclose: true
										})
										window.$(`#id_${element}`).attr({
											placeholder: 'YYYY-MM-DD',
											readonly: 'readonly'
										})
									}
									if (element.includes('expires')) {
										window.$(`#id_${element}`).datepicker({
											dateFormat: 'yy-mm-dd',
										})
										window.$(`#id_${element}`).attr({
											placeholder: 'YYYY-MM-DD',
											readonly: 'readonly'
										})
									}
									self.handleVismaAdminFields()

								} else {
									// console.log('not found')
								}
							})
							this.runShopifyScript()
							this.runShopifyAuth()
							this.runStripeAuth()
							if ('shopify_access_token' in self.shopSettingsCopy) {
								if (self.shopSettingsCopy['shopify_access_token']) {
									delete self.shopSettingsCopy['shopify_access_token']
									if (document.getElementById('shopify-auth-btn')) {
										document.getElementById('shopify-auth-btn').remove()
									}
								}

							}
							if ('token' in self.shopSettingsCopy) {
								if (self.shopSettingsCopy['token']) {
									delete self.shopSettingsCopy['token']
									if (document.getElementById('shopify-auth-btn')) {
										document.getElementById('shopify-auth-btn').remove()
									}
								}

							}
							if (this.tabId === 2) window.Bus.$emit('start-appsetting-tab2-tour')
						}, 500)
						setTimeout(() => {
							let self = this
							if (parseInt(self.$route.query.type) === 37) {
								window.$('#id_shop_name').on('input', function (event) {
									self.autoSaveOfficeRndCredential()
								})
								window.$('#id_client_id').on('input', function (event) {
									self.autoSaveOfficeRndCredential()
								})
								window.$('#id_client_secret').on('input', function (event) {
									self.autoSaveOfficeRndCredential()
								})
								self.getOfficerndLocations()
							}
						}, 500)
						this.getCustomAccounts(this.subId)


					}
				}).catch((err) => {
				this.vloading = false
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								this.getConfigHelpText(param, acc)
							})
							.catch(_ => {
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		getWaysOfDeliveryOption(key) {
			const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.GET_WAYS_OF_DELIVERY_OPTIONS + `${key}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.GET_WAYS_OF_DELIVERY_OPTIONS + `${key}/`
			this.$store.dispatch('customer/getWaysOfDeliveryOption', {URL})
				.then(res => {
					if (res.data.success) {
						this.waysOfDeliveryOption = res.data.data
					}
				}).catch((err) => {
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								this.getWaysOfDeliveryOption(key)
							})
							.catch(_ => {
								// console.log('error response')
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
			// }

		},
		getWaysOfDeliverySubscription(key) {
			const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.GET_WAYS_OF_DELIVERY_SUBSCRIPTION + `${key}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.GET_WAYS_OF_DELIVERY_SUBSCRIPTION + `${key}/`
			this.$store.dispatch('customer/getWaysOfDeliverySubscription', {URL})
				.then(res => {
					if (res.data.success) {
						this.wayOfDeliveryArray = res.data.data
					}
				}).catch((err) => {
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								this.getWaysOfDeliverySubscription(key)
							})
							.catch(_ => {
								// console.log('error response')
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		getTermOfDeliveryOption(key) {
			const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.GET_TERMS_OF_DELIVERY_OPTIONS + `${key}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.GET_TERMS_OF_DELIVERY_OPTIONS + `${key}/`
			this.$store.dispatch('customer/getTermOfDeliveryOption', {URL})
				.then(res => {
					// console.log('DSTERMS::::::', res)

				}).catch((err) => {
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								this.getTermOfDeliveryOption(key)
							})
							.catch(_ => {
								// console.log('error response')
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		getPaymentTermOption(key) {
			const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.GET_PAYMENT_TERM_OPTIONS + `${key}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.GET_PAYMENT_TERM_OPTIONS + `${key}/`
			this.$store.dispatch('customer/getPaymentTermOption', {URL})
				.then(res => {
					if (res.data.success && res.data.data) {
						this.paymentTerms = res.data.data
					}
				}).catch((err) => {
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								// this.getPaymentTermOption(key)
							})
							.catch(_ => {
								// console.log('error response')
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		getActiveAccountNumbers(key, type, acc) {
			const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.GET_ACTIVE_ACCOUNT_NUMBERS + `${key}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.GET_ACTIVE_ACCOUNT_NUMBERS + `${key}/`
			this.$store.dispatch('customer/getActiveAccountNumbers', {URL})
				.then(res => {
					try {
						this.accountNumbers = res.data.data.results
						this.autoSetupAccountFields(this.accountNumbers)
						this.getConfigHelpText(this.$route.query.type, this.$route.query.acc)

					} catch (e) {
						console.log(e, 'er')
					}


					this.getCustomAccounts(key)
				}).catch((err) => {
				if (err.response.status === 400) {
					let error_message = err.response.data.data
					if (error_message && err.response.data.code === 500 && parseInt(this.$route.query.acc) === 0) {
						// if (error_message.includes('refreshing access_token')) {
						// 	this.showForm = false
						// }
					}
				}
			})

		},
		checkIfValid(dt_key) {
			let datetime = moment().format('YYYY-MM-DD hh:mm')
			if (datetime < dt_key) {
				return true
			} else {
				return false
			}
		},
		addAccountNumbers() {
			const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.ADD_ACCOUNT_NUMBERS + `${this.subId}/${number}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.ADD_ACCOUNT_NUMBERS + `${this.subId}/${number}/`
			// this.$services.axios.defaults.headers.common.Authorization = `Bearer ${this.USER_TOKEN}`
			// this.$services.axios.get(URL)
			this.$store.dispatch('customer/addAccountNumbers', {URL})
				.then(res => {
					// console.log('Numbers:', res)
					if (res.data.success) {
						this.accountNumbers = res.data.data
					}
				}).catch((err) => {
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
							})
							.catch(_ => {
								// console.log('error response')
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		getPaymentProvider() {
			const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.PAYMENT_PROVIDER : this.$services.endpoints.PAYMENT_PROVIDER
			// this.$services.axios.defaults.headers.common.Authorization = `Bearer ${this.USER_TOKEN}`
			// this.$services.axios.get(URL)
			this.$store.dispatch('customer/getPaymentProvider', {URL})
				.then(res => {
					// console.log('PAYMENT provider::::::', res)
					if (res.data.success) {
						this.providerPaym = res.data.data
						// console.log('DDDD:', res.data.data[0][1])
					}
				}).catch((err) => {
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								this.getPaymentProvider()
							})
							.catch(_ => {
								// console.log('error response')
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		getPaymentTermSubscription(key) {
			const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.GET_PAYMENT_TERMS_SUBSCRIPTION + `${key}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.GET_PAYMENT_TERMS_SUBSCRIPTION + `${key}/`
			// this.$services.axios.defaults.headers.common.Authorization = `Bearer ${this.USER_TOKEN}`
			// this.$services.axios.get(URL)
			this.$store.dispatch('customer/getPaymentTermSubscription', {URL})
				.then(res => {
					// console.log('PAYMENT SUBJJJ::::::', res)
					if (res.data.success) {
						this.paymentTermsArray = res.data.data
					}
				}).catch((err) => {
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								this.getPaymentTermSubscription(key)
							})
							.catch(_ => {
								// console.log('error response')
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		getVismaBankAccountNumbers(key) {
			const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.VISMA_BANK_ACCOUNT_NUM + `${key}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.VISMA_BANK_ACCOUNT_NUM + `${key}/`
			this.$store.dispatch('customer/getVismaBankAccountNumbers', {URL})
				.then(res => {
					this.vismaBankAccount = res.data.results
					console.log(this.vismaBankAccount)
				}).catch((err) => {
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								this.getVismaBankAccountNumbers(key)
							})
							.catch(_ => {
								// console.log('error response')
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
					if (err.response.data.hasOwnProperty('visma_error')) {
						let message = this.GET_LOCALE === 'se' ? err.response.data.message_swe : err.response.data.message
						window.Bus.$emit('output-modal-error', {'message': message})
					}
				}
			})
		},
		autoSetupAccountFields(accountsList) {
			let self = this
			let customSelect = document.querySelectorAll('.custom-select')
			customSelect.forEach(element => {
				let voucherAccount = element
				if (['id_voucher_serie', 'id_voucher_series', 'id_price_list', 'id_payment_term_code', 'id_warehouse_location_code',
					'id_vat_rate', 'id_get_stock_from', 'id_article_type', 'id_orders_with_status', 'id_post_orders_to', 'id_unit_id'].includes(window.$(element).attr('id')) === false) {

					if (voucherAccount.options.length < 2) {
						accountsList.forEach(item => {
							const option = document.createElement('option')
							option.text = [1, 2].includes(parseInt(self.$route.query.acc)) === true ? item.Description : item.Number + ' ' + item.Description
							option.value = [1, 2].includes(parseInt(self.$route.query.acc)) === true ? item.Number : item.Number
							voucherAccount.add(option)
						})
						window.$(element).select2({
							tags: true,
							createTag: function (params) {
								return {
									id: params.id,
									text: params.text,
								}
							}
						})
						let elementID = window.$(element).attr('id').replace('id_', '')
						if (elementID && ['voucher_serie'].includes(elementID) === false) {
							window.$(element).val(self.shopSettings[elementID]).change()
						} else {
							window.$(element).val('').change()
						}

					}
				}

			})
		},
		getVismaForeignPaymentCode(key) {
			const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.VISMA_FOREIGN_PAYMENT_CODE + `${key}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.VISMA_FOREIGN_PAYMENT_CODE + `${key}/`
			// this.$services.axios.defaults.headers.common.Authorization = `Bearer ${this.USER_TOKEN}`
			// this.$services.axios.get(URL)
			this.$store.dispatch('customer/getRequestFromBE', {URL})
				.then(res => {
					this.vismaForeignPayment = res.data

				}).catch((err) => {
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								this.getVismaForeignPaymentCode(key)
							})
							.catch(_ => {
								// console.log('error response')
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		getVismaUnit(key) {
			const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.GET_VISMA_UNIT + `${key}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.GET_VISMA_UNIT + `${key}/`
			this.$store.dispatch('customer/getRequestFromBE', {URL})
				.then(res => {
					this.vismaUnit = res.data.data
					console.log("done fetching Visma Unit")

				}).catch((err) => {
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								this.getVismaUnit(key)
							})
							.catch(_ => {
								// console.log('error response')
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		clearWayDeliveryForm() {
			this.wayDoption = ''
			this.storeTitle = ''
		},
		createWayDeliverySubscription() {
			if (this.wayDoption === '' || this.storeTitle === '') {
				this.$services.helpers.notification(this.$t('fieldRequired'), 'error', this)
			} else if (this.wayDoption.length > 50 || this.storeTitle.length > 50) {
				this.$services.helpers.notification(this.$t('fieldValue'), 'error', this)
			} else {
				this.addingDeliveryWay = true
				const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.CREATE_WAY_OF_DELIVERY_SUBSCRIPTION + `${this.subId}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.CREATE_WAY_OF_DELIVERY_SUBSCRIPTION + `${this.subId}/`
				// this.$services.axios.defaults.headers.common.Authorization = `Bearer ${this.USER_TOKEN}`
				// this.$services.axios.post(URL, {
				//     })
				const payload = {
					title: this.storeTitle,
					match_code: this.wayDoption.split('*')[0].trim(),
					match_description: this.wayDoption.split('*')[1].trim()
				}
				this.$store.dispatch('customer/createWayDeliverySubscription', {URL, dataObject: payload})
					.then(res => {
						this.clearWayDeliveryForm()
						this.addingDeliveryWay = false
						if (res.data.success) {
							this.$services.helpers.notification(this.$t('createdDeliverySubscription'), 'success', this)

							this.getWaysOfDeliverySubscription(this.subId)
						}
					}).catch((err) => {
					this.addingDeliveryWay = false
					if (err.response.status === 400) {
						if (err.response.data.code === 401) {
							this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
								.then(_ => {
								})
								.catch(_ => {
									// console.log('error response')
									this.$store.commit('auth/CLEAR_AUTH_USER', null)
									window.Bus.$emit('sign-out')
								})
						}
					}
				})

			}
		},
		createPaymentTermSubscription() {
			if (this.paymentTcode === '' || this.paymentProvider === '') {
				this.$services.helpers.notification(this.$t('fieldRequired'), 'error', this)
			} else {
				this.addingPaymentTerms = true
				const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.CREATE_PAYMENT_TERM_SUBSCRIPTION + `${this.subId}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.CREATE_PAYMENT_TERM_SUBSCRIPTION + `${this.subId}/`
				// this.$services.axios.defaults.headers.common.Authorization = `Bearer ${this.USER_TOKEN}`
				// this.$services.axios.post(URL, {
				//     })
				const payload = {
					gateway_in_app: this.paymentProvider,
					gateway_in_accounting: this.paymentTcode,
				}
				this.$store.dispatch('customer/createPaymentTermSubscription', {URL, dataObject: payload})
					.then(res => {
						// console.log('posted:', res)
						this.addingPaymentTerms = false
						if (res.data.success) {
							this.$services.helpers.notification(this.$t('createdPayTermSubscription'), 'success', this)

							this.getPaymentTermSubscription(this.subId)
						}
					}).catch((err) => {
					this.addingPaymentTerms = false
					if (err.response.status === 400) {
						if (err.response.data.code === 401) {
							this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
								.then(_ => {
									// this.getSubscriptionList()
								})
								.catch(_ => {
									// console.log('error response')
									this.$store.commit('auth/CLEAR_AUTH_USER', null)
									window.Bus.$emit('sign-out')
								})
						}
					}
				})

			}
		},
		deleteWaysOfDeliverySubscription(id) {
			this.$services.helpers.notification(this.$t('pleaseWait'), 'progress', this)
			const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.DELETE_WAYS_OF_DELIVERY_SUBSCRIPTION + `${this.subId}/${id}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.DELETE_WAYS_OF_DELIVERY_SUBSCRIPTION + `${this.subId}/${id}/`
			// this.$services.axios.defaults.headers.common.Authorization = `Bearer ${this.USER_TOKEN}`
			// this.$services.axios.delete(URL)
			this.$store.dispatch('customer/deleteWaysOfDeliverySubscription', {URL})
				.then(res => {
					bus.$emit('hide-notify-me', '')
					if (res.status === 204) {
						this.$services.helpers.notification(this.$t('deletedDeliverySubscription'), 'success', this)

						this.getWaysOfDeliverySubscription(this.subId)
					}
				}).catch((err) => {
				bus.$emit('hide-notify-me', '')
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								// this.getSubscriptionList()
							})
							.catch(_ => {
								// console.log('error response')
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})

		},
		deletePaymentTermSubscription(id) {
			this.$services.helpers.notification(this.$t('pleaseWait'), 'progress', this)
			const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.DELETE_PAYMENT_TERMS_SUBSCRIPTION + `${this.subId}/${id}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.DELETE_PAYMENT_TERMS_SUBSCRIPTION + `${this.subId}/${id}/`
			// this.$services.axios.defaults.headers.common.Authorization = `Bearer ${this.USER_TOKEN}`
			// this.$services.axios.delete(URL)
			this.$store.dispatch('customer/deletePaymentTermSubscription', {URL})
				.then(res => {
					bus.$emit('hide-notify-me', '')
					if (res.status === 204) {
						this.$services.helpers.notification(this.$t('deletedPayTermSubscription'), 'success', this)

						this.getPaymentTermSubscription(this.subId)
					}
				}).catch((err) => {
				bus.$emit('hide-notify-me', '')
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								this.getSubscriptionList()
							})
							.catch(_ => {
								// console.log('error response')
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})

		},
		deleteCostCenterSubscription(id) {
			this.$services.helpers.notification(this.$t('pleaseWait'), 'progress', this)
			const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.DELETE_PAYMENT_TERMS_SUBSCRIPTION + `${this.subId}/${id}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.DELETE_SUBSCRIPTION_COST_CENTER + `${this.subId}/${id}/`

			this.$store.dispatch('customer/customDeleteRequest', {URL})
				.then(res => {
					this.$services.helpers.notification(this.$t('deletedSuccessfully'), 'success', this)

					this.getSubscriptionCostCenter(this.subId, this.app_type, this.acc)
				}).catch((err) => {
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								this.deleteCostCenterSubscription(id)
							})
							.catch(_ => {
								// console.log('error response')
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})

		},
		createCostCenterSubscription() {
			if (this.costCenter.cost_center_code === '') {
				this.$services.helpers.notification(this.$t('fieldRequired'), 'error', this)
			} else {
				let countryCode = ''
				let el = document.getElementById('customer_country')
				if (window.$(el).select2('val')) {
					countryCode = window.$(el).select2('val')
				} else {
					this.$services.helpers.notification(this.$t('fieldRequired'), 'error', this)
					return false
				}
				this.addingCostCenter = true
				const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.CREATE_SUBSCRIPTION_COST_CENTER + `${this.subId}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.CREATE_SUBSCRIPTION_COST_CENTER + `${this.subId}/`
				let costCenter = this.costCenterList.filter(item => item.Code === this.costCenter.cost_center_code)
				if (costCenter.length <= 0) {
					return false
				}
				let payload = {
					costcenter_name: costCenter[0].Description,
					cost_center_code: costCenter[0].Code,
					customer_country: countryCode,
					subscription: this.subId,
				}
				this.$store.dispatch('customer/customPostRequest', {URL: URL, data: payload})
					.then(res => {
						// console.log('posted:', res)
						this.addingCostCenter = false
						this.$services.helpers.notification(this.$t('createdSuccessfully'), 'success', this)
						this.costCenter = {
							customer_country: '',
							cost_center_code: '',

						}
						window.$(el).val("").trigger('change')
						this.getSubscriptionCostCenter(this.subId, this.app_type, this.acc)
					}).catch((err) => {
					this.addingPaymentTerms = false
					if (err.response.status === 400) {
						if (err.response.data.code === 401) {
							this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
								.then(_ => {
									this.createCostCenterSubscription()
								})
								.catch(_ => {
									// console.log('error response')
									this.$store.commit('auth/CLEAR_AUTH_USER', null)
									window.Bus.$emit('sign-out')
								})
						}
					}
				})

			}
		},
		performTestSubscription() {
			this.runningTest = true
			const payload = {
				URL: this.GET_USER_COMPANY_ID ? this.$services.endpoints.TEST_SUBSCRIPTION + `${this.subId}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.TEST_SUBSCRIPTION + `${this.subId}/`
			}

			bus.$emit('perform-single-test', payload)

			// const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.TEST_SUBSCRIPTION + `${this.subId}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.TEST_SUBSCRIPTION + `${this.subId}/`
			// // this.$services.axios.defaults.headers.common.Authorization = `Bearer ${this.USER_TOKEN}`
			// // this.$services.axios.get(URL)
			// this.$store.dispatch('customer/performTestSubscription', { URL })
			// .then(res => {
			//     this.runningTest = false
			//     // console.log(res)
			//     if (res.data.success) {
			//         this.$services.helpers.notification(res.data.data, 'success', this)
			//     }
			// }).catch((err) => {
			//     // console.log(err.response)
			//     this.runningTest = false
			//     if (err.response.status === 400) {
			//         if (err.response.data.code === 401) {
			//             this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
			//             .then(_ => {
			//                 // this.getSubscriptionList()
			//             })
			//             .catch(_ => {
			//                 // console.log('error response')
			//                 this.$store.commit('auth/CLEAR_AUTH_USER', null)
			//                 window.Bus.$emit('sign-out')
			//             })
			//         }
			//         this.$services.helpers.notification(err.response.data.data, 'success', this)
			//     }
			// })
		},
		CreateBambooMapping() {
			if (this.absenceType === '' || this.absenceCauseEmployee === '') {
				this.$services.helpers.notification(this.$t('fieldRequired'), 'error', this)
			} else if (this.absenceType === 'absence_types' && this.timeOffValue === '') {
				this.$services.helpers.notification(this.$t('fieldRequired'), 'error', this)
			} else if ((this.absenceType === 'emp_custom_fields' && this.empTable === '') ||
				(this.absenceType === 'emp_custom_fields' && this.empField === '')) {
				this.$services.helpers.notification(this.$t('fieldRequired'), 'error', this)
			} else {
				const obj = {}
				this.addingBamboo = true
				const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.CREATE_BAMBOO_MAPPING + `${this.subId}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.CREATE_BAMBOO_MAPPING + `${this.subId}/`

				if (this.absenceType === 'absence_types') {
					obj.key = this.timeOffValue
					obj.val = this.absenceCauseEmployee
					obj.group_desc = this.absenceType
				} else {
					obj.key = this.empField
					obj.val = this.absenceCauseEmployee
					obj.table = this.empTable
					obj.group_desc = this.absenceType
				}

				// this.$services.axios.defaults.headers.common.Authorization = `Bearer ${this.USER_TOKEN}`
				// this.$services.axios.post(URL, obj)
				this.$store.dispatch('customer/CreateBambooMapping', {URL, obj})
					.then(res => {
						this.addingBamboo = false
						if (res.data.success) {
							this.$services.helpers.notification(this.$t('bambooCreated'), 'success', this)
							this.getBambooMapData()
						}
					}).catch((err) => {
					this.addingBamboo = false
					if (err.response.status === 400) {
						if (err.response.data.code === 401) {
							this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
								.then(_ => {
								})
								.catch(_ => {
									// console.log('error response')
									this.$store.commit('auth/CLEAR_AUTH_USER', null)
									window.Bus.$emit('sign-out')
								})
						}
						this.$services.helpers.notification(err.response.data.data, 'error', this)
					}
				})

			}
		},
		deleteBambooData(id) {
			this.$services.helpers.notification(this.$t('pleaseWait'), 'progress', this)
			const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.DELETE_BAMBOO_DATA + `${this.subId}/${id}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.DELETE_BAMBOO_DATA + `${this.subId}/${id}/`
			// this.$services.axios.defaults.headers.common.Authorization = `Bearer ${this.USER_TOKEN}`
			// this.$services.axios.delete(URL)
			this.$store.dispatch('customer/deleteBambooData', {URL})
				.then(res => {
					bus.$emit('hide-notify-me', '')
					if (res.status === 204) {
						this.$services.helpers.notification(this.$t('bambooDeleted'), 'success', this)
						this.getBambooMapData(id)
					}
				}).catch((err) => {
				bus.$emit('hide-notify-me', '')
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								// this.getSubscriptionList()
							})
							.catch(_ => {
								// console.log('error response')
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})

		},
		getBambooType() {
			// this.$services.axios.defaults.headers.common.Authorization = `Bearer ${this.USER_TOKEN}`
			// this.$services.axios.get(this.$services.endpoints.GET_BAMBOOHR_TYPES)
			this.$store.dispatch('customer/getBambooType', {URL: this.$services.endpoints.GET_BAMBOOHR_TYPES})
				.then(res => {
					// console.log('TYp:', res)
					if (res.status === 200) {
						this.bambooHRType = res.data.data
					}
				}).catch((err) => {
				// this.showCustomAccount = false
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								this.getBambooType()
							})
							.catch(_ => {
								// console.log('here')
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		getBambooFortnoxCause() {
			// this.$services.axios.defaults.headers.common.Authorization = `Bearer ${this.USER_TOKEN}`
			// this.$services.axios.get(this.$services.endpoints.GET_FORTNOX_CAUSE_CODE)
			this.$store.dispatch('customer/getBambooFortnoxCause', {URL: this.$services.endpoints.GET_FORTNOX_CAUSE_CODE})
				.then(res => {
					// console.log('fortnox cause:', res)
					if (res.status === 200) {
						this.bambooFortnoxCause = res.data.data
					}
				}).catch((err) => {
				// this.showCustomAccount = false
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								this.getBambooFortnoxCause()
							})
							.catch(_ => {
								// console.log('here')
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		getBambooTimeOffs() {
			// this.$services.axios.defaults.headers.common.Authorization = `Bearer ${this.USER_TOKEN}`
			// this.$services.axios.get(this.$services.endpoints.GET_BAMBOOHR_TIME_OFFS + `${this.subId}/`)
			this.$store.dispatch('customer/getBambooTimeOffs', {URL: this.$services.endpoints.GET_BAMBOOHR_TIME_OFFS + `${this.subId}/`})
				.then(res => {
					// console.log('timeoffs:', res)
					if (res.status === 200) {
						this.bambooTimeOff = res.data.data
					}
				}).catch((err) => {
				// this.showCustomAccount = false
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								this.getBambooTimeOffs()
							})
							.catch(_ => {
								// console.log('here')
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		getBambooEmpField() {
			// this.$services.axios.defaults.headers.common.Authorization = `Bearer ${this.USER_TOKEN}`
			// this.$services.axios.get(this.$services.endpoints.GET_BAMBOOHR_EMP_FIELDS)
			this.$store.dispatch('customer/getBambooEmpField', {URL: this.$services.endpoints.GET_BAMBOOHR_EMP_FIELDS})
				.then(res => {
					// console.log('emp field:', res)
					if (res.status === 200) {
						this.bambooEmpField = res.data.data
					}
				}).catch((err) => {
				// this.showCustomAccount = false
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								this.getBambooEmpField()
							})
							.catch(_ => {
								// console.log('here')
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		getBambooMapData() {
			// this.$services.axios.defaults.headers.common.Authorization = `Bearer ${this.USER_TOKEN}`
			// this.$services.axios.get(this.$services.endpoints.GET_BAMBOOHR_MAP_DATA + `${this.subId}/`)
			this.$store.dispatch('customer/getBambooMapData', {URL: this.$services.endpoints.GET_BAMBOOHR_MAP_DATA + `${this.subId}/`})
				.then(res => {
					// console.log('map dta:', res)
					if (res.status === 200) {
						this.bambooMapData = res.data.data
					}
				}).catch((err) => {
				// this.showCustomAccount = false
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								this.getBambooMapData()
							})
							.catch(_ => {
								// console.log('here')
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		uploadSwishFile() {
			let URL
			const file = document.getElementById('swish-file-upload').files
			if (file.length < 1) {
				this.$services.helpers.notification(this.$t('fileRequired'), 'error', this)
			} else {
				this.uploading = true
				const formData = new FormData()
				if (parseInt(this.appType) === APP.ADYEN_PLUS) {
					URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.ADYEN_PLUS_FILE + `${this.subId}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.ADYEN_PLUS_FILE + `${this.subId}/`
					formData.append('adyen_file', file[0])
				}
				if (parseInt(this.appType) === APP.SWISH_HANDEL) {
					URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.SWISH_HANDEL_FILE + `${this.subId}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.SWISH_HANDEL_FILE + `${this.subId}/`
					formData.append('swish_file', file[0])
				}
				window.Bus.$emit('upload-setting-file', {URL, formData, app: this.appType})
			}
		},
		ConnectAmazonLegacy() {
			this.connectLegacy = true
			// const { key, type } = this.$route.query
			// const obj = {
			//     id : key,
			//     app_type : type
			// }
			setTimeout(() => {
				// window.sessionStorage.setItem('vsubs', JSON.stringify(obj))
				this.connectLegacy = false
				window.open(this.linkAmazonLegacy)
			}, 1000)
		},
		connectAmazonMarketPlace() {
			if (document.getElementById('id_region')) {
				this.connectLegacy = true
				let region = document.getElementById('id_region')
				if (['eu-west-1', 'us-east-1', 'us-west-2'].includes(region.value) === true) {
					const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.GET_USER_APP_CONFIG + `${this.subId}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.GET_USER_APP_CONFIG + `${this.subId}/`
					this.$store.dispatch('customer/autoSaveAmazonRegion', {
						URL: URL,
						data: {region: region.value}
					}).then(() => {
						const AMAZON_URL = `${this.$services.endpoints.AMAZON_MARKETPLACE_ENDPOINT}obtain_amazon_auth_url/${this.subId}/`
						this.$store.dispatch('customer/getAmazonAuthUrl', {URL: AMAZON_URL}).then(resp => {
							this.connectLegacy = false
							window.open(resp.data.url, '_blank')
						}).catch(er => {
							this.connectLegacy = false
							this.$services.helpers.notification(er.response.data.message, 'error', this)
						})
					}).catch(e => {
						this.connectLegacy = false
						this.$services.helpers.notification(e.response.data.message, 'error', this)
					})
				} else {
					this.connectLegacy = false
					this.$services.helpers.notification(this.$t('selectRegion'), 'error', this)
				}
			} else {
				this.connectLegacy = false
				this.$services.helpers.notification(this.$t('selectRegion'), 'error', this)
			}

		},
		autoSaveOfficeRndCredential: debounce(function () {
			if (document.getElementById('id_shop_name') && document.getElementById('id_client_id') && document.getElementById('id_client_secret')) {
				let id_shop_name = document.getElementById('id_shop_name').value
				let id_client_id = document.getElementById('id_client_id').value
				let id_client_secret = document.getElementById('id_client_secret').value
				if (id_shop_name && id_client_secret && id_client_id) {
					const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.GET_USER_APP_CONFIG + `${this.$route.query.key}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.GET_USER_APP_CONFIG + `${this.$route.query.key}/`
					this.$store.dispatch('customer/autoSaveAmazonRegion', {
						URL: URL,
						data: {
							shop_name: id_shop_name,
							client_id: id_client_id,
							client_secret: id_client_secret
						}
					}).then(() => {
						this.getOfficerndLocations()
					}).catch(e => {
						this.$services.helpers.notification(e.response.data.message, 'error', this)
					})
				}
			}
		}, 500),
		connectAmazonEU() {
			this.connectEu = true
			// const { key, type } = this.$route.query
			// const obj = {
			//     id : key,
			//     app_type : type
			// }
			setTimeout(() => {
				// window.sessionStorage.setItem('vsubs', JSON.stringify(obj))
				this.connectEu = false
				window.open(this.linkAmazonEU)
			}, 1000)
		},
		fileChange(e) {
			const self = this
			const docType = ['pdf', 'csv', 'vnd.ms-excel', 'vnd.openxmlformats-officedocument.spreadsheetml.sheet']
			const fileType = e.target.files[0].type.split('/')[1]

			if (!docType.includes(fileType)) {
				self.$services.helpers.notification(this.$t('invalidFile'), 'error', this)
				document.getElementById('swish-file-upload').value = ''
			} else {
				this.fileAdded = true
			}
		},
		clearFileField() {
			document.getElementById('swish-file-upload').value = ''
			this.fileAdded = false
		},
		uid(e) {
			if (e.uid) return e.uid
			const key = Math.random()
				.toString(16)
				.slice(2)
			this.$set(e, 'uid', key)
			return e.uid
		},
		clearFormField() {
			this.accType = ''
			this.matchTyp = ''
			this.matchCode = ''
			this.vatValue = ''
			this.acctNumber = ''
			this.ruleType = ''
			// this.onChangeCurrencyCountry('')
			this.onChangeAccountNumber('')
		},
		openTab(id) {
			if (id === 1) {
				this.percentage = 30
				this.tabId = id
				// this.color = '#13c2c2'
				window.localStorage.setItem('__settingTab', id)
				document.getElementById('tab-0').classList.add('active')
				document.getElementById('tab-1').classList.remove('active')
				document.getElementById('tab-2').classList.remove('active')
				window.Bus.$emit('start-appsetting-tab1-tour')
			} else if (id === 2) {
				this.percentage = 65
				this.tabId = id
				// this.color = '#fadb14'
				window.localStorage.setItem('__settingTab', id)
				document.getElementById('tab-0').classList.remove('active')
				document.getElementById('tab-1').classList.add('active')
				document.getElementById('tab-2').classList.remove('active')
				window.Bus.$emit('start-appsetting-tab2-tour')
			} else if (id === 3) {
				this.percentage = 100
				this.tabId = id
				// this.color = '#389e0d'
				window.localStorage.setItem('__settingTab', id)
				document.getElementById('tab-0').classList.remove('active')
				document.getElementById('tab-1').classList.remove('active')
				document.getElementById('tab-2').classList.add('active')
				window.Bus.$emit('start-appsetting-tab3-tour')
			}
		},
		openCancelSubscription() {
			// if (this.currentSubscription.length > 0) {
			// 	if (['pro', 'growth', 'enterprise'].includes(this.currentSubscription[0].dedicated_server) === true) {
			// 		window.Bus.$emit('open-cancel-subscription', {
			// 			"key": this.dedicatedServerInformation.currentSubscription[0].id,
			// 			"type": this.appType,
			// 			"acc": this.accountingValue,
			// 			"server": this.currentSubscription[0].dedicated_server,
			// 			"created": this.dedicatedServerInformation.currentSubscription[0].created,
			// 			"fixed_contract": this.dedicatedServerInformation.currentSubscription[0].fixed_contract,
			// 		})
			// 	} else {
			//
			// 	}
			// }
			window.Bus.$emit('open-cancel-subscription', {
				"key": this.subId,
				"type": this.appType,
				"acc": this.accountingValue,
				"server": 'standard'
			})

		},
		getAllStockPoints(type) {
			if (STOCKPOINT_FORTNOX.includes(parseInt(type)) === true) {
				const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.STOCK_POINTS + `${this.subId}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.STOCK_POINTS + `${this.subId}/`
				this.$store.dispatch('customer/customGetRequest', {URL}).then(res => {
					if (res.status === 200) {
						if (res.data.hasOwnProperty('data') && res.data.code === 200) {
							this.stockPointsList = res.data.data

						}
					}
				}).catch(err => {

				})
			}
		},
		addonRequest(key, type, acc) {
			this.showReconciliationAppsField(type, acc)
			this.getAllStockPoints(type)
			this.runPaypalSupplierDetails(key, type, acc)
			this.runVismaApps(key, type, acc)
			this.showStripeData(type)
			this.showShopifyData(type)
			this.getSubscriptionCostCenter(key, type, acc)
			this.getCustomAccounts(key)
			this.getV3CustomAccountForm(type)
			this.showBambooTable(type)
			this.showTestOrderButton(type)
			this.runActiveAccountVisma(key, type, acc)
			this.showCustomV3Apps(type, acc)

			this.showCostCenterField(type, acc)

			this.runWoocommerceSettings(type, acc, key)
			this.runCustomAccountWidget(type, acc, key)
			this.runCustomPaymentTermWidget(type, acc, key)
			this.runCustomDeliveryTermWidget(type, acc, key)

			this.runAmazonSettings(type, acc, key)

			this.showFileUploader(type)
			this.reconciliationAppField(type)
			this.getOfficerndLocations()

			this.runActiveAccountNumbersFortnox(key, type, acc)
			this.getConfigHelpText(this.$route.query.type, this.$route.query.acc)
		},
		getOfficerndLocations: function () {
			if (parseInt(this.$route.query.type) === 37) {
				const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.GET_OFFICERND + `${this.subId}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.GET_OFFICERND + `${this.subId}/`
				this.$store.dispatch('customer/customGetRequest', {URL}).then(res => {
					if (res.status === 200) {
						this.officeLocations = res.data.data
						let self = this
						if (document.getElementById(`id_master_location`)) {
							let options = `<option value="all">${self.$t('allLocation')}</option>`
							Object.keys(self.officeLocations).map(item => {
								let op = `<option value="${item}">${item}</option>`
								options += op
							})
							document.getElementById(`id_master_location`).innerHTML = ''
							setTimeout(function () {
								document.getElementById(`id_master_location`).innerHTML = options
							}, 500)
							setTimeout(function () {
								let value = self.shopSettings['master_location']
								window.$(document.getElementById(`id_master_location`)).val(value).trigger('change')
							}, 500)

						}
					}
				}).catch(err => {

				})
			}

		}
	},
	mounted() {
		const {key, type, acc} = this.$route.query
		window.addEventListener("load", (event) => {
			// for an instance when the user reload the page
			removeDataLocalStorage(`app_type_${type}`)
			removeDataLocalStorage(`country_key`)
		});
		this.showAmazonMarketPlace = false
		this.app_type = type
		this.acc = acc
		this.tab = 1
		this.currentAppType = this.$route.query.type
		this.paymentTermsArray = []
		this.voucherSeries = []
		this.priceList = []
		this.costCenterList = []
		this.vismaAccounting = ACCOUNT_TYPE.VISMA
		this.fortnoxAccounting = ACCOUNT_TYPE.FORTNOX


		if (key !== null && type !== null) {
			this.subId = key
			this.appType = type
			this.accountingValue = acc
			this.showAmazonMarketPlace = false
			this.setUpAccountSetting()
			// this.getSubscriptionReadInfo(key)
			Object.keys(this.reconciliatonAccountList).map(item => {
				this.reconciliatonAccountList[item].acct_no = ''
				this.reconciliatonAccountList[item].id = null
			})
			Object.keys(this.proAccountList).map(item => {
				this.proAccountList[item].acct_no = ''
				this.proAccountList[item].id = null
			})


			bus.$on('fire-account-number', (_) => {
				this.getActiveAccountNumbers(key)
			})

			const settingTab = window.localStorage.getItem('__settingTab')
			if (parseInt(settingTab) > 0 && parseInt(settingTab) < 4) {
				this.tabId = parseInt(settingTab)
			} else {
				this.tabId = 1
			}
		}
		window.Bus.$on('reload-current-subscription', e => {
			this.cancelledSubscriptionFlag = false
			this.getSubscriptionConfig(key)

		})

		window.Bus.$on('finished-upload', _ => {
			this.uploading = false
			this.clearFileField()
		})

		window.Bus.$on('finished-test', _ => {
			this.runningTest = false
		})

		window.Bus.$on('finished-sku-test', _ => {
			this.activating = false
			this.subscriptionDeactivate = true
			this.subscriptionActivate = false
			this.activateSubAfterSku()
		})
		window.Bus.$on('finished-sku-test-failed', _ => {
			this.activating = false
		})
		// console.log(this.ALL)
		window.Bus.$on('open-settings-tab', e => {
			this.tabId = parseInt(e)
		})


	},
}
</script>

<style lang="scss" scoped>
@import "./../../../assets/variables.scss";

.align-box-center {
	width: 80%;
	margin: 0 auto;
}

.font-read {
	font-size: 1.6rem;
	background: #BB3333;
	color: #fff;
	font-weight: 800;
	border-radius: 5px;
	padding: 0.2rem;
}

.font-configure {
	font-size: 1.6rem;
	background: #1A9DD6;
	color: #fff;
	font-weight: 800;
	border-radius: 5px;
	padding: 0.2rem;
}

.font-activate {
	font-size: 1.6rem;
	background: #2FC058;
	color: #fff;
	font-weight: 800;
	border-radius: 5px;
	padding: 0.2rem;
}

.app-theme-gray .main-card > .card-body > .card-title::before {
	background: unset !important;
}

.btn-slider-b {
	color: #fff;
	background-color: #13c2c2;
	border-color: #13c2c2;
}

.tabs-animated .nav-link::before {
	background: #13c2c2 !important;
}

.override-form-ca {
	padding-bottom: 0.2rem !important;
	padding-top: 0.2rem !important;
	font-size: unset !important;
}

.override-form-numbers {
	padding-bottom: 0rem !important;
	padding-top: 0.2rem !important;
	font-size: unset !important;
	height: calc(1.75rem + 2px) !important;
}

// .form-control:focus{
//     // color: #495057;
//     // background-color: #fff;
//     // border-color: #e2e8f0 !important;
//     // outline: 0;
//     // box-shadow: unset !important;
// }
.input-group-append {
	height: calc(1.75rem + 2px);
}

.border-b-theme {
	border-bottom: 5px solid $form-border;
	border-bottom-left-radius: 2px;
	border-bottom-right-radius: 2px;
	// border: 1px solid $form-border !important;
}

.theme-color-tab {
	color: $form-border;
}

.theme-color-sec {
	color: $theme-primary-color !important;
	font-weight: 900;
}

.deep-b-theme {
	border-bottom: 5px solid $theme-primary-color !important;
}

.btn-border-all {
	border: 1px solid $theme-secondary-color !important;
	// color: $theme-secondary-color !important;
}

.btn-full-theme {
	background: $theme-secondary-color !important;
}

.pad-overi {
	padding: .175rem .75rem !important;
}

.form-select, .form-input {
	border: 1px solid $form-border !important;
	height: unset !important;
	padding: .175rem .75rem !important;
}

.form-control:focus {
	box-shadow: unset !important;
	border: 1px solid $theme-primary-color !important;
}

.form-control {
	border: 1px solid $form-border !important;
}

input[type="file"] {
	display: none;
}

.custom-file-upload {
	// border: 1px solid #ccc;
	color: white;
	border-radius: 5px;
	background-color: $theme-primary-color;
	display: inline-block;
	padding: 6px 12px;
	cursor: pointer;
}

table th, .table td {
	padding: 0.2rem !important;
}

.disable-btn {
	background-color: #800080 !important;
	color: #ffff !important;
}

.w-f-100 {
	width: 100% !important;
}

.test-btn {
	background-color: #EE4D81;
	// border-color: #EE4D81;
}

.activate-btn {
	background-color: #0DB23C;
}

.deactivate-btn {
	background-color: #583EF9;
}

.table-responsive::-webkit-scrollbar {
	display: none !important; /* width of the entire scrollbar */
}

.table-responsive::-webkit-scrollbar-track {
	background: $form-border; /* color of the tracking area */
}

.table th, .table td {
	border-top: unset !important;
}

.cancel-btn, .cancel-btn:hover {
	background: #10a4c2 !important;
	color: #ffffff
}

.c-btn {
	font-size: 15px !important;
}

.app-theme-gray .main-card {
	box-shadow: 0 0 0 0 transparent !important;
}

.pl-1, .px-1 {
	padding-left: 0.25rem !important;
}

.bg-white {
	background-color: #fff !important;
}

.color-text {
	color: #61696d !important;
	font-weight: 300 !important;
}
</style>
