<template>
	<div class="card-body">
		<h5 data-cy='tab-one-info-header' class="card-title">{{ $t('readInfo') }}</h5>
		<div class="container">
			<div class="row">
				<div class="col-md-12" v-loading='loadingInfo'>
											<span data-cy='subscription-info-title'
												  class="text-gray-600 text-2xl mb-3 v-app-1">{{
													appReadInfo.info_content_title
												}}</span>
					<p data-cy='subscription-info-message' class="font-light"
					   v-html="appReadInfo.info_content_html">
					</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>


export default {
	name: "appDescriptionSection",
	data: function () {
		return {
			loadingInfo: false,
			appReadInfo: {
				info_content_title: '',
				info_content_html: ''
			}

		}
	},
	methods: {
		getSubscriptionReadInfo(param) {
			this.loadingInfo = true
			this.$store.dispatch('customer/getSubscriptionReadInfo', {URL: this.$services.endpoints.GET_READ_INFO_SUBSCRIPTION + `${param}/`})
				.then(res => {
					this.loadingInfo = false
					if (res.status === 200) {
						this.showContinue = true
						this.appReadInfo.info_content_title = res.data.data.info_content_title
						this.appReadInfo.info_content_html = this.$sanitize(res.data.data.info_content_html)
					}
					if (this.tabId === 1) window.Bus.$emit('start-appsetting-tab1-tour')
				}).catch((err) => {
				this.loadingInfo = false
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								this.getSubscriptionReadInfo(param)
							})
							.catch(_ => {
								// console.log('here')
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
	},
	mounted() {
		this.getSubscriptionReadInfo(this.$route.query.key)
	}
}
</script>

<style scoped>

</style>
