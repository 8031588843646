<template :key="new Date().getMinutes()">
	<div class="app-main__inner">
		<ul class="body-tabs d-flex justify-content-between body-tabs-layout nav align-box-center">
			<li class="nav-item border-b-theme theme-color-tab" :class="tabId === 1 ? 'deep-b-theme' : ''">
				<a role="tab" class="nav-link active" :class="tabId === 1 ? 'theme-color-sec' : ''" id="tab-0"
				   @click="openTab(1)">
					<!--data-toggle="tab" href="#tab-content-0" aria-selected="true" @click="openTab(1)"-->
					<span class="d-flex align-items-center"> <i class="iconly-brokenInfo-Square mr-1"></i> <span
						class="text-xs">{{ $t('readInfo') }}</span> </span>
				</a>
			</li>
			<li class="nav-item border-b-theme theme-color-tab" :class="tabId === 2 ? 'deep-b-theme' : ''">
				<a role="tab" class="nav-link" :class="tabId === 2 ? 'theme-color-sec' : ''" id="tab-1"
				   @click="openTab(2)">
					<!--data-toggle="tab" href="#tab-content-1" aria-selected="false" @click="openTab(2)"-->
					<span class="d-flex align-items-center"><i class="iconly-brokenFilter mr-1"></i><span
						class="text-xs">{{ $t('configure') }}</span> </span>
				</a>
			</li>
			<li class="nav-item border-b-theme theme-color-tab" :class="tabId === 3 ? 'deep-b-theme' : ''">
				<a role="tab" class="nav-link" :class="tabId === 3 ? 'theme-color-sec' : ''" id="tab-2"
				   @click="openTab(3)">
					<!--data-toggle="tab" href="#tab-content-2" aria-selected="false" @click="openTab(3)"-->
					<span class="d-flex align-items-center"><i class=" iconly-brokenTick-Square mr-1"></i><span
						class="text-xs">{{ $t('activate') }}</span> </span>
				</a>
			</li>
		</ul>


		<!-- <k-progress :percent="percentage" active :show-text="false" :color="color" :line-height="10"></k-progress> -->
		<div class="tab-content mt-4">
			<div class="tab-pane tabs-animation fade active show" v-show="tabId === 1" role="tabpanel">
				<div class="row">
					<div class="col-md-12">
						<div class="main-card card">
							<app-description-section :key="appDescriptionKey"/>
							<div class="row">
								<div class="col-md-12 p-4">
									<div class="float-right">
										<button data-cy='continue-btn'
												class="v-app-2 c-btn btn-next btn text-sm w-full font-black btn-lg border btn-border-all btn-full-theme text-white hover:text-white"
												@click="openTab(2)">{{ $t('continue') }}
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="tab-pane tabs-animation fade active show" v-show="tabId === 2"> <!--id="tab-content-1"-->
				<div class="row">
					<div class="col-md-12">
						<div class="main-card bg-white pl-1">
							<app-setting-configuration-section v-if="tabId === 2" :key="appSettingKey"/>
							<!--							<div class="absolute inset-0 z-40" @click="modal = false" v-if="modal"></div>-->
							<!--							<div class="card-body">-->
							<!--								<h5 data-cy="tab-config-2" class="card-title">{{ $t('config') }} </h5>-->
							<!--								<div class="container">-->
							<!--									<div class="row" v-loading='vloading'>-->
							<!--										<div class="col-md-12 mb-3" v-if="showAmazon">-->
							<!--											<div class="flex space-x-10">-->
							<!--												<button @click="ConnectAmazonLegacy" :disabled='connectLegacy'-->
							<!--														class="c-btn btn-amazon btn btn-theme-amazon btn-primary btn-wide cursor-not-allowed shadow-lg">-->
							<!--													<div class="flex items-center m-auto" v-if="connectLegacy">-->
							<!--														<svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"-->
							<!--															 xmlns="http://www.w3.org/2000/svg" fill="none"-->
							<!--															 viewBox="0 0 24 24">-->
							<!--															<circle class="opacity-25" cx="12" cy="12" r="10"-->
							<!--																	stroke="currentColor" stroke-width="4"></circle>-->
							<!--															<path class="opacity-75" fill="currentColor"-->
							<!--																  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>-->
							<!--														</svg>-->
							<!--														{{ $t('connecting') }}-->
							<!--													</div>-->
							<!--													<div class="flex" v-else>-->
							<!--														<img src="https://img.icons8.com/plumpy/24/000000/amazon.png"/>-->
							<!--														<span class="text-white font-semibold">Anslut till Amazon Nordamerika (Legacy)</span>-->
							<!--													</div>-->

							<!--												</button>-->
							<!--												<button @click="connectAmazonEU" :disabled='connectEu'-->
							<!--														class="c-btn btn-amazon btn btn-theme-amazon btn-primary btn-wide cursor-not-allowed shadow-lg">-->
							<!--													<div class="flex items-center m-auto" v-if="connectEu">-->
							<!--														<svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"-->
							<!--															 xmlns="http://www.w3.org/2000/svg" fill="none"-->
							<!--															 viewBox="0 0 24 24">-->
							<!--															<circle class="opacity-25" cx="12" cy="12" r="10"-->
							<!--																	stroke="currentColor" stroke-width="4"></circle>-->
							<!--															<path class="opacity-75" fill="currentColor"-->
							<!--																  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>-->
							<!--														</svg>-->
							<!--														{{ $t('connecting') }}-->
							<!--													</div>-->
							<!--													<div class="flex" v-else>-->
							<!--														<img src="https://img.icons8.com/plumpy/24/000000/amazon.png"/>-->
							<!--														<span class="text-white font-semibold">Anslut till Amazon EU (Legacy)</span>-->
							<!--													</div>-->
							<!--												</button>-->
							<!--											</div>-->
							<!--										</div>-->
							<!--										<div class="col-md-12 mb-3" v-if="showAmazonMarketPlace">-->
							<!--											<div class="flex space-x-10">-->
							<!--												<button @click="connectAmazonMarketPlace" :disabled='connectLegacy'-->
							<!--														class="c-btn btn-amazon btn btn-theme-amazon btn-primary btn-wide cursor-not-allowed shadow-lg">-->
							<!--													<div class="flex items-center m-auto" v-if="connectLegacy">-->
							<!--														<svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"-->
							<!--															 xmlns="http://www.w3.org/2000/svg" fill="none"-->
							<!--															 viewBox="0 0 24 24">-->
							<!--															<circle class="opacity-25" cx="12" cy="12" r="10"-->
							<!--																	stroke="currentColor" stroke-width="4"></circle>-->
							<!--															<path class="opacity-75" fill="currentColor"-->
							<!--																  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>-->
							<!--														</svg>-->
							<!--														{{ $t('connecting') }}-->
							<!--													</div>-->
							<!--													<div class="flex" v-else>-->
							<!--														<img src="https://img.icons8.com/plumpy/24/000000/amazon.png"/>-->
							<!--														<span class="text-white font-semibold">Anslut till Amazon</span>-->
							<!--													</div>-->

							<!--												</button>-->
							<!--											</div>-->
							<!--										</div>-->
							<!--										<div class="col-md-12 mb-10" v-if="showForm">-->
							<!--											<div class="flex flex-col space-y-4">-->
							<!--												<span id='stripe-auth-form' class="flex space-x-4"-->
							<!--													  v-if="showStripe"></span>-->
							<!--												<span id='shopify-auth-form' class="flex space-x-4"-->
							<!--													  v-if="showShopify && showShopifyBtn"></span>-->
							<!--											</div>-->
							<!--										</div>-->
							<!--										<div class="col-md-3 v-app-3">-->
							<!--											<div v-if="showForm">-->
							<!--												<div class="flex flex-col space-y-4">-->
							<!--													<div data-cy='subscription-form'-->
							<!--														 class="flex flex-col font-light space-y-3 form-config"-->
							<!--														 v-html="helpText"></div>-->
							<!--													<div id="custom-v3" class="mt-2 form-config"-->
							<!--														 v-if="showV3">-->

							<!--														<div class="form-config">-->
							<!--															<label class="flex justify-between w-full mt-1 color-text">{{-->
							<!--																	$t('sales_account_se')-->
							<!--																}}-->
							<!--																(25%):-->
							<!--																&lt;!&ndash; <span-->
							<!--																	class="tooltip-settings" id="id_account_span_tooltip"><i-->
							<!--																	class="text-sm help-color fa fa-question-circle"></i></span> &ndash;&gt;-->
							<!--															</label>-->
							<!--															<select id='id_sales_account_se_twenty_five'-->
							<!--																	name="id_account_span_name"-->
							<!--																	v-model="customAccountList.sales_account_se_twenty_five"-->
							<!--																	maxlength="256"-->
							<!--																	required=""-->
							<!--																	class="custom-v3-class c-pad-setting form-control h-c28 font-light text-xs"-->
							<!--																	custom='sales_account_se_twenty_five'-->
							<!--																	is_account_field=""></select>-->
							<!--														</div>-->

							<!--														<div class="form-config">-->
							<!--															<label class="flex justify-between w-full mt-1 color-text">{{-->
							<!--																	$t('sales_account_se')-->
							<!--																}}-->
							<!--																(12%):-->
							<!--																&lt;!&ndash; <span-->
							<!--																	class="tooltip-settings"><i-->
							<!--																	class="text-sm help-color fa fa-question-circle"></i></span> &ndash;&gt;-->
							<!--															</label>-->
							<!--															<select id='id_sales_account_se_twelve' maxlength="256"-->
							<!--																	required=""-->
							<!--																	v-model="customAccountList.sales_account_se_twelve"-->
							<!--																	class="custom-v3-class c-pad-setting form-control h-c28 font-light text-xs"-->
							<!--																	custom='sales_account_se_twelve'-->
							<!--																	is_account_field=""></select>-->
							<!--														</div>-->
							<!--														<div class="form-config">-->
							<!--															<label class="flex justify-between w-full mt-1 color-text">{{-->
							<!--																	$t('sales_account_se')-->
							<!--																}}-->
							<!--																(6%):-->
							<!--																&lt;!&ndash; <span-->
							<!--																	class="tooltip-settings"><i-->
							<!--																	class="text-sm help-color fa fa-question-circle"></i></span> &ndash;&gt;-->
							<!--															</label>-->
							<!--															<select id='id_sales_account_se_six' maxlength="256"-->
							<!--																	required=""-->
							<!--																	v-model="customAccountList.sales_account_se_six"-->
							<!--																	class="custom-v3-class c-pad-setting form-control h-c28 font-light text-xs"-->
							<!--																	custom='sales_account_se_six'-->
							<!--																	is_account_field=""></select>-->
							<!--														</div>-->

							<!--														<div v-if="!removeExtraV3Fields" class="form-config">-->

							<!--															<label class="flex justify-between w-full mt-1 color-text">{{-->
							<!--																	$t('sales_account')-->
							<!--																}}-->
							<!--																EU:-->
							<!--																&lt;!&ndash; <span-->
							<!--																	class="tooltip-settings"><i-->
							<!--																	class="text-sm help-color fa fa-question-circle"></i></span> &ndash;&gt;-->
							<!--															</label>-->
							<!--															<select id='id_sales_account_eu' maxlength="256"-->
							<!--																	required=""-->
							<!--																	v-model="customAccountList.sales_account_eu"-->
							<!--																	class="custom-v3-class c-pad-setting form-control h-c28 font-light text-xs"-->
							<!--																	custom='sales_account_eu'-->
							<!--																	is_account_field=""></select>-->
							<!--														</div>-->

							<!--														<div v-if="!removeExtraV3Fields" class="form-config">-->
							<!--															<label class="flex justify-between  w-full mt-1 color-text">{{-->
							<!--																	$t('sales_account_export')-->
							<!--																}}:-->
							<!--																&lt;!&ndash; <span-->
							<!--																	class="tooltip-settings"><i-->
							<!--																	class="text-sm help-color fa fa-question-circle"></i></span> &ndash;&gt;-->
							<!--															</label>-->
							<!--															<select id='id_sales_account_wo' maxlength="256"-->
							<!--																	required=""-->
							<!--																	v-model="customAccountList.sales_account_wo"-->
							<!--																	class="custom-v3-class c-pad-setting form-control h-c28 font-light text-xs"-->
							<!--																	custom='sales_account_wo'-->
							<!--																	is_account_field=""></select>-->
							<!--														</div>-->
							<!--													</div>-->
							<!--													<div id="pro-app" class="mt-2 form-config">-->
							<!--														<div class="form-config" v-if="pro_app.vat_acct">-->
							<!--															<label class="flex justify-between w-full mt-1 color-text">{{-->
							<!--																	$t('vat_acct')-->
							<!--																}}:-->
							<!--																&lt;!&ndash; <span-->
							<!--																	class="tooltip-settings" id="id_account_span_tooltip"><i-->
							<!--																	class="text-sm help-color fa fa-question-circle"></i></span> &ndash;&gt;-->
							<!--															</label>-->
							<!--															<select-->
							<!--																name="id_account_span_name"-->
							<!--																required=""-->
							<!--																id="vat_acct"-->
							<!--																custom="vat_acct"-->
							<!--																class="pro-app c-pad-setting form-control h-c28 font-light text-xs"-->
							<!--																is_account_field=""></select>-->
							<!--														</div>-->
							<!--														<div class="form-config"-->
							<!--															 v-if="pro_app.receivable_acct">-->
							<!--															<label class="flex justify-between w-full mt-1 color-text">{{-->
							<!--																	$t('receivable_acct')-->
							<!--																}}:-->
							<!--																&lt;!&ndash; <span-->
							<!--																	class="tooltip-settings" id="id_account_span_tooltip"><i-->
							<!--																	class="text-sm help-color fa fa-question-circle"></i></span> &ndash;&gt;-->
							<!--															</label>-->
							<!--															<select-->
							<!--																name="id_account_span_name"-->
							<!--																required=""-->
							<!--																id="receivable_acct"-->
							<!--																custom="receivable_acct"-->
							<!--																class="pro-app c-pad-setting form-control h-c28 font-light text-xs"-->
							<!--																is_account_field=""></select>-->
							<!--														</div>-->
							<!--														<div class="form-config"-->
							<!--															 v-if="pro_app.sales_account">-->
							<!--															<label class="flex justify-between w-full mt-1 color-text">{{-->
							<!--																	$t('sales_account')-->
							<!--																}}:-->
							<!--																&lt;!&ndash; <span-->
							<!--																	class="tooltip-settings" id="id_account_span_tooltip"><i-->
							<!--																	class="text-sm help-color fa fa-question-circle"></i></span> &ndash;&gt;-->
							<!--															</label>-->
							<!--															<select-->
							<!--																name="id_account_span_name"-->
							<!--																required=""-->
							<!--																id="sales_account"-->
							<!--																custom="sales_account"-->
							<!--																class="pro-app c-pad-setting form-control h-c28 font-light text-xs"-->
							<!--																is_account_field=""></select>-->
							<!--														</div>-->
							<!--													</div>-->
							<!--													<div id="reconciliation-app" class="mt-2 form-config">-->
							<!--														<div class="form-config" v-if="reconciliations.payout">-->
							<!--															<label class="flex justify-between w-full mt-1 color-text">{{-->
							<!--																	$t('payout')-->
							<!--																}}:-->
							<!--																&lt;!&ndash; <span-->
							<!--																	class="tooltip-settings" id="id_account_span_tooltip"><i-->
							<!--																	class="text-sm help-color fa fa-question-circle"></i></span> &ndash;&gt;-->
							<!--															</label>-->
							<!--															<select-->
							<!--																name="id_account_span_name"-->
							<!--																required=""-->
							<!--																id="payout"-->
							<!--																custom="payout"-->
							<!--																class="reconciliation-class c-pad-setting form-control h-c28 font-light text-xs"-->
							<!--																is_account_field=""></select>-->
							<!--														</div>-->
							<!--														<div class="form-config"-->
							<!--															 v-if="reconciliations.payout_account_bank">-->
							<!--															<label class="flex justify-between w-full mt-1 color-text">{{-->
							<!--																	$t('payoutAccountBank')-->
							<!--																}}:-->
							<!--																&lt;!&ndash; <span-->
							<!--																	class="tooltip-settings" id="id_account_span_tooltip"><i-->
							<!--																	class="text-sm help-color fa fa-question-circle"></i></span> &ndash;&gt;-->
							<!--															</label>-->
							<!--															<select-->
							<!--																name="id_account_span_name"-->
							<!--																required=""-->
							<!--																id="payout_account_bank"-->
							<!--																custom="payout_account_bank"-->
							<!--																class="reconciliation-class c-pad-setting form-control h-c28 font-light text-xs"-->
							<!--																is_account_field=""></select>-->
							<!--														</div>-->
							<!--														<div class="form-config"-->
							<!--															 v-if="reconciliations.provider_fee_account">-->
							<!--															<label class="flex justify-between w-full mt-1 color-text">{{-->
							<!--																	$t('providerFeeAccount')-->
							<!--																}}:-->
							<!--																&lt;!&ndash; <span-->
							<!--																	class="tooltip-settings" id="id_account_span_tooltip"><i-->
							<!--																	class="text-sm help-color fa fa-question-circle"></i></span> &ndash;&gt;-->
							<!--															</label>-->
							<!--															<select-->
							<!--																name="id_account_span_name"-->
							<!--																required=""-->
							<!--																id="provider_fee_account"-->
							<!--																custom="provider_fee_account"-->
							<!--																class="reconciliation-class reconciliation c-pad-setting form-control h-c28 font-light text-xs"-->
							<!--																is_account_field=""></select>-->
							<!--														</div>-->
							<!--														<div class="form-config"-->
							<!--															 v-if="reconciliations.fee_vat_acct_debit">-->
							<!--															<label class="flex justify-between w-full mt-1 color-text">{{-->
							<!--																	$t('reverseFeeVatDebitAccount')-->
							<!--																}}:-->
							<!--															</label>-->
							<!--															<select-->
							<!--																name="id_account_span_name"-->
							<!--																required=""-->
							<!--																id="fee_vat_acct_debit"-->
							<!--																custom="fee_vat_acct_debit"-->
							<!--																class="reconciliation-class reconciliation-app c-pad-setting form-control h-c28 font-light text-xs"-->
							<!--																is_account_field=""></select>-->
							<!--														</div>-->
							<!--														<div class="form-config"-->
							<!--															 v-if="reconciliations.fee_vat_acct_credit">-->
							<!--															<label class="flex justify-between w-full mt-1 color-text">{{-->
							<!--																	$t('reverseFeeVatCreditAccount')-->
							<!--																}}:-->
							<!--															</label>-->
							<!--															<select-->
							<!--																name="id_account_span_name"-->
							<!--																required=""-->
							<!--																id="fee_vat_acct_credit"-->
							<!--																custom="fee_vat_acct_credit"-->
							<!--																class="reconciliation-class reconciliation-app c-pad-setting form-control h-c28 font-light text-xs"-->
							<!--																is_account_field=""></select>-->
							<!--														</div>-->

							<!--													</div>-->
							<!--													<div id="voucher-app" class="mt-2 form-config"-->
							<!--														 v-if="Object.values(voucher_app).includes(true) === true">-->
							<!--														<div class="form-config"-->
							<!--															 v-for="key in Object.keys(voucher_app)">-->
							<!--															<div v-if="voucher_app[key] === true">-->
							<!--																<label-->
							<!--																	class="flex justify-between w-full mt-1 color-text">{{-->
							<!--																		$t(`${key}`)-->
							<!--																	}}:-->
							<!--																	&lt;!&ndash; <span-->
							<!--																		class="tooltip-settings" id="id_account_span_tooltip"><i-->
							<!--																		class="text-sm help-color fa fa-question-circle"></i></span> &ndash;&gt;-->
							<!--																</label>-->
							<!--																<select-->
							<!--																	:name="`id_${key}`"-->
							<!--																	required=""-->
							<!--																	:id="key"-->
							<!--																	:custom="key"-->
							<!--																	class="voucher-app c-pad-setting form-control h-c28 font-light text-xs"-->
							<!--																	is_account_field=""></select>-->
							<!--															</div>-->
							<!--														</div>-->
							<!--													</div>-->

							<!--													<button @click="updateAppSettings" type="submit"-->
							<!--															style="width:80%; border-radius:8px !important;"-->
							<!--															class="v-app-4 c-btn btn-submit btn btn-lg text-sm font-black btn-theme-custom btn-lg shadow-lg"-->
							<!--															:disabled='updating'>-->
							<!--														<span v-if="updating">{{ $t('updating') }}</span>-->
							<!--														<span class='whitespace-nowrap' v-else>-->
							<!--                                                            {{ $t('updateInfo') }}-->
							<!--                                                        </span>-->
							<!--													</button>-->
							<!--												</div>-->
							<!--											</div>-->
							<!--											<div v-else>-->
							<!--												<div v-if="parseInt(accountingValue) === parseInt(vismaAccounting)">-->
							<!--													<h2 class="text-gray-600 text-xl mb-3 md:whitespace-nowrap">-->
							<!--														Välkommen till din Visma eEkonomi Koppling</h2>-->
							<!--													<button @click="redirectVisma" :disabled='connecting'-->
							<!--															class="c-btn btn-test btn hover:text-white text-base btn-custom pad-overi cursor-not-allowed shadow-lg">-->
							<!--														<div class="flex items-center m-auto" v-if="connecting">-->
							<!--															<svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"-->
							<!--																 xmlns="http://www.w3.org/2000/svg" fill="none"-->
							<!--																 viewBox="0 0 24 24">-->
							<!--																<circle class="opacity-25" cx="12" cy="12" r="10"-->
							<!--																		stroke="currentColor" stroke-width="4"></circle>-->
							<!--																<path class="opacity-75" fill="currentColor"-->
							<!--																	  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>-->
							<!--															</svg>-->
							<!--															{{ $t('connecting') }}-->
							<!--														</div>-->
							<!--														<span v-else>Anslut till Visma</span>-->
							<!--													</button>-->
							<!--												</div>-->
							<!--												<div v-if="parseInt(accountingValue) === parseInt(fortnoxAccounting)">-->

							<!--													&lt;!&ndash; <h2 class="text-gray-600 text-xl mb-3 md:whitespace-nowrap">Välkommen till din Fortnox eEkonomi Koppling</h2> &ndash;&gt;-->
							<!--													<button @click="redirectFortnox" :disabled='connecting'-->
							<!--															class="c-btn btn-test btn hover:text-white text-base btn-custom pad-overi cursor-not-allowed shadow-lg">-->
							<!--														<div class="flex items-center m-auto" v-if="connecting">-->
							<!--															<svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"-->
							<!--																 xmlns="http://www.w3.org/2000/svg" fill="none"-->
							<!--																 viewBox="0 0 24 24">-->
							<!--																<circle class="opacity-25" cx="12" cy="12" r="10"-->
							<!--																		stroke="currentColor" stroke-width="4"></circle>-->
							<!--																<path class="opacity-75" fill="currentColor"-->
							<!--																	  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>-->
							<!--															</svg>-->
							<!--															{{ $t('connecting') }}-->
							<!--														</div>-->
							<!--														<span v-else>Anslut till Fortnox</span>-->
							<!--													</button>-->
							<!--												</div>-->
							<!--											</div>-->
							<!--										</div>-->

							<!--									</div>-->
							<!--								</div>-->
							<!--								<div class="col-md-8"></div>-->
							<!--							</div>-->
							<!--							<h3 class="text-gray-400 text-xl font-semibold mt-5 mb-4 pl-3"-->
							<!--								v-if="showPaymentTerms ||showCustomAccount || showDeliverySettings || showBamboo">-->
							<!--								{{ $t('optionalSetting') }}</h3>-->

							<!--							<span v-if="showCustomAccount"-->
							<!--								  class="block text-gray-500 text-base pl-3 mt-3">{{-->
							<!--									$t('accountSetting')-->
							<!--								}} <span class="ml-1 tooltip-settings"><i-->
							<!--									class="text-sm help-color fa fa-question-circle"></i><span-->
							<!--									class="w-80 tooltiptext text-xs shadow-sm border word-breaking">Här har ni möjlighet att mappa specifika länder och/eller valutor med specifika konton. Ni kan också ange specifika konton för specifika omständigheter.</span></span></span>-->
							<!--							<div class="border col-md-11 rounded mb-8 mt-1 p-2"-->
							<!--								 v-if="showCustomAccount">-->
							<!--								<div class="row">-->
							<!--									<div class="col-md-12 rounded md:pr-4 p-3 mb-3">-->
							<!--										<form class="flex flex-col"-->
							<!--											  @submit.prevent="addCustomAccount">-->
							<!--											<div class="row flex flex-col md:flex-row md:pr-3">-->
							<!--												<div class="col-sm-3 optional-config-form">-->
							<!--													<label for="kontotyp"-->
							<!--														   class="text-gray-600 font-semibold">Kontotyp-->
							<!--														<span class="ml-1 tooltip-settings"><i-->
							<!--															class="text-sm help-color fa fa-question-circle cursor-pointer"></i><span-->
							<!--															class="w-60 tooltiptext text-xs shadow-sm border word-breaking">Ange vilken typ av konto ni vill mappa (t.ex. intäktskonto eller utbetalningskonto)</span></span></label><br>-->
							<!--													<select v-model="accType"-->
							<!--															@change="onChangeAccountType"-->
							<!--															id="konto-type"-->
							<!--															class="form-select override-form-ca w-full"-->
							<!--															required>-->
							<!--														<option :value="Object.keys(type)[0]"-->
							<!--																v-for="(type, i) in accountType"-->
							<!--																:key="i">{{-->
							<!--																Object.values(type)[0]-->
							<!--															}}-->
							<!--														</option>-->
							<!--													</select>-->
							<!--												</div>-->
							<!--												<div class="col-sm-3 optional-config-form">-->
							<!--													<label for="Matchningstyp"-->
							<!--														   class="text-gray-600 font-semibold">Matchningstyp-->
							<!--														<span class="ml-1 tooltip-settings"><i-->
							<!--															class="text-sm help-color fa fa-question-circle"></i><span-->
							<!--															class="w-60 tooltiptext text-xs shadow-sm border word-breaking">Här anger ni om ni vill att matchningen avgörs per land eller per valuta.</span></span></label><br>-->
							<!--													<select :value="matchTyp" id="match-type"-->
							<!--															@change="switchType"-->
							<!--															class="form-select override-form-ca w-full"-->
							<!--															required>-->
							<!--														<option :value="Object.keys(type)[0]"-->
							<!--																v-for="(type, i) in matchType"-->
							<!--																:key="i">{{-->
							<!--																Object.values(type)[0]-->
							<!--															}}-->
							<!--														</option>-->
							<!--													</select>-->
							<!--												</div>-->
							<!--												<div class="col-sm-3">-->
							<!--													<label for="Landskod"-->
							<!--														   class="text-gray-600 font-semibold"-->
							<!--														   v-if="parseInt(matchTyp) === 1">Landskod-->
							<!--														<span class="ml-1 tooltip-settings"><i-->
							<!--															class="text-sm help-color fa fa-question-circle"></i><span-->
							<!--															class="w-60 tooltiptext text-xs shadow-sm border word-breaking">Ange landet ni vill mappa till kontot i fråga.</span></span></label>-->
							<!--													<label for="Valuta"-->
							<!--														   class="text-gray-600 font-semibold"-->
							<!--														   v-else>Valuta <span-->
							<!--														class="ml-1 tooltip-settings"><i-->
							<!--														class="text-sm help-color fa fa-question-circle"></i><span-->
							<!--														class="w-60 tooltiptext text-xs shadow-sm border word-breaking">Ange valutan ni vill mappa till kontot i fråga.</span></span></label><br>-->
							<!--													<div>-->
							<!--														<div v-show="showCountry"-->
							<!--															 class="optional-config-form">-->
							<!--															<select v-model="matchCode"-->
							<!--																	id="country-select"-->

							<!--																	class="form-select select-field override-form-ca w-full">-->
							<!--																<option :value="country[0]"-->
							<!--																		v-for="(country, i) in countries"-->
							<!--																		:key="i">{{ country[1] }}-->
							<!--																</option>-->
							<!--															</select>-->
							<!--														</div>-->
							<!--														<div v-show="!showCountry"-->
							<!--															 class="optional-config-form">-->
							<!--															<select v-model="matchCode"-->
							<!--																	id="currency-select"-->
							<!--																	class="form-select select-field override-form-ca w-full">-->
							<!--																<option :value="currency"-->
							<!--																		v-for="(currency, i) in allCurrencies"-->
							<!--																		:key="i">{{ currency }}-->
							<!--																</option>-->
							<!--															</select>-->
							<!--														</div>-->
							<!--													</div>-->


							<!--												</div>-->
							<!--												<div class="col-sm-3 optional-config-form">-->
							<!--													<label for="kontotyp"-->
							<!--														   class="text-gray-600 font-semibold">Konto#-->
							<!--														<span class="ml-1 tooltip-settings"><i-->
							<!--															class="text-sm help-color fa fa-question-circle"></i><span-->
							<!--															class="w-60 tooltiptext text-xs shadow-sm border word-breaking">Här anger ni kontonumret på det konto ni försöker lägga upp</span></span></label><br>-->
							<!--													<div-->
							<!--														class="flex form-group optional-config-form">-->
							<!--														<select v-model="acctNumber"-->
							<!--																class="form-select override-form-ca w-full"-->
							<!--																id="account-numbers-select">-->
							<!--															<option selected disabled></option>-->
							<!--															<option :value="account.Number"-->
							<!--																	v-for="account in accountNumbers"-->
							<!--																	:key="uid(account)">-->
							<!--																{{-->
							<!--																	account.Number.length > 6 ? null : '(' + account.Number-->
							<!--																		+ ') '-->
							<!--																}}-->
							<!--																{{ account.Description }}-->
							<!--															</option>-->
							<!--														</select>-->
							<!--														&lt;!&ndash; <div class="input-group-append">-->
							<!--															<span class="input-group-text cursor-pointer" @click="openAddAccount">-->
							<!--																<el-tooltip content="Add new account numbers">-->
							<!--																	<svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">-->
							<!--																	<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />-->
							<!--																	</svg>-->
							<!--																</el-tooltip>-->
							<!--															</span>-->
							<!--														</div> &ndash;&gt;-->
							<!--													</div>-->
							<!--													&lt;!&ndash; <div class="w-1/2 absolute bg-white z-50 shadow border rounded mt-3" v-if="modal && filteredAccount.length > 0">-->
							<!--														<ul>-->
							<!--															<li class="py-2 pl-2 hover:bg-gray-100 cursor-pointer" v-for="(item, i) in filteredAccount.slice(0, 5)" :key="i" @click="setAccount(item)">{{ item.Number + ' ' +  }}</li>-->
							<!--														</ul>-->
							<!--													</div> &ndash;&gt;-->

							<!--												</div>-->
							<!--												<div class="col-sm-3"-->
							<!--													 v-if="hideReconciliationField">-->
							<!--													<label for="kontotyp"-->
							<!--														   class="text-gray-600 font-semibold">{{-->
							<!--															$t('ruleType')-->
							<!--														}} <span class="ml-1 tooltip-settings"><i-->
							<!--															class="text-sm help-color fa fa-question-circle"></i><span-->
							<!--															class="w-60 tooltiptext text-xs shadow-sm border word-breaking">Här har ni möjlighet att ange om detta konto ska användas för något specifikt, så som frakt eller företagskunder</span></span></label><br>-->
							<!--													<select v-model="ruleType" id="konto-type"-->
							<!--															class="form-select override-form-ca w-full">-->
							<!--														<option value="company">{{-->
							<!--																$t('forCompany')-->
							<!--															}}-->
							<!--														</option>-->
							<!--														<option value="shipping">-->
							<!--															{{ $t('forShipping') }}-->
							<!--														</option>-->
							<!--														<option value="feelines">-->
							<!--															{{ $t('forFeeLines') }}-->
							<!--														</option>-->
							<!--														<option value="gift_card">-->
							<!--															{{ $t('forGiftCard') }}-->
							<!--														</option>-->
							<!--														<option value="discount">-->
							<!--															{{ $t('forDiscount') }}-->
							<!--														</option>-->
							<!--													</select>-->
							<!--												</div>-->
							<!--												<div class="col-sm-3 optional-config-form"-->
							<!--													 v-if="parseInt(accType) === 1 || parseInt(accType) === 3">-->
							<!--													<label for="vat"-->
							<!--														   class="text-gray-600 font-semibold"> Vat <span-->
							<!--														class="ml-1 tooltip-settings"><i-->
							<!--														class="text-sm help-color fa fa-question-circle"></i><span-->
							<!--														class="w-60 tooltiptext text-xs shadow-sm border word-breaking">Vat</span></span></label><br>-->
							<!--													<select v-model="vatValue" id="vat-type-value"-->
							<!--															class="form-select override-form-ca w-full">-->
							<!--														<option :value="`${item.vat}-${item.code}`"-->
							<!--																v-for="(item, i) in vatRates"-->
							<!--																:key="i">{{ item.country + `: ${item.vat}%` }}-->
							<!--														</option>-->
							<!--													</select>-->
							<!--												</div>-->
							<!--												<div class="col-sm-1">-->
							<!--													<label for="kontotyp" class="mb-3 mt-1"></label><br>-->
							<!--													<button-->
							<!--														class="addingCustomAccountbtn hover:text-white text-base btn-custom pad-overi"-->
							<!--														:disabled='addingCustomAccount'>-->
							<!--                                                        <span v-if="addingCustomAccount">-->
							<!--                                                            <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"-->
							<!--																 xmlns="http://www.w3.org/2000/svg" fill="none"-->
							<!--																 viewBox="0 0 24 24">-->
							<!--                                                            <circle class="opacity-25" cx="12" cy="12" r="10"-->
							<!--																	stroke="currentColor" stroke-width="4"></circle>-->
							<!--                                                            <path class="opacity-75" fill="currentColor"-->
							<!--																  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>-->
							<!--                                                            </svg>-->
							<!--                                                        </span>-->
							<!--														<span v-else>-->
							<!--                                                            Spara-->
							<!--                                                        </span>-->
							<!--													</button>-->
							<!--												</div>-->
							<!--												&lt;!&ndash;										<div class="col-sm-3"&ndash;&gt;-->
							<!--												&lt;!&ndash;											 v-if="hideReconciliationField">&ndash;&gt;-->
							<!--												&lt;!&ndash;											<label for="kontotyp"&ndash;&gt;-->
							<!--												&lt;!&ndash;												   class="text-gray-600 font-semibold">{{
								&ndash;&gt;-- >
								< !-- &lt; ! & ndash;                                                    $t('ruleType') & ndash;&gt;-- >
								< !-- &lt; ! & ndash;
							}}&ndash;&gt;-->
							<!--												&lt;!&ndash;												<span class="ml-1 tooltip-settings"><i&ndash;&gt;-->
							<!--												&lt;!&ndash;													class="text-sm help-color fa fa-question-circle"></i><span&ndash;&gt;-->
							<!--												&lt;!&ndash;													class="w-60 tooltiptext shadow-sm border word-breaking">Här har ni möjlighet att ange om detta konto ska användas för något specifikt, så som frakt eller företagskunder</span></span></label><br>&ndash;&gt;-->
							<!--												&lt;!&ndash;											<select v-model="ruleType" id="konto-type"&ndash;&gt;-->
							<!--												&lt;!&ndash;													class="form-select override-form-ca w-full">&ndash;&gt;-->
							<!--												&lt;!&ndash;												<option value="company">{{
								&ndash;&gt;-- >
								< !-- &lt; ! & ndash;                                                        $t('forCompany') & ndash;&gt;-- >
								< !-- &lt; ! & ndash;
							}}&ndash;&gt;-->
							<!--												&lt;!&ndash;												</option>&ndash;&gt;-->
							<!--												&lt;!&ndash;												<option value="shipping">&ndash;&gt;-->
							<!--												&lt;!&ndash;													{{ $t('forShipping') }}&ndash;&gt;-->
							<!--												&lt;!&ndash;												</option>&ndash;&gt;-->
							<!--												&lt;!&ndash;												<option value="feelines">&ndash;&gt;-->
							<!--												&lt;!&ndash;													{{ $t('forFeeLines') }}&ndash;&gt;-->
							<!--												&lt;!&ndash;												</option>&ndash;&gt;-->
							<!--												&lt;!&ndash;											</select>&ndash;&gt;-->
							<!--												&lt;!&ndash;										</div>&ndash;&gt;-->
							<!--												&lt;!&ndash;										<div class="col-sm-1">&ndash;&gt;-->
							<!--												&lt;!&ndash;											<label for="kontotyp" class="mb-3 mt-1"></label><br>&ndash;&gt;-->
							<!--												&lt;!&ndash;											<button&ndash;&gt;-->
							<!--												&lt;!&ndash;												class="addingCustomAccountbtn hover:text-white text-base btn-custom pad-overi"&ndash;&gt;-->
							<!--												&lt;!&ndash;												:disabled='addingCustomAccount'>&ndash;&gt;-->
							<!--												&lt;!&ndash;                                                        <span v-if="addingCustomAccount">&ndash;&gt;-->
							<!--												&lt;!&ndash;                                                            <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"&ndash;&gt;-->
							<!--												&lt;!&ndash;																 xmlns="http://www.w3.org/2000/svg" fill="none"&ndash;&gt;-->
							<!--												&lt;!&ndash;																 viewBox="0 0 24 24">&ndash;&gt;-->
							<!--												&lt;!&ndash;                                                            <circle class="opacity-25" cx="12" cy="12" r="10"&ndash;&gt;-->
							<!--												&lt;!&ndash;																	stroke="currentColor" stroke-width="4"></circle>&ndash;&gt;-->
							<!--												&lt;!&ndash;                                                            <path class="opacity-75" fill="currentColor"&ndash;&gt;-->
							<!--												&lt;!&ndash;																  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>&ndash;&gt;-->
							<!--												&lt;!&ndash;                                                            </svg>&ndash;&gt;-->
							<!--												&lt;!&ndash;                                                        </span>&ndash;&gt;-->
							<!--												&lt;!&ndash;												<span v-else>&ndash;&gt;-->
							<!--												&lt;!&ndash;                                                            Spara&ndash;&gt;-->
							<!--												&lt;!&ndash;                                                        </span>&ndash;&gt;-->
							<!--												&lt;!&ndash;											</button>&ndash;&gt;-->
							<!--												&lt;!&ndash;										</div>&ndash;&gt;-->
							<!--											</div>-->
							<!--											&lt;!&ndash; <div class="flex flex-col md:flex-row align-items-center md:pr-3">-->
							<!--												<div class="col-sm-3 optional-config-form">-->
							<!--													<label for="company" class="text-gray-600 font-semibold">{{ $t('forCompany') }} <span class="ml-1"><i class="text-sm help-color fa fa-question-circle"></i><span class="w-60 tooltiptext text-xs shadow-sm border word-breaking"></span></span></label><br>-->
							<!--													<input type="checkbox" v-model="forCompany" class="form-control h-4 checkbox-height">-->
							<!--												</div>-->
							<!--												<div class="col-sm-3 optional-config-form">-->
							<!--													<label for="shipping" class="text-gray-600 font-semibold">{{ $t('forShipping') }} <span class="ml-1"><i class="text-sm help-color fa fa-question-circle"></i><span class="w-60 tooltiptext text-xs shadow-sm border word-breaking"></span></span></label><br>-->
							<!--													<input type="checkbox" v-model="forShipping" class="form-control h-4 checkbox-height">-->
							<!--												</div>-->
							<!--												<div class="col-sm-3 optional-config-form mb-1">-->
							<!--													<label for="feelines" class="text-gray-600 font-semibold">{{ $t('forFeeLines') }} <span class="ml-1"><i class="text-sm help-color fa fa-question-circle"></i><span class="w-60 tooltiptext text-xs shadow-sm border word-breaking"></span></span></label><br>-->
							<!--													<input type="checkbox" v-model="forFeeLines" class="form-control h-4 checkbox-height">-->
							<!--												</div>-->
							<!--												<div class="col-sm-3 optional-config-form">-->
							<!--													<button class="btn hover:text-white text-base btn-custom pad-overi w-full" :disabled='addingCustomAccount'>-->
							<!--														<span v-if="addingCustomAccount">-->
							<!--															<svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">-->
							<!--															<circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>-->
							<!--															<path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>-->
							<!--															</svg>-->
							<!--														</span>-->
							<!--														<span v-else>-->
							<!--															Spara-->
							<!--														</span>-->
							<!--													</button>-->
							<!--												</div>-->
							<!--											</div> &ndash;&gt;-->
							<!--										</form>-->
							<!--									</div>-->
							<!--									<div class="col-md-10 p-3">-->
							<!--										<div class="table-responsive">-->
							<!--											<table style="width: 100%;" id="exampledestroy"-->
							<!--												   class="table">-->
							<!--												<thead class="hidden">-->
							<!--												<tr>-->
							<!--													<th class="text-xs">Kontotyp</th>-->
							<!--													<th class="text-xs">Matchningsfält</th>-->
							<!--													<th class="text-xs">Land/Valutatyp</th>-->
							<!--													<th class="text-xs">Konto#</th>-->
							<!--													<th class="text-xs"></th>-->
							<!--													<th class="text-xs">Action</th>-->
							<!--												</tr>-->
							<!--												</thead>-->
							<!--												<tbody>-->
							<!--												<tr v-for="(account,key) in customAccounts"-->
							<!--													:key="key">-->
							<!--													<td class="text-xs">-->
							<!--                                                            <span>-->
							<!--                                                               {{ getType(accountType, account.acct_type) }}-->
							<!--                                                            </span>-->
							<!--													</td>-->
							<!--													<td class="text-xs">-->
							<!--                                                            <span>-->
							<!--                                                                {{ getMatchFormType(matchType, account.match_type) }}-->
							<!--                                                            </span>-->
							<!--													</td>-->
							<!--													<td class="text-xs">-->
							<!--														<span-->
							<!--															v-if="account.match_code && account.match_code.length > 2">{{-->
							<!--																account.match_code-->
							<!--															}}</span>-->
							<!--														<span v-else>{{-->
							<!--																filterAccountMatchCode(account.match_code)-->
							<!--															}}</span>-->
							<!--													</td>-->
							<!--													<td class="text-xs">{{-->
							<!--															filterAccountNumberMatch(account.acct_no)-->
							<!--														}}-->
							<!--													</td>-->
							<!--													<td class="text-xs">-->
							<!--                                                            <span v-if="account.hasOwnProperty('for_shipping')">-->
							<!--                                                                <span v-if="account.for_shipping">-->
							<!--                                                                    {{-->
							<!--																		$t('forShipping')-->
							<!--																	}}-->
							<!--                                                                <br>-->
							<!--                                                                </span>-->
							<!--                                                                <span v-if="account.for_company">-->
							<!--                                                                    {{-->
							<!--																		$t('forCompany')-->
							<!--																	}}-->
							<!--                                                                <br>-->
							<!--                                                                </span>-->
							<!--                                                                <span v-if="account.for_fee_lines">-->
							<!--                                                                    {{-->
							<!--																		$t('forFeeLines')-->
							<!--																	}}-->
							<!--                                                                </span>-->

							<!--                                                                <span v-if="account.for_discount">-->
							<!--                                                                    {{-->
							<!--																		$t('forDiscount')-->
							<!--																	}}-->
							<!--                                                                </span>-->
							<!--                                                                <span v-if="account.for_gift_cards">-->
							<!--                                                                    {{-->
							<!--																		$t('forGiftCard')-->
							<!--																	}}-->
							<!--                                                                </span>-->
							<!--                                                            </span>-->
							<!--														&lt;!&ndash; <span></span> &ndash;&gt;-->
							<!--													</td>-->
							<!--													<td class="text-xs">-->
							<!--                                                            <span>-->
							<!--																{{ account.vat_rate }}-->
							<!--															</span>-->
							<!--														&lt;!&ndash; <span></span> &ndash;&gt;-->
							<!--													</td>-->
							<!--													<td class="text-xs">-->
							<!--														<span @click="editCustomAccount(account)"-->
							<!--															  class="text-base cursor-pointer iconly-brokenEdit icon-color mr-2"></span>-->
							<!--														<span @click="deleteCustomAccount(account.id)"-->
							<!--															  class="text-base cursor-pointer iconly-brokenDelete icon-color"></span>-->
							<!--													</td>-->
							<!--												</tr>-->
							<!--												</tbody>-->
							<!--											</table>-->
							<!--										</div>-->
							<!--									</div>-->
							<!--								</div>-->
							<!--							</div>-->

							<!--							&lt;!&ndash; <span v-if="showPaymentTerms" class="text-gray-500 text-base mt-4 pl-3">{{ $t('paymentMappingSetting') }}-->
							<!--                                <span class="ml-1 tooltip-settings"><i class="text-sm help-color fa fa-question-circle"></i>-->
							<!--                                    <span class="w-96 tooltiptext shadow-sm border word-breaking">-->
							<!--                                        Här har ni möjlighet att mappa specifika betalleverantörer era kunder betalar med i webbutiken med betalningsvillkor i ekonomiprogrammet. Detta innebär att specifika betalningsvillkor kommer att anges i kundfakturan i ekonomiprogrammet beroende på vilken betalleverantör ordern i fråga betalats via. Med denna funktion kan ni enkelt skilja på vilka ordrar/fakturor som betalats med respektive betalväxel-->
							<!--                                    </span>-->
							<!--                                </span>-->
							<!--                            </span> &ndash;&gt;-->
							<!--							<div class="col-md-11 rounded mb-8 mt-1 py-3 pl-0 pr-0" v-if="showPaymentTerms">-->
							<!--								<div class="row">-->
							<!--									<div class="col-md-12 rounded p-3 mb-3">-->
							<!--										<form @submit.prevent="createPaymentTermSubscription">-->
							<!--											&lt;!&ndash;									<div class="flex flex-col md:flex-row">&ndash;&gt;-->
							<!--											&lt;!&ndash;										<div class="col-sm-3">&ndash;&gt;-->
							<!--											&lt;!&ndash;											<label for="kontotyp" class="text-gray-600 font-semibold">Kod för&ndash;&gt;-->
							<!--											&lt;!&ndash;												Betalvillkor <span class="ml-1 tooltip-settings"><i&ndash;&gt;-->
							<!--											&lt;!&ndash;													class="text-sm help-color fa fa-question-circle"></i><span&ndash;&gt;-->
							<!--											&lt;!&ndash;													class="w-60 tooltiptext shadow-sm border word-breaking">Ange koden för betalningsvillkoret i ekonomiprogrammet som ni vill använda, t.ex. KL.</span></span></label><br>&ndash;&gt;-->
							<!--											&lt;!&ndash;											<select v-model="paymentTcode"&ndash;&gt;-->
							<!--											&lt;!&ndash;													class="form-select override-form-ca w-full" required>&ndash;&gt;-->
							<!--											&lt;!&ndash;												<option :value="term.Code" v-for="(term, i) in paymentTerms"&ndash;&gt;-->
							<!--											&lt;!&ndash;														:key="i">{{ term.Description }}&ndash;&gt;-->
							<!--											&lt;!&ndash;												</option>&ndash;&gt;-->
							<!--											&lt;!&ndash;											</select>&ndash;&gt;-->
							<!--											&lt;!&ndash;										</div>&ndash;&gt;-->
							<!--											&lt;!&ndash;										<div class="col-sm-3">&ndash;&gt;-->
							<!--											&lt;!&ndash;											<label for="Matchningstyp" class="text-gray-600 font-semibold">Betalleverantör&ndash;&gt;-->
							<!--											&lt;!&ndash;												<span class="ml-1 tooltip-settings"><i&ndash;&gt;-->
							<!--											&lt;!&ndash;													class="text-sm help-color fa fa-question-circle"></i><span&ndash;&gt;-->
							<!--											&lt;!&ndash;													class="w-60 tooltiptext shadow-sm border word-breaking">Ange betalleverantören ni vill mappa till ovan betalningsvillkor, t.ex. Klarna.</span></span></label><br>&ndash;&gt;-->
							<!--											&lt;!&ndash;											<select v-model="paymentProvider"&ndash;&gt;-->
							<!--											&lt;!&ndash;													class="form-select override-form-ca w-full" required>&ndash;&gt;-->
							<!--											&lt;!&ndash;												&lt;!&ndash;@change="switchType"&ndash;&gt;&ndash;&gt;-->
							<!--											&lt;!&ndash;												<option :value="type[0]" v-for="(type, i) in providerPaym"&ndash;&gt;-->
							<!--											&lt;!&ndash;														:key="i">{{ type[1] }}&ndash;&gt;-->
							<!--											&lt;!&ndash;												</option>&ndash;&gt;-->
							<!--											&lt;!&ndash;											</select>&ndash;&gt;-->
							<!--											&lt;!&ndash;										</div>&ndash;&gt;-->
							<!--											&lt;!&ndash;										&lt;!&ndash; <div class="col-md-3"></div> &ndash;&gt;&ndash;&gt;-->
							<!--											&lt;!&ndash;										<div class="col-sm-3">&ndash;&gt;-->
							<!--											&lt;!&ndash;											<label for="kontotyp" class="mb-3 mt-1"></label><br>&ndash;&gt;-->
							<!--											&lt;!&ndash;											<button class="btn hover:text-white text-base btn-custom pad-overi"&ndash;&gt;-->
							<!--											&lt;!&ndash;													:disabled='addingPaymentTerms'>&ndash;&gt;-->
							<!--											&lt;!&ndash;												<table>&ndash;&gt;-->
							<!--											&lt;!&ndash;													<tbody>&ndash;&gt;-->
							<!--											&lt;!&ndash;													<tr>&ndash;&gt;-->
							<!--											&lt;!&ndash;														<td class="text-xs">&ndash;&gt;-->
							<!--											&lt;!&ndash;													<span v-if="account.match_code.length > 2">{{
								&ndash;&gt;-- >
								< !-- &lt; ! & ndash;                                                            account.match_code & ndash;&gt;-- >
								< !-- &lt; ! & ndash;
							}}</span>&ndash;&gt;-->
							<!--											&lt;!&ndash;															<span v-else>{{
								&ndash;&gt;-- >
								< !-- &lt; ! & ndash;                                                                    filterAccountMatchCode(account.match_code) & ndash;&gt;-- >
								< !-- &lt; ! & ndash;
							}}</span>&ndash;&gt;-->
							<!--											&lt;!&ndash;														</td>&ndash;&gt;-->
							<!--											&lt;!&ndash;														<td class="text-xs">{{
								&ndash;&gt;-- >
								< !-- &lt; ! & ndash;                                                                filterAccountNumberMatch(account.acct_no) & ndash;&gt;-- >
								< !-- &lt; ! & ndash;
							}}&ndash;&gt;-->
							<!--											&lt;!&ndash;														</td>&ndash;&gt;-->
							<!--											&lt;!&ndash;														<td class="text-xs">&ndash;&gt;-->
							<!--											&lt;!&ndash;                                                            <span v-if="account.hasOwnProperty('for_shipping')">&ndash;&gt;-->
							<!--											&lt;!&ndash;                                                                <span>{{
								&ndash;&gt;-- >
								< !-- &lt; ! & ndash;                                                                        $t('forShipping') + ' : ' + account.for_shipping & ndash;&gt;-- >
								< !-- &lt; ! & ndash;
							}}</span><br>&ndash;&gt;-->
							<!--											&lt;!&ndash;                                                                <span>{{
								&ndash;&gt;-- >
								< !-- &lt; ! & ndash;                                                                        $t('forCompany') + ' : ' + account.for_company & ndash;&gt;-- >
								< !-- &lt; ! & ndash;
							}}</span> <br>&ndash;&gt;-->
							<!--											&lt;!&ndash;                                                                <span>{{
								&ndash;&gt;-- >
								< !-- &lt; ! & ndash;                                                                        $t('forFeeLines') + ' : ' + account.for_fee_lines & ndash;&gt;-- >
								< !-- &lt; ! & ndash;
							}}</span>&ndash;&gt;-->
							<!--											&lt;!&ndash;                                                            </span>&ndash;&gt;-->
							<!--											&lt;!&ndash;															&lt;!&ndash; <span></span> &ndash;&gt;&ndash;&gt;-->
							<!--											&lt;!&ndash;														</td>&ndash;&gt;-->
							<!--											&lt;!&ndash;														<td class="text-xs">&ndash;&gt;-->
							<!--											&lt;!&ndash;													<span @click="deleteCustomAccount(account.id)"&ndash;&gt;-->
							<!--											&lt;!&ndash;														  class="text-base cursor-pointer iconly-brokenDelete icon-color"></span>&ndash;&gt;-->
							<!--											&lt;!&ndash;														</td>&ndash;&gt;-->
							<!--											&lt;!&ndash;													</tr>&ndash;&gt;-->
							<!--											&lt;!&ndash;													</tbody>&ndash;&gt;-->
							<!--											&lt;!&ndash;												</table>&ndash;&gt;-->
							<!--											&lt;!&ndash;											</button>&ndash;&gt;-->
							<!--											&lt;!&ndash;										</div>&ndash;&gt;-->
							<!--											&lt;!&ndash;									</div>&ndash;&gt;-->
							<!--										</form>-->
							<!--									</div>-->
							<!--								</div>-->
							<!--								<span v-if="showPaymentTerms"-->
							<!--									  class="text-gray-500 text-base mt-4 pl-3">{{ $t('paymentMappingSetting') }} <span-->
							<!--									class="ml-1 tooltip-settings"><i-->
							<!--									class="text-sm help-color fa fa-question-circle"></i><span-->
							<!--									class="w-96 tooltiptext text-xs shadow-sm border word-breaking">Här har ni möjlighet att mappa specifika betalleverantörer era kunder betalar med i webbutiken med betalningsvillkor i ekonomiprogrammet. Detta innebär att specifika betalningsvillkor kommer att anges i kundfakturan i ekonomiprogrammet beroende på vilken betalleverantör ordern i fråga betalats via. Med denna funktion kan ni enkelt skilja på vilka ordrar/fakturor som betalats med respektive betalväxel</span></span></span>-->
							<!--								<div class="border col-md-10 rounded mb-8 mt-1 p-3" v-if="showPaymentTerms">-->
							<!--									<div class="row">-->
							<!--										<div class="col-md-12 rounded p-3 mb-3">-->
							<!--											<form @submit.prevent="createPaymentTermSubscription">-->
							<!--												<div class="flex flex-col md:flex-row">-->
							<!--													<div class="col-sm-3">-->
							<!--														<label for="kontotyp"-->
							<!--															   class="text-gray-600 font-semibold whitespace-nowrap">Kod-->
							<!--															för Betalvillkor <span class="ml-1 tooltip-settings"><i-->
							<!--																class="text-sm help-color fa fa-question-circle"></i><span-->
							<!--																class="w-60 tooltiptext text-xs shadow-sm border word-breaking">Ange koden för betalningsvillkoret i ekonomiprogrammet som ni vill använda, t.ex. KL.</span></span></label><br>-->
							<!--														<select v-model="paymentTcode"-->
							<!--																class="form-select override-form-ca w-full" required>-->
							<!--															<option :value="term.Code" v-for="(term, i) in paymentTerms"-->
							<!--																	:key="i">{{ term.Description }}-->
							<!--															</option>-->
							<!--														</select>-->
							<!--													</div>-->
							<!--													<div class="col-sm-3">-->
							<!--														<label for="Matchningstyp"-->
							<!--															   class="text-gray-600 font-semibold whitespace-nowrap">Betalleverantör-->
							<!--															<span class="ml-1 tooltip-settings"><i-->
							<!--																class="text-sm help-color fa fa-question-circle"></i><span-->
							<!--																class="w-60 tooltiptext text-xs shadow-sm border word-breaking">Ange betalleverantören ni vill mappa till ovan betalningsvillkor, t.ex. Klarna.</span></span></label><br>-->
							<!--														<select v-model="paymentProvider"-->
							<!--																class="form-select override-form-ca w-full" required>-->
							<!--															&lt;!&ndash;@change="switchType"&ndash;&gt;-->
							<!--															<option :value="type[1]" v-for="(type, i) in providerPaym"-->
							<!--																	:key="i">-->
							<!--																{{ type[1] }}-->
							<!--															</option>-->
							<!--														</select>-->
							<!--													</div>-->
							<!--													&lt;!&ndash; <div class="col-md-3"></div> &ndash;&gt;-->
							<!--													<div class="col-sm-3">-->
							<!--														<label for="kontotyp" class="mb-3 mt-1"></label><br>-->
							<!--														<button-->
							<!--															class="btn hover:text-white text-base btn-custom pad-overi"-->
							<!--															:disabled='addingPaymentTerms'>-->
							<!--                                                        <span v-if="addingPaymentTerms">-->
							<!--                                                            <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"-->
							<!--																 xmlns="http://www.w3.org/2000/svg" fill="none"-->
							<!--																 viewBox="0 0 24 24">-->
							<!--                                                            <circle class="opacity-25" cx="12" cy="12" r="10"-->
							<!--																	stroke="currentColor" stroke-width="4"></circle>-->
							<!--                                                            <path class="opacity-75" fill="currentColor"-->
							<!--																  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>-->
							<!--                                                            </svg>-->
							<!--                                                        </span>-->
							<!--															<span v-else>-->
							<!--                                                            Spara-->
							<!--                                                        </span>-->
							<!--														</button>-->
							<!--													</div>-->
							<!--												</div>-->
							<!--											</form>-->
							<!--										</div>-->
							<!--										<div class="col-md-8 rounded"> &lt;!&ndash;px-5 py-2 v-if="paymentTerms.length > 0"&ndash;&gt;-->
							<!--											&lt;!&ndash; <div class="row" v-for="payment in paymentTermsArray" :key="payment.id">-->
							<!--												<div class="col-md-4">{{ paymentTerms.filter(item => item.Code === payment.gateway_in_app)[0].Description  }}</div>-->
							<!--												<div class="col-md-4">{{ providerPaym.filter(item => parseInt(item[0]) === parseInt(payment.gateway_in_accounting))[0][1] }}</div>-->
							<!--												<div class="col-md-4"><span @click="deletePaymentTermSubscription(payment.id)" class="text-base cursor-pointer iconly-brokenDelete icon-color"></span></div>-->
							<!--											</div> &ndash;&gt;-->

							<!--											<div class="table-responsive">-->
							<!--												<table style="width: 100%;" id="exampledestroy" class="table">-->
							<!--													<thead class="hidden">-->
							<!--													<tr>-->
							<!--														<th style="width:10%" class="text-xs">Betalvillkor</th>-->
							<!--														<th style="width:10%" class="text-xs">Betalleverantör</th>-->
							<!--														<th style="width:10%" class="text-xs">{{ $t('action') }}</th>-->
							<!--													</tr>-->
							<!--													</thead>-->
							<!--													<tbody>-->
							<!--													<tr v-for="payment in paymentTermsArray" :key="payment.id">-->
							<!--														<td class="text-xs">-->
							<!--															<span>{{ payment.gateway_in_app }}</span>-->
							<!--														</td>-->
							<!--														<td class="text-xs">-->
							<!--															<span>{{-->
							<!--																	getAccountingGateway(paymentTerms, payment)-->
							<!--																}}</span>-->
							<!--														</td>-->
							<!--														<td class="text-xs">-->
							<!--														<span @click="deletePaymentTermSubscription(payment.id)"-->
							<!--															  class="text-base cursor-pointer iconly-brokenDelete icon-color"></span>-->
							<!--														</td>-->
							<!--													</tr>-->
							<!--													</tbody>-->
							<!--												</table>-->
							<!--											</div>-->

							<!--										</div>-->
							<!--									</div>-->
							<!--								</div>-->
							<!--								<span v-if="showDeliverySettings"-->
							<!--									  class="text-gray-500 text-base pl-3">{{ $t('deliverySetting') }} <span-->
							<!--									class="ml-1 tooltip-settings"><i-->
							<!--									class="text-sm help-color fa fa-question-circle"></i><span-->
							<!--									class="w-60 tooltiptext text-xs shadow-sm border word-breaking">Här har ni möjlighet att mappa leveranssätt i webbutiken med förvalda leveransvillkor i ekonomiprogrammet.</span></span></span>-->
							<!--								<div class="border col-md-10 rounded mb-8 mt-1 p-3" v-if="showDeliverySettings">-->
							<!--									<div class="row">-->
							<!--										<div class="col-md-12 rounded p-3 mb-3">-->
							<!--											<form @submit.prevent="createWayDeliverySubscription">-->
							<!--												<div class="flex flex-col md:flex-row items-center">-->
							<!--													<div class="col-sm-3">-->
							<!--														<label for="kontotyp"-->
							<!--															   class="text-gray-600 font-semibold whitespace-pre-line">Webbutikens-->
							<!--															leveransvillkor <span class="ml-1 tooltip-settings"><i-->
							<!--																class="text-sm help-color fa fa-question-circle"></i><span-->
							<!--																class="w-60 tooltiptext text-xs shadow-sm border word-breaking">Skriv in titeln på det leveranssätt i webbutiken som ni vill mappa till ett leveransvillkor i ekonomiprogrammet.</span></span></label><br>-->
							<!--														<input v-model="storeTitle" type="text"-->
							<!--															   class="form-input override-form-ca w-full" required>-->
							<!--													</div>-->
							<!--													<div class="col-sm-3">-->
							<!--														<label for="Fortnox titel"-->
							<!--															   class="text-gray-600 font-semibold whitespace-pre-line">Fortnox-->
							<!--															Leveransvillkor <span class="ml-1 tooltip-settings"><i-->
							<!--																class="text-sm help-color fa fa-question-circle"></i><span-->
							<!--																class="w-60 tooltiptext text-xs shadow-sm border word-breaking">Välj från rullistan det leveransvillkor ni vill använda i ekonomiprogrammet.</span></span></label><br>-->
							<!--														<select v-model="wayDoption" ref='optionDelivery'-->
							<!--																id='optionDelivery'-->
							<!--																class="form-select override-form-ca w-full" required>-->
							<!--															<option :value="delivery.Code + '*' + delivery.Description"-->
							<!--																	v-for="(delivery, i) in waysOfDeliveryOption"-->
							<!--																	:key="i">-->
							<!--																{{ delivery.Description }}-->
							<!--															</option>-->
							<!--														</select>-->
							<!--													</div>-->
							<!--													<div class="col-sm-3">-->
							<!--														<label for="kontotyp" class="mb-3 mt-3"></label><br>-->
							<!--														<button-->
							<!--															class="btn hover:text-white text-base btn-custom pad-overi"-->
							<!--															:disabled='addingDeliveryWay'>-->
							<!--                                                        <span v-if="addingDeliveryWay">-->
							<!--                                                            <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"-->
							<!--																 xmlns="http://www.w3.org/2000/svg" fill="none"-->
							<!--																 viewBox="0 0 24 24">-->
							<!--                                                            <circle class="opacity-25" cx="12" cy="12" r="10"-->
							<!--																	stroke="currentColor" stroke-width="4"></circle>-->
							<!--                                                            <path class="opacity-75" fill="currentColor"-->
							<!--																  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>-->
							<!--                                                            </svg>-->
							<!--                                                        </span>-->
							<!--															<span v-else>-->
							<!--                                                            Spara-->
							<!--                                                        </span>-->
							<!--														</button>-->
							<!--													</div>-->
							<!--												</div>-->
							<!--											</form>-->
							<!--										</div>-->
							<!--										<div class="col-md-8">-->
							<!--											&lt;!&ndash; <div class="row" v-for="delivery in wayOfDeliveryArray" :key="delivery.id">-->
							<!--												<div class="col-md-4">{{ delivery.title }}</div>-->
							<!--												<div class="col-md-4">{{ delivery.match_description }}</div>-->
							<!--												<div class="col-md-4"><span @click="deleteWaysOfDeliverySubscription(delivery.id)" class="text-base cursor-pointer iconly-brokenDelete icon-color"></span></div>-->
							<!--											</div> &ndash;&gt;-->

							<!--											<div class="table-responsive">-->
							<!--												<table style="width: 100%;" id="exampledestroy" class="table">-->
							<!--													<thead class="hidden">-->
							<!--													<tr>-->
							<!--														<th style="width:33%" class="text-xs">Betalvillkor</th>-->
							<!--														<th style="width:33%" class="text-xs">Betalleverantör</th>-->
							<!--														<th style="width:33%" class="text-xs">{{ $t('action') }}</th>-->
							<!--													</tr>-->
							<!--													</thead>-->
							<!--													<tbody>-->
							<!--													<tr v-for="delivery in wayOfDeliveryArray" :key="delivery.id">-->
							<!--														<td class="text-xs">{{ delivery.title }}</td>-->
							<!--														<td class="text-xs">{{ delivery.match_description }}</td>-->
							<!--														<td class="text-xs">-->
							<!--														<span @click="deleteWaysOfDeliverySubscription(delivery.id)"-->
							<!--															  class="text-base cursor-pointer iconly-brokenDelete icon-color"></span>-->
							<!--														</td>-->
							<!--													</tr>-->
							<!--													</tbody>-->
							<!--												</table>-->
							<!--											</div>-->
							<!--										</div>-->
							<!--									</div>-->
							<!--								</div>-->
							<!--								<span v-if="showBamboo"-->
							<!--									  class="text-gray-500 text-base mt-4 pl-3 pl-3">{{-->
							<!--										this.$t('bambooHRSetting')-->
							<!--									}}</span>-->
							<!--								<div class="border col-md-10 rounded mb-8 mt-1 md:pr-3 p-2" v-if="showBamboo">-->
							<!--									<div class="row">-->
							<!--										<div class="col-md-11 rounded p-3 mb-3">-->
							<!--											<form @submit.prevent="CreateBambooMapping">-->
							<!--												<div class="flex flex col md:flex-row">-->
							<!--													<div class="col-sm-3">-->
							<!--														<label for="kontotyp"-->
							<!--															   class="text-gray-600 font-semibold">Typ</label><br>-->
							<!--														<select v-model="absenceType" @change="switchAbsenceType"-->
							<!--																class="form-select override-form-ca w-full" required>-->
							<!--															<option :value="type[0]" v-for="(type, i) in bambooHRType"-->
							<!--																	:key="i">{{ type[1] }}-->
							<!--															</option>-->
							<!--														</select>-->
							<!--													</div>-->
							<!--													<div class="col-sm-3">-->
							<!--														<label for="Matchningstyp"-->
							<!--															   class="text-gray-600 font-semibold whitespace-nowrap"-->
							<!--															   v-if="showFortnoxCause">Fortnox Absence Cause</label>-->
							<!--														<label for="Matchningstyp"-->
							<!--															   class="text-gray-600 font-semibold whitespace-nowrap"-->
							<!--															   v-else>Fortnox-->
							<!--															Employee Field</label><br>-->
							<!--														<select v-model="absenceCauseEmployee"-->
							<!--																class="form-select override-form-ca w-full" required-->
							<!--																v-if="showFortnoxCause">-->
							<!--															<option :value="type[0]"-->
							<!--																	v-for="(type, i) in bambooFortnoxCause"-->
							<!--																	:key="i">{{ type[1] }}-->
							<!--															</option>-->
							<!--														</select>-->
							<!--														<select v-model="absenceCauseEmployee"-->
							<!--																class="form-select override-form-ca w-full" required-->
							<!--																v-else>-->
							<!--															<option :value="type" v-for="(type, i) in bambooEmpField"-->
							<!--																	:key="i">{{ type }}-->
							<!--															</option>-->
							<!--														</select>-->
							<!--													</div>-->
							<!--													<div class="col-md-3" v-if="showFortnoxCause">-->
							<!--														<label for="Matchningstyp"-->
							<!--															   class="text-gray-600 font-semibold whitespace-nowrap">BambooHR-->
							<!--															TimeOff Type</label>-->
							<!--														<select v-model="timeOffValue"-->
							<!--																class="form-select override-form-ca w-full" required>-->
							<!--															<option :value="type[0]" v-for="(type, i) in bambooTimeOff"-->
							<!--																	:key="i">{{ type[1] }}-->
							<!--															</option>-->
							<!--														</select>-->
							<!--													</div>-->
							<!--													<div class="col-md-3" v-if="!showFortnoxCause">-->
							<!--														<label for="custom field"-->
							<!--															   class="text-gray-600 text-xs font-semibold whitespace-nowrap">Employee-->
							<!--															Table</label>-->
							<!--														<input v-model="empTable" type="text"-->
							<!--															   class="form-control override-form-numbers" required>-->
							<!--													</div>-->
							<!--													<div class="col-md-3" v-if="!showFortnoxCause">-->
							<!--														<label for="custom field"-->
							<!--															   class="text-gray-600 text-xs font-semibold whitespace-nowrap">Employee-->
							<!--															Field</label>-->
							<!--														<input v-model="empField" type="text"-->
							<!--															   class="form-control override-form-numbers" required>-->
							<!--													</div>-->
							<!--													<div class="col-sm-3">-->
							<!--														<label for="kontotyp" class="mb-3 mt-1"></label><br>-->
							<!--														<button-->
							<!--															class="btn hover:text-white text-base btn-custom pad-overi"-->
							<!--															:disabled='addingBamboo'>-->
							<!--                                                        <span v-if="addingBamboo">-->
							<!--                                                            <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"-->
							<!--																 xmlns="http://www.w3.org/2000/svg" fill="none"-->
							<!--																 viewBox="0 0 24 24">-->
							<!--                                                            <circle class="opacity-25" cx="12" cy="12" r="10"-->
							<!--																	stroke="currentColor" stroke-width="4"></circle>-->
							<!--                                                            <path class="opacity-75" fill="currentColor"-->
							<!--																  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>-->
							<!--                                                            </svg>-->
							<!--                                                        </span>-->
							<!--															<span v-else>-->
							<!--                                                            Spara-->
							<!--                                                        </span>-->
							<!--														</button>-->
							<!--													</div>-->
							<!--												</div>-->
							<!--											</form>-->
							<!--										</div>-->
							<!--										<div class="col-md-8 rounded">-->
							<!--											&lt;!&ndash; <div class="row" v-for="bambooMapped in bambooMapData" :key="bambooMapped.id">-->
							<!--												<div class="col-md-3">{{ bambooMapped.value }}</div>-->
							<!--												<div class="col-md-3">{{ bambooMapped.name }}</div>-->
							<!--												<div class="col-md-3">{{ bambooMapped.type }}</div>-->
							<!--												<div class="col-md-3"><span @click="deleteBambooData(bambooMapped.id)" class="text-base cursor-pointer iconly-brokenDelete icon-color"></span></div>-->
							<!--											</div> &ndash;&gt;-->

							<!--											<div class="table-responsive">-->
							<!--												<table style="width: 100%;" id="exampledestroy" class="table">-->
							<!--													<thead class="hidden">-->
							<!--													<tr>-->
							<!--														<th style="width:33%" class="text-xs">value</th>-->
							<!--														<th style="width:33%" class="text-xs">name</th>-->
							<!--														<th style="width:33%" class="text-xs">type</th>-->
							<!--														<th style="width:33%" class="text-xs">{{ $t('action') }}</th>-->
							<!--													</tr>-->
							<!--													</thead>-->
							<!--													<tbody>-->
							<!--													<tr v-for="bambooMapped in bambooMapData" :key="bambooMapped.id">-->
							<!--														<td class="text-xs">{{ bambooMapped.value }}</td>-->
							<!--														<td class="text-xs">{{ bambooMapped.name }}</td>-->
							<!--														<td class="text-xs">{{ bambooMapped.type }}</td>-->
							<!--														<td class="text-xs">-->
							<!--														<span @click="deleteBambooData(bambooMapped.id)"-->
							<!--															  class="text-base cursor-pointer iconly-brokenDelete icon-color"></span>-->
							<!--														</td>-->
							<!--													</tr>-->
							<!--													</tbody>-->
							<!--												</table>-->
							<!--											</div>-->

							<!--										</div>-->
							<!--									</div>-->
							<!--								</div>-->

							<!--							</div>-->
							<!--							<div class="col-md-11 rounded mb-8 mt-1 py-3 pl-0 pr-0" v-if="showCostCenter">-->
							<!--								<span v-if="showCostCenter"-->
							<!--									  class="text-gray-500 text-base mt-4 pl-3">{{ $t('costCenterMapping') }} <span-->
							<!--									class="ml-1 tooltip-settings"><i-->
							<!--									class="text-sm help-color fa fa-question-circle"></i><span-->
							<!--									class="w-96 tooltiptext text-xs shadow-sm border word-breaking">{{-->
							<!--										$t('costCenterMappingHelpText')-->
							<!--									}}</span></span></span>-->
							<!--								<div class="border col-md-10 rounded mb-8 mt-1 md:pr-3 p-2" v-if="showCostCenter">-->
							<!--									<div class="row">-->
							<!--										<div class="col-md-12 rounded p-3 mb-3">-->
							<!--											<form @submit.prevent="createCostCenterSubscription">-->
							<!--												<div class="flex flex-col md:flex-row">-->
							<!--													<div class="col-sm-4">-->
							<!--														<label for="cost_center_code"-->
							<!--															   class="text-gray-600 font-semibold whitespace-nowrap">{{-->
							<!--																$t('cost_center')-->
							<!--															}} <span class="ml-1 tooltip-settings"><i-->
							<!--																class="text-sm help-color fa fa-question-circle"></i><span-->
							<!--																class="w-60 tooltiptext text-xs shadow-sm border word-breaking">{{-->
							<!--																	$t('costCenterHelpText')-->
							<!--																}}</span></span></label><br>-->
							<!--														<select v-model="costCenter.cost_center_code"-->
							<!--																class="form-select cost-center override-form-ca w-full"-->
							<!--																id="cost_center_code"-->
							<!--																required>-->
							<!--															<option :value="cc.Code" v-for="(cc, i) in costCenterList"-->
							<!--																	:key="i">{{ cc.Description }}-->
							<!--															</option>-->
							<!--														</select>-->
							<!--													</div>-->
							<!--													<div class="col-sm-3">-->
							<!--														<label for="cost-center-country-select"-->
							<!--															   class="text-gray-600 font-semibold whitespace-nowrap">Land-->
							<!--															<span class="ml-1 tooltip-settings"><i-->
							<!--																class="text-sm help-color fa fa-question-circle"></i><span-->
							<!--																class="w-60 tooltiptext text-xs shadow-sm border word-breaking">{{-->
							<!--																	$t('landHelpText')-->
							<!--																}}</span></span></label><br>-->
							<!--														<select v-model="costCenter.customer_country"-->
							<!--																id="customer_country"-->
							<!--																class="form-select override-form-ca w-full">-->
							<!--															<option :value="country[0]"-->
							<!--																	v-for="(country, i) in countries"-->
							<!--																	:key="i">{{ country[1] }}-->
							<!--															</option>-->
							<!--														</select>-->
							<!--													</div>-->
							<!--													&lt;!&ndash; <div class="col-md-3"></div> &ndash;&gt;-->
							<!--													<div class="col-sm-3">-->
							<!--														<label for="kontotyp" class="mb-3 mt-1"></label><br>-->
							<!--														<button-->
							<!--															class="btn hover:text-white text-base btn-custom pad-overi"-->
							<!--															:disabled='addingCostCenter'>-->
							<!--                                                        <span v-if="addingCostCenter">-->
							<!--                                                            <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"-->
							<!--																 xmlns="http://www.w3.org/2000/svg" fill="none"-->
							<!--																 viewBox="0 0 24 24">-->
							<!--                                                            <circle class="opacity-25" cx="12" cy="12" r="10"-->
							<!--																	stroke="currentColor" stroke-width="4"></circle>-->
							<!--                                                            <path class="opacity-75" fill="currentColor"-->
							<!--																  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>-->
							<!--                                                            </svg>-->
							<!--                                                        </span>-->
							<!--															<span v-else>-->
							<!--                                                            Spara-->
							<!--                                                        </span>-->
							<!--														</button>-->
							<!--													</div>-->
							<!--												</div>-->
							<!--											</form>-->
							<!--										</div>-->
							<!--										<div class="col-md-8 rounded">-->
							<!--											<div class="table-responsive" style="    padding-left: 12px;">-->
							<!--												<table style="width: 100%;" id="exampledestroy" class="table">-->
							<!--													<thead class="hidden">-->
							<!--													<tr>-->
							<!--														<th style="width:10%" class="text-xs">{{-->
							<!--																$t('cost_center')-->
							<!--															}}-->
							<!--														</th>-->
							<!--														<th style="width:10%" class="text-xs">Betalleverantör</th>-->
							<!--														<th style="width:10%" class="text-xs">{{ $t('action') }}</th>-->
							<!--													</tr>-->
							<!--													</thead>-->
							<!--													<tbody>-->
							<!--													<tr v-for="cc in costCenterDbList" :key="cc.id">-->
							<!--														<td class="text-xs">-->
							<!--															<span>{{ cc.costcenter_name }}</span>-->
							<!--														</td>-->
							<!--														<td class="text-xs">-->
							<!--															<span>{{ getCountryName(cc.customer_country) }}</span>-->
							<!--														</td>-->
							<!--														<td class="text-xs">-->
							<!--														<span @click="deleteCostCenterSubscription(cc.id)"-->
							<!--															  class="text-base cursor-pointer iconly-brokenDelete icon-color"></span>-->
							<!--														</td>-->
							<!--													</tr>-->
							<!--													</tbody>-->
							<!--												</table>-->
							<!--											</div>-->

							<!--										</div>-->
							<!--									</div>-->

							<!--								</div>-->

							<!--							</div>-->

							<div class="row">
								<div class="col-md-12 p-4">
									<div class="float-right space-x-3 v-app-5">
										<button
											class="c-btn btn-next-white btn text-sm font-black btn-lg border btn-border-all text-blue-800 hover:text-blue-800"
											@click="openTab(1)">{{ $t('settingPrevious') }}
										</button>
										<button
											class="c-btn btn-next btn text-sm font-black btn-lg border btn-border-all btn-full-theme text-white hover:text-blue-900"
											@click="openTab(3)">{{ $t('continue') }}
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="tab-pane tabs-animation fade active show" v-show="tabId === 3" role="tabpanel">
				<app-settings-activate-section :accounting="$route.query.acc" :subscription="$route.query.key"
											   :key="appSubscriptionActivate"
											   :apptype="$route.query.type" v-if="tabId === 3"/>
				<div class="row" style="display: none !important;">
					<div class="col-md-12">
						<div class="main-card card">
							<div class="card-body">
								<div class="row border-b border-gray-200" v-if="showActivateSub && !showFileHandler">
									<div class="col-md-4">
										<h5 class="card-title hidden md:block">{{ $t('activateApp') }}</h5>
									</div>
									<div class="col-md-4"></div>
									<div class="col-md-4">
										<h5 class="card-title hidden md:block" v-if="showTestButton">{{
												$t('testOrder')
											}}</h5>
									</div>
								</div>
								<h5 class="card-title font-light" v-else>{{ $t('fileUpload') }}</h5><br>

								<div class="row" v-if="showActivateSub && !showFileHandler">
									<h5 class="card-title block md:hidden ml-3">{{ $t('activateApp') }}</h5>
									<div class="flex space-x-3"
										 :class="{'col-md-4': showTestButton === true,'col-md-6': showTestButton=== false}">
										<button v-if='subscriptionDeactivate' :disabled='deactivating'
												@click="deactivateSubscription"
												class="v-app-7 c-btn btn-shadow mb-3 deactivate-btn text-white px-6 text-white text-xl py-2 rounded shadow-xl">
											<div class="flex items-center m-auto" v-if="deactivating">
												<svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
													 xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
													<circle class="opacity-25" cx="12" cy="12" r="10"
															stroke="currentColor" stroke-width="4"></circle>
													<path class="opacity-75" fill="currentColor"
														  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
												</svg>
												{{ $t('deactivating') }}
											</div>
											<span v-else>{{ $t('deactivate') }}</span>
										</button>
										<button v-if='subscriptionActivate' :disabled='activating'
												@click="activateSubscription"
												class="v-app-7 c-btn btn-activate mb-3 activate-btn px-10 text-white text-xl py-2 rounded shadow-xl">
											<div class="flex items-center m-auto" v-if="activating">
												<svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
													 xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
													<circle class="opacity-25" cx="12" cy="12" r="10"
															stroke="currentColor" stroke-width="4"></circle>
													<path class="opacity-75" fill="currentColor"
														  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
												</svg>
												{{ $t('activating') }}
											</div>
											<span v-else>{{ $t('activateNow') }}</span>
										</button>

									</div>

									<div class="col-md-4 mt-1" v-if="showTestButton">
										<h5 class="card-title block md:hidden">{{ $t('testOrder') }}</h5>
										<button @click="performTestSubscription"
												class="v-app-6 c-btn btn-test test-btn px-12 text-white text-xl py-2 rounded shadow-xl"
												:disabled='runningTest'>
											<!--btn btn-custom btn-slider-b btn-wide btn-lg px-5 shadow-2xl-->
											<div class="flex items-center m-auto" v-if="runningTest">
												<svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
													 xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
													<circle class="opacity-25" cx="12" cy="12" r="10"
															stroke="currentColor" stroke-width="4"></circle>
													<path class="opacity-75" fill="currentColor"
														  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
												</svg>
												{{ $t('testing') }}
											</div>
											<span v-else>{{ $t('testSend') }}</span>
										</button>
									</div>
									<div
										:class="{'col-md-4': showTestButton === true,'col-md-6': showTestButton=== false}"
										v-if="currentSubscription">
										<button v-on:click="openCancelSubscription"
												v-if="cancelledSubscriptionFlag === true"
												class="v-app-7 c-btn mb-3 btn-shadow cancel-btn px-10 text-white text-xl py-2 rounded shadow-xl float-right">
											<div class="flex items-center m-auto" v-if="activating">
												<svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
													 xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
													<circle class="opacity-25" cx="12" cy="12" r="10"
															stroke="currentColor" stroke-width="4"></circle>
													<path class="opacity-75" fill="currentColor"
														  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
												</svg>
												{{ $t('activating') }}
											</div>
											<span v-else>Cancellera integration</span>
										</button>
									</div>
								</div>
								<div class="mt-1" v-if="showFileHandler">
									<form @submit.prevent="uploadSwishFile">
										<!-- <h2 class="text-gray-400 text-base font-light">Ladda upp csv fil för inrapportering till Fortnox</h2> -->
										<div class="form-group flex">
											<label
												:class="fileAdded ? 'bg-gray-400 px-2 py-1 rounded cursor-pointer' : 'custom-file-upload'">
												<input type="file" class="inputfile form-control-file"
													   id="swish-file-upload" @change="fileChange"
													   aria-label="Swish Handel File" required
													   accept="application/pdf, text/csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet">
												<i class="iconly-brokenUpload"></i> {{ $t('chooseFile') }}
											</label>
											<span v-if="fileAdded" class="ml-3">
                                                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-green-500"
													 viewBox="0 0 20 20" fill="currentColor">
                                                <path fill-rule="evenodd"
													  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
													  clip-rule="evenodd"/>
                                                </svg>
                                            </span>
										</div>
										<button type="submit" :disabled='uploading'
												class="btn btn-theme-custom hover:text-white btn-wide btn-lg mt-2">
											<div class="flex items-center m-auto" v-if="uploading">
												<svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
													 xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
													<circle class="opacity-25" cx="12" cy="12" r="10"
															stroke="currentColor" stroke-width="4"></circle>
													<path class="opacity-75" fill="currentColor"
														  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
												</svg>
												{{ $t('uploading') }}
											</div>
											<span v-else>{{ $t('fileUpload') }}</span>
										</button>
									</form>
								</div>
							</div>
							<div class="row">
								<div class="col-md-12 p-4">
									<div class="float-right">
										<button
											class="c-btn btn-next-white btn text-sm font-black btn-lg border btn-border-all text-blue-700 hover:text-blue-900"
											@click="openTab(2)">{{ $t('settingPrevious') }}
										</button>
										<!-- <button class="btn btn-slider-b" @click="openTab(3)">Continue</button> -->
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
// import KProgress from 'k-progress'
// import $ from 'jquery'
import {mapGetters} from 'vuex'
import bus from './../../bus'
import {
	ACCOUNT_TYPE,
	APPID as APP,
	BAMBOO_TABLE_ONLY,
	COST_CENTER_LIST,
	CUSTOM_ACCOUNT_WIDGET_FORTNOX,
	CUSTOM_DELIVERY_WIDGET_FORTNOX,
	CUSTOM_PAYMENT_TERM_WIDGET_FORTNOX,
	CUSTOM_V3_APPS_FORTNOX,
	CUSTOM_V3_APPS_VISMA,
	CUSTOM_VAT_RATE_V3_APP_VISMA,
	PAYMENT_TERM_FIELD_FORTNOX,
	PAYMENT_TERM_FIELD_VISMA,
	PRICE_LIST_SUBSCRIPTION_FORTNOX,
	RECONCILIATION_APPS,
	SHOPIFY_BTN_REDIRECT,
	SHOPIFY_V3_APPS,
	SHOW_FILE_UPLOADER,
	SKU_VALIDATOR,
	STOCKPOINT_FORTNOX,
	STRIPE_SUBSCRIPTIONS,
	TEST_ORDER_BUTTON,
	VOUCHER_SERIES_FORTNOX,
	VOUCHER_SERIES_VISMA,
	RECONCILIATION_APPS_WITH_NO_FEE,
	RECONCILIATION_APPS_WITH_VAT_FEE_AND_REVERSE_VAT,
	RECONCILIATION_APPS_WITH_VAT_FEE,
	RECONCILIATION_APPS_WITH_NO_VAT_FEE, PRO_APP, VOUCHERS_APP

} from './../../app/index'
import {cleanVatValues} from './../../services/constants/vatConstant'
import {
	checkIfExist,
	saveDataLocalStorage,
	getDataLocalStorage,
	removeDataLocalStorage
} from "./../../services/storage-window"
import moment from "moment"
import appSettingsActivateSection from "./../../views/Automatisera/components/appSettingsActivateSection"
import appSettingConfigurationSection from "./components/appSettingConfigurationSection";
import appDescriptionSection from "./components/appDescriptionSection";

window.$ = window.jQuery = require('jquery')
// Required jquery-ui
require('./../../assets/jquery-ui/jquery-ui')
require('./../../assets/select2/select2.min')

export default {
	name: 'app-settings',
	components: {
		// 'k-progress' : KProgress
		appSettingsActivateSection,
		appSettingConfigurationSection,
		appDescriptionSection
	},
	data() {
		return {
			shopSettings: {},
			helpText: [],
			configKey: null,
			tabId: 1,
			errorFlag: false,
			showCustomAccount: false,
			showCountry: true,
			subscriptionDeactivate: false,
			subscriptionActivate: false,
			showActivateSub: false,
			showContinue: true,
			appSettingKey: new Date().getMinutes(),
			appDescriptionKey: new Date().getMinutes(),
			appSubscriptionActivate: new Date().getMinutes(),
			connectLegacy: false,
			connectEu: false,
			runningTest: false,
			showFortnoxCause: true,
			fileAdded: false,
			ruleType: '',
			vatValue: '',
			fortnoxLink: '',
			vismaLink: '',
			vismaTextName: '',
			absenceType: '',
			absenceCauseEmployee: '',
			timeOffValue: '',
			empTable: '',
			empField: '',
			countries: [],
			allCountries: [],
			accountNumbers: [],
			filteredAccount: [],
			selectAccount: '',
			currency: [],
			accountType: [],
			customAccounts: [],
			bambooHRType: [],
			bambooFortnoxCause: [],
			bambooTimeOff: [],
			bambooEmpField: [],
			bambooMapData: [],
			matchType: [],
			matchTypeCopy: [],
			currentSubscription: [],
			singleSubscription: [],
			waysOfDeliveryOption: [],
			wayOfDeliveryArray: [],
			providerPaym: [],
			paymentTermsArray: [],
			wayDoption: '',
			storeTitle: '',
			paymentTerms: [],
			voucherSeries: [],
			priceList: [],
			allCurrencies: [],
			vatRates: cleanVatValues(),
			customV3ListAccounts: [
				{
					key: 'sales_account_wo',
					match: 'WO',
					vat: ''
				},
				{
					key: 'sales_account_eu',
					match: 'EU',
					vat: ''
				},
				{
					key: 'sales_account_se_six',
					match: 'SE',
					vat: '0.06'
				},
				{
					key: 'sales_account_se_twelve',
					match: 'SE',
					vat: '0.12'
				},
				{
					key: 'sales_account_se_twenty_five',
					match: 'SE',
					vat: '0.25'
				}
			],
			customAccountList: {
				sales_account_wo: '',
				sales_account_eu: '',
				sales_account_se_six: '',
				sales_account_se_twelve: '',
				sales_account_se_twenty_five: '',
			},
			ayden_plus_form: {
				'eu_vat_account': '',
				'export_vat_account': '',
			},
			customAccountLisEqual: {
				'sales_account_wo': 'Sales account export',
				'sales_account_eu': 'Sales account EU',
				'sales_account_se_six': 'Sales account SE (6%)',
				'sales_account_se_twelve': 'Sales account SE (12%)',
				'sales_account_se_twenty_five': 'Sales account SE (25%)',
				'export_vat_account': 'Export vat account',
				'eu_vat_account': 'EU vat account',
			},
			// paymentProviderArray : [],
			paymentTcode: '',
			paymentProvider: '',
			subId: null,
			appType: null,
			accountingValue: null,
			vismaAccounting: null,
			fortnoxAccounting: null,
			appReadInfo: {
				info_content_title: '',
				info_content_html: ''
			},
			accType: '',
			currentAppType: '',
			matchTyp: 1,
			matchCode: '',
			matchCurrency: '',
			vatCode: '',
			acctNumber: '',
			forCompany: false,
			forShipping: false,
			forFeeLines: false,
			forGiftCard: false,
			forDiscount: false,
			showAllCountries: true,
			linkAmazonLegacy: '',
			linkAmazonEU: '',
			color: '#13c2c2',
			showFileHandler: false,
			showAmazon: false,
			showAmazonMarketPlace: false,
			percentage: 30,
			updating: false,
			addingCustomAccount: false,
			showForm: false,
			showDeliverySettings: false,
			showPaymentTerms: false,
			paypalSupplierDetails: [],
			vloading: true,
			connecting: false,
			uploading: false,
			loadingInfo: false,
			activating: false,
			deactivating: false,
			addingDeliveryWay: false,
			addingPaymentTerms: false,
			addingCostCenter: false,
			addingBamboo: false,
			showBamboo: false,
			modal: false,
			showStripe: false,
			showShopify: false,
			showShopifyBtn: false,
			shopifyPayBtnOnly: false,
			checkVismaAvailabilityToken: null,
			showTestButton: false,
			vismaBankAccount: [],
			v3CustomAccount: [],
			vismaForeignPayment: [],
			vismaUnit: [],
			hideReconciliationField: true,
			app_type: null,
			acc: null,
			showV3: false,
			cancelledSubscriptionFlag: false,
			dedicatedServerInformation: {
				allSubscriptions: '',
				activeSubscriptions: '',
				currentSubscription: [],
				customsAccount: [],
				paymentTermsArray: [],
				vismaBankAccount: [],
				vismaForeignPayment: [],
				vismaUnit: [],
				wayOfDeliveryArray: [],
				termsOfDeliveryArray: [],
				paymentTerms: [],
				waysOfDeliveryOption: [],
				server: 'standard',
				tokens: {
					access: '',
					refresh_toke: ''
				}
			},
			removeExtraV3Fields: false,
			showCostCenter: false,
			shopName: '',
			stockPointsList: [],
			costCenter: {
				cost_center_code: '',
				customer_country: ''
			},
			costCenterList: [],
			costCenterDbList: [],
			subscriptionConfigResponse: {},
			reconciliations: {
				payout: false,
				payout_account_bank: false,
				provider_fee_account: false,
				fee_vat_acct_debit: false,
				fee_vat_acct_credit: false,
			},
			pro_app: {
				vat_acct: false,
				receivable_acct: false,
				sales_account: false,
			},
			voucher_app: {
				vat_acct: false,
				payout: false,
				sales_account: false,
			},
			reconciliatonAccountList: {
				'payout': {
					acct_type: 2,
					acct_no: '',
					match_type: 2,
					match_code: 'SEK',
					id: null
				},
				'payout_account_bank': {
					acct_type: 12,
					acct_no: '',
					match_type: 2,
					match_code: 'SEK',
					id: null
				},
				'provider_fee_account': {
					acct_type: 10,
					acct_no: '',
					match_type: 2,
					match_code: 'SEK',
					id: null
				},
				'fee_vat_acct_debit': {
					acct_type: 13,
					acct_no: '',
					match_type: 2,
					match_code: 'SEK',
					id: null
				},
				'fee_vat_acct_credit': {
					acct_type: 14,
					acct_no: '',
					match_type: 2,
					match_code: 'SEK',
					id: null
				},
			},
			proAccountList: {
				'vat_acct': {
					acct_type: 1,
					acct_no: '',
					match_type: 1,
					match_code: 'SE',
					id: null
				},
				'receivable_acct': {
					acct_type: 5,
					acct_no: '',
					match_type: 1,
					match_code: 'SEK',
					id: null
				},
				'sales_account': {
					acct_type: 3,
					acct_no: '',
					match_type: 1,
					match_code: 'SEK',
					id: null
				},
			},
			voucherAccountList: {
				'vat_acct': {
					acct_type: 1,
					acct_no: '',
					match_type: 1,
					match_code: 'SE',
					id: null
				},
				'payout': {
					acct_type: 5,
					acct_no: '',
					match_type: 1,
					match_code: 'SEK',
					id: null
				},
				'sales_account': {
					acct_type: 3,
					acct_no: '',
					match_type: 1,
					match_code: 'SEK',
					id: null
				},
			},
			accountTypeList: [{1: 'Moms konto'},
				{2: 'Utbetalningskonto'},
				{3: 'Intäktskonto'},
				{4: 'leverantörsreskontra'},
				{5: 'Kundfordringar'},
				{6: 'Periodiseringskonto Intäkt'},
				{7: 'Periodiserad debet konto'},
				{8: 'Periodiserad kredit konto'},
				{9: 'Leverantörskonto'},
				{10: 'Konto betalväxel avgift'},
				{11: 'Monmskonto för betalväxel avgift'},
				{12: 'Utbetalningskonto Bank'},
				{13: 'Omvänd moms på betalväxel avgift - Debet'},
				{14: 'Omvänd moms på betalväxel avgift - Kredit'},
				{15: 'Specific Fee Account'}],
			officeLocations: []
		}
	},
	computed: {
		...mapGetters({
			USER_TOKEN: 'auth/USER_TOKEN',
			USER_REFRESH: 'auth/USER_REFRESH',
			GET_LOGIN_CREDENTIAL: 'auth/GET_LOGIN_CREDENTIAL',
			GET_USER_COMPANY_ID: 'customer/GET_USER_COMPANY_ID',
			GET_COMPANY_BILLING: 'customer/GET_COMPANY_BILLING',
			GET_SUBSCRIPTION: 'customer/GET_SUBSCRIPTION',
			GET_V3_APP: 'customer/GET_V3_APP',
			GET_LOCALE: 'utility/GET_LOCALE_LANG'
		})
	},
	watch: {
		$route(to, from) {
			// react to route changes
			const {key, type, acc} = to.query
			this.app_type = type
			this.subId = key
			this.appType = type
			this.accountingValue = acc
			this.helpText = []
			this.tab = 1
			// this.customAccounts = []
			this.paymentTermsArray = []
			this.voucherSeries = []
			this.priceList = []
			this.accountNumbers = []
			this.paymentTerms = []
			this.showContinue = false
			this.showActivateSub = false
			this.checkVismaAvailabilityToken = null
			this.vismaAccounting = ACCOUNT_TYPE.VISMA
			this.fortnoxAccounting = ACCOUNT_TYPE.FORTNOX

			// this.showForm = false
			this.showDeliverySettings = false
			this.showPaymentTerms = false
			this.showBamboo = false
			this.showCustomAccount = false
			this.showAmazon = false
			this.showFileHandler = false

			// this.addonRequest(this.subId, this.appType, this.accountingValue)
			this.getSubscriptionList()
			// this.getSubscriptionConfig(key)
			// this.getSubscriptionReadInfo(key)

			this.configKey = key
			this.percentage = 30
			this.tabId = 1
			this.appDescriptionKey = new Date().getMilliseconds()
			Object.keys(this.reconciliatonAccountList).map(item => {
				this.reconciliatonAccountList[item].acct_no = ''
				this.reconciliatonAccountList[item].id = null
			})
			Object.keys(this.proAccountList).map(item => {
				this.proAccountList[item].acct_no = ''
				this.proAccountList[item].id = null
			})
			Object.keys(this.voucherAccountList).map(item => {
				this.voucherAccountList[item].acct_no = ''
				this.voucherAccountList[item].id = null
			})

			window.localStorage.setItem('__settingTab', this.tabId)

			// this.getSubscriptionApps(key)

			// this.helpText = this.helpText
			document.getElementById('tab-0').classList.add('active')
			document.getElementById('tab-1').classList.remove('active')
			document.getElementById('tab-2').classList.remove('active')
		},
		tabId: function (old, newVal) {
			if (this.tabId === 2) {
				this.runShopifyScript()
			}
		}
	},
	mounted() {
		let self = this
		const {key, type, acc} = this.$route.query
		window.addEventListener("load", (event) => {
			// for an instance when the user reload the page
			removeDataLocalStorage(`app_type_${type}`)
			removeDataLocalStorage(`country_key`)
		});
		this.showAmazonMarketPlace = false

		if (this.$route.query.hasOwnProperty('amazon_marketplace')) {
			if (this.$route.query.amazon_marketplace === 'success') {
				console.log("am here")
				let region = this.$route.query.region
				let regions = {
					'eu-west-1': 'Du har framgångsrikt anslutit till Amazon Europe Region',
					'us-east-1': 'Du har framgångsrikt anslutit till Amazon North America Region',
				}
				setTimeout(function () {
					if (regions[region]) {
						self.$services.helpers.notification(regions[region], 'success', this)
					} else {
						self.$services.helpers.notification(this.$t('authSuccess'), 'success', this)
					}
				}, 5000)

			} else {
				this.$services.helpers.notification(this.$t('authFailed'), 'error', this)
			}
			setTimeout(function () {
				self.$router.replace({
					name: 'appsettings',
					query: {
						key: key,
						type: type,
						acc: acc,
					}
				})
			}, 8000)

		}
		if (this.$route.query.hasOwnProperty('vismaonline')) {
			if (this.$route.query.vismaonline === 'success') {
				this.$services.helpers.notification(this.$t('authSuccess'), 'success', this)
			} else {
				if (this.$route.query.hasOwnProperty('auth_error')) {
					if (this.$route.query.auth_error === 'licence_error') {
						this.$services.helpers.notification('Enligt Visma har du inte köpt Visma API licens, ' +
							'därför går det inte att integrera mot ditt Visma konto. ' +
							'Vänligen läs guiden på första sidan för instruktioner gällande Visma API licens.', 'error', this)
					} else if (this.$route.query.auth_error === 'token_error') {
						this.$services.helpers.notification('Visma-token kunde inte erhållas, försök igen', 'error', this)
					} else {
						if (this.$route.query.hasOwnProperty('message')) {
							this.$services.helpers.notification(this.$route.query.message, 'error', this)
						} else {
							this.$services.helpers.notification('Visma-token kunde inte erhållas, försök igen', 'error', this)
						}
					}
				} else {
					this.$services.helpers.notification(this.$t('authFailed'), 'error', this)
				}
			}
			let self = this
			setTimeout(function () {
				self.$router.replace({
					name: 'appsettings',
					query: {
						key: key,
						type: type,
						acc: acc,
					}
				})
			}, 5000)
			return false
		}
		this.app_type = type
		this.acc = acc
		this.tab = 1
		this.currentAppType = this.$route.query.type
		this.paymentTermsArray = []
		this.voucherSeries = []
		this.priceList = []
		this.costCenterList = []
		this.vismaAccounting = ACCOUNT_TYPE.VISMA
		this.fortnoxAccounting = ACCOUNT_TYPE.FORTNOX


		if (key !== null && type !== null) {
			this.subId = key
			this.appType = type
			this.accountingValue = acc
			this.showAmazonMarketPlace = false
			this.getSubscriptionList()
			Object.keys(this.reconciliatonAccountList).map(item => {
				this.reconciliatonAccountList[item].acct_no = ''
				this.reconciliatonAccountList[item].id = null
			})
			Object.keys(this.proAccountList).map(item => {
				this.proAccountList[item].acct_no = ''
				this.proAccountList[item].id = null
			})

			// this.checkVismaAvailability(key)
			// this.runActiveAccountNumbersFortnox(key, type, acc)
			// this.runPaypalSupplierDetails(key, type, acc)
			// this.runVismaApps(key, type, acc)
			// this.showStripeData(type)
			// this.showShopifyData(type)
			// this.getConfigHelpText(type, acc)
			//
			// this.getSubscriptionCostCenter(key, type, acc)
			// this.getCustomAccounts(key)
			//
			// this.getV3CustomAccountForm(type)
			// this.showBambooTable(type)
			// this.showTestOrderButton(type)
			// this.runActiveAccountVisma(key, type, acc)
			// this.getSubscriptionConfig(key)
			// this.showCustomV3Apps(type, acc)
			// this.showCostCenterField(type, acc)
			// this.getSubscriptionReadInfo(key)
			// this.getAllStockPoints(type)
			// this.runWoocommerceSettings(type, acc, key)
			// this.runCustomAccountWidget(type, acc, key)
			// this.runCustomPaymentTermWidget(type, acc, key)
			// this.runCustomDeliveryTermWidget(type, acc, key)
			//
			// this.runAmazonSettings(type, acc, key)
			//
			// this.showFileUploader(type)
			// this.reconciliationAppField(type)


			bus.$on('fire-account-number', (_) => {
				this.getActiveAccountNumbers(key)
			})

			const settingTab = window.localStorage.getItem('__settingTab')
			if (parseInt(settingTab) > 0 && parseInt(settingTab) < 4) {
				this.tabId = parseInt(settingTab)
			} else {
				this.tabId = 1
			}
		}
		window.Bus.$on('reset-tab', e => {
			this.tabId = 1
			window.localStorage.setItem('__settingTab', 1)
		})
		window.Bus.$on('reload-current-subscription', e => {
			this.cancelledSubscriptionFlag = false
			this.getSubscriptionConfig(key)

		})

		window.Bus.$on('finished-upload', _ => {
			this.uploading = false
			this.clearFileField()
		})

		window.Bus.$on('finished-test', _ => {
			this.runningTest = false
		})

		window.Bus.$on('finished-sku-test', _ => {
			this.activating = false
			this.subscriptionDeactivate = true
			this.subscriptionActivate = false
			this.activateSubAfterSku()
		})
		window.Bus.$on('finished-sku-test-failed', _ => {
			this.activating = false
		})
		// console.log(this.ALL)
		window.Bus.$on('open-settings-tab', e => {
			this.tabId = parseInt(e)
		})
		window.Bus.$on('reload-custom-account', e => {
			this.getCustomAccounts(this.$route.query.key)
		})

	},
	created() {
		this.allCurrencies = this.$services.helpers.allCurrencies()
	},
	beforeMount() {
		this.helpText = []
		this.showAmazonMarketPlace = false
	},
	methods: {
		showCustomV3Apps(type, acc) {
			if (parseInt(acc) === ACCOUNT_TYPE.FORTNOX && CUSTOM_V3_APPS_FORTNOX.includes(parseInt(type))) {
				this.showV3 = true
				this.removeExtraV3Fields = false
			} else if (parseInt(acc) === ACCOUNT_TYPE.VISMA && CUSTOM_V3_APPS_VISMA.includes(parseInt(type))) {
				this.showV3 = true
				this.removeExtraV3Fields = true
			} else {
				this.showV3 = false
			}
		},
		showReconciliationAppsField(type, acc) {
			/*
			* this method handle enabling of custom account fields
			* */
			Object.keys(this.reconciliations).map(key => {
				this.reconciliations[key] = false
			})
			Object.keys(this.pro_app).map(key => {
				this.pro_app[key] = false
			})
			Object.keys(this.voucher_app).map(key => {
				this.voucher_app[key] = false
			})
			Object.keys(this.reconciliatonAccountList).map(item => {
				this.reconciliatonAccountList[item].acct_no = ''
				this.reconciliatonAccountList[item].id = null
			})
			Object.keys(this.proAccountList).map(item => {
				this.proAccountList[item].acct_no = ''
				this.proAccountList[item].id = null
			})
			Object.keys(this.voucherAccountList).map(item => {
				this.voucherAccountList[item].acct_no = ''
				this.voucherAccountList[item].id = null
			})

			if (parseInt(acc) === ACCOUNT_TYPE.FORTNOX) {
				if (RECONCILIATION_APPS_WITH_NO_FEE.includes(parseInt(type))
					&& RECONCILIATION_APPS_WITH_VAT_FEE.includes(parseInt(type))
					&& RECONCILIATION_APPS_WITH_VAT_FEE_AND_REVERSE_VAT.includes(parseInt(type))
					&& RECONCILIATION_APPS_WITH_NO_FEE.includes(parseInt(type))
				) {
					this.reconciliations.payout = true
				}
				if (RECONCILIATION_APPS_WITH_VAT_FEE.includes(parseInt(type)) || RECONCILIATION_APPS_WITH_NO_VAT_FEE.includes(parseInt(type))) {
					this.reconciliations.payout = true
					this.reconciliations.payout_account_bank = true
					this.reconciliations.provider_fee_account = true
				}
				if (RECONCILIATION_APPS_WITH_VAT_FEE_AND_REVERSE_VAT.includes(parseInt(type))) {
					this.reconciliations.payout = true
					this.reconciliations.payout_account_bank = true
					this.reconciliations.provider_fee_account = true
					this.reconciliations.fee_vat_acct_debit = true
				}

				if (RECONCILIATION_APPS_WITH_VAT_FEE_AND_REVERSE_VAT.includes(parseInt(type))) {
					this.reconciliations.fee_vat_acct_debit = true
					this.reconciliations.fee_vat_acct_credit = true
				}
				if (RECONCILIATION_APPS_WITH_NO_FEE.includes(parseInt(type))) {
					this.reconciliations.payout = true
				}
				if (PRO_APP.includes(parseInt(type))) {
					Object.keys(this.pro_app).map(key => {
						this.pro_app[key] = true
					})
				}

				if (VOUCHERS_APP.includes(parseInt(type))) {
					Object.keys(this.voucher_app).map(key => {
						this.voucher_app[key] = true
					})
				}

			}
		},
		showCostCenterField(type, acc) {
			/*
			* Show cost center field for all app inside allow cost center list
			* */

			if (COST_CENTER_LIST.includes(parseInt(type)) && parseInt(acc) === ACCOUNT_TYPE.FORTNOX) {
				this.showCostCenter = true
				this.getCostCenter(this.subId, type, acc)
				this.getCountry()
			} else {
				this.showCostCenter = false
			}
		},
		getType(arrayValue, num) {
			if (this.accountTypeList.length > 0) {
				const type = this.accountTypeList.filter(item => parseInt(Object.keys(item)[0]) === parseInt(num))[0]
				if (type) {
					return Object.values(type)[0]
				}
			}
		},
		getMatchFormType(arrayValue, num) {
			if (arrayValue.length > 0) {
				const type = arrayValue.filter(item => parseInt(Object.keys(item)[0]) === parseInt(num))[0]
				if (type) {
					return Object.values(type)[0]
				}
			}
		},
		getAppGateway(arrayValue, value) {
			if (arrayValue.length > 0) {
				const filterArray = arrayValue.filter(item => parseInt(item[1]) === parseInt(value.gateway_in_app))
				if (filterArray.length > 0) {
					return filterArray[0][1]
				}
			}
		},
		getAccountingGateway(arrayValue, value) {
			if (arrayValue.length > 0) {
				const filterArray = arrayValue.filter(item => item.Code === value.gateway_in_accounting)
				if (filterArray.length > 0) {
					return filterArray[0].Description
				}
			}
		},
		getCountryName(countryCode) {
			let country = this.countries.filter(item => item.includes(countryCode))
			if (country.length > 0) {
				return country[0][1]
			} else {
				return ''
			}
		},
		showTestOrderButton(type) {
			if (TEST_ORDER_BUTTON.includes(parseInt(type))) {
				this.showTestButton = true
			} else {
				this.showTestButton = false
			}
		},
		identifyShopifyBtn() {
			if (this.currentSubscription) {
				if ([parseInt(APP.SHOPIFY_PAYMENT), parseInt(APP.SHOPIFY), SHOPIFY_V3_APPS[0]].includes(parseInt(this.currentSubscription.app_type)) === true) {
					this.showShopifyBtn = true
				} else if (this.currentSubscription.app_type === 51 && parseInt(this.$route.query.acc) === 1) {
					this.showShopifyBtn = true
				} else {
					this.showShopifyBtn = false
				}
				// if (this.GET_SUBSCRIPTION.all_subscriptions) {
				//
				// 	const filterShopifyPay = this.GET_SUBSCRIPTION.all_subscriptions.filter(item => parseInt(item.app_type) === parseInt(APP.SHOPIFY_PAYMENT))
				// 	// console.log('pay:', filterShopifyPay)
				// 	const filterShopify = this.GET_SUBSCRIPTION.all_subscriptions.filter(item => parseInt(item.app_type) === parseInt(APP.SHOPIFY))
				//
				// 	const filterShopifyV3 = this.GET_SUBSCRIPTION.all_subscriptions.filter(item => parseInt(item.app_type) === parseInt(SHOPIFY_V3_APPS[0]))
				// 	if (filterShopify.length > 0 && filterShopifyPay.length > 0 && filterShopifyV3.length > 0) {
				// 		this.showShopifyBtn = true
				//
				// 	} else {
				// 		this.showShopifyBtn = true
				// 	}
				// }
			}
		},
		runVismaApps(key, type, acc) {
			if (parseInt(acc) === ACCOUNT_TYPE.VISMA) {
				this.getVismaBankAccountNumbers(key)
				this.getVismaUnit(key)
				// this.getVismaForeignPaymentCode(key)
			}
		},
		reconciliationAppField(type) {
			if (RECONCILIATION_APPS.includes(parseInt(type))) {
				this.hideReconciliationField = false
			} else {
				this.hideReconciliationField = true
			}
		},
		getSubscriptionApps(key) {
			this.showActivateSub = true
			if (this.GET_SUBSCRIPTION.active_subscriptions) {
				// Check if subscription is already active
				const filter = this.GET_SUBSCRIPTION.all_subscriptions.filter(item => item.id === parseInt(key))
				this.currentSubscription = filter
				if (filter.length > 0) {
					this.subscriptionDeactivate = true
					this.subscriptionActivate = false
					// checking fortnox subscription token
					this.cancelledSubscriptionFlag = filter[0].sub_status
					if (filter[0].sub_status === true) {
						this.subscriptionDeactivate = true
						this.subscriptionActivate = false
					} else {
						this.subscriptionDeactivate = false
						this.subscriptionActivate = true
					}
					if (filter[0].fortnox_token_state.hasOwnProperty('fortnox_redirect_url')) {
						this.fortnoxLink = filter[0].fortnox_token_state.fortnox_redirect_url
						this.showForm = filter[0].fortnox_token_state.token_status !== 'show_button';
					} else {
						if (this.shopSettings.hasOwnProperty('token_status')) {
							if (this.shopSettings.token_status === true) {
								this.showForm = false
							} else {
								this.showForm = true
							}
						} else {
							this.showForm = true
						}

					}
				} else {
					this.subscriptionDeactivate = false
					this.subscriptionActivate = true
				}
				if (this.tabId === 3) window.Bus.$emit('start-appsetting-tab3-tour')
			}
		},
		showStripeData(type) {
			if ([9, 11, 57, 58, 59].includes(parseInt(type))) {
				this.showStripe = true
			} else {
				this.showStripe = false
			}
		},
		showShopifyData(type) {
			if (parseInt(type) === APP.SHOPIFY || parseInt(type) === APP.SHOPIFY_PAYMENT || parseInt(type) === APP.SHOPIFY_V3) {
				this.showShopify = true
			} else if (this.currentSubscription.app_type === 51 && parseInt(this.$route.query.acc) === 1) {
				this.showShopify = true
			} else {
				this.showShopify = false
			}
		},
		switchAbsenceType(e) {
			if (e.target.value === 'emp_custom_fields') {
				this.showFortnoxCause = false
			} else {
				this.showFortnoxCause = true
			}
			// console.log(e.target.value)
		},
		showBambooTable(type) {
			if (BAMBOO_TABLE_ONLY.includes(parseInt(type))) {
				this.getBambooType()
				this.getBambooFortnoxCause()
				this.getBambooTimeOffs()
				this.getBambooEmpField()
				this.getBambooMapData()
				this.showBamboo = true
			} else {
				this.showBamboo = false
			}
		},
		openAddAccount() {
			this.$store.commit('utility/OPEN_MODAL_UTIL')
		},
		filterAccnt() {
			// console.log(this.accountNumbers)
			this.filteredAccount = this.accountNumbers.filter(account => {
				return String(account.Number).includes(this.acctNumber)
			})
		},
		setAccount(account) {
			this.acctNumber = account.Number
			this.selectAccount = account.Number
			this.modal = false
		},
		getV3CustomAccountForm(app_type) {
			// this make request to fetch custodm account field for V3 APPS
			// console.log('V3:', app_type)
			if (CUSTOM_V3_APPS_FORTNOX.includes(parseInt(app_type))) {
				// console.log(this.CUSTOM_V3_APPS_FORTNOX)
				let payload = {URL: `${this.$services.endpoints.GET_CUSTOM_V3_ACCOUNT}${app_type}`}
				this.$store.dispatch('customer/customGetRequest', payload)
					.then(_ => {
					}).catch(_ => {

				})
			} else if (CUSTOM_V3_APPS_VISMA.includes(parseInt(app_type))) {
				const URL_PATH = this.GET_USER_COMPANY_ID ? this.$services.endpoints.FETCH_CUSTOM_V3_ACCOUNT + `${this.subId}/${this.GET_USER_COMPANY_ID}/` : `${this.$services.endpoints.FETCH_CUSTOM_V3_ACCOUNT}${this.subId}`
				let payload = {URL: URL_PATH}
				this.$store.dispatch('customer/customGetRequest', payload)
					.then(resp => {


					}).catch(_ => {

				})
			}
		},
		insertAfter(newNode, existingNode) {
			existingNode.parentNode.insertBefore(newNode, existingNode.nextSibling)
		},
		filterAccountMatchCode(matchCode) {
			const filter = this.countries.filter(item => item[0] === matchCode)
			if (filter.length > 0) {
				return filter[0][1]
			} else {
				if (matchCode === 'WO') {
					return 'Export'
				} else {
					return matchCode
				}
			}
		},
		filterAccountNumberMatch(number) {
			if (parseInt(this.accountingValue) === ACCOUNT_TYPE.VISMA) {
				const filter = this.accountNumbers.filter(item => item.Number === number)
				if (filter.length > 0) {
					return filter[0].Description
				}
			} else {
				return number
			}
		},
		runShopifyScript() {
			const self = this
			let timer
			const waitTime = 500
			if (SHOPIFY_BTN_REDIRECT.includes(parseInt(this.appType))) {
				if (document.getElementById('id_shop_name')) {
					const el = document.getElementById('id_shop_name')
					// self.shopName = text
					el.addEventListener('keyup', e => {
						const text = e.currentTarget.value

						clearTimeout(timer)

						// Wait for x seconds and save data
						timer = setTimeout(() => {
							self.shopName = text
							self.autoSaveShopName(text)
						}, waitTime)
					})
				}
			}
		},
		autoSaveShopName(name) {
			const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.GET_USER_APP_CONFIG + `${this.subId}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.GET_USER_APP_CONFIG + `${this.subId}/`
			this.$store.dispatch('customer/autoSaveShopName', {URL, shop_name: name})

		},
		autoSaveAmazonRegion(name) {
			const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.GET_USER_APP_CONFIG + `${this.subId}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.GET_USER_APP_CONFIG + `${this.subId}/`
			this.$store.dispatch('customer/autoSaveAmazonRegion', {URL, region: name})
			// send data to dedicated server

		},
		runShopifyAuth() {
			this.identifyShopifyBtn()
			const self = this
			// if (document.getElementById('shopify-auth-btn')) {
			// 	document.getElementById('shopify-auth-btn').addEventListener('click', function () {
			// 		if (self.shopName) {
			// 			document.getElementById('shopify-auth-btn').disabled = true
			// 			document.getElementById('shopify-auth-btn').innerText = 'Bearbetar'
			// 			const URL = self.GET_USER_COMPANY_ID ? self.$services.endpoints.SHOPIFY_AUTH + `${self.subId}/${self.GET_USER_COMPANY_ID}/?app_bridge=${self.app_type}` : self.$services.endpoints.SHOPIFY_AUTH + `${self.subId}/?app_bridge=${self.app_type}`
			// 			if (self.app_type === APP.SHOPIFY_V3) {
			// 				window.open(`https://shopify.automatiseramera.se/?shop=${self.shopSettings.shop_name}.myshopify.com`, 'self')
			// 			} else {
			// 				self.$store.dispatch('customer/runShopifyAuth', {URL})
			// 					.then(res => {
			// 						// console.log(res)
			// 						document.getElementById('shopify-auth-btn').disabled = false
			// 						document.getElementById('shopify-auth-btn').innerText = 'Anslut med shopify'
			// 						if (res.status === 200) {
			// 							setTimeout(() => {
			// 								window.open(res.data.data, '_self')
			// 							}, 1000)
			// 						}
			// 					}).catch((err) => {
			// 					document.getElementById('shopify-auth-btn').disabled = false
			// 					document.getElementById('shopify-auth-btn').innerText = 'Anslut med shopify'
			// 					if (err.response.status === 400) {
			// 						if (err.response.data.code === 401) {
			// 							self.$store.dispatch('auth/refreshUserToken', self.USER_REFRESH)
			// 								.then(_ => {
			//
			// 								})
			// 								.catch(_ => {
			// 									// console.log('here')
			// 									self.$store.commit('auth/CLEAR_AUTH_USER', null)
			// 									window.Bus.$emit('sign-out')
			// 								})
			// 						}
			// 					}
			// 				})
			// 			}
			//
			// 		} else {
			// 			self.$services.helpers.notification(self.$t('shopNameRequire'), 'error', self)
			// 		}
			// 	})
			// }
		},
		runStripeAuth() {
			const self = this
			if (document.getElementById('stripe-auth-btn')) {
				document.getElementById('stripe-auth-btn').addEventListener('click', function () {
					document.getElementById('stripe-auth-btn').disabled = true
					document.getElementById('stripe-auth-btn').innerText = 'Bearbetar'
					const URL = self.GET_USER_COMPANY_ID ? self.$services.endpoints.STRIPE_AUTH + `${self.subId}/${self.GET_USER_COMPANY_ID}/` : self.$services.endpoints.STRIPE_AUTH + `${self.subId}/`
					// self.$services.axios.defaults.headers.common.Authorization = `Bearer ${self.USER_TOKEN}`
					// self.$services.axios.get(URL)
					self.$store.dispatch('customer/runShopifyAuth', {URL})
						.then(res => {
							// console.log(res)
							document.getElementById('stripe-auth-btn').disabled = false
							document.getElementById('stripe-auth-btn').innerText = 'Anslut med stripe'
							if (res.status === 200) {
								setTimeout(() => {
									window.open(res.data.data, '_self')
								}, 1000)
							}
						}).catch((err) => {
						document.getElementById('stripe-auth-btn').disabled = false
						document.getElementById('stripe-auth-btn').innerText = 'Anslut med stripe'
						if (err.response.status === 400) {
							if (err.response.data.code === 401) {
								self.$store.dispatch('auth/refreshUserToken', self.USER_REFRESH)
									.then(_ => {

									})
									.catch(_ => {
										// console.log('here')
										self.$store.commit('auth/CLEAR_AUTH_USER', null)
										window.Bus.$emit('sign-out')
									})
							}
						}
					})
				})
			}
		},
		checkVismaAvailability(param, ty = null) {
			this.$store.dispatch('customer/checkVismaAvailability', {sub: this.subId}) // Checking availability of visma sub to display button
				.then(response => {
					this.checkVismaAvailabilityToken = response.data.data.credentials_healthy
					this.getSubscriptionConfig(param)
				})
				.catch(_ => {
					this.checkVismaAvailabilityToken = null
					this.getSubscriptionConfig(param)
				})
		},
		runCustomAccountWidget(type, accountingType, key) {
			if (parseInt(accountingType) === ACCOUNT_TYPE.FORTNOX && (CUSTOM_ACCOUNT_WIDGET_FORTNOX.includes(parseInt(type)))) {
				this.customAccountData(key)
				this.showCustomAccount = true
			}

			if (parseInt(type) === APP.WOOCOMMERCE && parseInt(accountingType) === ACCOUNT_TYPE.VISMA_ADMIN) {
				this.customAccountData(key)
				this.showCustomAccount = true
			}

			if ((parseInt(type) === APP.QUICKBUTIK || parseInt(type) === APP.WOOCOMMERCE) && parseInt(accountingType) === ACCOUNT_TYPE.VISMA) {
				this.customAccountData(key)
				this.showCustomAccount = true
			}
		},
		runCustomPaymentTermWidget(type, accountingType, key) {
			if (parseInt(accountingType) === ACCOUNT_TYPE.FORTNOX && (CUSTOM_PAYMENT_TERM_WIDGET_FORTNOX.includes(parseInt(type)))) {
				this.PaymentTermsData(key)
				this.showPaymentTerms = true
			}
		},
		runCustomDeliveryTermWidget(type, accountingType, key) {
			if (parseInt(accountingType) === ACCOUNT_TYPE.FORTNOX && (CUSTOM_DELIVERY_WIDGET_FORTNOX.includes(parseInt(type)))) {
				this.DeliveryData(key)
				this.showDeliverySettings = true
			}
		},
		runWoocommerceSettings(type, accountingType, key) {
			if (parseInt(type) === APP.WOOCOMMERCE && parseInt(accountingType) === ACCOUNT_TYPE.FORTNOX) {
				this.getPriceList(key)
			}
		},
		runAmazonSettings(type, accountingType, key) {
			if ([APP.AMAZON].includes(parseInt(type)) === true && parseInt(accountingType) === ACCOUNT_TYPE.FORTNOX) {
				// this.customAccountData(key)parseInt(type)
				// this.showCustomAccount = true
				this.showAmazon = true
			} else if (APP.AMAZON_MARKETPLACE === parseInt(type)) {
				this.showAmazonMarketPlace = true

			} else {
				this.showAmazonMarketPlace = false
				this.showAmazon = false
			}
		},
		customAccountData(key) {
			// this.getActiveAccountNumbers(key)  // We are adding this active account because it will be fetch first
			this.getAccountType()
			this.getMatchType()
			this.getCountry()
			this.getCustomAccounts(key)
			setTimeout(() => {
				const self = this
				window.$('#currency-select, #konto-typ, #match-types, #country-select, #account-numbers-select, #vat-type-value').select2({
					templateSelection: function (data) {
						if (data.element.parentNode.id === 'country-select' || data.element.parentNode.id === 'currency-select') {
							self.onChangeCurrencyCountry(data.id)
						}

						if (data.element.parentNode.id === 'account-numbers-select') {
							self.onChangeAccountNumber(data.id)
						}

						if (data.element.parentNode.id === 'vat-type-value') {
							self.onChangeVat(data.id)
						}

						return data.id
					},
				})
				window.$('#country-select').on("select2:select", function (e) {
					self.matchCode = e.target.value
				});
				window.$('#currency-select').on("select2:select", function (e) {
					self.matchCode = e.target.value
				});
				window.$('#vat-type-value').on("select2:select", function (e) {
					self.vatValue = e.target.value
				});
			}, 3000)
		},
		onChangeCurrencyCountry(val) {
			let onlySekCountryCode = [10, 13]
			if (onlySekCountryCode.includes(parseInt(this.accType))) {
				this.matchCode = 'SEK'
			} else {
				this.matchCode = val
			}

		},
		onChangeAccountNumber(val) {
			this.acctNumber = val
		},
		onChangeVat(val) {
			this.vatValue = val
		},
		runActiveAccountNumbersFortnox(key, type, acc) {
			this.getVoucherSeriesForSubscription(key, type, acc)
			this.getPricelistForSubscription(key, type, acc)
			this.getPaymentTermForSubscription(key, type, acc)
			this.getActiveAccountNumbers(key, type, acc)
			// if (parseInt(acc) === ACCOUNT_TYPE.FORTNOX) {
			// }
		},
		runPaypalSupplierDetails(key, type, acc) {
			if (parseInt(acc) === ACCOUNT_TYPE.FORTNOX && parseInt(type) === APP.PAYPAL_SUPPLIER) {
				this.getPaypalSupplierDropdown(key)
				this.getVismaForeignPaymentCode(key)
			}
		},
		runActiveAccountVisma(key, type, acc) {
			if (parseInt(acc) === ACCOUNT_TYPE.VISMA) {
				this.getActiveAccountNumbers(key, type, acc)
			}
		},
		getVoucherSeriesForSubscription(key, type, acc) {
			if ((parseInt(acc) === ACCOUNT_TYPE.FORTNOX) && (VOUCHER_SERIES_FORTNOX.includes(parseInt(type)))) {
				this.getVoucherSeries(key)
			}
			if ((parseInt(acc) === ACCOUNT_TYPE.VISMA) && (VOUCHER_SERIES_VISMA.includes(parseInt(type)))) {
				this.getVoucherSeries(key)
			}
		},
		getPricelistForSubscription(key, type, acc) {
			if ((parseInt(acc) === ACCOUNT_TYPE.FORTNOX) && (PRICE_LIST_SUBSCRIPTION_FORTNOX.includes(parseInt(type)))) {
				this.getPriceList(key)
			}
		},
		getPaymentTermForSubscription(key, type, acc) {
			if ((parseInt(acc) === ACCOUNT_TYPE.FORTNOX) && (PAYMENT_TERM_FIELD_FORTNOX.includes(parseInt(type)))) {
				this.getPaymentTermOption(key)
			}
			if ((parseInt(acc) === ACCOUNT_TYPE.VISMA) && (PAYMENT_TERM_FIELD_VISMA.includes(parseInt(type)))) {
				this.getPaymentTermOption(key)
			}
		},
		getCostCenter(subID, type, acc) {
			if (COST_CENTER_LIST.includes(parseInt(type)) && parseInt(acc) === ACCOUNT_TYPE.FORTNOX) {
				const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.GET_COST_CENTER + `${subID}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.GET_COST_CENTER + `${subID}/`

				this.$store.dispatch('customer/customGetRequest', {URL})
					.then(res => {
						this.costCenterList = res.data.data
					}).catch((err) => {
					// this.showCustomAccount = false
					if (err.response.status === 400) {
						if (err.response.data.code === 401) {
							this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
								.then(_ => {
									this.getCostCenter(subID)
								})
								.catch(_ => {
									// console.log('here')
									this.$store.commit('auth/CLEAR_AUTH_USER', null)
									window.Bus.$emit('sign-out')
								})
						}
					}
				})
			}
		},
		getSubscriptionCostCenter(subID, type, acc) {
			if (COST_CENTER_LIST.includes(parseInt(type)) && parseInt(acc) === ACCOUNT_TYPE.FORTNOX) {
				const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.GET_SUBSCRIPTION_COST_CENTER + `${subID}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.GET_SUBSCRIPTION_COST_CENTER + `${subID}/`

				this.$store.dispatch('customer/customGetRequest', {URL})
					.then(res => {
						// console.log('Acccounts::::::', res)
						if (res.data.data.length > 0) {
							this.costCenterDbList = res.data.data
						}
					}).catch((err) => {
					// this.showCustomAccount = false
					if (err.response.status === 400) {
						if (err.response.data.code === 401) {
							this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
								.then(_ => {
									this.getCostCenter(subID)
								})
								.catch(_ => {
									// console.log('here')
									this.$store.commit('auth/CLEAR_AUTH_USER', null)
									window.Bus.$emit('sign-out')
								})
						}
					}
				})
			}
		},
		DeliveryData(key) {
			this.getWaysOfDeliveryOption(key)
			this.getWaysOfDeliverySubscription(key)
			this.getTermOfDeliveryOption(key)
		},
		PaymentTermsData(key) {
			this.getPaymentProvider()
			this.getPaymentTermOption(key) // Already calleds
			this.getPaymentTermSubscription(key)
		},
		showFileUploader(type) {
			if (SHOW_FILE_UPLOADER.includes(parseInt(type))) {
				this.showFileHandler = true
			} else {
				this.showFileHandler = false
			}
		},
		convertHTML(html) {
			// Create a new div element
			var tempDivElement = document.createElement('div')
			// Set the HTML content with the given value
			tempDivElement.innerHTML = html
			// Retrieve the text property of the element
			return tempDivElement.textContent || tempDivElement.innerText || ''
		},
		redirectVisma() {
			this.connecting = true
			const {key, type} = this.$route.query
			const obj = {
				id: key,
				app_type: type
			}
			console.log(this.vismaLink)
			setTimeout(() => {
				window.sessionStorage.setItem('vsubs', JSON.stringify(obj))
				this.connecting = false
				console.log(this.vismaLink)
				window.open(this.vismaLink, '_self')
			}, 1000)
		},
		redirectFortnox() {
			this.connecting = true
			const {key, type} = this.$route.query
			const obj = {
				id: key,
				app_type: type
			}
			setTimeout(() => {
				window.sessionStorage.setItem('fsubs', JSON.stringify(obj))
				this.connecting = false
				window.open(this.fortnoxLink, '_self')
			}, 1000)
		},
		switchType(e) {
			this.matchTyp = parseInt(e.target.value)
			if (parseInt(e.target.value) === 2) {
				// this.matchCode = ''
				this.showAllCountries = true
				this.showCountry = false

			} else {
				this.showAllCountries = true
				// this.matchCode = ''
				this.showCountry = true
			}
		},
		onChangeAccountType(e) {
			let onlySekCountryCode = [14, 13, 11]
			if ([3].includes(parseInt(e.target.value))) {
				this.matchType = this.matchTypeCopy
			} else {
				// show only  currency (valuta)
				let code = []
				for (var j = 0; j < this.matchTypeCopy.length; j++) {
					let mtype = this.matchTypeCopy[j]
					if (parseInt(Object.keys(mtype)[0]) === 2) {
						code.push(mtype)
					}
				}
				this.showCountry = false
				this.matchType = code
			}
			window.$('#currency-select').select2('destroy')
			if (onlySekCountryCode.includes(parseInt(e.target.value))) {
				this.allCurrencies = ['SEK']
				this.matchCode = 'SEK'
			} else {

				this.allCurrencies = this.$services.helpers.allCurrencies()
			}
			window.$('#currency-select').select2()
			if ([10, 11, 12, 2, 14, 13].includes(parseInt(e.target.value))) {
				this.matchTyp = 2
				this.showCountry = false
			} else {
				this.matchTyp = 1
				this.matchCode = ''
				this.showCountry = true
			}
			document.getElementById('match-type').attributes
		},
		addCustomAccount() {
			const {type, key} = this.$route.query
			if (this.accType === '' || this.matchTyp === '' || this.matchCode === '') {
				this.$services.helpers.notification(this.$t('fieldRequired'), 'error', this)
			} else if (this.acctNumber === '') {
				this.$services.helpers.notification(this.$t('accountNumberRequired'), 'error', this)
			} else {
				this.addingCustomAccount = true
				const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.CREATE_CUSTOM_ACCOUNT + `${this.subId}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.CREATE_CUSTOM_ACCOUNT + `${this.subId}/`
				const payload = {
					subscription: type,
					acct_type: this.accType,
					match_type: this.matchTyp,
					match_code: this.matchCode,
					// vat_rate : this.vatCode,
					acct_no: this.acctNumber
				}

				if (parseInt(this.vatValue)) {
					// calculate vatcentage
					let val = this.vatValue.split('-')[0]
					payload.vat_rate = parseFloat(val / 100).toFixed(2)
				}

				if (!RECONCILIATION_APPS.includes(parseInt(this.appType))) {
					if (this.ruleType !== null) {
						this.forCompany = this.ruleType === 'company'
						this.forShipping = this.ruleType === 'shipping'
						this.forFeeLines = this.ruleType === 'feelines'
						this.forDiscount = this.ruleType === 'discount'
						this.forGiftCard = this.ruleType === 'gift_card'
					}

					payload.for_company = this.forCompany
					payload.for_shipping = this.forShipping
					payload.for_fee_lines = this.forFeeLines
					payload.for_gift_cards = this.forGiftCard
					payload.for_discount = this.forDiscount
				}


				this.$store.dispatch('customer/addCustomAccount', {data: payload, URL})
					.then(res => {
						this.addingCustomAccount = false
						// console.log(res)
						if (res.status === 200) {
							this.clearFormField()
							this.$services.helpers.notification(this.$t('createdCustom'), 'success', this)
							this.getCustomAccounts(key)
						}
					}).catch((err) => {
					this.addingCustomAccount = false
					if (err.response.status === 400) {
						if (err.response.data.code === 401) {
							this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
								.then(_ => {

								})
								.catch(_ => {
									// console.log('here')
									this.$store.commit('auth/CLEAR_AUTH_USER', null)
									window.Bus.$emit('sign-out')
								})
						}
						this.$services.helpers.notification(err.response.data, 'error', this)
					}
				})

				// send data to dedicated server
			}
		},
		editCustomAccount: function (account) {
			window.Bus.$emit('open-custom-account-edit', {
				account: account,
				accountNumbers: this.accountNumbers,
				currencies: this.allCurrencies,
				countries: this.countries,
				accountType: this.accountType,
				matchType: this.matchTypeCopy
			})
		},
		deleteCustomAccount(customAcId) {
			const {key} = this.$route.query
			const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.DELETE_CUSTOM_ACCOUNT + `${this.subId}/${customAcId}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.DELETE_CUSTOM_ACCOUNT + `${this.subId}/${customAcId}/`
			// this.$services.axios.defaults.headers.common.Authorization = `Bearer ${this.USER_TOKEN}`
			// this.$services.axios.delete(URL)
			this.$store.dispatch('customer/deleteCustomAccount', {URL})
				.then(res => {
					bus.$emit('hide-notify-me', '')
					// console.log(res)
					if (res.status === 204) {
						this.getCustomAccounts(key)
						this.$services.helpers.notification(this.$t('deletedCustom'), 'success', this)
					}
				}).catch((err) => {
				bus.$emit('hide-notify-me', '')
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {

							})
							.catch(_ => {
								// console.log('here')
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
			// delete data from dedicated server

		},
		getVoucherSeries(param) {
			// this.$services.endpoints.GET_VOUCHER_SERIES + `${param}/`
			const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.GET_VOUCHER_SERIES + `${param}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.GET_VOUCHER_SERIES + `${param}/`
			this.$store.dispatch('customer/getVoucherSeries', {URL: URL})
				.then(response => {
					this.voucherSeries = response.data.data
				})
				.catch(err => {
					if (err.response.status === 400) {
						if (err.response.data.code === 401) {
							this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
								.then(_ => {
									this.getVoucherSeries(param)

								})
								.catch(_ => {
									// console.log('here')
									this.$store.commit('auth/CLEAR_AUTH_USER', null)
									window.Bus.$emit('sign-out')
								})
						}
					}
				})
		},
		getPaypalSupplierDropdown(param) {
			this.$store.dispatch('customer/getPaypalSupplier', {URL: this.$services.endpoints.GET_PAYPAL_SUPPLIER_DETAILS + `${param}/`})
				.then(response => {
					// console.log('supplier:', response)
					this.paypalSupplierDetails = response.data.data
				})
				.catch(err => {
					if (err.response.status === 400) {
						if (err.response.data.code === 401) {
							this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
								.then(_ => {
									this.getPaypalSupplierDropdown(param)
								})
								.catch(_ => {
									// console.log('here')
									this.$store.commit('auth/CLEAR_AUTH_USER', null)
									window.Bus.$emit('sign-out')
								})
						}
					}
				})
		},
		getPriceList(key) {
			const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.GET_PRICE_LIST + `${key}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.GET_PRICE_LIST + `${key}/`

			this.$store.dispatch('customer/getPriceList', {URL})
				.then(response => {
					this.priceList = response.data.data
				})
				.catch(err => {
					if (err.response.status === 400) {
						if (err.response.data.code === 401) {
							this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
								.then(_ => {
									this.getPriceList(key)
								})
								.catch(_ => {
									// console.log('here')
									this.$store.commit('auth/CLEAR_AUTH_USER', null)
									window.Bus.$emit('sign-out')
								})
						}
					}
				})
		},
		getSubscriptionReadInfo(param) {
			this.loadingInfo = true
			this.$store.dispatch('customer/getSubscriptionReadInfo', {URL: this.$services.endpoints.GET_READ_INFO_SUBSCRIPTION + `${param}/`})
				.then(res => {
					this.loadingInfo = false
					if (res.status === 200) {
						this.showContinue = true
						this.appReadInfo.info_content_title = res.data.data.info_content_title
						this.appReadInfo.info_content_html = this.$sanitize(res.data.data.info_content_html)
					}
					if (this.tabId === 1) window.Bus.$emit('start-appsetting-tab1-tour')
				}).catch((err) => {
				this.loadingInfo = false
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								this.getSubscriptionReadInfo(param)
							})
							.catch(_ => {
								// console.log('here')
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		activateSubAfterSku() {
			const {key} = this.$route.query
			if (key) {
				this.activating = true
				const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.ACTIVATE_SUBSCRIPTION + `${key}/1/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.ACTIVATE_SUBSCRIPTION + `${key}/1/`
				this.$store.dispatch('customer/activateSubscription', {URL})
					.then(res => {
						this.activating = false
						if (res.status === 200) {
							this.$services.helpers.notification(this.$t('activatedSubscription'), 'success', this)
							this.getSubscriptionConfig(key)
							this.getSubscriptionList(key)
							this.subscriptionDeactivate = true
							this.subscriptionActivate = false
						}
					}).catch((err) => {
					this.activating = false
					if (err.response.status === 400) {
						if (err.response.data.code === 401) {
							this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
								.then(_ => {
									// this.getSubscriptionConfig()
								})
								.catch(_ => {
									// console.log('here')
									this.$store.commit('auth/CLEAR_AUTH_USER', null)
									window.Bus.$emit('sign-out')
								})
						}
					}
				})
			}
		},
		activateSubscription() {
			const {key} = this.$route.query

			if (key) {
				if (SKU_VALIDATOR.includes(parseInt(this.appType))) {
					// const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.SKU_ACTIVATE_SUBSCRIPTION + `${key}/1/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.SKU_ACTIVATE_SUBSCRIPTION + `${key}/1/`
					if (this.currentSubscription.length > 0) {
						let URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.SKU_ACTIVATE_SUBSCRIPTION + `${key}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.SKU_ACTIVATE_SUBSCRIPTION + `${key}/`
						bus.$emit('sku-modal', {URL: URL, 'server': this.currentSubscription[0].dedicated_server})
					}

				} else {
					this.activating = true
					const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.ACTIVATE_SUBSCRIPTION + `${key}/1/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.ACTIVATE_SUBSCRIPTION + `${key}/1/`
					this.$store.dispatch('customer/activateSubscription', {URL})
						.then(res => {
							this.activating = false
							// console.log('Activated app:', res)
							if (res.status === 200) {
								this.$services.helpers.notification(this.$t('activatedSubscription'), 'success', this)
								this.getSubscriptionConfig(key)
								this.subscriptionDeactivate = true
								this.subscriptionActivate = false
							}
						}).catch((err) => {
						this.activating = false
						if (err.response.status === 400) {
							if (err.response.data.code === 401) {
								this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
									.then(_ => {
										// this.getSubscriptionConfig()
									})
									.catch(_ => {
										// console.log('here')
										this.$store.commit('auth/CLEAR_AUTH_USER', null)
										window.Bus.$emit('sign-out')
									})
							}
						}
					})
				}
			}
		},
		deactivateSubscription() {
			const {key} = this.$route.query
			if (key) {
				this.deactivating = true
				const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.ACTIVATE_SUBSCRIPTION + `${key}/0/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.ACTIVATE_SUBSCRIPTION + `${key}/0/`
				this.$store.dispatch('customer/deactivateSubscription', {URL})
					.then(res => {
						this.deactivating = false
						if (res.status === 200) {
							this.$services.helpers.notification(this.$t('deactivatedSubscription'), 'success', this)
							this.getSubscriptionConfig(key)
							this.subscriptionDeactivate = false
							this.subscriptionActivate = true
						}
					}).catch((err) => {
					this.deactivating = false
					if (err.response.status === 400) {
						if (err.response.data.code === 401) {
							this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
								.then(_ => {
									// this.getSubscriptionConfig()
								})
								.catch(_ => {
									// console.log('here')
									this.$store.commit('auth/CLEAR_AUTH_USER', null)
									window.Bus.$emit('sign-out')
								})
						}
					}
				})
			}
		},
		autoRefreshFortnoxToken(subID) {
			const URL = this.$services.endpoints.AUTO_REFRESH_FORTNOX_SUBSCRIPTIONS_TOKEN + `${subID}/`
			this.$store.dispatch('customer/customPutRequest', {URL: URL, 'data': {}}).then(_ => {
				this.showForm = true
				this.getSubscriptionConfig(subID)
				this.getSubscriptionList(subID)
			}).catch(err => {
				this.showForm = false
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								// console.log('hey me')
								this.autoRefreshFortnoxToken(subID)
							})
							.catch(_ => {
								// console.log('here')
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					} else {
						this.showForm = false
					}

				}
			})
		},
		updateAppSettings() {
			if (document.getElementById('id_booking_date')) {
				let currentSubscription = this.currentSubscription
				// let currentDate = currentSubscription[0].created
				const subscriptionCreatedDate = currentSubscription.created
				const subcriptionDate = document.getElementById('id_booking_date').value
				const difference = this.$services.moment(subcriptionDate).diff(subscriptionCreatedDate, 'days')
				if (difference < -6 && subcriptionDate) {
					this.$services.helpers.notification(this.$t('daysBack'), 'error', this)
					return false
				}
			}
			const dataObject = {}
			const customV3Object = {}
			const reconciliationAccounts = {}
			const proAccounts = {}
			const voucherAccounts = {}
			const arraySetting = Object.keys(this.shopSettings)
			let excluded_fields = ['master_location']
			arraySetting.forEach(element => {
				if (excluded_fields.includes(element) === false) {
					if (document.getElementById(`id_${element}`)) {
						if (document.getElementById(`id_${element}`).hasAttribute('is_multiple_select')) {
							if (window.$(`#id_${element}`).select2('val')) {
								dataObject[element] = window.$(`#id_${element}`).select2('val')
							}
						} else {
							if (document.getElementById(`id_${element}`).value) {
								dataObject[element] = document.getElementById(`id_${element}`).value
							}

						}
					}
				}
			})
			// swish handel validation
			try {
				if (document.getElementById('id_swish_bank_type')) {
					let swish_bank_type = document.getElementById('id_swish_bank_type').value
					if (swish_bank_type === 'seb') {
						if (window.$('#id_swish_bank_id').val() === '') {
							this.$services.helpers.notification('SEB Swish bankreferens är obligatoriskt', 'error', this)
							return false
						}
					}
				}
			} catch (e) {
			}


			if (CUSTOM_V3_APPS_FORTNOX.includes(parseInt(this.app_type)) || CUSTOM_V3_APPS_VISMA.includes(parseInt(this.app_type))) {

				const customv3 = document.querySelectorAll('.custom-v3-class')
				customv3.forEach(element => {
					// getAttribute
					if (!window.$(element).select2('val')) {
						bus.$emit('hide-notify-me', '')
						return false
					} else {
						customV3Object[element.getAttribute('custom')] = window.$(element).select2('val')
					}
				})
			}
			if (Object.values(this.reconciliations).includes(true)) {
				const reconciliationFields = document.querySelectorAll('.reconciliation-class')
				reconciliationFields.forEach(field => {
					if (!window.$(field).select2('val')) {
						bus.$emit('hide-notify-me', '')
						return false
					} else {
						reconciliationAccounts[field.getAttribute('custom')] = window.$(field).select2('val')
					}
				})
			}
			if (Object.values(this.pro_app).includes(true)) {
				const propFields = document.querySelectorAll('.pro-app')
				propFields.forEach(field => {
					if (!window.$(field).select2('val')) {
						bus.$emit('hide-notify-me', '')
						return false
					} else {
						proAccounts[field.getAttribute('custom')] = window.$(field).select2('val')
					}
				})
			}
			if (Object.values(this.voucher_app).includes(true)) {
				const propFields = document.querySelectorAll('.voucher-app')
				propFields.forEach(field => {
					if (!window.$(field).select2('val')) {
						bus.$emit('hide-notify-me', '')
						return false
					} else {
						voucherAccounts[field.getAttribute('custom')] = window.$(field).select2('val')
					}
				})
			}

			// if (this.errorFlag === false) {
			this.updating = true
			const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.GET_USER_APP_CONFIG + `${this.subId}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.GET_USER_APP_CONFIG + `${this.subId}/`


			this.$store.dispatch('customer/updateAppSettings', {URL, dataObject})
				.then(res => {
					if (res.data.success) {
						this.updating = false
						this.$services.helpers.notification(this.$t('updateApp'), 'success', this)
					}
				}).catch((err) => {
				this.updating = false
				// console.log(err.response)
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
							})
							.catch(_ => {
								// console.log('here')
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})

			if (CUSTOM_V3_APPS_FORTNOX.includes(parseInt(this.app_type))) {
				let success = true
				const {type, key} = this.$route.query
				Object.keys(customV3Object).forEach(element => {
					const customValues = this.customV3ListAccounts.filter(el => el.key === element)

					const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.CREATE_CUSTOM_ACCOUNT + `${this.subId}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.CREATE_CUSTOM_ACCOUNT + `${this.subId}/`
					const payload = {
						subscription: type,
						acct_type: "3",
						match_type: 1,
						match_code: customValues.length > 0 ? customValues[0].match : '',
						acct_no: customV3Object[element]
					}
					let accountExist = []
					if (['WO', 'EU'].includes(payload.match_code)) {
						accountExist = this.customAccounts.filter(item => String(item.match_code) === String(customValues[0].match))
					} else {
						accountExist = this.customAccounts.filter(item => String(item.vat_rate) === String(customValues[0].vat))
					}
					if (customValues[0].vat !== '') {
						payload.vat_rate = customValues[0].vat ? customValues[0].vat : ''
					}
					if (accountExist.length > 0) {
						let URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.UPDATE_CUSTOM_ACCOUNT + `${this.subId}/${accountExist[0].id}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.UPDATE_CUSTOM_ACCOUNT + `${this.subId}/${accountExist[0].id}/`
						this.$store.dispatch('customer/updateCustomAccount', {data: payload, URL})
							.then(res => {
							})
							.catch(err => {
								success = false
								this.updating = false
								if (err.response.status === 400) {
									if (err.response.data.code === 400) {
										this.$services.helpers.notification(err.response.data.data, 'error', this)
									}

									if (err.response.data.code === 401) {
										this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
											.then(_ => {
												// this.updateAppSettings()
											})
											.catch(_ => {
												// console.log('here')
												this.$store.commit('auth/CLEAR_AUTH_USER', null)
												window.Bus.$emit('sign-out')
											})
									}
								}
							})
					} else {
						this.$store.dispatch('customer/addCustomAccount', {data: payload, URL})
							.then(res => {
							})
							.catch(err => {
								success = false
								this.updating = false
								if (err.response.status === 400) {
									if (err.response.data.code === 400) {
										this.$services.helpers.notification(err.response.data.data, 'error', this)
									}

									if (err.response.data.code === 401) {
										this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
											.then(_ => {
												// this.updateAppSettings()
											})
											.catch(_ => {
												// console.log('here')
												this.$store.commit('auth/CLEAR_AUTH_USER', null)
												window.Bus.$emit('sign-out')
											})
									}
								}
							})
					}

				})

				if (success) {
					this.updating = false
					this.errorFlag = false
					// this.$services.helpers.notification(this.$t('updateApp'), 'success', this)
				}
			}
			if (CUSTOM_V3_APPS_VISMA.includes(parseInt(this.app_type))) {
				let success = true
				const {type, key} = this.$route.query
				Object.keys(customV3Object).forEach(element => {
					const customValues = this.customV3ListAccounts.filter(el => el.key === element)
					let URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.CREATE_CUSTOM_ACCOUNT + `${this.subId}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.CREATE_CUSTOM_ACCOUNT + `${this.subId}/`
					const payload = {
						subscription: type,
						acct_type: "3",
						match_type: 1,
						match_code: customValues.length > 0 ? customValues[0].match : '',
						acct_no: customV3Object[element]
					}
					let accountExist = this.v3CustomAccount.filter(item => String(item.vat_rate) === String(customValues[0].vat))
					if (customValues[0].vat !== '') {
						payload.vat_rate = customValues[0].vat ? customValues[0].vat : ''
					}
					if (accountExist.length > 0) {
						let URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.UPDATE_CUSTOM_ACCOUNT + `${this.subId}/${accountExist[0].id}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.UPDATE_CUSTOM_ACCOUNT + `${this.subId}/${accountExist[0].id}/`
						this.$store.dispatch('customer/updateCustomAccount', {data: payload, URL})
							.then(res => {
							})
							.catch(err => {
								success = false
								this.updating = false
								if (err.response.status === 400) {
									if (err.response.data.code === 400) {
										this.$services.helpers.notification(err.response.data.data, 'error', this)
									}

									if (err.response.data.code === 401) {
										this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
											.then(_ => {
												// this.updateAppSettings()
											})
											.catch(_ => {
												// console.log('here')
												this.$store.commit('auth/CLEAR_AUTH_USER', null)
												window.Bus.$emit('sign-out')
											})
									}
								}
							})
					} else {
						this.$store.dispatch('customer/addCustomAccount', {data: payload, URL})
							.then(res => {
							})
							.catch(err => {
								success = false
								this.updating = false
								if (err.response.status === 400) {
									if (err.response.data.code === 400) {
										this.$services.helpers.notification(err.response.data.data, 'error', this)
									}

									if (err.response.data.code === 401) {
										this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
											.then(_ => {
												// this.updateAppSettings()
											})
											.catch(_ => {
												// console.log('here')
												this.$store.commit('auth/CLEAR_AUTH_USER', null)
												window.Bus.$emit('sign-out')
											})
									}
								}
							})
					}


				})
				if (success) {
					this.updating = false

				}
			}
			if (Object.values(this.reconciliations).includes(true)) {
				Object.keys(reconciliationAccounts).map(item => {
					const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.CREATE_CUSTOM_ACCOUNT + `${this.subId}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.CREATE_CUSTOM_ACCOUNT + `${this.subId}/`
					let payload = this.reconciliatonAccountList[item]
					payload['acct_no'] = reconciliationAccounts[item]
					this.reconciliatonAccountList[item].acct_no = reconciliationAccounts[item]
					payload.subscription = this.subId
					if (payload['id'] !== null && payload['id'] !== '') {
						let URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.UPDATE_CUSTOM_ACCOUNT + `${this.subId}/${payload.id}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.UPDATE_CUSTOM_ACCOUNT + `${this.subId}/${payload.id}/`
						this.$store.dispatch('customer/updateCustomAccount', {data: payload, URL})
							.then(res => {
								this.reconciliatonAccountList[item].id = res.data.data.id
							})
							.catch(err => {
								if (err.response.status === 400) {
									if (err.response.data.code === 400) {
										this.$services.helpers.notification(err.response.data.data, 'error', this)
									}

									if (err.response.data.code === 401) {
										this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
											.then(_ => {
												// this.updateAppSettings()
											})
											.catch(_ => {
												// console.log('here')
												this.$store.commit('auth/CLEAR_AUTH_USER', null)
												window.Bus.$emit('sign-out')
											})
									}
								}
							})
					} else {
						delete payload.id
						this.$store.dispatch('customer/addCustomAccount', {data: payload, URL})
							.then(res => {
								this.reconciliatonAccountList[item].id = res.data.data.id
							})
							.catch(err => {
								this.updating = false
								if (err.response.status === 400) {
									if (err.response.data.code === 400) {
										this.$services.helpers.notification(err.response.data.data, 'error', this)
									}

									if (err.response.data.code === 401) {
										this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
											.then(_ => {
												// this.updateAppSettings()
											})
											.catch(_ => {
												// console.log('here')
												this.$store.commit('auth/CLEAR_AUTH_USER', null)
												window.Bus.$emit('sign-out')
											})
									}
								}
							})
					}


				})
			}
			if (Object.values(this.pro_app).includes(true)) {
				Object.keys(proAccounts).map(item => {
					const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.CREATE_CUSTOM_ACCOUNT + `${this.subId}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.CREATE_CUSTOM_ACCOUNT + `${this.subId}/`
					let payload = this.proAccountList[item]
					payload.acct_no = proAccounts[item]
					this.proAccountList[item].acct_no = proAccounts[item]
					payload.subscription = this.subId
					if (payload['id'] !== null && payload['id'] !== '') {
						let URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.UPDATE_CUSTOM_ACCOUNT + `${this.subId}/${payload.id}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.UPDATE_CUSTOM_ACCOUNT + `${this.subId}/${payload.id}/`
						this.$store.dispatch('customer/updateCustomAccount', {data: payload, URL})
							.then(res => {
								this.proAccountList[item] = res.data.data.id
							})
							.catch(err => {
								if (err.response.status === 400) {
									if (err.response.data.code === 400) {
										this.$services.helpers.notification(err.response.data.data, 'error', this)
									}

									if (err.response.data.code === 401) {
										this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
											.then(_ => {
												// this.updateAppSettings()
											})
											.catch(_ => {
												// console.log('here')
												this.$store.commit('auth/CLEAR_AUTH_USER', null)
												window.Bus.$emit('sign-out')
											})
									}
								}
							})
					} else {
						delete payload.id
						this.$store.dispatch('customer/addCustomAccount', {data: payload, URL})
							.then(res => {
								console.log(res)
							})
							.catch(err => {
								this.updating = false
								if (err.response.status === 400) {
									if (err.response.data.code === 400) {
										this.$services.helpers.notification(err.response.data.data, 'error', this)
									}

									if (err.response.data.code === 401) {
										this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
											.then(_ => {
												// this.updateAppSettings()
											})
											.catch(_ => {
												// console.log('here')
												this.$store.commit('auth/CLEAR_AUTH_USER', null)
												window.Bus.$emit('sign-out')
											})
									}
								}
							})
					}


				})
			}
			if (Object.values(this.voucher_app).includes(true)) {
				Object.keys(voucherAccounts).map(item => {
					const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.CREATE_CUSTOM_ACCOUNT + `${this.subId}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.CREATE_CUSTOM_ACCOUNT + `${this.subId}/`
					let payload = this.voucherAccountList[item]
					payload.acct_no = voucherAccounts[item]
					this.voucherAccountList[item].acct_no = voucherAccounts[item]
					payload.subscription = this.subId
					if (payload['id'] !== null && payload['id'] !== '') {
						let URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.UPDATE_CUSTOM_ACCOUNT + `${this.subId}/${payload.id}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.UPDATE_CUSTOM_ACCOUNT + `${this.subId}/${payload.id}/`
						this.$store.dispatch('customer/updateCustomAccount', {data: payload, URL})
							.then(res => {
								this.voucherAccountList[item] = res.data.data.id
							})
							.catch(err => {
								if (err.response.status === 400) {
									if (err.response.data.code === 400) {
										this.$services.helpers.notification(err.response.data.data, 'error', this)
									}

									if (err.response.data.code === 401) {
										this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
											.then(_ => {
												// this.updateAppSettings()
											})
											.catch(_ => {
												// console.log('here')
												this.$store.commit('auth/CLEAR_AUTH_USER', null)
												window.Bus.$emit('sign-out')
											})
									}
								}
							})
					} else {
						delete payload.id
						this.$store.dispatch('customer/addCustomAccount', {data: payload, URL})
							.then(res => {
							})
							.catch(err => {
								this.updating = false
								if (err.response.status === 400) {
									if (err.response.data.code === 400) {
										this.$services.helpers.notification(err.response.data.data, 'error', this)
									}

									if (err.response.data.code === 401) {
										this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
											.then(_ => {
												// this.updateAppSettings()
											})
											.catch(_ => {
												// console.log('here')
												this.$store.commit('auth/CLEAR_AUTH_USER', null)
												window.Bus.$emit('sign-out')
											})
									}
								}
							})
					}


				})
			}
			let self = this
			setTimeout(function () {
				self.getCustomAccounts(self.currentSubscription.id)
			}, 200)
		},
		getCustomAccounts(subID) {
			const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.GET_CUSTOM_ACCOUNT_SUB + `${subID}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.GET_CUSTOM_ACCOUNT_SUB + `${subID}/`
			// this.$services.axios.defaults.headers.common.Authorization = `Bearer ${this.USER_TOKEN}`
			// this.$services.axios.get(URL)


			this.$store.dispatch('customer/getCustomAccounts', {URL})
				.then(res => {
					let exclude_dict = ["EU", "WO", "SE", 0.25, 0.12, 0.06, 0]
					this.customAccounts = []
					let customAccountListDict = {
						0.12: 'sales_account_se_twelve',
						0.06: 'sales_account_se_six',
						0.25: 'sales_account_se_twenty_five',
					}
					this.customAccountList = {
						'sales_account_wo': '',
						'sales_account_eu': '',
						'sales_account_se_six': '',
						'sales_account_se_twelve': '',
						'sales_account_se_twenty_five': '',
					}

					if (res.data.success) {
						if (CUSTOM_V3_APPS_VISMA.includes(parseInt(this.$route.query.type)) && [1].includes(parseInt(this.$route.query.acc)) === true) {

							this.v3CustomAccount = res.data.data.filter(item => CUSTOM_VAT_RATE_V3_APP_VISMA.includes(String(item.vat_rate)) === true)
							let self = this
							setTimeout(function () {
								self.v3CustomAccount.map(item => {
									if (String(item.vat_rate) === '0.25') {
										if (document.getElementById('id_sales_account_se_twenty_five')) {
											window.$(`#id_sales_account_se_twenty_five`).val(item.acct_no).trigger('change');
										}
									} else if (String(item.vat_rate) === '0.12') {
										if (document.getElementById('id_sales_account_se_twelve')) {
											window.$(`#id_sales_account_se_twelve`).val(item.acct_no).trigger('change');
										}
									} else if (String(item.vat_rate) === '0.06') {
										if (document.getElementById('id_sales_account_se_six')) {
											window.$(`#id_sales_account_se_six`).val(item.acct_no).trigger('change');
										}
									}
								})
							}, 500)
						} else {
							res.data.data.map(item => {
								// console.log('what is here::', item)
								if (item.match_code === "EU") {
									window.$(`#id_sales_account_eu`).val(item.acct_no).change()
									this.customAccounts.push(item)
								} else if (item.match_code === "WO") {
									window.$(`#id_sales_account_wo`).val(item.acct_no).change()
									this.customAccounts.push(item)
								} else {
									window.$(`#id_${customAccountListDict[item.vat_rate]}`).val(parseInt(item.acct_no)).change()
									this.customAccounts.push(item)
								}

							})
							let account_to_exclude = []
							Object.keys(this.reconciliatonAccountList).map(val => {
								let filtered = res.data.data.filter(item => parseInt(item.acct_type) === this.reconciliatonAccountList[val].acct_type && item.match_code === 'SEK' && item.vat_rate === null)
								if (filtered.length > 0) {
									this.reconciliatonAccountList[val].acct_no = filtered[0].acct_no
									this.reconciliatonAccountList[val].id = filtered[0].id
									if (document.getElementById(val)) {
										window.$(`#${val}`).val(this.reconciliatonAccountList[val].acct_no).trigger('change');
									}
									account_to_exclude.push(filtered[0].id)
								} else {
									if (document.getElementById(val)) {
										window.$(`#${val}`).val('').trigger('change');
									}
								}
							})
							Object.keys(this.proAccountList).map(val => {
								let filtered = res.data.data.filter(item => parseInt(item.acct_type) === parseInt(this.proAccountList[val].acct_type) && item.match_code === this.proAccountList[val].match_code && item.vat_rate === null)
								if (filtered.length > 0) {
									this.proAccountList[val].acct_no = filtered[0].acct_no
									this.proAccountList[val].id = filtered[0].id
									if (document.getElementById(val)) {
										window.$(`#${val}`).val(this.proAccountList[val].acct_no).trigger('change');
									}
									account_to_exclude.push(filtered[0].id)
								} else {
									if (document.getElementById(val)) {
										window.$(`#${val}`).val('').trigger('change');
									}
								}
							})
							Object.keys(this.voucherAccountList).map(val => {
								let filtered = res.data.data.filter(item => parseInt(item.acct_type) === parseInt(this.voucherAccountList[val].acct_type) && item.match_code === this.voucherAccountList[val].match_code && item.vat_rate === null)
								if (filtered.length > 0) {
									this.voucherAccountList[val].acct_no = filtered[0].acct_no
									this.voucherAccountList[val].id = filtered[0].id
									if (document.getElementById(val)) {
										window.$(`#${val}`).val(this.voucherAccountList[val].acct_no).trigger('change');
									}
									account_to_exclude.push(filtered[0].id)
								} else {
									if (document.getElementById(val)) {
										window.$(`#${val}`).val('').trigger('change');
									}
								}
							})
							let cusAccount = res.data.data.filter(item => account_to_exclude.includes(item.id) === false)
							this.customAccounts = cusAccount
						}


					}
				}).catch((err) => {
				// this.showCustomAccount = false
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								// this.getCustomAccounts(subID)
							})
							.catch(_ => {
								// console.log('here')
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
			console.log(this.reconciliatonAccountList)
		},
		getSeCustomAccounts(subID) {
			const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.GET_CUSTOM_ACCOUNT_SUB + `${subID}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.GET_CUSTOM_ACCOUNT_SUB + `${subID}/`
			// this.$services.axios.defaults.headers.common.Authorization = `Bearer ${this.USER_TOKEN}`
			// this.$services.axios.get(URL)
			this.$store.dispatch('customer/getCustomAccounts', {URL})
				.then(res => {
					// console.log('Acccounts::::::', res)
					// if (res.data.success) {
					// 	console.log(this.customAccounts)
					// }
				}).catch((err) => {
				// this.showCustomAccount = false
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								this.getCustomAccounts(subID)
							})
							.catch(_ => {
								// console.log('here')
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		getAccountType() {
			// this.$services.axios.defaults.headers.common.Authorization = `Bearer ${this.USER_TOKEN}` this.$services.endpoints.GET_ACCOUNT_TYPES + `${this.subId}/`
			// this.$services.axios.get(this.$services.endpoints.GET_ACCOUNT_TYPES)
			const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.GET_ACCOUNT_TYPES + `${this.subId}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.GET_ACCOUNT_TYPES + `${this.subId}/`
			this.$store.dispatch('customer/getAccountType', {URL: URL})
				.then(res => {
					if (res.status === 200) {
						this.accountType = res.data.data
					}
				}).catch((err) => {
				// this.showCustomAccount = false
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								// this.getAccountType()
							})
							.catch(_ => {
								// console.log('here')
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		getMatchType() {
			// this.$services.axios.defaults.headers.common.Authorization = `Bearer ${this.USER_TOKEN}`
			// this.$services.axios.get(this.$services.endpoints.GET_MATCH_TYPE)
			this.$store.dispatch('customer/getMatchType', {URL: this.$services.endpoints.GET_MATCH_TYPE})
				.then(res => {
					// console.log('Match TYPE::::::', res)
					if (res.status === 200) {
						this.matchType = res.data.data
						this.matchTypeCopy = res.data.data
					}
				}).catch((err) => {
				// this.showCustomAccount = false
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								// this.getMatchType()
							})
							.catch(_ => {
								// console.log('here')
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		getCountry() {
			let country_key = 'country_key'

			if (checkIfExist(country_key) === true) {
				this.countries = JSON.parse(getDataLocalStorage(country_key)).data
			} else {
				this.$store.dispatch('customer/getCountry', {URL: this.$services.endpoints.GET_COUNTRIES})
					.then(res => {
						// console.log('countries:', res)
						if (res.status === 200) {
							this.countries = res.data.data
							this.allCountries = res.data.data
							saveDataLocalStorage(country_key, JSON.stringify({data: res.data.data}))
						}
					}).catch((err) => {
					// this.showCustomAccount = false
					if (err.response.status === 400) {
						if (err.response.data.code === 401) {
							this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
								.then(_ => {
									// this.getCountry()
								})
								.catch(_ => {
									// console.log('here')
									this.$store.commit('auth/CLEAR_AUTH_USER', null)
									window.Bus.$emit('sign-out')
								})
						}
					}
				})
			}
			if (this.countries.length > 0) {
				if (this.showCostCenter === true) {
					let el = document.getElementById('customer_country')
					if (el) {
						window.$(el).select2({
							width: 200
						})
						window.$(el).val("").trigger('change')
					}
				}
			}
		},
		getSubscriptionList() {
			const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.GET_USER_SUBSCRIPTION + `${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.GET_USER_SUBSCRIPTION
			this.$store.dispatch('customer/getSubscriptionList', {URL})
				.then(_ => {
					if (this.tabId === 3) window.Bus.$emit('start-appsetting-tab3-tour')
				}).catch((err) => {
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								this.getSubscriptionList()
							})
							.catch(_ => {
								// console.log('error response')
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		getSubscriptionConfig(param) {
			this.vloading = true
			const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.GET_USER_APP_CONFIG + `${param}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.GET_USER_APP_CONFIG + `${param}/`
			this.$store.dispatch('customer/getSubscriptionConfig', {URL})
				.then(res => {
					this.showForm = false
					this.showActivateSub = true
					this.vloading = false
					if (res.data.success) {
						this.shopSettings = res.data.data[Object.keys(res.data.data)[0]]
						this.subscriptionConfigResponse = res.data.data
						this.currentSubscription = res.data.data.subscription
						this.subscriptionDeactivate = false
						this.subscriptionActivate = false
						// checking fort-nox subscription token
						this.cancelledSubscriptionFlag = this.currentSubscription.sub_status
						if (this.currentSubscription.sub_status === true) {
							this.subscriptionDeactivate = true
							this.subscriptionActivate = false
						} else {
							this.subscriptionDeactivate = false
							this.subscriptionActivate = true
						}
						if (this.currentSubscription.fortnox_token_state.hasOwnProperty('fortnox_redirect_url')) {
							this.fortnoxLink = this.currentSubscription.fortnox_token_state.fortnox_redirect_url
							this.showForm = this.currentSubscription.fortnox_token_state.token_status === 'show_button' ? false : true;
						} else if (this.subscriptionConfigResponse.hasOwnProperty('visma_redirect_url')) {
							this.vismaLink = this.subscriptionConfigResponse.visma_redirect_url
							if (this.subscriptionConfigResponse.token_is_expired === true) {
								this.showForm = false
							} else {
								this.showForm = true
							}
						} else {
							this.showForm = true
						}
						if (this.subscriptionConfigResponse.hasOwnProperty('amazon_settings')) {
							this.linkAmazonLegacy = this.subscriptionConfigResponse.na_auth_url
							this.linkAmazonEU = this.subscriptionConfigResponse.eu_auth_url
						}
						if (this.showForm === true) {
							this.getConfigHelpText(this.app_type, this.accountingValue)
						}
						if (this.tabId === 3) window.Bus.$emit('start-appsetting-tab3-tour')
						this.identifyShopifyBtn()
						// if (res.data.data.hasOwnProperty('visma_redirect_url')) {
						// 	if (this.shopSettings.token_status === true) {
						// 		this.showForm = false
						// 		this.vismaLink = res.data.data.visma_redirect_url
						// 	} else {
						// 		this.showForm = true
						// 	}
						// } else {
						// 	if (res.data.data.amazon_settings) {
						// 		this.linkAmazonLegacy = res.data.data.na_auth_url
						// 		this.linkAmazonEU = res.data.data.eu_auth_url
						// 		this.showForm = false
						// 	}
						//
						// }

					}
				}).catch((err) => {
				this.vloading = false
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								this.getSubscriptionConfig(param)
							})
							.catch(_ => {
								// console.log('here')
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		getConfigHelpText(param, acc = null) {
			this.vloading = true
			let self = this
			this.$store.dispatch('customer/getConfigForm', {URL: this.$services.endpoints.GET_HELP_CONFIG_TEXT + `${param}/${acc}/`})
				.then(res => {
					this.vloading = false
					if (res.data.success) {
						this.helpText = res.data.data.form.join('')
						setTimeout(() => {
							Object.keys(res.data.data.help_text).forEach(element => {
								if (document.getElementById(`id_${element}`)) {
									const node = document.createElement('span')
									// node.setAttribute('tooltip-settings', this.convertHTML(res.data.data.help_text[element])) //
									node.setAttribute('class', 'ml-1') // res.data.data.help_text[element]
									node.setAttribute('class', 'tooltip-settings') // tooltip-settings
									node.setAttribute('id', `id_${element}_span_tooltip`) // tooltip-settings

									if (!document.getElementById(`id_${element}_span_tooltip`)) {
										const child = document.createElement('i')
										node.appendChild(child)
										if (res.data.data.help_text[element] !== '') {
											const child2 = document.createElement('span')
											const text = document.createTextNode(this.convertHTML(res.data.data.help_text[element]))
											child2.setAttribute('class', 'w-60 tooltiptext shadow-sm border')
											child2.appendChild(text)
											node.appendChild(child2)
										}
										child.setAttribute('class', 'text-sm help-color fa fa-question-circle')
										const label = document.getElementById(`id_${element}`).previousSibling
										label.setAttribute('class', 'flex justify-between w-full')
										// console.log('label:::', label)
										label.appendChild(node)
									}

									// amazon multiple select for available countries
									if (document.getElementById(`id_${element}`).hasAttribute('is_multiple_select') && document.getElementById(`id_${element}`).tagName === 'SELECT') {
										window.$(`#id_${element}`).select2({
											tokenSeparators: [',', ' '],
											templateSelection: function (data) {
												return data.text
											},
										})
									}

									// For Fortnox subscription
									// Let create dropdown options for all fields that has payment mode for paypal supplier
									if (document.getElementById(`id_${element}`).hasAttribute('is_payment_mode_field') && document.getElementById(`id_${element}`).tagName === 'SELECT') {
										const paymentModeSelect = document.getElementById(`id_${element}`)
										// paymentModeSelect.setAttribute('class', 'w-c65')
										if (paymentModeSelect.options.length < 2) {
											if (this.paypalSupplierDetails.is_payment_mode_field && this.paypalSupplierDetails.is_payment_mode_field.length > 0) {
												this.paypalSupplierDetails.is_payment_mode_field.forEach(item => {
													const option = document.createElement('option')
													option.text = item.AccountNumber + ' ' + item.Description
													option.value = item.Code
													paymentModeSelect.add(option)
												})
												window.$(`#id_${element}`).select2({
													templateSelection: function (data) {
														return data.id
													},
												})
											}
										}
									}

									// For visma apps (VISMA Unit)
									if (document.getElementById(`id_${element}`).hasAttribute('is_unit_id_field') && document.getElementById(`id_${element}`).tagName === 'SELECT') {
										const vismaUnit = document.getElementById(`id_${element}`)
										// vismaUnit.setAttribute('class', 'w-c65')
										if (vismaUnit.options.length < 2) {
											if (this.vismaUnit.length > 0) {
												this.vismaUnit.forEach(item => {
													const option = document.createElement('option')
													option.text = item.Name
													option.value = item.Id
													vismaUnit.add(option)
												})
												window.$(`#id_${element}`).select2({
													tags: true,
													createTag: function (params) {
														return {
															id: params.id,
															text: params.text,
														}
													},
												})
											}
										}
									}

									// Let create dropdown options for all fields that has accounts
									if (document.getElementById(`id_${element}`).hasAttribute('is_account_field') && document.getElementById(`id_${element}`).tagName === 'SELECT') {
										const accountSelect = document.getElementById(`id_${element}`)
										accountSelect.setAttribute('required', true)
										// accountSelect.setAttribute('class', 'w-c65')
										if (accountSelect.options.length < 2) {
											if (this.accountNumbers.length > 0) {
												this.accountNumbers.forEach(item => {
													const option = document.createElement('option')
													option.text = parseInt(acc) === 1 ? item.Description : item.Number + ' ' + item.Description
													option.value = item.Number
													accountSelect.add(option)
												})
												window.$(`#id_${element}`).select2({
													tags: true,
													createTag: function (params) {
														return {
															id: params.id,
															text: params.text,
														}
													},
													// templateSelection : function (data) {
													//     console.log(data)
													//     return data.id
													// },
												})
											}
										}
									}

									// Let create dropdown options for all fields that has payment term or payment term code
									if (document.getElementById(`id_${element}`).hasAttribute('is_payment_term_field') && document.getElementById(`id_${element}`).tagName === 'SELECT') {
										const paymentTermSelect = document.getElementById(`id_${element}`)
										paymentTermSelect.setAttribute('required', true)
										if (paymentTermSelect.options.length < 2) {
											if (this.paymentTerms.length > 0) {
												this.paymentTerms.forEach(item => {
													const option = document.createElement('option')
													option.text = item.Description
													option.value = item.Code
													paymentTermSelect.add(option)
												})
												window.$(`#id_${element}`).select2({
													tags: true,
													createTag: function (params) {
														return {
															id: params.id,
															text: params.text,
														}
													},
													// templateSelection : function (data) {
													//     return data.id
													// },
												})
											}
										}
									}

									// Dropdown options for all fields for voucher series
									if (document.getElementById(`id_${element}`).hasAttribute('is_voucher_series_field') && document.getElementById(`id_${element}`).tagName === 'SELECT') {
										const voucherSelect = document.getElementById(`id_${element}`)
										voucherSelect.setAttribute('required', true)
										// voucherSelect.setAttribute('class', 'w-c65')
										if (voucherSelect.options.length < 2) {
											if (this.voucherSeries.length > 0) {
												this.voucherSeries.forEach(item => {
													const option = document.createElement('option')
													option.text = item.Description
													option.value = item.Code
													voucherSelect.add(option)
												})
												window.$(`#id_${element}`).select2({
													tags: true,
													createTag: function (params) {
														return {
															id: params.id,
															text: params.text,
														}
													},
													// templateSelection : function (data) {
													//     return data.id
													// },
												})
											}
										}
									}

									// Dropdown options for all fields for pricelist
									if (document.getElementById(`id_${element}`).hasAttribute('is_price_list_field') && document.getElementById(`id_${element}`).tagName === 'SELECT') {
										const priceListSelect = document.getElementById(`id_${element}`)
										priceListSelect.setAttribute('required', true)
										// priceListSelect.setAttribute('class', 'w-c65')
										if (priceListSelect.options.length < 2) {
											if (this.priceList.length > 0) {
												this.priceList.forEach(item => {
													const option = document.createElement('option')
													option.text = item.Description
													option.value = item.Code
													priceListSelect.add(option)
												})
												window.$(`#id_${element}`).select2({
													tags: true,
													createTag: function (params) {
														return {
															id: params.id,
															text: params.text,
														}
													},
												})
											}
										}
									}
									// Drop down options for all fields for Warehouse location
									if (document.getElementById(`id_${element}`).hasAttribute('is_warehouse_field') && document.getElementById(`id_${element}`).tagName === 'SELECT') {
										const warehouseLocation = document.getElementById(`id_${element}`)
										warehouseLocation.setAttribute('required', true)
										if (warehouseLocation.options.length < 2) {
											if (this.stockPointsList.length > 0) {
												this.stockPointsList.forEach(item => {
													const option = document.createElement('option')
													option.text = item.name
													option.value = item.code
													warehouseLocation.add(option)
												})
												window.$(`#id_${element}`).select2({
													tags: true,
													createTag: function (params) {
														return {
															id: params.id,
															text: params.text,
														}
													},
												})
											}
										}

									}
									// Remove account id field and label for stripe subscription
									if (STRIPE_SUBSCRIPTIONS.includes(parseInt(this.appType))) {
										if (document.getElementById('id_account_code')) {
											document.getElementById('id_account_code').setAttribute('type', 'hidden')

											const label = document.getElementById('id_account_code').previousSibling
											label.style.display = 'none'
										}
									}

									// amazon market-place connect button
									if ([52].includes(parseInt(this.app_type))) {
										if (this.shopSettings.hasOwnProperty('token_status')) {
											if (this.shopSettings.token_status === true) {
												this.showAmazonMarketPlace = false
											}
										}
									}

									// For Visma apps Only
									if (document.getElementById(`id_${element}`).hasAttribute('is_visma_bank_account_field') && document.getElementById(`id_${element}`).tagName === 'SELECT') {
										const vismaListSelect = document.getElementById(`id_${element}`)
										vismaListSelect.setAttribute('required', true)
										// vismaListSelect.setAttribute('class', 'w-c65')
										if (vismaListSelect.options.length < 2) {
											if (this.vismaBankAccount.length > 0) {
												this.vismaBankAccount.forEach(item => {
													const option = document.createElement('option')
													option.text = item.Name
													option.value = item.Id
													vismaListSelect.add(option)
												})
												window.$(`#id_${element}`).select2({
													tags: true,
													createTag: function (params) {
														return {
															id: params.id,
															text: params.text,
														}
													}
												})
											}
										}
									}
									// For visma subscriptions only
									if (document.getElementById(`id_${element}`).hasAttribute('is_visma_bank_account_number_field') && document.getElementById(`id_${element}`).tagName === 'SELECT') {
										const vismaListSelect = document.getElementById(`id_${element}`)
										vismaListSelect.setAttribute('required', true)
										// vismaListSelect.setAttribute('class', 'w-c65')
										if (vismaListSelect.options.length < 2) {
											if (this.vismaBankAccount.length > 0) {
												this.vismaBankAccount.forEach(item => {
													const option = document.createElement('option')
													option.text = item.Name
													option.value = item.LedgerAccountNumber
													vismaListSelect.add(option)
												})
												window.$(`#id_${element}`).select2({
													tags: true,
													createTag: function (params) {
														return {
															id: params.id,
															text: params.text,
														}
													}
												})
											}
										}
									}

									// For Visma apps Only (Paypal supplier)
									if (document.getElementById(`id_${element}`).hasAttribute('is_visma_foreign_payment_code_field') && document.getElementById(`id_${element}`).tagName === 'SELECT') {
										const vismaForeignPaymentSelect = document.getElementById(`id_${element}`)
										vismaForeignPaymentSelect.setAttribute('required', true)
										if (vismaListSelect.options.length < 2) {
											if (this.vismaForeignPayment.length > 0) {
												this.vismaForeignPayment.forEach(item => {
													const option = document.createElement('option')
													option.text = item.Description
													option.value = item.Id
													vismaForeignPaymentSelect.add(option)
												})
												window.$(`#id_${element}`).select2({
													tags: true,
													createTag: function (params) {
														return {
															id: params.id,
															text: params.text,
														}
													},
													// templateSelection : function (data) {
													//     return data.id
													// },
												})
											}
										}
									}

									// Getting stripe auth btn
									if ([9, 11, 57, 58, 59].includes(parseInt(param))) {
										const stripeName = document.getElementById('stripe-auth-form')
										stripeName.innerHTML = ''
										const div = document.createElement('div')
										div.setAttribute('class', 'space-x-4')
										const button = document.createElement('button')

										if (this.shopSettings['account_code'] || this.shopSettings['stripe_token_status'] === true) {
											button.innerText = `Stripe: ${this.$t('connectedStripe')}`
											button.setAttribute('class', 'c-btn btn-activate btn-theme-custom px-6 text-white py-2 rounded shadow-xl')
											// div.append(label)
											div.append(button)
											stripeName.append(div)
										} else {
											button.innerText = this.$t('connectStripe')
											button.setAttribute('id', 'stripe-auth-btn')
											button.setAttribute('class', 'c-btn btn-auth px-6 text-white py-2 rounded shadow-xl')
											// div.append(label)
											div.append(button)
											stripeName.append(div)
										}
									}

									// Getting shopify auth btn
									if ([27, 21, 51].includes(parseInt(param)) === true) {
										const shopifyName = document.getElementById('shopify-auth-form')

										if (shopifyName) {
											shopifyName.innerHTML = ''
											const div = document.createElement('div')
											div.setAttribute('class', 'space-x-4')
											if (this.shopSettings.hasOwnProperty('shop_name')) {
												this.shopName = this.shopSettings['shop_name']
												document.getElementById('id_shop_name').value = this.shopSettings['shop_name']
											}
											if (this.shopSettings['token_status'] === true || this.shopSettings['shopify_access_token']) {
												const label = document.createElement('label')
												label.setAttribute('for', 'shopify_auth')
												label.setAttribute('class', 'text-gray-700')
												label.innerText = 'Shopify ID'
												const button = document.createElement('button')
												button.innerText = `Shopify: ${this.$t('connectedShopify')}`
												// button.setAttribute('id', 'shopify-auth-btn')
												button.setAttribute('class', 'c-btn btn-activate btn-theme-custom px-6 text-white py-2 rounded shadow-xl')
												div.append(label)
												div.append(button)
												shopifyName.append(div)


												if (document.getElementById('id_token')) {
													document.getElementById('id_token').style.display = 'none'
													document.querySelector('[for=id_token]').style.display = 'none'
												}
												if (document.getElementById('id_shopify_access_token')) {
													document.getElementById('id_shopify_access_token').style.display = 'none'
													document.querySelector('[for=id_shopify_access_token]').style.display = 'none'
												}

												// setTimeout(function () {
												// 	if (document.getElementById('id_token')){
												//
												// 	}
												// },300)
											} else {
												// shopifyName.innerHTML = ''
												// const div = document.createElement('div')
												// div.setAttribute('class', 'space-x-4')
												const label = document.createElement('label')
												label.setAttribute('for', 'shopify_auth')
												label.setAttribute('class', 'text-gray-700')
												// label.innerText = 'Shopify ID'
												const button = document.createElement('button')
												button.innerText = this.$t('shopifyGuide')
												button.setAttribute('id', 'shopify-auth-btn')
												button.setAttribute('class', 'c-btn btn-auth-pay bg-blue-900 px-6 text-white py-2 rounded shadow-xl')
												div.append(label)
												div.append(button)
												shopifyName.append(div)
												document.getElementById('shopify-auth-btn').addEventListener('click', function () {
													let routeData = self.$router.resolve({name: 'shopify-guide'});
													window.open(routeData.href, '_blank');
													// self.$router.replace({name: 'shopify-guide'})
												})
											}

										}
									}
									// Event handle for checkbox values
									if (document.getElementById(`id_${element}`).getAttribute('type') === 'checkbox') {
										const checkbox = document.getElementById(`id_${element}`)

										checkbox.addEventListener('change', (event) => {
											let unallowed_double_checked = ['is_post_invoices', 'is_post_orders']
											if (unallowed_double_checked.includes(event.target.name)) {
												if (event.currentTarget.checked) {
													if (event.target.name === 'is_post_invoices') {
														document.getElementById(`id_is_post_invoices`).checked = true
														document.getElementById(`id_is_post_invoices`).value = true
														document.getElementById(`id_is_post_invoices`).setAttribute('checked', true)
														document.getElementById(`id_is_post_orders`).checked = false
														document.getElementById(`id_is_post_orders`).value = false
														document.getElementById(`id_is_post_orders`).removeAttribute('checked', true)
													}
													if (event.target.name === 'is_post_orders') {
														document.getElementById(`id_is_post_orders`).checked = true
														document.getElementById(`id_is_post_orders`).value = true
														document.getElementById(`id_is_post_orders`).setAttribute('checked', true)
														document.getElementById(`id_is_post_invoices`).checked = false
														document.getElementById(`id_is_post_invoices`).value = false
														document.getElementById(`id_is_post_invoices`).removeAttribute('checked', true)
													}
												} else {
													document.getElementById(`id_${element}`).value = false
													document.getElementById(`id_${element}`).removeAttribute('checked')
												}
											} else {
												if (event.currentTarget.checked) {
													document.getElementById(`id_${element}`).value = true
													document.getElementById(`id_${element}`).setAttribute('checked', true)
												} else {
													document.getElementById(`id_${element}`).value = false
													document.getElementById(`id_${element}`).removeAttribute('checked')
												}
											}

										})
										// Validate if checkbox is already true
										if (this.shopSettings[element]) {
											checkbox.setAttribute('checked', true)
										} else {
											checkbox.removeAttribute('checked')
										}
									}

									// Applying styles to certain fields
									if (document.getElementById(`id_${element}`).getAttribute('type') === 'checkbox') {
										document.getElementById(`id_${element}`).classList.add('form-control', 'h-4', 'checkbox-height')
									}
									if (document.getElementById(`id_${element}`).getAttribute('type') !== 'checkbox') {
										document.getElementById(`id_${element}`).classList.add('c-pad-setting', 'form-control', 'h-c28', 'font-light', 'text-xs')
									}
									if (document.getElementById(`id_${element}`).tagName === 'SELECT') {
										document.getElementById(`id_${element}`).classList.add('c-pad-setting', 'w-f-100', 'custom-select', 'h-c28', 'font-light', 'text-xs')
									}
									if (document.getElementById(`id_${element}`).hasAttribute('is_multiple_select')) {
										window.$(`#id_${element}`).val(this.shopSettings[element]).change()
									} else if (document.getElementById(`id_${element}`).hasAttribute('is_account_field')) {
										if (this.shopSettings[element]) {
											window.$(`#id_${element}`).val(this.shopSettings[element]).change()
										}

									} else if (document.getElementById(`id_${element}`).hasAttribute('is_payment_mode_field')) {
										if (this.shopSettings[element]) {
											window.$(`#id_${element}`).val(this.shopSettings[element]).change()
										}
									} else if (document.getElementById(`id_${element}`).hasAttribute('is_payment_term_field')) {
										if (this.shopSettings[element]) {
											window.$(`#id_${element}`).val(this.shopSettings[element]).change()
										}

									} else if (document.getElementById(`id_${element}`).hasAttribute('is_voucher_series_field')) {
										if (this.shopSettings[element]) {
											window.$(`#id_${element}`).val(this.shopSettings[element]).change()
										}

									} else if (document.getElementById(`id_${element}`).hasAttribute('is_visma_bank_account_field')) {
										if (this.shopSettings[element]) {
											window.$(`#id_${element}`).val(this.shopSettings[element]).change()
										}

									} else if (document.getElementById(`id_${element}`).hasAttribute('is_visma_bank_account_number_field')) {
										if (this.shopSettings[element]) {
											window.$(`#id_${element}`).val(this.shopSettings[element]).change()
										}

									} else if (document.getElementById(`id_${element}`).hasAttribute('is_visma_foreign_payment_code_field')) {
										if (this.shopSettings[element]) {
											window.$(`#id_${element}`).val(this.shopSettings[element]).change()
										}

									} else if (document.getElementById(`id_${element}`).hasAttribute('is_unit_id_field')) {
										if (this.shopSettings[element]) {
											window.$(`#id_${element}`).val(this.shopSettings[element]).change()
										}

									} else if (document.getElementById(`id_${element}`).hasAttribute('is_price_list_field')) {
										if (this.shopSettings[element]) {
											window.$(`#id_${element}`).val(this.shopSettings[element]).change()
										}

									} else if (document.getElementById(`id_warehouse_location_code`)) {
										if (this.shopSettings['warehouse_location_code']) {
											window.$(`#id_${element}`).val(this.shopSettings['warehouse_location_code']).change()
										}

									} else {

										if (this.shopSettings[element]) {
											document.getElementById(`id_${element}`).value = this.shopSettings[element]
										}

									}

									// apply voucher series input data
									if (document.getElementById(`id_voucher_series`) && element === 'voucher_series') {
										let voucherSelect = document.getElementById(`id_voucher_series`)
										voucherSelect.setAttribute('required', true)
										if (this.shopSettings[element]) {
											voucherSelect.value = this.shopSettings[element]
										}
									}
									if (document.getElementById(`id_region`) && element === 'region') {
										let regionSelect = document.getElementById(`id_region`)
										regionSelect.setAttribute('required', true)
										if (this.shopSettings[element]) {
											document.getElementById(`id_region`).value = this.shopSettings[element]
										}

									}
									if (document.getElementById(`id_booking_date`) && element === 'booking_date') {
										let dateSelect = document.getElementById(`id_booking_date`)
										dateSelect.setAttribute('required', true)
										if (this.shopSettings[element]) {
											document.getElementById(`id_booking_date`).value = this.shopSettings[element]
										}

									}
									// apply master location
									if (element === 'master_location' && document.getElementById(`id_${element}`)) {
										let options = ''
										Object.keys(self.officeLocations).map(item => {
											let op = `<option value="${self.officeLocations[item]}}">${item}</option>`
											options += op
										})
										document.getElementById(`id_master_location`).innerHTML = ''
										setTimeout(function () {
											document.getElementById(`id_master_location`).innerHTML = options
										}, 500)

									}
									// monitor swish handle selection field
									if (document.getElementById('id_swish_bank_type')) {
										let swish_bank_type = document.getElementById('id_swish_bank_type').value
										if (swish_bank_type === 'seb') {
											window.$('[for="id_swish_bank_id"]').show()
											window.$('#id_swish_bank_id').show()
										} else {
											window.$('[for="id_swish_bank_id"]').hide()
											window.$('#id_swish_bank_id').hide()
										}
										document.getElementById('id_swish_bank_type').addEventListener('change', function (e) {
											if (e.target.value === 'seb') {
												window.$('#id_swish_bank_id').show()
												window.$('[for="id_swish_bank_id"]').show()
											} else {
												window.$('[for="id_swish_bank_id"]').hide()
												window.$('#id_swish_bank_id').hide()
											}
										})
									}
									if (element.includes('date')) {
										window.$(`#id_${element}`).datepicker({
											dateFormat: 'yy-mm-dd',
											startDate: new Date(),
											todayBtn: 'linked',
											keyboardNavigation: false,
											forceParse: false,
											calendarWeeks: true,
											autoclose: true
										})
										window.$(`#id_${element}`).attr({
											placeholder: 'YYYY-MM-DD',
											readonly: 'readonly'
										})
									}
									if (element.includes('expires')) {
										window.$(`#id_${element}`).datepicker({
											dateFormat: 'yy-mm-dd',
										})
										window.$(`#id_${element}`).attr({
											placeholder: 'YYYY-MM-DD',
											readonly: 'readonly'
										})
									}

								} else {
									// console.log('not found')
								}
							})
							this.runShopifyScript()
							this.runShopifyAuth()
							this.runStripeAuth()
							if (this.tabId === 2) window.Bus.$emit('start-appsetting-tab2-tour')
						}, 500)

						this.getCustomAccounts(this.subId)

						const customv3 = document.querySelectorAll('.custom-v3-class')
						const reconciliations = document.querySelectorAll('.reconciliation-class')
						const pro_app = document.querySelectorAll('.pro-app')
						const voucher_app = document.querySelectorAll('.voucher-app')
						customv3.forEach(element => {
							const accountSelect = element
							// accountSelect.setAttribute('class', 'w-c65')
							if (accountSelect.options.length < 2) {
								if (this.accountNumbers.length > 0) {
									this.accountNumbers.forEach(item => {
										const option = document.createElement('option')
										option.text = parseInt(acc) === 1 ? item.Description : item.Number + ' ' + item.Description
										option.value = item.Number
										accountSelect.add(option)
									})
									window.$(element).select2({
										tags: true,
										createTag: function (params) {
											return {
												id: params.id,
												text: params.text,
											}
										}
									})
									// console.log('what is value::', window.$(element).val())
									// if (!window.$(element).val()) {
									//     }
									window.$(element).val('').change()
								}
							}
						})
						reconciliations.forEach(element => {
							const reAccountSelect = element
							if (reAccountSelect.options.length < 2) {
								if (this.accountNumbers.length > 0) {
									this.accountNumbers.forEach(item => {
										const option = document.createElement('option')
										option.text = parseInt(acc) === 1 ? item.Description : item.Number + ' ' + item.Description
										option.value = item.Number
										reAccountSelect.add(option)
									})
									window.$(element).select2({
										tags: true,
										createTag: function (params) {
											return {
												id: params.id,
												text: params.text,
											}
										}
									})
									window.$(element).val('').change()
								}
							}
						})
						pro_app.forEach(element => {
							let proAccount = element
							if (proAccount.options.length < 2) {
								if (this.accountNumbers.length > 0) {
									this.accountNumbers.forEach(item => {
										const option = document.createElement('option')
										option.text = parseInt(acc) === 1 ? item.Description : item.Number + ' ' + item.Description
										option.value = item.Number
										proAccount.add(option)
									})
									window.$(element).select2({
										tags: true,
										createTag: function (params) {
											return {
												id: params.id,
												text: params.text,
											}
										}
									})
									window.$(element).val('').change()
								}
							}
						})
						voucher_app.forEach(element => {
							let voucherAccount = element
							if (voucherAccount.options.length < 2) {
								if (this.accountNumbers.length > 0) {
									this.accountNumbers.forEach(item => {
										const option = document.createElement('option')
										option.text = parseInt(acc) === 1 ? item.Description : item.Number + ' ' + item.Description
										option.value = item.Number
										voucherAccount.add(option)
									})
									window.$(element).select2({
										tags: true,
										createTag: function (params) {
											return {
												id: params.id,
												text: params.text,
											}
										}
									})
									window.$(element).val('').change()
								}
							}
						})

						// in enable select2 configuration on cost center select field
						// const costCenters = document.getElementById('id_cost_center')
						// window.$(costCenters).select2()

					}
				}).catch((err) => {
				this.vloading = false
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								this.getConfigHelpText(param, acc)
							})
							.catch(_ => {
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		getWaysOfDeliveryOption(key) {
			let way_of_delivery = `way_of_delivery_${this.appType}`
			let datetime = moment().add(1, 'hours').format('YYYY-MM-DD hh:mm')
			// if (checkIfExist(way_of_delivery) === true) {
			//
			// } else {
			const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.GET_WAYS_OF_DELIVERY_OPTIONS + `${key}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.GET_WAYS_OF_DELIVERY_OPTIONS + `${key}/`
			this.$store.dispatch('customer/getWaysOfDeliveryOption', {URL})
				.then(res => {
					if (res.data.success) {
						this.waysOfDeliveryOption = res.data.data
					}
				}).catch((err) => {
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								this.getWaysOfDeliveryOption(key)
							})
							.catch(_ => {
								// console.log('error response')
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
			// }

		},
		getWaysOfDeliverySubscription(key) {
			const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.GET_WAYS_OF_DELIVERY_SUBSCRIPTION + `${key}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.GET_WAYS_OF_DELIVERY_SUBSCRIPTION + `${key}/`
			this.$store.dispatch('customer/getWaysOfDeliverySubscription', {URL})
				.then(res => {
					if (res.data.success) {
						this.wayOfDeliveryArray = res.data.data
					}
				}).catch((err) => {
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								this.getWaysOfDeliverySubscription(key)
							})
							.catch(_ => {
								// console.log('error response')
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		getTermOfDeliveryOption(key) {
			const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.GET_TERMS_OF_DELIVERY_OPTIONS + `${key}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.GET_TERMS_OF_DELIVERY_OPTIONS + `${key}/`
			this.$store.dispatch('customer/getTermOfDeliveryOption', {URL})
				.then(res => {
					// console.log('DSTERMS::::::', res)

				}).catch((err) => {
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								this.getTermOfDeliveryOption(key)
							})
							.catch(_ => {
								// console.log('error response')
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		getPaymentTermOption(key) {

			const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.GET_PAYMENT_TERM_OPTIONS + `${key}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.GET_PAYMENT_TERM_OPTIONS + `${key}/`
			this.$store.dispatch('customer/getPaymentTermOption', {URL})
				.then(res => {
					if (res.data.success && res.data.data) {
						this.paymentTerms = res.data.data
					}
				}).catch((err) => {
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								// this.getPaymentTermOption(key)
							})
							.catch(_ => {
								// console.log('error response')
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		getActiveAccountNumbers(key, type, acc) {
			const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.GET_ACTIVE_ACCOUNT_NUMBERS + `${key}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.GET_ACTIVE_ACCOUNT_NUMBERS + `${key}/`
			this.$store.dispatch('customer/getActiveAccountNumbers', {URL})
				.then(res => {
					this.accountNumbers = res.data.data.results
					this.getConfigHelpText(type, acc)
				}).catch((err) => {
				if (err.response.status === 400) {
					let error_message = err.response.data.data
					if (error_message && err.response.data.code === 500 && parseInt(this.$route.query.acc) === 0) {
						if (error_message.includes('refreshing access_token')) {
							this.showForm = false
						}
					}
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								this.getActiveAccountNumbers(key, type, acc)
							})
							.catch(_ => {
								// console.log('error response')
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
			// let store_key = `app_type_${type}`
			// let datetime = moment().add(1, 'hours').format('YYYY-MM-DD hh:mm')
			// if (checkIfExist(store_key) === true) {
			// 	if (this.checkIfValid(moment(JSON.parse(getDataLocalStorage(store_key)).datetime).format('YYYY-MM-DD hh:mm')) === false) {
			// 		removeDataLocalStorage(store_key)
			// 		this.getActiveAccountNumbers(key, type, acc)
			// 	} else {
			// 		this.accountNumbers = JSON.parse(getDataLocalStorage(store_key)).data
			// 	}
			// } else {
			//
			// }

		},
		checkIfValid(dt_key) {
			let datetime = moment().format('YYYY-MM-DD hh:mm')
			if (datetime < dt_key) {
				return true
			} else {
				return false
			}
		},
		addAccountNumbers() {
			const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.ADD_ACCOUNT_NUMBERS + `${this.subId}/${number}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.ADD_ACCOUNT_NUMBERS + `${this.subId}/${number}/`
			// this.$services.axios.defaults.headers.common.Authorization = `Bearer ${this.USER_TOKEN}`
			// this.$services.axios.get(URL)
			this.$store.dispatch('customer/addAccountNumbers', {URL})
				.then(res => {
					// console.log('Numbers:', res)
					if (res.data.success) {
						this.accountNumbers = res.data.data
					}
				}).catch((err) => {
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
							})
							.catch(_ => {
								// console.log('error response')
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		getPaymentProvider() {
			const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.PAYMENT_PROVIDER : this.$services.endpoints.PAYMENT_PROVIDER
			// this.$services.axios.defaults.headers.common.Authorization = `Bearer ${this.USER_TOKEN}`
			// this.$services.axios.get(URL)
			this.$store.dispatch('customer/getPaymentProvider', {URL})
				.then(res => {
					// console.log('PAYMENT provider::::::', res)
					if (res.data.success) {
						this.providerPaym = res.data.data
						// console.log('DDDD:', res.data.data[0][1])
					}
				}).catch((err) => {
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								this.getPaymentProvider()
							})
							.catch(_ => {
								// console.log('error response')
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		getPaymentTermSubscription(key) {
			const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.GET_PAYMENT_TERMS_SUBSCRIPTION + `${key}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.GET_PAYMENT_TERMS_SUBSCRIPTION + `${key}/`
			// this.$services.axios.defaults.headers.common.Authorization = `Bearer ${this.USER_TOKEN}`
			// this.$services.axios.get(URL)
			this.$store.dispatch('customer/getPaymentTermSubscription', {URL})
				.then(res => {
					// console.log('PAYMENT SUBJJJ::::::', res)
					if (res.data.success) {
						this.paymentTermsArray = res.data.data
					}
				}).catch((err) => {
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								this.getPaymentTermSubscription(key)
							})
							.catch(_ => {
								// console.log('error response')
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		getVismaBankAccountNumbers(key) {
			const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.VISMA_BANK_ACCOUNT_NUM + `${key}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.VISMA_BANK_ACCOUNT_NUM + `${key}/`
			// this.$services.axios.defaults.headers.common.Authorization = `Bearer ${this.USER_TOKEN}`
			// this.$services.axios.get(URL)
			this.$store.dispatch('customer/getVismaBankAccountNumbers', {URL})
				.then(res => {
					this.vismaBankAccount = res.data.results
				}).catch((err) => {
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								this.getVismaBankAccountNumbers(key)
							})
							.catch(_ => {
								// console.log('error response')
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
					if (err.response.data.hasOwnProperty('visma_error')) {
						let message = this.GET_LOCALE === 'se' ? err.response.data.message_swe : err.response.data.message
						window.Bus.$emit('output-modal-error', {'message': message})
					}
				}
			})
		},
		getVismaForeignPaymentCode(key) {
			const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.VISMA_FOREIGN_PAYMENT_CODE + `${key}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.VISMA_FOREIGN_PAYMENT_CODE + `${key}/`
			// this.$services.axios.defaults.headers.common.Authorization = `Bearer ${this.USER_TOKEN}`
			// this.$services.axios.get(URL)
			this.$store.dispatch('customer/getRequestFromBE', {URL})
				.then(res => {
					this.vismaForeignPayment = res.data

				}).catch((err) => {
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								this.getVismaForeignPaymentCode(key)
							})
							.catch(_ => {
								// console.log('error response')
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		getVismaUnit(key) {
			const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.GET_VISMA_UNIT + `${key}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.GET_VISMA_UNIT + `${key}/`
			// this.$services.axios.defaults.headers.common.Authorization = `Bearer ${this.USER_TOKEN}`
			// this.$services.axios.get(URL)
			this.$store.dispatch('customer/getRequestFromBE', {URL})
				.then(res => {
					this.vismaUnit = res.data.data

				}).catch((err) => {
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								this.getVismaUnit(key)
							})
							.catch(_ => {
								// console.log('error response')
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		clearWayDeliveryForm() {
			this.wayDoption = ''
			this.storeTitle = ''
		},
		createWayDeliverySubscription() {
			if (this.wayDoption === '' || this.storeTitle === '') {
				this.$services.helpers.notification(this.$t('fieldRequired'), 'error', this)
			} else if (this.wayDoption.length > 50 || this.storeTitle.length > 50) {
				this.$services.helpers.notification(this.$t('fieldValue'), 'error', this)
			} else {
				this.addingDeliveryWay = true
				const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.CREATE_WAY_OF_DELIVERY_SUBSCRIPTION + `${this.subId}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.CREATE_WAY_OF_DELIVERY_SUBSCRIPTION + `${this.subId}/`
				// this.$services.axios.defaults.headers.common.Authorization = `Bearer ${this.USER_TOKEN}`
				// this.$services.axios.post(URL, {
				//     })
				const payload = {
					title: this.storeTitle,
					match_code: this.wayDoption.split('*')[0].trim(),
					match_description: this.wayDoption.split('*')[1].trim()
				}
				this.$store.dispatch('customer/createWayDeliverySubscription', {URL, dataObject: payload})
					.then(res => {
						this.clearWayDeliveryForm()
						this.addingDeliveryWay = false
						if (res.data.success) {
							this.$services.helpers.notification(this.$t('createdDeliverySubscription'), 'success', this)

							this.getWaysOfDeliverySubscription(this.subId)
						}
					}).catch((err) => {
					this.addingDeliveryWay = false
					if (err.response.status === 400) {
						if (err.response.data.code === 401) {
							this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
								.then(_ => {
								})
								.catch(_ => {
									// console.log('error response')
									this.$store.commit('auth/CLEAR_AUTH_USER', null)
									window.Bus.$emit('sign-out')
								})
						}
					}
				})

			}
		},
		createPaymentTermSubscription() {
			if (this.paymentTcode === '' || this.paymentProvider === '') {
				this.$services.helpers.notification(this.$t('fieldRequired'), 'error', this)
			} else {
				this.addingPaymentTerms = true
				const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.CREATE_PAYMENT_TERM_SUBSCRIPTION + `${this.subId}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.CREATE_PAYMENT_TERM_SUBSCRIPTION + `${this.subId}/`
				// this.$services.axios.defaults.headers.common.Authorization = `Bearer ${this.USER_TOKEN}`
				// this.$services.axios.post(URL, {
				//     })
				const payload = {
					gateway_in_app: this.paymentProvider,
					gateway_in_accounting: this.paymentTcode,
				}
				this.$store.dispatch('customer/createPaymentTermSubscription', {URL, dataObject: payload})
					.then(res => {
						// console.log('posted:', res)
						this.addingPaymentTerms = false
						if (res.data.success) {
							this.$services.helpers.notification(this.$t('createdPayTermSubscription'), 'success', this)

							this.getPaymentTermSubscription(this.subId)
						}
					}).catch((err) => {
					this.addingPaymentTerms = false
					if (err.response.status === 400) {
						if (err.response.data.code === 401) {
							this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
								.then(_ => {
									// this.getSubscriptionList()
								})
								.catch(_ => {
									// console.log('error response')
									this.$store.commit('auth/CLEAR_AUTH_USER', null)
									window.Bus.$emit('sign-out')
								})
						}
					}
				})

			}
		},
		deleteWaysOfDeliverySubscription(id) {
			this.$services.helpers.notification(this.$t('pleaseWait'), 'progress', this)
			const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.DELETE_WAYS_OF_DELIVERY_SUBSCRIPTION + `${this.subId}/${id}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.DELETE_WAYS_OF_DELIVERY_SUBSCRIPTION + `${this.subId}/${id}/`
			// this.$services.axios.defaults.headers.common.Authorization = `Bearer ${this.USER_TOKEN}`
			// this.$services.axios.delete(URL)
			this.$store.dispatch('customer/deleteWaysOfDeliverySubscription', {URL})
				.then(res => {
					bus.$emit('hide-notify-me', '')
					if (res.status === 204) {
						this.$services.helpers.notification(this.$t('deletedDeliverySubscription'), 'success', this)

						this.getWaysOfDeliverySubscription(this.subId)
					}
				}).catch((err) => {
				bus.$emit('hide-notify-me', '')
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								// this.getSubscriptionList()
							})
							.catch(_ => {
								// console.log('error response')
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})

		},
		deletePaymentTermSubscription(id) {
			this.$services.helpers.notification(this.$t('pleaseWait'), 'progress', this)
			const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.DELETE_PAYMENT_TERMS_SUBSCRIPTION + `${this.subId}/${id}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.DELETE_PAYMENT_TERMS_SUBSCRIPTION + `${this.subId}/${id}/`
			// this.$services.axios.defaults.headers.common.Authorization = `Bearer ${this.USER_TOKEN}`
			// this.$services.axios.delete(URL)
			this.$store.dispatch('customer/deletePaymentTermSubscription', {URL})
				.then(res => {
					bus.$emit('hide-notify-me', '')
					if (res.status === 204) {
						this.$services.helpers.notification(this.$t('deletedPayTermSubscription'), 'success', this)

						this.getPaymentTermSubscription(this.subId)
					}
				}).catch((err) => {
				bus.$emit('hide-notify-me', '')
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								this.getSubscriptionList()
							})
							.catch(_ => {
								// console.log('error response')
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})

		},
		deleteCostCenterSubscription(id) {
			this.$services.helpers.notification(this.$t('pleaseWait'), 'progress', this)
			const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.DELETE_PAYMENT_TERMS_SUBSCRIPTION + `${this.subId}/${id}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.DELETE_SUBSCRIPTION_COST_CENTER + `${this.subId}/${id}/`

			this.$store.dispatch('customer/customDeleteRequest', {URL})
				.then(res => {
					this.$services.helpers.notification(this.$t('deletedSuccessfully'), 'success', this)

					this.getSubscriptionCostCenter(this.subId, this.app_type, this.acc)
				}).catch((err) => {
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								this.deleteCostCenterSubscription(id)
							})
							.catch(_ => {
								// console.log('error response')
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})

		},
		createCostCenterSubscription() {
			if (this.costCenter.cost_center_code === '') {
				this.$services.helpers.notification(this.$t('fieldRequired'), 'error', this)
			} else {
				let countryCode = ''
				let el = document.getElementById('customer_country')
				if (window.$(el).select2('val')) {
					countryCode = window.$(el).select2('val')
				} else {
					this.$services.helpers.notification(this.$t('fieldRequired'), 'error', this)
					return false
				}
				this.addingCostCenter = true
				const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.CREATE_SUBSCRIPTION_COST_CENTER + `${this.subId}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.CREATE_SUBSCRIPTION_COST_CENTER + `${this.subId}/`
				let costCenter = this.costCenterList.filter(item => item.Code === this.costCenter.cost_center_code)
				if (costCenter.length <= 0) {
					return false
				}
				let payload = {
					costcenter_name: costCenter[0].Description,
					cost_center_code: costCenter[0].Code,
					customer_country: countryCode,
					subscription: this.subId,
				}
				this.$store.dispatch('customer/customPostRequest', {URL: URL, data: payload})
					.then(res => {
						// console.log('posted:', res)
						this.addingCostCenter = false
						this.$services.helpers.notification(this.$t('createdSuccessfully'), 'success', this)
						this.costCenter = {
							customer_country: '',
							cost_center_code: '',

						}
						window.$(el).val("").trigger('change')
						this.getSubscriptionCostCenter(this.subId, this.app_type, this.acc)
					}).catch((err) => {
					this.addingPaymentTerms = false
					if (err.response.status === 400) {
						if (err.response.data.code === 401) {
							this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
								.then(_ => {
									this.createCostCenterSubscription()
								})
								.catch(_ => {
									// console.log('error response')
									this.$store.commit('auth/CLEAR_AUTH_USER', null)
									window.Bus.$emit('sign-out')
								})
						}
					}
				})

			}
		},
		performTestSubscription() {
			this.runningTest = true
			const payload = {
				URL: this.GET_USER_COMPANY_ID ? this.$services.endpoints.TEST_SUBSCRIPTION + `${this.subId}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.TEST_SUBSCRIPTION + `${this.subId}/`
			}

			bus.$emit('perform-single-test', payload)

			// const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.TEST_SUBSCRIPTION + `${this.subId}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.TEST_SUBSCRIPTION + `${this.subId}/`
			// // this.$services.axios.defaults.headers.common.Authorization = `Bearer ${this.USER_TOKEN}`
			// // this.$services.axios.get(URL)
			// this.$store.dispatch('customer/performTestSubscription', { URL })
			// .then(res => {
			//     this.runningTest = false
			//     // console.log(res)
			//     if (res.data.success) {
			//         this.$services.helpers.notification(res.data.data, 'success', this)
			//     }
			// }).catch((err) => {
			//     // console.log(err.response)
			//     this.runningTest = false
			//     if (err.response.status === 400) {
			//         if (err.response.data.code === 401) {
			//             this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
			//             .then(_ => {
			//                 // this.getSubscriptionList()
			//             })
			//             .catch(_ => {
			//                 // console.log('error response')
			//                 this.$store.commit('auth/CLEAR_AUTH_USER', null)
			//                 window.Bus.$emit('sign-out')
			//             })
			//         }
			//         this.$services.helpers.notification(err.response.data.data, 'success', this)
			//     }
			// })
		},
		CreateBambooMapping() {
			if (this.absenceType === '' || this.absenceCauseEmployee === '') {
				this.$services.helpers.notification(this.$t('fieldRequired'), 'error', this)
			} else if (this.absenceType === 'absence_types' && this.timeOffValue === '') {
				this.$services.helpers.notification(this.$t('fieldRequired'), 'error', this)
			} else if ((this.absenceType === 'emp_custom_fields' && this.empTable === '') ||
				(this.absenceType === 'emp_custom_fields' && this.empField === '')) {
				this.$services.helpers.notification(this.$t('fieldRequired'), 'error', this)
			} else {
				const obj = {}
				this.addingBamboo = true
				const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.CREATE_BAMBOO_MAPPING + `${this.subId}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.CREATE_BAMBOO_MAPPING + `${this.subId}/`

				if (this.absenceType === 'absence_types') {
					obj.key = this.timeOffValue
					obj.val = this.absenceCauseEmployee
					obj.group_desc = this.absenceType
				} else {
					obj.key = this.empField
					obj.val = this.absenceCauseEmployee
					obj.table = this.empTable
					obj.group_desc = this.absenceType
				}

				// this.$services.axios.defaults.headers.common.Authorization = `Bearer ${this.USER_TOKEN}`
				// this.$services.axios.post(URL, obj)
				this.$store.dispatch('customer/CreateBambooMapping', {URL, obj})
					.then(res => {
						this.addingBamboo = false
						if (res.data.success) {
							this.$services.helpers.notification(this.$t('bambooCreated'), 'success', this)
							this.getBambooMapData()
						}
					}).catch((err) => {
					this.addingBamboo = false
					if (err.response.status === 400) {
						if (err.response.data.code === 401) {
							this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
								.then(_ => {
								})
								.catch(_ => {
									// console.log('error response')
									this.$store.commit('auth/CLEAR_AUTH_USER', null)
									window.Bus.$emit('sign-out')
								})
						}
						this.$services.helpers.notification(err.response.data.data, 'error', this)
					}
				})

			}
		},
		deleteBambooData(id) {
			this.$services.helpers.notification(this.$t('pleaseWait'), 'progress', this)
			const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.DELETE_BAMBOO_DATA + `${this.subId}/${id}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.DELETE_BAMBOO_DATA + `${this.subId}/${id}/`
			// this.$services.axios.defaults.headers.common.Authorization = `Bearer ${this.USER_TOKEN}`
			// this.$services.axios.delete(URL)
			this.$store.dispatch('customer/deleteBambooData', {URL})
				.then(res => {
					bus.$emit('hide-notify-me', '')
					if (res.status === 204) {
						this.$services.helpers.notification(this.$t('bambooDeleted'), 'success', this)
						this.getBambooMapData(id)
					}
				}).catch((err) => {
				bus.$emit('hide-notify-me', '')
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								// this.getSubscriptionList()
							})
							.catch(_ => {
								// console.log('error response')
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})

		},
		getBambooType() {
			// this.$services.axios.defaults.headers.common.Authorization = `Bearer ${this.USER_TOKEN}`
			// this.$services.axios.get(this.$services.endpoints.GET_BAMBOOHR_TYPES)
			this.$store.dispatch('customer/getBambooType', {URL: this.$services.endpoints.GET_BAMBOOHR_TYPES})
				.then(res => {
					// console.log('TYp:', res)
					if (res.status === 200) {
						this.bambooHRType = res.data.data
					}
				}).catch((err) => {
				// this.showCustomAccount = false
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								this.getBambooType()
							})
							.catch(_ => {
								// console.log('here')
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		getBambooFortnoxCause() {
			// this.$services.axios.defaults.headers.common.Authorization = `Bearer ${this.USER_TOKEN}`
			// this.$services.axios.get(this.$services.endpoints.GET_FORTNOX_CAUSE_CODE)
			this.$store.dispatch('customer/getBambooFortnoxCause', {URL: this.$services.endpoints.GET_FORTNOX_CAUSE_CODE})
				.then(res => {
					// console.log('fortnox cause:', res)
					if (res.status === 200) {
						this.bambooFortnoxCause = res.data.data
					}
				}).catch((err) => {
				// this.showCustomAccount = false
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								this.getBambooFortnoxCause()
							})
							.catch(_ => {
								// console.log('here')
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		getBambooTimeOffs() {
			// this.$services.axios.defaults.headers.common.Authorization = `Bearer ${this.USER_TOKEN}`
			// this.$services.axios.get(this.$services.endpoints.GET_BAMBOOHR_TIME_OFFS + `${this.subId}/`)
			this.$store.dispatch('customer/getBambooTimeOffs', {URL: this.$services.endpoints.GET_BAMBOOHR_TIME_OFFS + `${this.subId}/`})
				.then(res => {
					// console.log('timeoffs:', res)
					if (res.status === 200) {
						this.bambooTimeOff = res.data.data
					}
				}).catch((err) => {
				// this.showCustomAccount = false
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								this.getBambooTimeOffs()
							})
							.catch(_ => {
								// console.log('here')
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		getBambooEmpField() {
			// this.$services.axios.defaults.headers.common.Authorization = `Bearer ${this.USER_TOKEN}`
			// this.$services.axios.get(this.$services.endpoints.GET_BAMBOOHR_EMP_FIELDS)
			this.$store.dispatch('customer/getBambooEmpField', {URL: this.$services.endpoints.GET_BAMBOOHR_EMP_FIELDS})
				.then(res => {
					// console.log('emp field:', res)
					if (res.status === 200) {
						this.bambooEmpField = res.data.data
					}
				}).catch((err) => {
				// this.showCustomAccount = false
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								this.getBambooEmpField()
							})
							.catch(_ => {
								// console.log('here')
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		getBambooMapData() {
			// this.$services.axios.defaults.headers.common.Authorization = `Bearer ${this.USER_TOKEN}`
			// this.$services.axios.get(this.$services.endpoints.GET_BAMBOOHR_MAP_DATA + `${this.subId}/`)
			this.$store.dispatch('customer/getBambooMapData', {URL: this.$services.endpoints.GET_BAMBOOHR_MAP_DATA + `${this.subId}/`})
				.then(res => {
					// console.log('map dta:', res)
					if (res.status === 200) {
						this.bambooMapData = res.data.data
					}
				}).catch((err) => {
				// this.showCustomAccount = false
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								this.getBambooMapData()
							})
							.catch(_ => {
								// console.log('here')
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		uploadSwishFile() {
			let URL
			const file = document.getElementById('swish-file-upload').files
			if (file.length < 1) {
				this.$services.helpers.notification(this.$t('fileRequired'), 'error', this)
			} else {
				this.uploading = true
				const formData = new FormData()
				if (parseInt(this.appType) === APP.ADYEN_PLUS) {
					URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.ADYEN_PLUS_FILE + `${this.subId}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.ADYEN_PLUS_FILE + `${this.subId}/`
					formData.append('adyen_file', file[0])
				}
				if (parseInt(this.appType) === APP.SWISH_HANDEL) {
					URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.SWISH_HANDEL_FILE + `${this.subId}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.SWISH_HANDEL_FILE + `${this.subId}/`
					formData.append('swish_file', file[0])
				}
				window.Bus.$emit('upload-setting-file', {URL, formData, app: this.appType})
			}
		},
		ConnectAmazonLegacy() {
			this.connectLegacy = true
			// const { key, type } = this.$route.query
			// const obj = {
			//     id : key,
			//     app_type : type
			// }
			setTimeout(() => {
				// window.sessionStorage.setItem('vsubs', JSON.stringify(obj))
				this.connectLegacy = false
				window.open(this.linkAmazonLegacy)
			}, 1000)
		},
		connectAmazonMarketPlace() {
			if (document.getElementById('id_region')) {
				this.connectLegacy = true
				let region = document.getElementById('id_region')
				if (['eu-west-1', 'us-east-1', 'us-west-2'].includes(region.value) === true) {
					const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.GET_USER_APP_CONFIG + `${this.subId}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.GET_USER_APP_CONFIG + `${this.subId}/`
					this.$store.dispatch('customer/autoSaveAmazonRegion', {
						URL: URL,
						data: {region: region.value}
					}).then(() => {
						const AMAZON_URL = `${this.$services.endpoints.AMAZON_MARKETPLACE_ENDPOINT}obtain_amazon_auth_url/${this.subId}/`
						this.$store.dispatch('customer/getAmazonAuthUrl', {URL: AMAZON_URL}).then(resp => {
							this.connectLegacy = false
							window.open(resp.data.url, '_blank')
						}).catch(er => {
							this.connectLegacy = false
							this.$services.helpers.notification(er.response.data.message, 'error', this)
						})
					}).catch(e => {
						this.connectLegacy = false
						this.$services.helpers.notification(e.response.data.message, 'error', this)
					})
				} else {
					this.connectLegacy = false
					this.$services.helpers.notification(this.$t('selectRegion'), 'error', this)
				}
			} else {
				this.connectLegacy = false
				this.$services.helpers.notification(this.$t('selectRegion'), 'error', this)
			}

		},
		connectAmazonEU() {
			this.connectEu = true
			// const { key, type } = this.$route.query
			// const obj = {
			//     id : key,
			//     app_type : type
			// }
			setTimeout(() => {
				// window.sessionStorage.setItem('vsubs', JSON.stringify(obj))
				this.connectEu = false
				window.open(this.linkAmazonEU)
			}, 1000)
		},
		fileChange(e) {
			const self = this
			const docType = ['pdf', 'csv', 'vnd.ms-excel', 'vnd.openxmlformats-officedocument.spreadsheetml.sheet']
			const fileType = e.target.files[0].type.split('/')[1]

			if (!docType.includes(fileType)) {
				self.$services.helpers.notification(this.$t('invalidFile'), 'error', this)
				document.getElementById('swish-file-upload').value = ''
			} else {
				this.fileAdded = true
			}
		},
		clearFileField() {
			document.getElementById('swish-file-upload').value = ''
			this.fileAdded = false
		},
		uid(e) {
			if (e.uid) return e.uid
			const key = Math.random()
				.toString(16)
				.slice(2)
			this.$set(e, 'uid', key)
			return e.uid
		},
		clearFormField() {
			this.accType = ''
			this.matchTyp = ''
			this.matchCode = ''
			this.vatValue = ''
			this.acctNumber = ''
			this.ruleType = ''
			// this.onChangeCurrencyCountry('')
			this.onChangeAccountNumber('')
			this.removeValueFromSelectLib()
		},
		removeValueFromSelectLib() {
			window.$('#currency-select, #konto-typ, #match-types, #country-select,#country-select-sek, #account-numbers-select, #vat-type-value').val(null).trigger('change')
		},
		openTab(id) {
			if (id === 1) {
				this.percentage = 30
				this.tabId = id
				// this.color = '#13c2c2'
				window.localStorage.setItem('__settingTab', id)
				document.getElementById('tab-0').classList.add('active')
				document.getElementById('tab-1').classList.remove('active')
				document.getElementById('tab-2').classList.remove('active')
				this.appDescriptionKey = new Date().getMilliseconds()
				window.Bus.$emit('start-appsetting-tab1-tour')
			} else if (id === 2) {
				this.percentage = 65
				this.tabId = id
				// this.color = '#fadb14'
				window.localStorage.setItem('__settingTab', id)
				document.getElementById('tab-0').classList.remove('active')
				document.getElementById('tab-1').classList.add('active')
				document.getElementById('tab-2').classList.remove('active')
				this.appSettingKey = new Date().getMilliseconds()
				window.Bus.$emit('start-appsetting-tab2-tour')
			} else if (id === 3) {
				this.percentage = 100
				this.tabId = id
				// this.color = '#389e0d'
				window.localStorage.setItem('__settingTab', id)
				document.getElementById('tab-0').classList.remove('active')
				document.getElementById('tab-1').classList.remove('active')
				document.getElementById('tab-2').classList.add('active')
				this.appSubscriptionActivate = new Date().getMilliseconds()
				window.Bus.$emit('start-appsetting-tab3-tour')
			}
		},
		openCancelSubscription() {
			// if (this.currentSubscription.length > 0) {
			// 	if (['pro', 'growth', 'enterprise'].includes(this.currentSubscription[0].dedicated_server) === true) {
			// 		window.Bus.$emit('open-cancel-subscription', {
			// 			"key": this.dedicatedServerInformation.currentSubscription[0].id,
			// 			"type": this.appType,
			// 			"acc": this.accountingValue,
			// 			"server": this.currentSubscription[0].dedicated_server,
			// 			"created": this.dedicatedServerInformation.currentSubscription[0].created,
			// 			"fixed_contract": this.dedicatedServerInformation.currentSubscription[0].fixed_contract,
			// 		})
			// 	} else {
			//
			// 	}
			// }
			window.Bus.$emit('open-cancel-subscription', {
				"key": this.subId,
				"type": this.appType,
				"acc": this.accountingValue,
				"server": 'standard'
			})

		},
		getAllStockPoints(type) {
			if (STOCKPOINT_FORTNOX.includes(parseInt(type)) === true) {
				const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.STOCK_POINTS + `${this.subId}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.STOCK_POINTS + `${this.subId}/`
				this.$store.dispatch('customer/customGetRequest', {URL}).then(res => {
					if (res.status === 200) {
						if (res.data.hasOwnProperty('data') && res.data.code === 200) {
							this.stockPointsList = res.data.data
						}
					}
				}).catch(err => {

				})
			}
		},
		addonRequest: function (key, type, acc) {
			this.showReconciliationAppsField(type, acc)
			this.runActiveAccountNumbersFortnox(key, type, acc)
			this.runPaypalSupplierDetails(key, type, acc)
			this.runVismaApps(key, type, acc)
			this.showStripeData(type)
			this.showShopifyData(type)
			this.getSubscriptionCostCenter(key, type, acc)
			this.getCustomAccounts(key)
			this.getV3CustomAccountForm(type)
			this.showBambooTable(type)
			this.showTestOrderButton(type)
			this.runActiveAccountVisma(key, type, acc)
			this.getSubscriptionConfig(key)
			this.showCustomV3Apps(type, acc)

			this.showCostCenterField(type, acc)
			this.getAllStockPoints(type)
			this.runWoocommerceSettings(type, acc, key)
			this.runCustomAccountWidget(type, acc, key)
			this.runCustomPaymentTermWidget(type, acc, key)
			this.runCustomDeliveryTermWidget(type, acc, key)

			this.runAmazonSettings(type, acc, key)

			this.showFileUploader(type)
			this.reconciliationAppField(type)
			this.getOfficerndLocations()
		},
		getOfficerndLocations: function () {
			if (parseInt(this.$route.query.type) === 37) {
				const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.GET_OFFICERND + `${this.subId}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.GET_OFFICERND + `${this.subId}/`
				this.$store.dispatch('customer/customGetRequest', {URL}).then(res => {
					if (res.status === 200) {
						this.officeLocations = res.data.data
					}
				}).catch(err => {

				})
			}

		}
	}
}
</script>

<style lang="scss" scoped>
@import "./../../assets/variables.scss";

.align-box-center {
	width: 80%;
	margin: 0 auto;
}

.font-read {
	font-size: 1.6rem;
	background: #BB3333;
	color: #fff;
	font-weight: 800;
	border-radius: 5px;
	padding: 0.2rem;
}

.font-configure {
	font-size: 1.6rem;
	background: #1A9DD6;
	color: #fff;
	font-weight: 800;
	border-radius: 5px;
	padding: 0.2rem;
}

.font-activate {
	font-size: 1.6rem;
	background: #2FC058;
	color: #fff;
	font-weight: 800;
	border-radius: 5px;
	padding: 0.2rem;
}

.app-theme-gray .main-card > .card-body > .card-title::before {
	background: unset !important;
}

.btn-slider-b {
	color: #fff;
	background-color: #13c2c2;
	border-color: #13c2c2;
}

.tabs-animated .nav-link::before {
	background: #13c2c2 !important;
}

.override-form-ca {
	padding-bottom: 0.2rem !important;
	padding-top: 0.2rem !important;
	font-size: unset !important;
}

.override-form-numbers {
	padding-bottom: 0rem !important;
	padding-top: 0.2rem !important;
	font-size: unset !important;
	height: calc(1.75rem + 2px) !important;
}

// .form-control:focus{
//     // color: #495057;
//     // background-color: #fff;
//     // border-color: #e2e8f0 !important;
//     // outline: 0;
//     // box-shadow: unset !important;
// }
.input-group-append {
	height: calc(1.75rem + 2px);
}

.border-b-theme {
	border-bottom: 5px solid $form-border;
	border-bottom-left-radius: 2px;
	border-bottom-right-radius: 2px;
	// border: 1px solid $form-border !important;
}

.theme-color-tab {
	color: $form-border;
}

.theme-color-sec {
	color: $theme-primary-color !important;
	font-weight: 900;
}

.deep-b-theme {
	border-bottom: 5px solid $theme-primary-color !important;
}

.btn-border-all {
	border: 1px solid $theme-secondary-color !important;
	// color: $theme-secondary-color !important;
}

.btn-full-theme {
	background: $theme-secondary-color !important;
}

.pad-overi {
	padding: .175rem .75rem !important;
}

.form-select, .form-input {
	border: 1px solid $form-border !important;
	height: unset !important;
	padding: .175rem .75rem !important;
}

.form-control:focus {
	box-shadow: unset !important;
	border: 1px solid $theme-primary-color !important;
}

.form-control {
	border: 1px solid $form-border !important;
}

input[type="file"] {
	display: none;
}

.custom-file-upload {
	// border: 1px solid #ccc;
	color: white;
	border-radius: 5px;
	background-color: $theme-primary-color;
	display: inline-block;
	padding: 6px 12px;
	cursor: pointer;
}

table th, .table td {
	padding: 0.2rem !important;
}

.disable-btn {
	background-color: #800080 !important;
	color: #ffff !important;
}

.w-f-100 {
	width: 100% !important;
}

.test-btn {
	background-color: #EE4D81;
	// border-color: #EE4D81;
}

.activate-btn {
	background-color: #0DB23C;
}

.deactivate-btn {
	background-color: #583EF9;
}

.table-responsive::-webkit-scrollbar {
	display: none !important; /* width of the entire scrollbar */
}

.table-responsive::-webkit-scrollbar-track {
	background: $form-border; /* color of the tracking area */
}

.table th, .table td {
	border-top: unset !important;
}

.cancel-btn, .cancel-btn:hover {
	background: #10a4c2 !important;
	color: #ffffff
}

.c-btn {
	font-size: 15px !important;
}

.app-theme-gray .main-card {
	box-shadow: 0 0 0 0 transparent !important;
}

.pl-1, .px-1 {
	padding-left: 0.25rem !important;
}

.bg-white {
	background-color: #fff !important;
}

.color-text {
	color: #61696d !important;
	font-weight: 300 !important;
}
</style>
