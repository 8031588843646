<template>
	<div>
		<span
			class="text-gray-500 text-base mt-4 pl-3">{{ $t('paymentMappingSetting') }} <span
			class="ml-1 tooltip-settings"><i
			class="text-sm help-color fa fa-question-circle"></i><span
			class="w-96 tooltiptext text-xs shadow-sm border word-breaking">Här har ni möjlighet att mappa specifika betalleverantörer era kunder betalar med i webbutiken med betalningsvillkor i ekonomiprogrammet. Detta innebär att specifika betalningsvillkor kommer att anges i kundfakturan i ekonomiprogrammet beroende på vilken betalleverantör ordern i fråga betalats via. Med denna funktion kan ni enkelt skilja på vilka ordrar/fakturor som betalats med respektive betalväxel</span></span></span>
		<div class="border col-md-10 rounded mb-8 mt-1 p-3">
			<div class="row">
				<div class="col-md-12 rounded p-3 mb-3">
					<form @submit.prevent="createPaymentTermSubscription">
						<div class="flex flex-col md:flex-row">
							<div class="col-sm-4">
								<label for="kontotyp"
									   class="text-gray-600 font-semibold whitespace-nowrap">Kod
									för Betalvillkor <span class="ml-1 tooltip-settings"><i
										class="text-sm help-color fa fa-question-circle"></i><span
										class="w-60 tooltiptext text-xs shadow-sm border word-breaking">Ange koden för betalningsvillkoret i ekonomiprogrammet som ni vill använda, t.ex. KL.</span></span></label><br>
								<select v-model="paymentTcode" id="payment-terms-code"
										class="form-select override-form-ca w-full" required>
									<option :value="term.Code" v-for="(term, i) in paymentTerms"
											:key="i">{{ term.Description }}
									</option>
								</select>
							</div>
							<div class="col-sm-4">
								<label
									class="text-gray-600 font-semibold whitespace-nowrap">Betalleverantör
									<span class="ml-1 tooltip-settings"><i
										class="text-sm help-color fa fa-question-circle"></i><span
										class="w-60 tooltiptext text-xs shadow-sm border word-breaking">Ange betalleverantören ni vill mappa till ovan betalningsvillkor, t.ex. Klarna.</span></span></label><br>
								<select v-model="paymentProvider" id="payment-provider-key"
										class="form-select override-form-ca w-full" required>
									<!--@change="switchType"-->
									<option :value="type[1]" v-for="(type, i) in providerPaym"
											:key="i">
										{{ type[1] }}
									</option>
								</select>
							</div>
							<!-- <div class="col-md-3"></div> -->
							<div class="col-sm-3">
								<label for="kontotyp" class="mb-3 mt-1"></label><br>
								<button
									class="btn hover:text-white text-base btn-custom pad-overi h-8"
									:disabled='addingPaymentTerms'>
                                                        <span v-if="addingPaymentTerms">
                                                            <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
																 xmlns="http://www.w3.org/2000/svg" fill="none"
																 viewBox="0 0 24 24">
                                                            <circle class="opacity-25" cx="12" cy="12" r="10"
																	stroke="currentColor" stroke-width="4"></circle>
                                                            <path class="opacity-75" fill="currentColor"
																  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                            </svg>
                                                        </span>
									<span v-else class="text-xs relative -top-1">
                                                            Spara
                                                        </span>
								</button>
							</div>
						</div>
					</form>
				</div>
				<div class="col-md-10 rounded">
					<div class="pl-3">
						<div class="table-responsive">
							<table style="width: 100%;" id="exampledestroy" class="table">
								<thead class="hidden">
								<tr>
									<th style="width:10%" class="text-xs">Betalleverantör</th>
									<th style="width:10%" class="text-xs">Betalvillkor</th>
									<th style="width:10%" class="text-xs">{{ $t('action') }}</th>
								</tr>
								</thead>
								<tbody>
								<tr v-for="payment in paymentTermsArray" :key="payment.id">
									<td class="text-xs">
															<span>{{
																	getAccountingGateway(paymentTerms, payment)
																}}</span>
									</td>
									<td class="text-xs">
										<span>{{ payment.gateway_in_app }}</span>
									</td>

									<td class="text-xs">
														<span @click="deletePaymentTermSubscription(payment.id)"
															  class="text-base cursor-pointer iconly-brokenDelete icon-color"></span>
									</td>
								</tr>
								</tbody>
							</table>
						</div>
					</div>

				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {mapGetters} from "vuex";
import bus from "../../../bus";

export default {
	name: "deliveryMappingComponent",
	data: function () {
		return {
			paymentTcode: '',
			paymentProvider: '',
			paymentTerms: [],
			providerPaym: [],
			paymentTermsArray: [],
			addingPaymentTerms: false
		}
	},
	methods: {
		getAccountingGateway(arrayValue, value) {
			if (arrayValue.length > 0) {
				const filterArray = arrayValue.filter(item => item.Code === value.gateway_in_accounting)
				if (filterArray.length > 0) {
					return filterArray[0].Description
				}
			}
		},
		createPaymentTermSubscription() {
			if (this.paymentTcode === '' || this.paymentProvider === '') {
				this.$services.helpers.notification(this.$t('fieldRequired'), 'error', this)
			} else {
				this.addingPaymentTerms = true
				const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.CREATE_PAYMENT_TERM_SUBSCRIPTION + `${this.$route.query.key}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.CREATE_PAYMENT_TERM_SUBSCRIPTION + `${this.$route.query.key}/`
				// this.$services.axios.defaults.headers.common.Authorization = `Bearer ${this.USER_TOKEN}`
				// this.$services.axios.post(URL, {
				//     })
				const payload = {
					gateway_in_app: this.paymentProvider,
					gateway_in_accounting: this.paymentTcode,
				}
				this.$store.dispatch('customer/createPaymentTermSubscription', {URL, dataObject: payload})
					.then(res => {
						// console.log('posted:', res)
						this.addingPaymentTerms = false
						if (res.data.success) {
							this.$services.helpers.notification(this.$t('createdPayTermSubscription'), 'success', this)

							this.getPaymentTermSubscription(this.$route.query.key)
						}
					}).catch((err) => {
					this.addingPaymentTerms = false
					if (err.response.status === 400) {
						if (err.response.data.code === 401) {
							this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
								.then(_ => {
									// this.getSubscriptionList()
								})
								.catch(_ => {
									// console.log('error response')
									this.$store.commit('auth/CLEAR_AUTH_USER', null)
									window.Bus.$emit('sign-out')
								})
						}
					}
				})

			}
		},
		getPaymentTermSubscription(key) {
			const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.GET_PAYMENT_TERMS_SUBSCRIPTION + `${key}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.GET_PAYMENT_TERMS_SUBSCRIPTION + `${key}/`
			this.$store.dispatch('customer/getPaymentTermSubscription', {URL})
				.then(res => {
					this.paymentTermsArray = res.data.data
				}).catch((err) => {
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								this.getPaymentTermSubscription(key)
							})
							.catch(_ => {
								// console.log('error response')
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		getPaymentProvider() {
			const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.PAYMENT_PROVIDER : this.$services.endpoints.PAYMENT_PROVIDER
			// this.$services.axios.defaults.headers.common.Authorization = `Bearer ${this.USER_TOKEN}`
			// this.$services.axios.get(URL)
			this.$store.dispatch('customer/getPaymentProvider', {URL})
				.then(res => {
					// console.log('PAYMENT provider::::::', res)
					if (res.data.success) {
						this.providerPaym = res.data.data
						// console.log('DDDD:', res.data.data[0][1])
					}
				}).catch((err) => {
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								this.getPaymentProvider()
							})
							.catch(_ => {
								// console.log('error response')
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
			let el = document.getElementById('payment-provider-key')
			let self = this
			if (el) {
				window.$(el).select2({
					width: 300
				})
				window.$(el).val("").trigger('change')
				window.$('#payment-provider-key').on("select2:select", function (e) {
					self.paymentProvider = e.target.value
				});
			}
		},
		getPaymentTermOption(key) {
			const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.GET_PAYMENT_TERM_OPTIONS + `${key}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.GET_PAYMENT_TERM_OPTIONS + `${key}/`
			this.$store.dispatch('customer/getPaymentTermOption', {URL})
				.then(res => {
					if (res.data.success && res.data.data) {
						this.paymentTerms = res.data.data
					}
				}).catch((err) => {
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								// this.getPaymentTermOption(key)
							})
							.catch(_ => {
								// console.log('error response')
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
			let el = document.getElementById('payment-terms-code')
			let self = this
			if (el) {
				window.$(el).select2({
					width: 300
				})
				window.$(el).val("").trigger('change')
				window.$('#payment-terms-code').on("select2:select", function (e) {
					self.paymentTcode = e.target.value
				});
			}
		},
		deletePaymentTermSubscription(id) {
			const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.DELETE_PAYMENT_TERMS_SUBSCRIPTION + `${this.$route.query.key}/${id}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.DELETE_PAYMENT_TERMS_SUBSCRIPTION + `${this.$route.query.key}/${id}/`
			this.$store.dispatch('customer/deletePaymentTermSubscription', {URL})
				.then(res => {
					bus.$emit('hide-notify-me', '')
					if (res.status === 204) {
						this.$services.helpers.notification(this.$t('deletedPayTermSubscription'), 'success', this)

						this.getPaymentTermSubscription(this.$route.query.key)
					}
				}).catch((err) => {
				bus.$emit('hide-notify-me', '')
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								this.getSubscriptionList()
							})
							.catch(_ => {
								// console.log('error response')
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})

		},
	},
	mounted() {
		this.getPaymentTermSubscription(this.$route.query.key)
		this.getPaymentTermOption(this.$route.query.key)
		this.getPaymentProvider()
	},
	computed: {
		...mapGetters({
			USER_TOKEN: 'auth/USER_TOKEN',
			USER_REFRESH: 'auth/USER_REFRESH',
			GET_LOGIN_CREDENTIAL: 'auth/GET_LOGIN_CREDENTIAL',
			GET_USER_COMPANY_ID: 'customer/GET_USER_COMPANY_ID',
			GET_COMPANY_BILLING: 'customer/GET_COMPANY_BILLING',
			GET_SUBSCRIPTION: 'customer/GET_SUBSCRIPTION',
			GET_V3_APP: 'customer/GET_V3_APP',
			GET_LOCALE: 'utility/GET_LOCALE_LANG'
		})
	},
}
</script>

<style scoped>

</style>
