<template>
	<div>
	<span
		class="text-gray-500 text-base pl-3">{{ $t('deliverySetting') }} <span
		class="ml-1 tooltip-settings"><i
		class="text-sm help-color fa fa-question-circle"></i><span
		class="w-60 tooltiptext text-xs shadow-sm border word-breaking">Här har ni möjlighet att mappa leveranssätt i webbutiken med förvalda leveransvillkor i ekonomiprogrammet.</span></span></span>
		<div class="border col-md-10 rounded mb-8 mt-1 p-3">
			<div class="row">
				<div class="col-md-12 rounded p-3 mb-3">
					<form @submit.prevent="createWayDeliverySubscription">
						<div class="flex flex-col md:flex-row items-center">
							<div class="col-sm-4">
								<label
									class="text-gray-600 font-semibold whitespace-pre-line">Webbutikens
									leveransvillkor <span class="ml-1 tooltip-settings"><i
										class="text-sm help-color fa fa-question-circle"></i><span
										class="w-60 tooltiptext text-xs shadow-sm border word-breaking">Skriv in titeln på det leveranssätt i webbutiken som ni vill mappa till ett leveransvillkor i ekonomiprogrammet.</span></span></label><br>
								<input v-model="storeTitle" type="text"
									   class="form-input override-form-ca w-full h-8 border border-pink-500" required>
							</div>
							<div class="col-sm-4">
								<label
									class="text-gray-600 font-semibold whitespace-pre-line">Fortnox
									Leveransvillkor <span class="ml-1 tooltip-settings"><i
										class="text-sm help-color fa fa-question-circle"></i><span
										class="w-60 tooltiptext text-xs shadow-sm border word-breaking">Välj från rullistan det leveransvillkor ni vill använda i ekonomiprogrammet.</span></span></label><br>
								<select v-model="wayDoption" ref='optionDelivery'
										id='optionDelivery'
										class="form-select override-form-ca w-full" required>
									<option :value="delivery.Code + '*' + delivery.Description"
											v-for="(delivery, i) in waysOfDeliveryOption"
											:key="i">
										{{ delivery.Description }}
									</option>
								</select>
							</div>
							<div class="col-sm-3">
								<label for="kontotyp" class="mb-3 mt-3"></label><br>
								<button
									class="btn hover:text-white text-base btn-custom pad-overi h-8"
									:disabled='addingDeliveryWay'>
                                                        <span v-if="addingDeliveryWay">
                                                            <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
																 xmlns="http://www.w3.org/2000/svg" fill="none"
																 viewBox="0 0 24 24">
                                                            <circle class="opacity-25" cx="12" cy="12" r="10"
																	stroke="currentColor" stroke-width="4"></circle>
                                                            <path class="opacity-75" fill="currentColor"
																  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                            </svg>
                                                        </span>
									<span v-else class="relative -top-1 text-xs">
                                                            Spara
                                                        </span>
								</button>
							</div>
						</div>
					</form>
				</div>
				<div class="col-md-8">
					<div class="table-responsive">
						<table style="width: 100%;" id="exampledestroy" class="table">
							<thead class="hidden">
							<tr>
								<th style="width:33%" class="text-xs">Betalvillkor</th>
								<th style="width:33%" class="text-xs">Betalleverantör</th>
								<th style="width:33%" class="text-xs">{{ $t('action') }}</th>
							</tr>
							</thead>
							<tbody>
							<tr v-for="delivery in wayOfDeliveryArray" :key="delivery.id">
								<td class="text-xs">{{ delivery.title }}</td>
								<td class="text-xs">{{ delivery.match_description }}</td>
								<td class="text-xs">
														<span @click="deleteWaysOfDeliverySubscription(delivery.id)"
															  class="text-base cursor-pointer iconly-brokenDelete icon-color"></span>
								</td>
							</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import bus from "../../../bus";
import moment from "moment";
import {mapGetters} from "vuex";

export default {
	name: "deliveryMappingComponent",
	data: function () {
		return {
			storeTitle: '',
			wayDoption: '',
			addingDeliveryWay: false,
			wayOfDeliveryArray: [],
			waysOfDeliveryOption: [],
		}
	},
	methods: {
		createWayDeliverySubscription() {
			if (this.wayDoption === '' || this.storeTitle === '') {
				this.$services.helpers.notification(this.$t('fieldRequired'), 'error', this)
			} else if (this.wayDoption.length > 50 || this.storeTitle.length > 50) {
				this.$services.helpers.notification(this.$t('fieldValue'), 'error', this)
			} else {
				this.addingDeliveryWay = true
				const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.CREATE_WAY_OF_DELIVERY_SUBSCRIPTION + `${this.$route.query.key}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.CREATE_WAY_OF_DELIVERY_SUBSCRIPTION + `${this.$route.query.key}/`
				const payload = {
					title: this.storeTitle,
					match_code: this.wayDoption.split('*')[0].trim(),
					match_description: this.wayDoption.split('*')[1].trim()
				}
				this.$store.dispatch('customer/createWayDeliverySubscription', {URL, dataObject: payload})
					.then(res => {
						this.clearWayDeliveryForm()
						this.addingDeliveryWay = false
						if (res.data.success) {
							this.$services.helpers.notification(this.$t('createdDeliverySubscription'), 'success', this)

							this.getWaysOfDeliverySubscription(this.$route.query.key)
						}
					}).catch((err) => {
					this.addingDeliveryWay = false
					if (err.response.status === 400) {
						if (err.response.data.code === 401) {
							this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
								.then(_ => {
								})
								.catch(_ => {
									// console.log('error response')
									this.$store.commit('auth/CLEAR_AUTH_USER', null)
									window.Bus.$emit('sign-out')
								})
						}
					}
				})

			}
		},
		clearWayDeliveryForm() {
			this.wayDoption = ''
			this.storeTitle = ''
			window.$('#optionDelivery').val("").trigger('change')
		},
		deleteWaysOfDeliverySubscription(id) {
			this.$services.helpers.notification(this.$t('pleaseWait'), 'progress', this)
			const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.DELETE_WAYS_OF_DELIVERY_SUBSCRIPTION + `${this.$route.query.key}/${id}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.DELETE_WAYS_OF_DELIVERY_SUBSCRIPTION + `${this.$route.query.key}/${id}/`
			this.$store.dispatch('customer/deleteWaysOfDeliverySubscription', {URL})
				.then(res => {
					bus.$emit('hide-notify-me', '')
					if (res.status === 204) {
						this.$services.helpers.notification(this.$t('deletedDeliverySubscription'), 'success', this)

						this.getWaysOfDeliverySubscription(this.$route.query.key)
					}
				}).catch((err) => {
				bus.$emit('hide-notify-me', '')
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								// this.getSubscriptionList()
							})
							.catch(_ => {
								// console.log('error response')
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})

		},
		getWaysOfDeliveryOption(key) {
			const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.GET_WAYS_OF_DELIVERY_OPTIONS + `${key}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.GET_WAYS_OF_DELIVERY_OPTIONS + `${key}/`
			this.$store.dispatch('customer/getWaysOfDeliveryOption', {URL})
				.then(res => {
					if (res.data.success) {
						this.waysOfDeliveryOption = res.data.data
					}
				}).catch((err) => {
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								this.getWaysOfDeliveryOption(key)
							})
							.catch(_ => {
								// console.log('error response')
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
			let el = document.getElementById('optionDelivery')
			let self = this
			if (el) {
				window.$(el).select2({
					width: 300
				})
				window.$(el).val("").trigger('change')
				window.$('#optionDelivery').on("select2:select", function (e) {
					self.wayDoption = e.target.value
				});
			}

		},
		getWaysOfDeliverySubscription(key) {
			const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.GET_WAYS_OF_DELIVERY_SUBSCRIPTION + `${key}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.GET_WAYS_OF_DELIVERY_SUBSCRIPTION + `${key}/`
			this.$store.dispatch('customer/getWaysOfDeliverySubscription', {URL})
				.then(res => {
					if (res.data.success) {
						this.wayOfDeliveryArray = res.data.data
					}
				}).catch((err) => {
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								this.getWaysOfDeliverySubscription(key)
							})
							.catch(_ => {
								// console.log('error response')
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		getTermOfDeliveryOption(key) {
			const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.GET_TERMS_OF_DELIVERY_OPTIONS + `${key}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.GET_TERMS_OF_DELIVERY_OPTIONS + `${key}/`
			this.$store.dispatch('customer/getTermOfDeliveryOption', {URL})
				.then(res => {
					// console.log('DSTERMS::::::', res)

				}).catch((err) => {
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								this.getTermOfDeliveryOption(key)
							})
							.catch(_ => {
								// console.log('error response')
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
	},
	mounted() {
		this.getWaysOfDeliveryOption(this.$route.query.key)
		this.getTermOfDeliveryOption(this.$route.query.key)
		this.getWaysOfDeliverySubscription(this.$route.query.key)
	},
	computed: {
		...mapGetters({
			USER_TOKEN: 'auth/USER_TOKEN',
			USER_REFRESH: 'auth/USER_REFRESH',
			GET_LOGIN_CREDENTIAL: 'auth/GET_LOGIN_CREDENTIAL',
			GET_USER_COMPANY_ID: 'customer/GET_USER_COMPANY_ID',
			GET_COMPANY_BILLING: 'customer/GET_COMPANY_BILLING',
			GET_SUBSCRIPTION: 'customer/GET_SUBSCRIPTION',
			GET_V3_APP: 'customer/GET_V3_APP',
			GET_LOCALE: 'utility/GET_LOCALE_LANG'
		})
	},
}
</script>

<style lang="scss">
@import "./../../../assets/variables.scss";

.form-select, .form-input {
	border: 1px solid $form-border !important;
	height: unset !important;
	padding: .175rem .75rem !important;
}

.form-control:focus {
	box-shadow: unset !important;
	border: 1px solid $theme-primary-color !important;
}

.form-control {
	border: 1px solid $form-border !important;
}

</style>
