<template>
  <div>
    <select ref='select' class="w-[100px]">
      <slot></slot>
    </select>
  </div>
</template>

<script>
import Vue from "vue"

export default {
  name: "select2Component",
  props: {
    options: {
      type: Array
    },
    value: {
      type: String
    },
    width: {
      default: '300'
    },
    optionType: {
      type: String,
      default: 'article'
    }
  },
  data: function () {
    return {
      optionsList: []
    }
  },
  mounted: function () {
    try {
      this.setUpSelect(this.options)
    } catch (e) {
    }

  },
  methods: {
    setUpSelect: function (options) {
      let vm = this;

      this.optionsList = options
      if (this.optionType === 'article') {
        this.optionsList.map(item => {
          item.text = [1, 2].includes(parseInt(vm.$route.query.acc)) === true ? item.Description : item.Number + ' ' + item.Description
          item.id = item.Number
        })
      } else if (this.optionType === 'bank-account') {
        this.optionsList.map(item => {
          item.text = [1, 2].includes(parseInt(vm.$route.query.acc)) === true ? item.Name : item.Number + ' ' + item.Description
          item.id = item.Id
        })
      }


      $(this.$refs.select)
          .select2({
            data: this.optionsList,
            createTag: function (params) {
              return {
                id: params.id,
                text: params.text,
                newOption: true
              }
            },
            templateResult: function (data) {
              var $result = $("<span></span>");
              $result.text(data.text);
              if (data.newOption) {
                $result.append(" <em>(new)</em>");
              }
              return $result;
            },
            width: vm.width + 'px'
          })
          .on('change', function (ev, args) {
            if (!(args && "ignore" in args)) {
              vm.$emit('input', $(this).val());
            }
          });
      Vue.nextTick(() => {
        $(this.$refs.select)
            .val(this.value)
            .trigger('change', {ignore: true})
      });
    }
  },
  watch: {
    value: function (value, oldValue) {
      // update value
      $(this.$refs.select)
          .val(this.value)
          .trigger('change', {ignore: true});
    },
    options: function (options) {
      // update options
      this.setUpSelect(options)
      // $(this.$refs.select).select2({data: options})
    }
  },
  destroyed: function () {
    $(this.$refs.select).off().select2('destroy')
  }
}
</script>

<style scoped>
.select2-container--default {
  min-width: 300px;
  max-width: 500px;
}
</style>
