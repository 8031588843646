<template>
	<div class="col-md-12 rounded mb-8 mt-1 py-3 pl-0 pr-0">
								<span
									class="text-gray-500 text-base mt-4 pl-3">{{ $t('costCenterMapping') }} <span
									class="ml-1 tooltip-settings"><i
									class="text-sm help-color fa fa-question-circle"></i><span
									class="w-96 tooltiptext text-xs shadow-sm border word-breaking">{{
										$t('costCenterMappingHelpText')
									}}</span></span></span>
		<div class="border col-md-10 rounded mb-8 mt-1 md:pr-3 p-2">
			<div class="row">
				<div class="col-md-12 rounded p-3 mb-3">
					<form @submit.prevent="createCostCenterSubscription">
						<div class="flex flex-col md:flex-row">
							<div class="col-sm-4">
								<label for="cost_center_code"
									   class="text-gray-600 font-semibold whitespace-nowrap">{{
										$t('cost_center')
									}} <span class="ml-1 tooltip-settings"><i
										class="text-sm help-color fa fa-question-circle"></i><span
										class="w-60 tooltiptext text-xs shadow-sm border word-breaking">{{
											$t('costCenterHelpText')
										}}</span></span></label><br>
								<select v-model="costCenter.cost_center_code"
										class="form-select cost-center override-form-ca w-full"
										id="cost_center_code"
										required>
									<option :value="cc.Code" v-for="(cc, i) in costCenterList"
											:key="i">{{ cc.Description }}
									</option>
								</select>
							</div>
							<div class="col-sm-4">
								<label
									class="text-gray-600 font-semibold whitespace-nowrap">Land
									<span class="ml-1 tooltip-settings"><i
										class="text-sm help-color fa fa-question-circle"></i><span
										class="w-60 tooltiptext text-xs shadow-sm border word-breaking">{{
											$t('landHelpText')
										}}</span></span></label><br>
								<select v-model="costCenter.customer_country"
										id="cost-center-customer_country"
										class="form-select override-form-ca w-full">
									<option :value="country[0]"
											v-for="(country, i) in countries"
											:key="i">{{ country[1] }}
									</option>
								</select>
							</div>
							<!-- <div class="col-md-3"></div> -->
							<div class="col-sm-3">
								<label class="mb-3 mt-1"></label><br>
								<button
									class="btn hover:text-white text-base btn-custom pad-overi h-8"
									:disabled='addingCostCenter'>
                                                        <span v-if="addingCostCenter">
                                                            <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
																 xmlns="http://www.w3.org/2000/svg" fill="none"
																 viewBox="0 0 24 24">
                                                            <circle class="opacity-25" cx="12" cy="12" r="10"
																	stroke="currentColor" stroke-width="4"></circle>
                                                            <path class="opacity-75" fill="currentColor"
																  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                            </svg>
                                                        </span>
									<span v-else class="relative -top-1 text-xs">
                                                            Spara
                                                        </span>
								</button>
							</div>
						</div>
					</form>
				</div>
				<div class="col-md-8 rounded">
					<div class="table-responsive" style="    padding-left: 12px;">
						<table style="width: 100%;" id="exampledestroy" class="table">
							<thead class="hidden">
							<tr>
								<th style="width:10%" class="text-xs">{{
										$t('cost_center')
									}}
								</th>
								<th style="width:10%" class="text-xs">Betalleverantör</th>
								<th style="width:10%" class="text-xs">{{ $t('action') }}</th>
							</tr>
							</thead>
							<tbody>
							<tr v-for="cc in costCenterDbList" :key="cc.id">
								<td class="text-xs">
									<span>{{ cc.costcenter_name }}</span>
								</td>
								<td class="text-xs">
									<span>{{ getCountryName(cc.customer_country) }}</span>
								</td>
								<td class="text-xs">
														<span @click="deleteCostCenterSubscription(cc.id)"
															  class="text-base cursor-pointer iconly-brokenDelete icon-color"></span>
								</td>
							</tr>
							</tbody>
						</table>
					</div>

				</div>
			</div>

		</div>

	</div>
</template>

<script>
import {checkIfExist, getDataLocalStorage, saveDataLocalStorage} from "../../../services/storage-window";
import {ACCOUNT_TYPE, COST_CENTER_LIST} from "../../../app/index";
import {mapGetters} from "vuex";

export default {
	name: "costCenterComponent",
	data: function () {
		return {
			costCenter: {
				cost_center_code: '',
				customer_country: ''
			},
			costCenterList: [],
			costCenterDbList: [],
			addingCostCenter: false,
			countries: [],
			allCountries: [],
		}
	},
	methods: {
		createCostCenterSubscription() {
			if (this.costCenter.cost_center_code === '' && this.costCenter.customer_country) {
				this.$services.helpers.notification(this.$t('fieldRequired'), 'error', this)
			} else {
				this.addingCostCenter = true
				const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.CREATE_SUBSCRIPTION_COST_CENTER + `${this.$route.query.key}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.CREATE_SUBSCRIPTION_COST_CENTER + `${this.$route.query.key}/`
				let costCenter = this.costCenterList.filter(item => item.Code === this.costCenter.cost_center_code)
				if (costCenter.length <= 0) {
					this.$services.helpers.notification(this.$t('selectedCostCenterDoesNotExist'), 'error', this)
					return false
				}
				let payload = {
					costcenter_name: costCenter[0].Description,
					cost_center_code: costCenter[0].Code,
					customer_country: this.costCenter.customer_country,
					subscription: this.$route.query.key,
				}
				this.$store.dispatch('customer/customPostRequest', {URL: URL, data: payload})
					.then(res => {
						// console.log('posted:', res)
						this.addingCostCenter = false
						this.$services.helpers.notification(this.$t('createdSuccessfully'), 'success', this)
						this.costCenter = {
							customer_country: '',
							cost_center_code: '',

						}
						window.$('#cost-center-customer_country').val("").trigger('change')
						window.$('#cost_center_code').val("").trigger('change')
						this.getSubscriptionCostCenter(this.$route.query.key, this.$route.query.type, this.$route.query.acc)
					}).catch((err) => {
					this.addingCostCenter = false
					if (err.response.status === 400) {
						if (err.response.data.code === 401) {
							this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
								.then(_ => {
									// this.createCostCenterSubscription()
								})
								.catch(_ => {
									// console.log('error response')
									this.$store.commit('auth/CLEAR_AUTH_USER', null)
									window.Bus.$emit('sign-out')
								})
						}
					}
				})

			}
		},
		deleteCostCenterSubscription(id) {
			const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.DELETE_PAYMENT_TERMS_SUBSCRIPTION + `${this.$route.query.key}/${id}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.DELETE_SUBSCRIPTION_COST_CENTER + `${this.$route.query.key}/${id}/`

			this.$store.dispatch('customer/customDeleteRequest', {URL})
				.then(res => {
					this.$services.helpers.notification(this.$t('deletedSuccessfully'), 'success', this)
					this.getSubscriptionCostCenter(this.$route.query.key, this.$route.query.type, this.$route.query.acc)
				}).catch((err) => {
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								this.deleteCostCenterSubscription(id)
							})
							.catch(_ => {
								// console.log('error response')
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})

		},
		getCountryName(countryCode) {
			let country = this.countries.filter(item => item.includes(countryCode))
			if (country.length > 0) {
				return country[0][1]
			} else {
				return ''
			}
		},
		getCountry() {
			let country_key = 'country_key'

			if (checkIfExist(country_key) === true) {
				this.countries = JSON.parse(getDataLocalStorage(country_key)).data
			} else {
				this.$store.dispatch('customer/getCountry', {URL: this.$services.endpoints.GET_COUNTRIES})
					.then(res => {
						// console.log('countries:', res)
						if (res.status === 200) {
							this.countries = res.data.data
							this.allCountries = res.data.data
							saveDataLocalStorage(country_key, JSON.stringify({data: res.data.data}))
						}
					}).catch((err) => {
					// this.showCustomAccount = false
					if (err.response.status === 400) {
						if (err.response.data.code === 401) {
							this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
								.then(_ => {
									// this.getCountry()
								})
								.catch(_ => {
									// console.log('here')
									this.$store.commit('auth/CLEAR_AUTH_USER', null)
									window.Bus.$emit('sign-out')
								})
						}
					}
				})
			}
			let el = document.getElementById('cost-center-customer_country')
			let self = this
			if (el) {
				window.$(el).select2({
					width: 300
				})
				window.$(el).val("").trigger('change')
				window.$('#cost-center-customer_country').on("select2:select", function (e) {
					self.costCenter.customer_country = e.target.value
				});
			}
		},
		getSubscriptionCostCenter(subID, type, acc) {
			if (COST_CENTER_LIST.includes(parseInt(type)) && parseInt(acc) === ACCOUNT_TYPE.FORTNOX) {
				const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.GET_SUBSCRIPTION_COST_CENTER + `${subID}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.GET_SUBSCRIPTION_COST_CENTER + `${subID}/`

				this.$store.dispatch('customer/customGetRequest', {URL})
					.then(res => {
						this.costCenterDbList = res.data.data
					}).catch((err) => {
					// this.showCustomAccount = false
					if (err.response.status === 400) {
						if (err.response.data.code === 401) {
							this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
								.then(_ => {
								})
								.catch(_ => {
									// console.log('here')
									this.$store.commit('auth/CLEAR_AUTH_USER', null)
									window.Bus.$emit('sign-out')
								})
						}
					}
				})
			}
		},
		getCostCenter(subID, type, acc) {
			if (COST_CENTER_LIST.includes(parseInt(type)) && parseInt(acc) === ACCOUNT_TYPE.FORTNOX) {
				const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.GET_COST_CENTER + `${subID}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.GET_COST_CENTER + `${subID}/`
				this.$store.dispatch('customer/customGetRequest', {URL})
					.then(res => {
						this.costCenterList = res.data.data
					}).catch((err) => {
					// this.showCustomAccount = false
					if (err.response.status === 400) {
						if (err.response.data.code === 401) {
							this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
								.then(_ => {
									// this.getCostCenter(subID)
								})
								.catch(_ => {
									// console.log('here')
									this.$store.commit('auth/CLEAR_AUTH_USER', null)
									window.Bus.$emit('sign-out')
								})
						}
					}
				})
			}
			let el = document.getElementById('cost_center_code')
			let self = this
			if (el) {
				window.$(el).select2({
					width: 300
				})
				window.$(el).val("").trigger('change')
				window.$('#cost_center_code').on("select2:select", function (e) {
					self.costCenter.cost_center_code = e.target.value
				});
			}
		},
	},
	computed: {
		...mapGetters({
			USER_TOKEN: 'auth/USER_TOKEN',
			USER_REFRESH: 'auth/USER_REFRESH',
			GET_LOGIN_CREDENTIAL: 'auth/GET_LOGIN_CREDENTIAL',
			GET_USER_COMPANY_ID: 'customer/GET_USER_COMPANY_ID',
			GET_COMPANY_BILLING: 'customer/GET_COMPANY_BILLING',
			GET_SUBSCRIPTION: 'customer/GET_SUBSCRIPTION',
			GET_V3_APP: 'customer/GET_V3_APP',
			GET_LOCALE: 'utility/GET_LOCALE_LANG'
		})
	},
	mounted() {
		this.getCountry()
		this.getCostCenter(this.$route.query.key, this.$route.query.type, this.$route.query.acc)
		this.getSubscriptionCostCenter(this.$route.query.key, this.$route.query.type, this.$route.query.acc)
	}
}
</script>

<style scoped>

</style>
